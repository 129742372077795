<div class="progress-container" *ngIf="publish">
    <div class="message" *ngIf="publish.Progress === 0">
        Checking database... This may take a moment.
    </div>
    <div class="message" *ngIf="publish.FileSyncProgress === 0 && publish.Progress === 25">
        Checking files... This may take a moment.
    </div>
    <div class="progress">
        <div class="percentage" *ngIf="publish.Progress > 0">
            {{ publish.Progress }}%</div>
        <div class="progress-bar" *ngIf="publish.Progress > 0"
             [ngStyle]="{ width: publish.Progress + '%' }"></div>
    </div>
</div>