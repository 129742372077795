<div class="controls-container" [ngSwitch]="fieldType">
  <div class="control" *ngSwitchCase="settingFieldTypes.TextBox">
    <input type="text" [ngModel]="formattedValue" (ngModelChange)="change($event)" [maxlength]="maxLength" [disabled]="disabled" />
  </div>
  <div class="control" *ngSwitchCase="settingFieldTypes.NumberBox">
    <input type="number" [ngModel]="formattedValue" (ngModelChange)="change($event)" [max]="maxValue" [min]="minValue" [disabled]="disabled" />
  </div>
  <div class="control" *ngSwitchCase="settingFieldTypes.TextArea">
    <textarea [ngModel]="formattedValue" (ngModelChange)="change($event)" [maxlength]="maxLength" [disabled]="disabled"></textarea>
  </div>
  <div class="control" *ngSwitchCase="settingFieldTypes.CheckBox">
    <input type="checkbox" [ngModel]="formattedValue" (ngModelChange)="change($event)" [disabled]="disabled" />
  </div>
  <div class="control" *ngSwitchCase="settingFieldTypes.DropDown">
    <select [ngModel]="formattedValue" (ngModelChange)="change($event)" [disabled]="disabled">
      <option *ngFor="let option of dropdownOptions" [ngValue]="option">{{option}}</option>
    </select>
  </div>
  <div class="control" *ngSwitchCase="settingFieldTypes.FileUpload">
    <div *ngIf="formattedValue.length" class="flex-row align-center">
      <img *ngFor="let val of formattedValue" [src]="fileBaseUrl + val" />
    </div>
    <div class="btn standard-positive file-input">
      <span class="icon">
        <svg-helper svgName="bracket_arrow_up"></svg-helper>
      </span>
      <span class="label">Upload</span>
      <input type="file" multiple (change)="onFileInput($event)" />
    </div>
  </div>
  <div class="control" *ngSwitchCase="settingFieldTypes.ExpandingSet">
    <expanding-set [expandingSet]="formattedValue" (add)="change(formattedValue)" (delete)="change(formattedValue)"
                   (typedText)="change(formattedValue)" (sort)="change(formattedValue)" [canSort]="canSort">
    </expanding-set>
  </div>
  <div class="control" *ngSwitchCase="settingFieldTypes.ColorPicker">
    <div class="color-picker-section">
      <div class="color-toggle icon" (colorPickerChange)="change($event)" [(colorPicker)]="formattedValue"
           [cpPosition]="'bottom'" [cpFallbackColor]="'#f3b544'"
           [cpOutputFormat]="'hex'">
        <svg-helper svgName="eyedropper"></svg-helper>
      </div>
      <div class="current-color" [style.background]="formattedValue === 'Default' ? '' : formattedValue"></div>
      <input class="hex-color-field" placeholder="Hex Value" type="text" [(ngModel)]="formattedValue"
             (ngModelChange)="change($event)" />
    </div>
  </div>
  <div class="control analyticsConfig" *ngSwitchCase="settingFieldTypes.AnalyticsConfig">
    <div class="is-enabled">
      <input type="checkbox" id="isEnabled" name="isEnabled" [(ngModel)]="analyticsConfig.isEnabled"
             (ngModelChange)="change($event)" /><label>Show Analytics</label>
    </div>
    <div class="url">
      <label>Copy and paste your link below.</label>
      <input id="url" name="url" [(ngModel)]="analyticsConfig.url" (ngModelChange)="change($event)"
             [disabled]="!analyticsConfig.isEnabled" />
    </div>
  </div>
  <div class="control analyticsConfig" *ngSwitchCase="settingFieldTypes.FloorplanSocial">
    <floorplan-specific-social [model]="formattedValue" (onModelChange)="onChildChange($event)"
                               [floorplans]="extraData">
    </floorplan-specific-social>
  </div>
  <div class="control analyticsConfig" *ngSwitchCase="settingFieldTypes.MultiExpandingSet">
    <multi-expanding-set [expandingSet]="formattedValue" [settingsCategoryId]="settingsCategoryId" [enabledProductList]="enabledProductList"
                         (add)="change(formattedValue)" (delete)="change(formattedValue)" (typedText)="change(formattedValue)"
                         (validateSetting)="onValidateSetting($event)">
    </multi-expanding-set>
  </div>
  <div class="control" *ngSwitchCase="settingFieldTypes.Icon">
    <icon-form-control [iconId]="value" (iconIdChange)="change($event)"></icon-form-control>
  </div>
  <div class="control" *ngSwitchCase="settingFieldTypes.ConvergeQuickLinks">
    <quick-link-editor [value]="value" (update)="change($event)"></quick-link-editor>
  </div>
  <div class="control" *ngSwitchCase="settingFieldTypes.AdvancedMedia">
    <advanced-media-editor [value]="value" [replacementMode]="isSetAtHigherTier" (valueUpdate)="onAdvancedMediaValueChange($event)"
                           (fileAdd)="onAdvancedMediaFileAdd($event)" (fileDelete)="onAdvancedMediaFileDelete($event)"></advanced-media-editor>
  </div>
  <div class="control" *ngSwitchDefault>
    <input type="text" [ngModel]="formattedValue" (ngModelChange)="change($event)" />
  </div>
</div>