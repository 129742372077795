import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  EventEmitter,
  ViewEncapsulation,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { sortByName } from '@ml/common';
import { Community } from '../../entities';
import { AnimationState, heightExpandCollapseAnimation } from '../../utility/animations';
import {
  BulkTagMappedType,
  BulkMappedTag,
  TagService,
  TagMappingAction,
  BulkTagActionType,
  TagMappingRequest
} from '../tag-editor/tag.service';
import { ToastService } from '../toast/toast.service';

@Component({
  selector: 'bulk-tag-remover',
  templateUrl: './bulk-tag-remover.component.html',
  styleUrls: ['./bulk-tag-remover.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [heightExpandCollapseAnimation(100)]
})
export class BulkTagRemoverComponent implements OnInit, OnChanges {
  @Input() mappingType: BulkTagMappedType;
  @Input() community: Community;
  @Input() commDivMappedTags: BulkMappedTag[] = [];
  @Output() tagChange = new EventEmitter<number>();
  tagCount: number;
  allMappedTags: BulkMappedTag[] = [];
  displayedTags: BulkMappedTag[] = [];
  animationState: string;
  isOpen = false;
  showDeleteConfirm = false;
  mappingsToBeRemoved: BulkMappedTag[] = [];

  constructor(
    private toaster: ToastService,
    private tagService: TagService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.updateAnimationState();
    this.parseTagsByLevelAndType();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.commDivMappedTags) this.parseTagsByLevelAndType();
  }

  trackByTagId(_, tag: BulkMappedTag) {
    return tag.TagId;
  }

  toggleAccordion() {
    this.isOpen = !this.isOpen;
    this.updateAnimationState();
  }

  toggleDeleteConfirm() {
    this.showDeleteConfirm = !this.showDeleteConfirm;
  }

  handleConfirmDeleteModal(tag: BulkMappedTag) {
    this.mappingsToBeRemoved = this.allMappedTags.filter(t => t.TagId === tag.TagId);
    this.toggleDeleteConfirm();
  }

  parseTagsByLevelAndType() {
    if (this.community) {
      if (this.mappingType === BulkTagMappedType.Lot) {
        this.allMappedTags = this.community.Neighborhoods.flatMap(n => n.Lots).flatMap(l =>
          l.Tags.map(t => new BulkMappedTag(t, l.LotId))
        );
      }
      if (this.mappingType === BulkTagMappedType.FloorPlan) {
        this.allMappedTags = this.community.Neighborhoods.flatMap(n => n.FloorPlans).flatMap(f =>
          f.Tags.map(t => new BulkMappedTag(t, null, f.FloorplanId))
        );
      }
    } else {
      if (this.mappingType === BulkTagMappedType.Lot) {
        this.allMappedTags = this.commDivMappedTags.filter(t => t.LotId);
      }
      if (this.mappingType === BulkTagMappedType.FloorPlan) {
        this.allMappedTags = this.commDivMappedTags.filter(t => t.FloorPlanId);
      }
    }
    this.setDisplayedTags();
  }

  setDisplayedTags() {
    this.tagCount = this.allMappedTags.length;
    // Filter out duplicate tags for display purposes and sort
    this.displayedTags = this.allMappedTags
      .filter((tag, i, array) => array.findIndex(t => t.TagId === tag.TagId) === i)
      .sort(sortByName);
  }

  async handleDelete() {
    this.toggleDeleteConfirm();
    this.toaster.showLoading();
    try {
      const removedTagId = this.mappingsToBeRemoved[0].TagId;
      const mappedWithAction: TagMappingRequest[] = this.mappingsToBeRemoved.map(t => ({
        TagId: t.TagId,
        Action: TagMappingAction[BulkTagActionType.Remove],
        LotId: t.LotId,
        FloorPlanId: t.FloorPlanId,
        InventoryHomeId: null
      }));
      this.tagService.updateMappings(mappedWithAction).then(() => {
        this.allMappedTags = this.allMappedTags.filter(t => t.TagId !== removedTagId);
        this.displayedTags = this.displayedTags.filter(t => t.TagId !== removedTagId);
        this.tagCount = this.allMappedTags.length;
        this.mappingsToBeRemoved = [];
        this.cdr.detectChanges();
        this.tagChange.emit(removedTagId);
        this.toaster.showSuccess('Success!', 'Selected Community Tags Removed');
      });
    } catch {
      this.toaster.showError('Error', 'Unable to remove selected community tags');
    }
  }

  private updateAnimationState() {
    this.animationState = this.isOpen ? AnimationState.Expanded : AnimationState.Collapsed;
  }
}
