import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import {
  getDistanceBetweenLatLng,
  getDistanceBetweenLatLngRounded,
  roundToPlace
} from '@ml/common';

@Injectable()
export class GoogleMapsService {
  readonly ApiKey = 'AIzaSyBTZ8qbiRFS-TeYOFuGac77SiVDZqSJ_dE';

  constructor(private http: HttpClient) {}

  async doNearbySearch(
    keyword: string,
    latitude: number,
    longitude: number,
    searchRadius?: number
  ): Promise<GoogleSearchResult[]> {
    const base = 'maps.googleapis.com/maps/api/place/nearbysearch/json';
    const queryParams = [
      `location=${latitude},${longitude}`,
      `radius=${searchRadius || 26400}`,
      `keyword=${keyword}`,
      `key=${this.ApiKey}`
    ];
    const queryString = '?' + queryParams.join('&');
    const googleApiUrl = base + queryString;

    const response: any = await this.http
      .get(`/api/utility/pass-thru?url=${googleApiUrl}`)
      .toPromise();
    return response.results.map(
      x =>
        new GoogleSearchResult(
          x.id || x.place_id,
          x.name,
          x.vicinity,
          x.geometry.location.lat,
          x.geometry.location.lng,
          this.getDistanceFromCommunity(
            latitude,
            longitude,
            x.geometry.location.lat,
            x.geometry.location.lng
          )
        )
    );
  }

  getDistanceFromCommunity(
    centerLat: number,
    centerLng: number,
    latitude: number,
    longitude: number
  ) {
    return getDistanceBetweenLatLngRounded(centerLat, centerLng, latitude, longitude, 1);
  }

  async doPlaceSearch(clientApiKey: string) {
    const googleApiUrl = `maps.googleapis.com/maps/api/place/findplacefromtext/json?key=${clientApiKey}&input=medialab&inputtype=textquery`;
    return await firstValueFrom(this.http.get(`/api/utility/pass-thru?url=${googleApiUrl}`));
  }
}

export class GoogleSearchResult {
  constructor(
    public Id: string,
    public Name: string,
    public Address: string,
    public Latitude: number,
    public Longitude: number,
    public DistanceFromCommunity?: number
  ) {}
}
