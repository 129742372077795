import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ColorPickerModule } from 'ngx-color-picker';

import { RouterModule } from '@angular/router';
import { GoogleMapsService } from '../content-editor/services/google-maps.service';
import { ReversePipe } from '../shared/reverse.pipe';
import { SafeBackgroundImageUrlPipe } from '../shared/safe-background-image-url.pipe';
import { SafeStylePipe } from '../shared/safe-style.pipe';
import { SafeHtmlPipe, SafeUrlPipe } from '../shared/safe-url.pipe';
import { ReportOverlayComponent } from '../tools/report/report-overlay/report-overlay.component';
import { ReportService } from '../tools/report/report.service';
import { AccordionFormComponent } from './accordion-form/accordion-form.component';
import { AccordionComponent } from './accordion/accordion.component';
import { AddPoiComponent } from './add-poi/add-poi.component';
import { AdvancedMediaEditorComponent } from './advanced-media-editor/advanced-media-editor.component';
import { AlertBannerComponent } from './alert-banner/alert-banner.component';
import { BackArrowComponent } from './back-arrow/back-arrow.component';
import { BulkLocationComponent } from './bulk-location/bulk-location.component';
import { BulkMappingComponent } from './bulk-mapping.component';
import { BulkTagRemoverComponent } from './bulk-tag-remover/bulk-tag-remover.component';
import { CanDeactivateModalComponent } from './can-deactivate-modal/can-deactivate-modal.component';
import { CascadingDropdownsComponent } from './cascading-dropdowns/cascading-dropdowns.component';
import { CheckEditPossibleDirective } from './check-edit-possible/check-edit-possible.directive';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CodeEditorComponent } from './code-editor/code-editor.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { CounterInputComponent } from './counter-input/counter-input.component';
import { DropdownComponent } from './dropdown.component';
import { DropzoneComponent } from './dropzone/dropzone.component';
import { DynamicScriptLoaderService } from './dynamic-script-loader.service';
import { ExpandingSetComponent } from './expanding-set/expanding-set.component';
import { FileInputFormComponent } from './file-input-form/file-input-form.component';
import { FileSystemService } from './file-system.service';
import { FloorplanSpecificSocialComponent } from './floorplan-specific-social/floorplan-specific-social.component';
import { FormControlProjectorComponent } from './form-control-projector/form-control-projector.component';
import { IconFormControlComponent } from './form-control-projector/icon-form-control/icon-form-control.component';
import { GalleryComponent } from './gallery/gallery.component';
import { HotspotMarkerComponent } from './hotspot-marker/hotspot-marker.component';
import { IconPickerComponent } from './icon-picker/icon-picker.component';
import { ImageSliderComponent } from './image-slider/image-slider.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { KeyValueSetComponent } from './key-value-set/key-value-set.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { MediaEditorComponent } from './media-editor/media-editor.component';
import { MlIncludeDirective } from './ml-include.directive';
import { MlInteractDirective } from './ml-interact.directive';
import { MlTransformerDirective } from './ml-transformer.directive';
import { ModalComponent } from './modal/modal.component';
import { MultiExpandingSetComponent } from './multi-expanding-set/multi-expanding-set.component';
import { PaginationComponent } from './pagination/pagination.component';
import { PercentageBarComponent } from './percentage-bar/percentage-bar.component';
import { PoiCategoryComponent } from './poi-category/poi-category.component';
import { ProgressTrackerComponent } from './progress-tracker/progress-tracker.component';
import { PublishProgressComponent } from './publish-progress/publish-progress.component';
import { QuickLinkEditorComponent } from './quick-link-editor/quick-link-editor.component';
import { ReportSettingsSelectComponent } from './report-settings-select/report-settings-select.component';
import { SettingsGroupComponent } from './settings-group/settings-group.component';
import { StateDropdownComponent } from './state-dropdown.component';
import { StdSettingsEditAccordionComponent } from './std-settings-edit-accordion/std-settings-edit-accordion.component';
import { SvgHelperComponent } from './svg-helper/svg-helper.component';
import { TagCounterComponent } from './tag-counter/tag-counter.component';
import { TagEditorComponent } from './tag-editor/tag-editor.component';
import { TileComponent } from './tile/tile.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { UpDownArrowsComponent } from './up-down-arrows/up-down-arrows.component';
import { UserSearchControlComponent } from './user-search-control/user-search-control.component';
import { VideoWithPreviewComponent } from './video-with-preview/video-with-preview.component';

import '@ml/web-components';
import { ToastComponent } from './toast/toast.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    ColorPickerModule,
    DragDropModule,
    MatDialogModule,
    RouterModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    PaginationComponent,
    MlIncludeDirective,
    DropdownComponent,
    MlTransformerDirective,
    MlInteractDirective,
    BulkMappingComponent,
    SafeBackgroundImageUrlPipe,
    SafeUrlPipe,
    SafeStylePipe,
    ReversePipe,
    ConfirmModalComponent,
    StateDropdownComponent,
    ModalComponent,
    CounterInputComponent,
    CascadingDropdownsComponent,
    CheckboxComponent,
    DropzoneComponent,
    AccordionFormComponent,
    ExpandingSetComponent,
    CanDeactivateModalComponent,
    BulkLocationComponent,
    AlertBannerComponent,
    TooltipComponent,
    CheckEditPossibleDirective,
    SafeHtmlPipe,
    SvgHelperComponent,
    BackArrowComponent,
    MultiExpandingSetComponent,
    ImageSliderComponent,
    KeyValueSetComponent,
    FormControlProjectorComponent,
    FloorplanSpecificSocialComponent,
    TileComponent,
    GalleryComponent,
    ReportOverlayComponent,
    IconPickerComponent,
    IconFormControlComponent,
    VideoWithPreviewComponent,
    AccordionComponent,
    FileInputFormComponent,
    ImageUploadComponent,
    SettingsGroupComponent,
    ProgressTrackerComponent,
    PercentageBarComponent,
    UpDownArrowsComponent,
    TagEditorComponent,
    TagCounterComponent,
    HotspotMarkerComponent,
    ReportSettingsSelectComponent,
    QuickLinkEditorComponent,
    PublishProgressComponent,
    UserSearchControlComponent,
    PoiCategoryComponent,
    AdvancedMediaEditorComponent,
    BulkTagRemoverComponent,
    AddPoiComponent,
    LoadingIndicatorComponent,
    MediaEditorComponent,
    StdSettingsEditAccordionComponent,
    CodeEditorComponent,
    ToastComponent
  ],
  exports: [
    PaginationComponent,
    MlIncludeDirective,
    DropdownComponent,
    MlTransformerDirective,
    MlInteractDirective,
    BulkMappingComponent,
    SafeBackgroundImageUrlPipe,
    SafeUrlPipe,
    SafeStylePipe,
    ReversePipe,
    ConfirmModalComponent,
    StateDropdownComponent,
    ModalComponent,
    CounterInputComponent,
    CascadingDropdownsComponent,
    CheckboxComponent,
    DropzoneComponent,
    AccordionFormComponent,
    ExpandingSetComponent,
    CanDeactivateModalComponent,
    BulkLocationComponent,
    AlertBannerComponent,
    TooltipComponent,
    CheckEditPossibleDirective,
    SafeHtmlPipe,
    SvgHelperComponent,
    BackArrowComponent,
    MultiExpandingSetComponent,
    ImageSliderComponent,
    KeyValueSetComponent,
    FormControlProjectorComponent,
    FloorplanSpecificSocialComponent,
    TileComponent,
    GalleryComponent,
    ReportOverlayComponent,
    IconFormControlComponent,
    VideoWithPreviewComponent,
    AccordionComponent,
    FileInputFormComponent,
    ImageUploadComponent,
    SettingsGroupComponent,
    ProgressTrackerComponent,
    PercentageBarComponent,
    UpDownArrowsComponent,
    TagEditorComponent,
    TagCounterComponent,
    HotspotMarkerComponent,
    ReportSettingsSelectComponent,
    PublishProgressComponent,
    UserSearchControlComponent,
    PoiCategoryComponent,
    AdvancedMediaEditorComponent,
    BulkTagRemoverComponent,
    LoadingIndicatorComponent,
    MediaEditorComponent,
    StdSettingsEditAccordionComponent,
    CodeEditorComponent,
    ToastComponent
  ],
  providers: [FileSystemService, ReportService, DynamicScriptLoaderService, GoogleMapsService]
})
export class ApolloCommonModule {}
