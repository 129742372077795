import {
  Component,
  ViewEncapsulation,
  Output,
  Input,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';

import { IconService, Icon } from '../../../entities';

@Component({
  selector: 'icon-form-control',
  templateUrl: './icon-form-control.component.html',
  styleUrls: ['./icon-form-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconFormControlComponent implements OnChanges {
  @Input() iconId: string | number;
  @Input() allowRemoval = false;
  @Output() iconIdChange = new EventEmitter<string>();
  svgContent: string;
  showIconPicker = false;
  currentIconId: number;

  constructor(private iconService: IconService, private cdr: ChangeDetectorRef) {}

  async ngOnChanges() {
    this.currentIconId = +this.iconId || 0;
    if (this.currentIconId) {
      this.svgContent = (await this.iconService.getById(this.currentIconId)).SvgContent;
      this.cdr.detectChanges();
    } else this.svgContent = '';
  }

  handleIconRemove() {
    this.currentIconId = 0;
    this.iconIdChange.emit('');
  }

  toggleIconPicker() {
    this.showIconPicker = !this.showIconPicker;
  }

  async handleIconSelect(icon: Icon) {
    this.iconIdChange.emit(icon.IconId.toString());
    this.toggleIconPicker();
  }
}
