import { trigger, transition, animate, style, state } from '@angular/animations';

export function enterLeaveFadeAnimation(animationSpeed: number = 250) {
  return trigger('enterLeaveFadeAnimation', [
    transition(':enter', [style({ opacity: 0 }), animate(animationSpeed)]),
    transition(':leave', animate(animationSpeed, style({ opacity: 0 })))
  ]);
}

export function enterLeaveScaleAnimation(animationSpeed: number = 300) {
  return trigger('enterLeaveScaleAnimation', [
    state('in', style({ transform: 'scale(1)' })),
    transition(':enter', [style({ transform: 'scale(0)' }), animate(animationSpeed)]),
    transition(':leave', [animate(animationSpeed, style({ transform: 'scale(0)' }))])
  ]);
}

export enum AnimationState {
  Expanded = 'expanded',
  Collapsed = 'collapsed'
}
export function heightExpandCollapseAnimation(animationSpeed: number = 200) {
  return trigger('heightExpandCollapse', [
    state(
      AnimationState.Expanded,
      style({
        height: '*',
        opacity: 1,
        overflow: 'visible'
      })
    ),
    state(
      AnimationState.Collapsed,
      style({
        height: '0',
        opacity: 0,
        overflow: 'hidden'
      })
    ),
    transition(
      `${AnimationState.Expanded} <=> ${AnimationState.Collapsed}`,
      animate(`${animationSpeed}ms ease-in-out`)
    )
  ]);
}
