import { throwError as observableThrowError, of as observableOf, Observable } from 'rxjs';
import { map, catchError, share } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Lookup } from '../shared/lookup';
import { User } from './user';
import { ACL } from './acl';

@Injectable()
export class AclService {
  store: ACL[];
  getListObservable: Observable<ACL[]>;

  constructor(private http: HttpClient) {
    this.store = new Array<ACL>();
  }

  getByName(module: string): Observable<ACL> {
    // check store first
    const acl = this.store.find(a => a.ResourceName === module);
    if (acl) {
      return observableOf(acl);
    } else {
      return this.http.get<ACL>(`/api/acls/${module}/${Lookup.Argo.AclDomain}`).pipe(
        map((resAcl: ACL) => {
          if (!this.store.some(s => s.ResourceName === resAcl.ResourceName))
            this.store.push(resAcl);

          return resAcl;
        })
      );
    }
  }

  getListByNames(modules: string[]): Observable<ACL[]> {
    const acls = this.store.filter(a => modules.indexOf(a.ResourceName) > -1);
    // if lengths the same then each acl must already be in the store
    if (acls.length === modules.length) {
      return observableOf(acls);
    } else {
      if (!this.getListObservable) {
        const resourceNames = modules.join(',');
        this.getListObservable = this.http
          .get<ACL[]>(`/api/acls?resourceNames=${resourceNames}&domain=${Lookup.Argo.AclDomain}`)
          .pipe(
            map((resAcls: ACL[]) => {
              // add to store if not there already
              resAcls.forEach(a => {
                if (!this.store.some(s => s.ResourceName === a.ResourceName)) this.store.push(a);
              });

              this.getListObservable = null;

              return resAcls;
            }),
            catchError((error: Error) => {
              this.getListObservable = null;
              return observableThrowError(error);
            }),
            share()
          );
      }

      return this.getListObservable;
    }
  }

  getModulesAuthorizedForClient(clientRole: string): Observable<ACL[]> {
    // get all modules
    return this.getListByNames(Object.keys(Lookup.Modules)).pipe(
      map((acls: ACL[]) => {
        // Filter Acls where HasAccess operation exists and client role is allowed
        // Or the module has no roles at all, it is just container for special operations
        return acls.filter(acl => {
          const module = Lookup.Modules[acl.ResourceName];
          if (!module) return false;
          if (!module.Roles.read && !module.Roles.write) return true;
          else {
            const hasAccOper = acl.Operations.find(op => op.OperationName === 'HasAccess');
            if (hasAccOper)
              return hasAccOper.Roles.some(role => role.Name === clientRole && role.IsAllowed);
          }
        });
      })
    );
  }

  getModulesAuthorizedForUser(user: User): Observable<ACL[]> {
    const clientRole = `${user.ClientName}_${user.ClientId}`;
    return this.getModulesAuthorizedForClient(clientRole).pipe(
      map((acls: ACL[]) => {
        // now filter to only Modules for which this user has a read/write role
        return acls.filter((acl: ACL) => {
          const module = Lookup.Modules[acl.ResourceName];
          if (!module) return false;

          return (
            user.isSuperAdmin ||
            user.hasRole(module.Roles.read) ||
            user.hasRole(module.Roles.write) ||
            (!module.Roles.read && !module.Roles.write)
          );
        });
      })
    );
  }

  doesRoleHaveModuleOperationPermission(
    role: string,
    module: string,
    operation: string
  ): Observable<boolean> {
    return this.getByName(module).pipe(
      map((acl: ACL) => {
        let hasPermission = false;

        const op = acl.Operations.find(o => o.OperationName === operation);
        if (op) hasPermission = op.Roles.some(r => r.Name === role && r.IsAllowed);

        return hasPermission;
      })
    );
  }

  private updateStore(acl: ACL, operation: string) {
    const storeIdx = this.store.findIndex(a => a.ResourceName === acl.ResourceName);
    if (storeIdx > -1) {
      const storedOperationIdx = this.store[storeIdx].Operations.findIndex(
        o => o.OperationName === operation
      );
      if (storedOperationIdx > -1)
        this.store[storeIdx].Operations[storedOperationIdx] = acl.Operations.find(
          o => o.OperationName === operation
        );
    } else {
      this.store.push(acl);
    }
  }

  bulkUpdateAclOperationRole(items: AclPatchDto[]) {
    return this.http.patch('/api/acls', items).pipe(
      map((acls: ACL[]) => {
        acls.forEach(x => x.Operations.forEach(z => this.updateStore(x, z.OperationName)));
        return acls;
      })
    );
  }
}

export class AclPatchDto {
  Domain: string;
  ResourceName: string;
  Mode: string;
  Operation: string;
  // either Principal or Role must be provided
  Role?: string;
  Principal?: string;

  constructor(dto?: AclPatchDto) {
    Object.assign(this, dto);
  }
}
