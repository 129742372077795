<div class="form-box">

    <div class="box-content">
        <div class="box-section">
            <div class="title">Add Custom POI</div>
            <div class="row">
                <div class="col-xs-12 field">
                    <div class="label">
                        <span class="warning">*</span>Title
                    </div>
                    <input id="poiTitle" type="text" name="Title" [(ngModel)]="poi.Name" required />
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 field">
                    <div class="label">
                        Street Address
                    </div>
                    <input type="text" name="Street" [(ngModel)]="poi.Address" />
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 field">
                    <div class="label">
                        City
                    </div>
                    <input type="text" name="City" [(ngModel)]="poi.City" />
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 field">
                    <div class="label">
                        State
                    </div>
                    <input type="text" name="State" [(ngModel)]="poi.State" />
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 field">
                    <div class="label">
                        <span class="warning">*</span>Latitude
                    </div>
                    <input id="poiLatitude" type="number" name="Latitude" [(ngModel)]="poi.Latitude" required />
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 field">
                    <div class="label">
                        <span class="warning">*</span>Longitude
                    </div>
                    <input id="poiLongitude" type="number" name="Longitude" [(ngModel)]="poi.Longitude" required />
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 field">
                    <div class="label">
                        POI Icon
                    </div>
                    <icon-form-control [(iconId)]="poi.IconId" (iconIdChange)="handleIconChange($event)"></icon-form-control>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <button class="btn priority-positive" (click)="updatePoi()">
                        <span class="label">{{poi.Id ? 'Save' : 'Add'}} POI</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>