import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  AfterViewInit
} from '@angular/core';

import { convertMilesToMeters, removeFromArray, SettingFieldType, sortByName } from '@ml/common';
import { Community, IconService } from '../../entities';
import { Guid } from '../guid';
import {
  AreaMapPoi,
  AreaMapPoiCategory,
  IScpContentImage
} from '../../content-editor/models/pages-contentjson';
import {
  GoogleMapsService,
  GoogleSearchResult
} from '../../content-editor/services/google-maps.service';
import { ImageVM } from '../image-upload/image-upload.component';
import { ToastService } from '../toast/toast.service';
import { createNumberSorterByPropertyName } from '../ui-helper.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'poi-category',
  templateUrl: './poi-category.component.html',
  styleUrls: ['./poi-category.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PoiCategoryComponent implements OnInit, AfterViewInit {
  @Input() category: AreaMapPoiCategory;
  @Input() community: Community;
  @Input() isUsingSavedResults: boolean;
  @Input() fileBaseUrl: string;
  @Input() searchRadius: number;
  @Input() reInit: Subject<boolean>;
  @Output() delete = new EventEmitter();
  @Output() update = new EventEmitter<AreaMapPoiCategory>();
  @Output() fileAdd = new EventEmitter<File>();
  @Output() fileRemove = new EventEmitter<string>();
  isOpen = false;
  searchResults: GoogleSearchResult[] = [];
  showFileUploadDialog = false;
  poiToEditId: string;
  imageFromPoiToEdit: ImageVM;
  imagesToUpload: ImageVM[] = [];
  colorFieldType = SettingFieldType.ColorPicker;
  showAddPoiForm = false;
  poiToEdit: AreaMapPoi = new AreaMapPoi();
  showPoiDelete = false;
  sortByForGoogleResults = SearchResultSortBy.Alphabetical;
  searchResultSortBy = SearchResultSortBy;
  distanceFromCommunityByPoiId: { [key: string]: number } = {};

  constructor(
    private googleMaps: GoogleMapsService,
    private toaster: ToastService,
    private changeDetectorRef: ChangeDetectorRef,
    private iconService: IconService
  ) {}

  ngOnInit() {
    this.category.ExcludedMarkers.sort(sortByName);
    if (!this.category.IncludedSavedResults) this.category.IncludedSavedResults = [];
    this.category.IncludedSavedResults.sort(sortByName);
    if (!this.category.SearchRadius) this.category.SearchRadius = this.searchRadius;
    this.distanceFromCommunityByPoiId = Object.fromEntries(
      this.category.IncludedSavedResults.map(poi => [poi.Id, this.getPoiDistanceFromCommunity(poi)])
    );
  }

  ngAfterViewInit(): void {
    this.reInit.subscribe(val => this.ngOnInit());
  }

  private getPoiDistanceFromCommunity(poi: AreaMapPoi): number {
    return this.googleMaps.getDistanceFromCommunity(
      +this.community.Latitude,
      +this.community.Longitude,
      poi.Latitude,
      poi.Longitude
    );
  }

  handleOpenToggle() {
    this.isOpen = !this.isOpen;
  }

  async handleGoogleSearch() {
    this.toaster.showLoading();
    const keywords = (this.category.Keyword || '')
      ?.split(',')
      .map(x => x.trim())
      .filter(x => x);

    const result =
      keywords.length > 0
        ? keywords.map(x => this.doNearbySearch(x))
        : [this.doNearbySearch(this.category.Name)];

    try {
      this.searchResults = (await Promise.all(result)).reduce((x, array) => [...array, ...x], []);
      this.sortSearchResults();
      this.toaster.hideLoading();
    } catch {
      this.toaster.showError(
        'Error',
        'Sorry, we were unable to perform that Google search just now'
      );
    }
  }

  sortSearchResults() {
    if (this.sortByForGoogleResults === SearchResultSortBy.Alphabetical) {
      this.searchResults.sort(sortByName);
    } else {
      this.searchResults.sort(createNumberSorterByPropertyName('DistanceFromCommunity'));
    }
    this.changeDetectorRef.markForCheck();
  }

  private doNearbySearch(searchTerm: string): Promise<GoogleSearchResult[]> {
    return this.googleMaps.doNearbySearch(
      searchTerm,
      +this.community.Latitude,
      +this.community.Longitude,
      this.category.SearchRadius
        ? convertMilesToMeters(this.category.SearchRadius)
        : convertMilesToMeters(this.searchRadius)
    );
  }

  handlePoiExcludeToggle(poi: GoogleSearchResult | AreaMapPoi) {
    const i = this.category.ExcludedMarkers.findIndex(x => x.Id === poi.Id);

    if (i > -1) this.category.ExcludedMarkers.splice(i, 1);
    else this.category.ExcludedMarkers.push({ Id: poi.Id, Name: poi.Name });

    this.update.emit(this.category);
  }

  handlePoiIncludeToggle(poi: GoogleSearchResult | AreaMapPoi) {
    const i = this.category.IncludedSavedResults.findIndex(x => x.Id === poi.Id);

    if (i > -1) this.category.IncludedSavedResults.splice(i, 1);
    else {
      this.category.IncludedSavedResults.push(Object.assign(new AreaMapPoi(), poi));
      this.category.IncludedSavedResults.sort(sortByName);
      this.distanceFromCommunityByPoiId[poi.Id] = this.getPoiDistanceFromCommunity(poi);
    }

    this.update.emit(this.category);
  }

  verifyRemoveAllPOI() {
    this.showPoiDelete = true;
  }

  cancelPoiDelete() {
    this.showPoiDelete = false;
  }

  handleAllPoiAddOrRemove(doAdd: boolean) {
    this.showPoiDelete = false;
    if (doAdd) {
      this.searchResults.forEach(poi => {
        if (!this.isIncluded(poi)) {
          this.category.IncludedSavedResults.push(Object.assign(new AreaMapPoi(), poi));
          this.distanceFromCommunityByPoiId[poi.Id] = this.getPoiDistanceFromCommunity(poi);
        }
      });
      this.category.IncludedSavedResults.sort(sortByName);
    } else {
      this.category.IncludedSavedResults = [];
    }

    this.update.emit(this.category);
  }

  async handleIconChange(iconId: string) {
    this.category.IconId = +iconId;
    this.category.IconSvgContent = (
      await this.iconService.getById(this.category.IconId)
    ).SvgContent;
    this.onInputChange();
  }

  handleMarkerColorChange() {
    this.onInputChange();
  }

  onInputChange() {
    this.update.emit(this.category);
  }

  isExcluded(poi: GoogleSearchResult) {
    return this.category.ExcludedMarkers.some(x => x.Id === poi.Id);
  }

  isIncluded(poi: GoogleSearchResult) {
    return (
      this.category.IncludedSavedResults &&
      this.category.IncludedSavedResults.some(x => x.Id === poi.Id)
    );
  }

  toggleFileUploadDialog(poi?: AreaMapPoi) {
    this.poiToEditId = undefined;
    this.imageFromPoiToEdit = undefined;

    if (poi) {
      this.poiToEditId = poi.Id;
      this.imageFromPoiToEdit = ImageVM.fromIScpContentImage(poi.Image, this.fileBaseUrl);
      const pendingImage = this.imagesToUpload.find(i => i.Filename.includes(poi.Id));
      if (pendingImage) {
        this.imageFromPoiToEdit.File = pendingImage.File;
        this.imageFromPoiToEdit.PreviewUrl = pendingImage.PreviewUrl;
      }
    }

    this.showFileUploadDialog = !this.showFileUploadDialog;
  }

  handleImagesChange(images: ImageVM[]) {
    if (images.length < 1) return;

    const image: ImageVM = images[0];
    let updatedImage: IScpContentImage;
    if (image.MarkedForDeletion) {
      updatedImage = undefined;
      const pendingUpload = this.imagesToUpload.find(f => f.Filename === image.Filename);
      if (pendingUpload) this.imagesToUpload = removeFromArray(pendingUpload, this.imagesToUpload);
      this.fileRemove.emit(image.Filename);
      this.imageFromPoiToEdit = undefined;
    } else {
      updatedImage = { Filename: image.Filename, SortOrder: 0 };
      if (image.File) {
        const fileExtension = image.File.name.substring(image.File.name.lastIndexOf('.') + 1);
        image.Filename = `${this.poiToEditId}.${fileExtension}`;
        updatedImage.Filename = image.Filename;
        this.imagesToUpload.push(image);
        this.fileAdd.emit(new File([image.File], image.Filename));
        this.imageFromPoiToEdit = image;
      }
    }

    this.category.IncludedSavedResults = this.category.IncludedSavedResults.map(poi => {
      return { ...poi, ...(poi.Id === this.poiToEditId && { Image: updatedImage }) };
    });

    this.update.emit(this.category);
  }

  handleAddPoi(poi: AreaMapPoi) {
    if (!poi.Id) {
      poi.Id = Guid.Generate();
      this.handlePoiIncludeToggle(poi);
    } else {
      const i = this.category.IncludedSavedResults.findIndex(x => x.Id === poi.Id);

      if (i > -1) this.category.IncludedSavedResults.splice(i, 1, poi);
      this.update.emit(this.category);
    }
    this.poiToEdit = new AreaMapPoi();
    this.showAddPoiForm = false;
  }

  handleOpenPoiForm() {
    this.showAddPoiForm = true;
  }

  handleEditPoi(poi: AreaMapPoi) {
    this.poiToEdit = poi;
    this.handleOpenPoiForm();
  }

  toggleAddPoiForm() {
    this.poiToEdit = new AreaMapPoi();
    this.showAddPoiForm = !this.showAddPoiForm;
  }
}

export enum SearchResultSortBy {
  Alphabetical,
  Distance
}
