import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

@Component({
  selector: 'tag-counter',
  templateUrl: './tag-counter.component.html',
  styleUrls: ['./tag-counter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagCounterComponent implements OnInit {
  @Input() count = 0;

  constructor() {}

  ngOnInit(): void {}
}
