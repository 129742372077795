import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'key-value-set',
  templateUrl: './key-value-set.component.html',
  styleUrls: ['./key-value-set.component.scss']
})
export class KeyValueSetComponent implements OnChanges {
  @Input()
  expandingSet: KeyValueSet = new KeyValueSet();

  @Input()
  itemLabel = 'Item';

  @Input()
  value: Array<KeyValueSetItem> = [];

  @Output()
  add = new EventEmitter<KeyValueSetItem>();

  @Output()
  changed = new EventEmitter<KeyValueSetItem[]>();

  @Output()
  delete = new EventEmitter<KeyValueSetItem>();

  @Output()
  typedText = new EventEmitter<any>();

  constructor() {}

  ngOnChanges() {
    if (this.value) {
      this.expandingSet.stored = this.value;
    }
  }

  addPending(set: KeyValueSet) {
    if (set.pending.setKey && set.pending.setValue) {
      set.createdCount++;
      set.pending.createdIndex = set.createdCount;

      this.add.emit(set.pending);
      this.changed.emit(this.value);

      set.pending = new KeyValueSetItem();
    }
  }

  deleteItem(set: KeyValueSet, index: number) {
    if (set.stored[index]) {
      const deleted = set.stored[index];

      this.delete.emit(deleted);
      this.changed.emit(this.value);
    }
  }

  typedTextChange(item: KeyValueSet) {
    this.typedText.emit(item);
    this.changed.emit(this.value);
  }

  afterTypedTextChange(item: KeyValueSetItem, propName: string, type?: string) {
    item[propName] = item[propName].trim();
    if (type !== 'pending') this.typedTextChange(this.expandingSet);
  }

  trackByIndex(index: number) {
    return index;
  }
}

export class KeyValueSetItem {
  setKey = '';
  setValue = '';
  createdIndex = 0;
}

export class KeyValueSet {
  pending: KeyValueSetItem = new KeyValueSetItem();
  stored: KeyValueSetItem[] = new Array<KeyValueSetItem>();
  createdCount = 0;

  constructor(public setKey: string = '', public setValue: string = '') {}

  // reset the created index to be in array order
  reindex() {
    this.stored.forEach((item, i) => {
      item.createdIndex = i + 1;
    });

    this.createdCount = this.stored.length;
  }

  clone(): KeyValueSet {
    const dupe = new KeyValueSet(this.setKey, this.setValue);

    // NOTE: this will not copy any class functions (the copies are not actually class instances)
    for (const attr in this) {
      if (this.hasOwnProperty(attr)) dupe[attr.toString()] = JSON.parse(JSON.stringify(this[attr]));
    }

    dupe.reindex();
    return dupe;
  }

  initStored(values: string[]) {
    if (values && values.length > 0) {
      this.stored = values.map((x, i) => {
        const item = new KeyValueSetItem();
        item.setKey = x;
        item.setValue = x;
        item.createdIndex = i;
        return item;
      });

      this.createdCount = this.stored.length;
    }
  }

  addItem(setKey: string, setValue: string) {
    const newValue = new KeyValueSetItem();
    newValue.setKey = setKey;
    newValue.setValue = setValue;
    this.stored.push(newValue);
  }
}
