import { of as observableOf, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { ManagementMap } from './managementmap';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ManagementMapsService {
  mapStore: ManagementMap[];
  hoodHistory: number[];
  clientHistory: number[];

  constructor(private http: HttpClient) {
    this.mapStore = new Array<ManagementMap>();
    this.hoodHistory = new Array<number>();
    this.clientHistory = new Array<number>();
  }

  getById(id: string): Observable<ManagementMap> {
    const mmap = this.mapStore.find(m => m.MapConfigurationId === id);
    if (mmap) {
      return observableOf(mmap);
    } else {
      return this.http.get<ManagementMap>(`/api/mapConfigurations/${id}`).pipe(
        map((resMap: ManagementMap) => {
          resMap = new ManagementMap(resMap);
          this.mapStore.push(resMap);
          return resMap;
        })
      );
    }
  }

  getByNeighborhoodId(id: number): Observable<ManagementMap[]> {
    // hoodHistory tracks whether we have done an http get for full neighborhood
    if (this.hoodHistory.indexOf(id) > -1) {
      return observableOf(this.mapStore.filter(m => m.Neighborhood.indexOf(id) > -1));
    } else {
      // note mapType param value is case-sensitive
      return this.http
        .get<ManagementMap[]>(`/api/mapConfigurations?mapType=Neighborhoods&id=${id}`)
        .pipe(
          map((resMaps: ManagementMap[]) => {
            resMaps = resMaps.map(m => new ManagementMap(m));
            // if in store then overwrite, otherwise add to store
            resMaps.forEach(m => {
              const storeIdx = this.mapStore.findIndex(
                s => s.MapConfigurationId === m.MapConfigurationId
              );
              if (storeIdx > -1) {
                this.mapStore[storeIdx] = m;
              } else {
                this.mapStore.push(m);
              }
            });

            // mark that we have done a full get for this neighborhood
            this.hoodHistory.push(id);

            return resMaps;
          })
        );
    }
  }

  getByClientId(clientId: number): Observable<ManagementMap[]> {
    // clientHistory tracks whether we have done an http get for an entire client
    if (this.clientHistory.indexOf(clientId) > -1) {
      return observableOf(this.mapStore.filter(m => m.ClientId === clientId));
    } else {
      return this.http.get<ManagementMap[]>(`/api/mapConfigurations/client/${clientId}`).pipe(
        map((maps: ManagementMap[]) => {
          maps = maps.map(m => new ManagementMap(m));
          // if in store then overwrite, otherwise add to store
          maps.forEach(m => {
            const storeIdx = this.mapStore.findIndex(
              s => s.MapConfigurationId === m.MapConfigurationId
            );
            if (storeIdx > -1) {
              this.mapStore[storeIdx] = m;
            } else {
              this.mapStore.push(m);
            }
          });

          // mark that we have done a full get for this client
          this.clientHistory.push(clientId);

          return maps;
        })
      );
    }
  }

  update(currentMap: ManagementMap): Observable<ManagementMap> {
    return this.http
      .put<ManagementMap>(`/api/mapConfigurations/${currentMap.MapConfigurationId}`, currentMap)
      .pipe(
        map((m: ManagementMap) => {
          m = new ManagementMap(m);
          // update the clientMapStore
          const storeIdx = this.mapStore.findIndex(
            s => s.MapConfigurationId === m.MapConfigurationId
          );
          if (storeIdx > -1) {
            this.mapStore[storeIdx] = m;
          } else {
            this.mapStore.push(m);
          }

          return m;
        })
      );
  }

  create(currentMap: ManagementMap): Observable<ManagementMap> {
    const headers = new HttpHeaders({
      RequestOrigin: 'Converge'
    });

    return this.http.post<ManagementMap>(`/api/mapConfigurations/`, currentMap, { headers }).pipe(
      map((m: ManagementMap) => {
        m = new ManagementMap(m);
        this.mapStore.push(m);

        return m;
      })
    );
  }

  delete(mmap: ManagementMap): Observable<void> {
    return this.http.delete<void>(`/api/mapConfigurations/${mmap.MapConfigurationId}`).pipe(
      map(() => {
        // update the mapStore
        const storeIdx = this.mapStore.findIndex(
          s => s.MapConfigurationId === mmap.MapConfigurationId
        );
        if (storeIdx > -1) this.mapStore.splice(storeIdx, 1);

        return;
      })
    );
  }
}
