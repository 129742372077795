import { Directive, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription, firstValueFrom, interval } from 'rxjs';

import { ArgoGateway } from '../argo.gateway';
import { ToastService } from '../toast/toast.service';

@Directive({
  selector: 'check-edit-possible'
})
export class CheckEditPossibleDirective implements OnInit, OnDestroy {
  intervalSub: Subscription;
  private checkIntervalTime = 20000;
  hasAlreadySeenError = false;

  @Output() statusChange = new EventEmitter<boolean>();

  constructor(private argoGateway: ArgoGateway, private toaster: ToastService) {}

  ngOnInit() {
    this.intervalSub = interval(this.checkIntervalTime).subscribe(() => {
      if (!navigator.onLine) return;
      this.checkIfStageIsUp();
    });

    this.checkIfStageIsUp();
  }

  ngOnDestroy() {
    if (this.intervalSub) this.intervalSub.unsubscribe();
  }

  private async checkIfStageIsUp() {
    try {
      await firstValueFrom(this.argoGateway.checkIfStageIsUp());
      this.onSuccess();
    } catch {
      this.onError();
    }
  }

  private onSuccess() {
    if (this.hasAlreadySeenError) {
      this.hasAlreadySeenError = false;
      this.statusChange.emit(true);

      this.toaster.showSuccess(
        'Resolved!',
        `At this time, all technical issues have been resolved.
           Contact our support team if you’re still experiencing system errors.`,
        8000
      );
    }
  }

  private onError() {
    if (!this.hasAlreadySeenError) {
      this.toaster.showError(
        'We’re working on it.',
        `We are currently experiencing an issue that is resulting in limited access for some sections of Converge.  
        We are actively working on getting all modules back online.  We apologize for any inconvenience this may have caused.`
      );

      this.hasAlreadySeenError = true;
      this.statusChange.emit(false);
    }
  }
}
