<div class="form-row" [ngClass]="{expanded: isOpen, dirty: isDirty, invalid: isInvalid }">
    <div class="header" (click)="toggle()">
        <ng-content select="[header-title]"></ng-content>
        <div class="button-group">
            <button *ngIf="allowDuplicate" class="btn standard-positive" (click)="doDuplicate($event)">
                <span class="icon">
                    <svg-helper svgName="duplicate"></svg-helper>
                </span>
                <span class="label">Duplicate &amp; Add</span>
            </button>
            <button *ngIf="allowSave" class="btn priority-positive" (click)="handleSave($event)">
                <span class="icon">
                    <svg-helper svgName="save"></svg-helper>
                </span>
                <span class="label">Save</span>
            </button>
            <button *ngIf="allowPublish" class="btn priority-positive" (click)="handlePublish($event)">
                <span class="icon">
                    <svg-helper svgName="save"></svg-helper>
                </span>
                <span class="label">Publish</span>
            </button>
            <publish-progress *ngIf="publishing" [publish]="publish"></publish-progress>
            <button *ngIf="allowDelete" class="btn standard-negative" (click)="delete.emit()">
                <span class="icon">
                    <svg-helper svgName="trash_can"></svg-helper>
                </span>
                <span class="label">Delete</span>
            </button>
            <button *ngIf="allowEditRequest" class="btn standard-positive" (click)="requestEdit.emit()">
                <span class="icon">
                    <svg-helper svgName="pencil_box"></svg-helper>
                </span>
                <span class="label">Change</span>
            </button>
            <div class="arrow">
                <div class="icon">
                    <svg-helper svgName="carrot_arrow_right"></svg-helper>
                </div>
            </div>
        </div>
    </div>
    <div class="body" [@heightExpandCollapse]="animationState">
        <ng-content select="[body]"></ng-content>
    </div>
</div>