<div class="form-box bordered fadeIn tag-remover">
  <div class="box-header" (click)="toggleAccordion()">
    <div>All Tags Used</div>
    <div class="right-header">
      <div *ngIf="tagCount" class="tag-count">
        <span>{{tagCount}}</span>
      </div>
      <div class="arrow" [ngClass]="{expanded: isOpen}">
        <!-- <div class="icon"> -->
        <svg-helper svgName="carrot_arrow_right"></svg-helper>
        <!-- </div> -->
      </div>
    </div>
  </div>
  <div [@heightExpandCollapse]="animationState">
    <div class="box-content">
      <div *ngFor="let tag of displayedTags; trackBy: trackByTagId" class="tag">
        <span>{{tag.Name}}</span>
        <button class="btn quick" matTooltip="Remove Tag" (click)="handleConfirmDeleteModal(tag)">
          <svg-helper svgName="trash_can_bold"></svg-helper>
        </button>
      </div>
    </div>
  </div>
  <confirm-modal *ngIf="showDeleteConfirm" (cancel)="toggleDeleteConfirm()" (proceed)="handleDelete()">
    <div class="message">
      <div class="icon large">
        <svg-helper svgName="alert_bold"></svg-helper>
      </div>
      <div class="title">Remove Tags?</div>
      <p>Are you sure you want to permanently remove {{mappingsToBeRemoved.length}} instances of "{{mappingsToBeRemoved[0].Name}}" from the Community?</p>
    </div>
  </confirm-modal>
</div>