import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnInit
} from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { SettingVM } from '../models/settings-vm';
import { SettingAndFileDTO } from '../../content-editor/shared/base-page-edit.component';

@Component({
  selector: 'std-settings-edit-accordion',
  templateUrl: './std-settings-edit-accordion.component.html',
  styleUrls: ['./std-settings-edit-accordion.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StdSettingsEditAccordionComponent implements OnChanges, OnInit {
  @Input() allSettings: SettingVM[];
  @Input() settingsGroupTitle: string;
  @Input() settingsCategoryTitle: string;
  @Output() settingChange = new EventEmitter<SettingVM>();
  @Output() settingDelete = new EventEmitter<SettingVM>();
  @Output() settingFileUpload = new EventEmitter<SettingAndFileDTO>();
  allFilteredSettings: SettingVM[] = [];
  allSettingsEditable = false;
  filesToUpload: File[] = [];
  filesToDelete: string[] = [];

  constructor(protected auth: AuthService) {
    this.allSettingsEditable = this.auth.currentUser.EditorPermissions.Overall;
  }

  ngOnInit(): void {
    if (!this.settingsGroupTitle) this.settingsGroupTitle = this.settingsCategoryTitle;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.allSettings) {
      this.allFilteredSettings = this.allSettings?.filter(
        s => s.SettingsCategoryName === this.settingsCategoryTitle
      );
    }
  }
  handleSettingChange(settingVM: SettingVM) {
    this.settingChange.emit(settingVM);
  }

  handleDeleteSetting(settingVM: SettingVM) {
    this.settingDelete.emit(settingVM);
  }

  uploadFileForSetting(settingWithFile: SettingAndFileDTO) {
    this.settingFileUpload.emit(settingWithFile);
  }
}
