import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {
  private dataLayer: any;

  constructor() {
    this.dataLayer = (window as any).dataLayer;
  }

  push(evtData: any) {
    if (this.dataLayer) this.dataLayer.push(evtData);
  }
}
