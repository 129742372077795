export class Status {
  StatusId: string; // actually a GUID
  StatusGroupId: string; // actually a GUID
  LegacyId: number;
  StatusType: number;
  Name: string;
  Description: string;
  ColorHexValue = 'ffffff';
  FocusColorHexValue = '';
  Code: string;
  SortOrder: number;
  CustomSymbol = '';

  get ColorHex() {
    return this.ColorHexValue ? '#' + this.ColorHexValue.replace('#', '') : '';
  }

  set ColorHex(hex: string) {
    if (hex) this.ColorHexValue = hex.replace('#', '');
  }

  get FocusColorHex() {
    return this.FocusColorHexValue ? '#' + this.FocusColorHexValue.replace('#', '') : '';
  }

  set FocusColorHex(hex: string) {
    if (hex) this.FocusColorHexValue = hex.replace('#', '');
  }

  constructor(data?: Status) {
    Object.assign(this, data);
  }
}

export class StatusGroup {
  StatusGroupId: string; // actually a GUID
  MapConfigurationId: string; // actually a GUID
  Title = '';
  Status: Status[] = new Array<Status>();
  IsDefault: boolean;

  constructor(data?: StatusGroup) {
    Object.assign(this, data);
    this.Status = this.Status.map(s => new Status(s));
  }
}

export class ManagementMap {
  MapConfigurationId: string; // actually a GUID
  ClientId: number;
  CustomDataDisplayFields: string[] = [];
  PublicName: string;
  Name: string;
  Description: string;
  MapType: string;
  StatusGroup: StatusGroup[] = [];
  Neighborhood: number[] = [];
  SortOrder: number;
  StandardFieldsToHide: string[] = [];
  SelectedLotColorHexValue: string;
  ShowSecondarySymbolsByDefault = false;

  get SelectedLotColorHex() {
    if (this.SelectedLotColorHexValue) return '#' + this.SelectedLotColorHexValue;
    else return this.SelectedLotColorHexValue;
  }

  set SelectedLotColorHex(hex: string) {
    if (hex) this.SelectedLotColorHexValue = hex.replace('#', '');
  }

  private _primarySG: StatusGroup = null;
  get PrimaryStatusGroup(): StatusGroup {
    if (!this._primarySG) this._primarySG = this.StatusGroup.find(s => s.IsDefault);
    return this._primarySG;
  }

  // finds the first non-default SG
  private _secondSG: StatusGroup = null;
  get SecondaryStatusGroup(): StatusGroup {
    if (!this._secondSG) this._secondSG = this.StatusGroup.find(s => !s.IsDefault);
    return this._secondSG;
  }

  get SafeName(): string {
    return this.PublicName || this.Name;
  }

  constructor(data?: ManagementMap) {
    Object.assign(this, data);
    this.StatusGroup = this.StatusGroup.map(s => new StatusGroup(s));
  }

  assignNeighborhood(id: number) {
    if (this.Neighborhood.indexOf(id) === -1) {
      this.Neighborhood.push(id);
    }
  }

  unassignNeighborhood(id: number) {
    const i = this.Neighborhood.indexOf(id);
    if (i > -1) {
      this.Neighborhood.splice(i, 1);
    }
  }

  isFieldHidden(field: string): boolean {
    return this.StandardFieldsToHide.indexOf(field) > -1;
  }
}
