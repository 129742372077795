import { OrderLocation } from '../entities/order';
import { ProductInstance } from '../entities/product';
import { OrderFormValuesBase } from '../orders/models/order-form-values-base';

export enum OrderItemType {
  Unset = 0,
  New = 1,
  Edit = 2,
  Reuse = 3
}

export class OrderProductInstance {
  OrderProductInstanceId: string;
  OrderId: string;
  ProductInstanceId: string;
  ProductInstance: ProductInstance;
  UniqueLocations: Array<OrderLocation> = new Array<OrderLocation>();
  SourceLocation: OrderLocation;
  OrderFormValues: OrderFormValuesBase;
  CreatedOn: string;
  ModifiedOn: string;
  Type: OrderItemType = 0;

  constructor(orderProductInstanceId?: string) {
    if (orderProductInstanceId) {
      this.OrderProductInstanceId = orderProductInstanceId;
    }
  }
}
