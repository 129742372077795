<div *ngIf="isShowing" [@scaleInOut]="'in'" class="toast-container" [class.blocking]="options.infinite">
    <div *ngIf="options.onlyLoadingIndicator" id="loading-indicator" class="backdrop">
        <div class="threedotloader">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </div>
    <div *ngIf="!options.onlyLoadingIndicator" class="backdrop" [ngClass]="{success: options.useSuccessStyle, error: options.useErrorStyle}">
        <div class="icon" *ngIf="options.iconName">
            <svg-helper [svgName]="options.iconName"></svg-helper>
        </div>
        <div *ngIf="options.loadingIndicatorAndMessage" id="loading-indicator">
            <div class="threedotloader">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>
        <div class="title">{{options.title}}</div>
        <div class="message" [innerHtml]="options.message"></div>
    </div>
</div>