import {
  throwError as observableThrowError,
  of as observableOf,
  Observable,
  firstValueFrom
} from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../entities/user';
import { Dictionary } from '../shared/data.structures';

@Injectable()
export class UserService {
  userStore: Dictionary<User>;
  clientHistory: number[];
  roleHistory: string[];

  constructor(private http: HttpClient) {
    this.userStore = new Dictionary<User>();
    this.clientHistory = new Array<number>();
    this.roleHistory = new Array<string>();
  }

  getByUsername(username: string): Observable<User> {
    // check store first
    const user = this.userStore.values().find(u => u.UserName === username);
    if (user) {
      return observableOf(user);
    } else {
      return this.http.get<User>(`/api/users/${username}`).pipe(map(this.instantiateAndAddToStore));
    }
  }

  getById(id: string): Observable<User> {
    // check store first
    const user = this.userStore[id];
    if (user) {
      return observableOf(user);
    } else {
      return this.http.get<User>(`/api/users/${id}`).pipe(map(this.instantiateAndAddToStore));
    }
  }

  private instantiateAndAddToStore = (u: User) => {
    u = new User(u);
    this.userStore.addOrUpdate(u.Id, u);
    return u;
  };

  getByClient(clientId: number, onlyActive = true): Observable<User[]> {
    // clientHistory tracks whether we have done an http get for all users by client
    if (this.clientHistory.indexOf(clientId) > -1) {
      return observableOf(this.userStore.values().filter(u => u.ClientId === clientId));
    } else {
      return this.http.get<User[]>(`/api/clients/${clientId}/users`).pipe(
        map(users => {
          users = users.filter(u => !onlyActive || u.IsActive).map(this.instantiateAndAddToStore);

          // add client id to history
          this.clientHistory.push(clientId);

          return users;
        }),
        catchError((error: Error) => {
          return observableThrowError(error);
        })
      );
    }
  }

  async search(searchTerm: string, clientId: number, roles: string[]) {
    const users = await this.http
      .get<User[]>(
        `/api/users/search?searchTerm=${searchTerm}&clientId=${clientId}&roles=${roles.join(',')}`
      )
      .toPromise();
    return users.map(x => new User(x));
  }

  getByRoleName(roleName: string, onlyActive = true): Observable<User[]> {
    // clientHistory tracks whether we have done an http get for all users by client
    if (this.roleHistory.indexOf(roleName) > -1) {
      return observableOf(this.userStore.values().filter(u => u.hasRole(roleName)));
    } else {
      return this.http.get<User[]>(`/api/roles/${roleName}/users`).pipe(
        map(users => {
          users = users.filter(u => !onlyActive || u.IsActive).map(this.instantiateAndAddToStore);

          // add client id to history
          this.roleHistory.push(roleName);

          return users;
        }),
        catchError((error: Error) => {
          return observableThrowError(error);
        })
      );
    }
  }

  create(user: User): Promise<User> {
    const body = {
      user,
      password: ''
    };
    body.user.IsActive = true;

    return firstValueFrom(
      this.http.post<User>(`/api/users`, body).pipe(map(this.instantiateAndAddToStore))
    );
  }

  delete(user: User) {
    user.IsActive = false;
    user.PIN = null;
    user.Roles = [];
    return this.http.put(`/api/users/${user.Id}`, user).pipe(
      tap(() => {
        this.userStore.remove(user.Id);
      })
    );
  }

  update(user: User): Observable<User> {
    return this.http
      .put<User>(`/api/users/${user.Id}`, user)
      .pipe(map(this.instantiateAndAddToStore));
  }

  updatePassword(userName: string, password: string): Observable<User> {
    const body = {
      userName,
      password
    };

    return this.http
      .patch<User>(`/api/users/${userName}`, body)
      .pipe(map(this.instantiateAndAddToStore));
  }

  updateAddresses(user: User): Observable<User> {
    return this.http
      .patch<User>(`/api/users/${user.UserName}/addresses`, user.Addresses)
      .pipe(map(this.instantiateAndAddToStore));
  }

  resendInvite(email: string) {
    return this.http.post(`/api/users/resend-invite/${email}`, null);
  }

  updateFtpPassword(userName: string, ftpPassword: string): Observable<User> {
    const body = {
      userName,
      password: ftpPassword
    };

    return this.http
      .patch<User>(`/api/users/ftp/${userName}`, body)
      .pipe(map(this.instantiateAndAddToStore));
  }

  /**
   * Gets a PIN unique with the given client
   *
   * @param clientId
   */
  generatePIN(clientId: number): Observable<number> {
    return this.http.get<number>(`/api/users/pin/${clientId}`);
  }
}
