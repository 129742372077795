import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
    selector: 'tile',
    templateUrl: './tile.component.html',
    styleUrls: ['./tile.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TileComponent implements OnInit {
    @Input() title: string;
    @Input() svgName: string;

    constructor() { }

    ngOnInit() {
    }

}
