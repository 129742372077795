import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeBackgroundImageUrl'
})
export class SafeBackgroundImageUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url: string) {
    if (!url) return '';
    return this.sanitizer.bypassSecurityTrustStyle(`url('${url}')`);
  }
}
