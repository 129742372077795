import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Root } from './root';
import { RootDocumentContainer } from './root-document-container';

@Injectable()
export class RootService {
  constructor(private http: HttpClient) {}

  // pass the full document id of the object which is the "root" of the RDC -- inlcudes document name: "floorplans/123"
  // this function assumes includeDocument=true which which means the only return type is Root
  getByDocumentId(id: string): Observable<Root> {
    return this.http.get<Root[]>(`/api/containers/${id}?includeDocuments=true`).pipe(
      map((res: Root[]) => {
        // RDCs now return as array -- temp fix use [0]
        const root = Object.assign(new Root(), res[0]);

        // standardize all keys to lower case
        const keys = Object.keys(root.Data);
        const lowerCaseData = {};
        keys.forEach(k => {
          lowerCaseData[k.toLowerCase()] = root.Data[k];
        });
        root.Data = lowerCaseData;
        root.RootDocumentContainer.Document = root.RootDocumentContainer.Document.toLowerCase();

        return root;
      })
    );
  }

  // get RDC based on its root document and product id
  getByDocumentAndProduct(id: string, productId: number): Observable<Root> {
    return this.http
      .get<Root>(`/api/containers/${id}/product/${productId}?includeDocuments=true`)
      .pipe(map(r => new Root(r)));
  }

  update(rdc: RootDocumentContainer): Observable<RootDocumentContainer> {
    return this.http.put<RootDocumentContainer>(`/api/containers/${rdc.numberId}`, rdc);
  }

  create(rdc: RootDocumentContainer): Observable<RootDocumentContainer> {
    return this.http.post<RootDocumentContainer>(`/api/containers`, rdc);
  }
}
