<accordion-form [allowDuplicate]="false" [allowDelete]="false" [closedOnInit]="true" [internalNames]="[settingsCategoryTitle]">
  <div header-title>
    <div class="icon large">
      <svg-helper svgName="gear"></svg-helper>
    </div>
    <div class="title">{{settingsGroupTitle}}</div>
  </div>
  <div body>
    <div class="row">
      <div class="col-xs-12">
        <ng-container *ngIf="allSettings?.length">
          <settings-group
                          [settingVMs]="allFilteredSettings"
                          [settingsEditable]="allSettingsEditable"
                          (settingChange)="handleSettingChange($event)"
                          (settingDelete)="handleDeleteSetting($event)"
                          (settingFileUploadChange)="uploadFileForSetting($event)"></settings-group>
        </ng-container>
      </div>
    </div>
  </div>
</accordion-form>