import { property as r } from "./property.js";
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
function t(t) {
  return r({
    ...t,
    state: !0
  });
}
export { t as state };
