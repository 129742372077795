import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';

import { OnChanges } from '@angular/core';
import { HotspotType, HotspotVM } from '../../content-editor/models/hotspot-vm';

@Component({
  selector: 'hotspot-marker',
  templateUrl: './hotspot-marker.component.html',
  styleUrls: ['./hotspot-marker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HotspotMarkerComponent implements OnInit, OnChanges {
  @Input() marker: HotspotVM;
  @Input() scale = 1;
  @Input() offsetX: number;
  @Input() offsetY: number;
  @Input() size = 1;
  @Input() sizeBase = 0;
  @Input() radar = 0;
  @Output() selected = new EventEmitter();
  @Output() markerChanged = new EventEmitter<HotspotVM>();
  transform = '';
  markerIcon: string;
  markerClass: string;
  scaleSize = 1;
  radarTransform = '';
  hasRadar = false;

  constructor(private ref: ElementRef) {}

  ngOnInit() {
    this.mouseInit();

    // at this time size and sizeBase should only be used in ISM map-markers
    if (this.sizeBase !== 0) {
      this.scaleSize = this.size / this.sizeBase;
    }
    this.transform = this.getTransform();
    this.radarTransform = `rotate(${this.radar + 45}deg)`;
  }

  ngOnChanges(changes: SimpleChanges) {
    switch (this.marker.Type) {
      case HotspotType.Neighborhood:
        this.markerIcon = 'community';
        break;
      case HotspotType.AmenityMap:
        this.markerIcon = 'amenity_map';
        break;
      case HotspotType.Media:
        this.markerIcon = 'camera_frame';
        this.markerClass = 'full-circle';
        this.hasRadar = this.radar != null && !isNaN(this.radar);
        break;
    }

    if (changes.size && changes.size.currentValue) {
      this.scaleSize = this.size / this.sizeBase;
    }

    if (changes.radar && changes.radar.currentValue !== changes.radar.previousValue) {
      this.radarTransform = `rotate(${this.radar + 45}deg)`;
    }

    if (changes.radar && changes.radar.currentValue === null) {
      this.radarTransform = `rotate(45deg)`;
    }

    if (changes.size || changes.scale || changes.offsetX || changes.offsetY)
      this.transform = this.getTransform();
  }

  mouseInit = () => {
    this.ref.nativeElement.removeEventListener('mousedown', this.mouseDown);
    this.ref.nativeElement.addEventListener('mousedown', this.mouseDown);
  };

  getTransform() {
    return `translate(${this.marker.X - this.sizeBase}px, ${
      this.marker.Y - this.sizeBase
    }px) scale(${this.scaleSize * (1 / this.scale)}) rotate(-45deg)`;
    // adjusting with counter scale is needed for IFP and ISM markers
    // MyScp community overall map has removed counter scale because it causes the point on marker shape to be inconsistent
  }

  mouseDown = (evt: MouseEvent) => {
    evt.stopPropagation();
    document.addEventListener('mousemove', this.mouseMove);
    document.addEventListener('mouseup', this.mouseUp);
  };

  mouseMove = (evt: any) => {
    const newX =
      (evt.clientX - this.offsetX) / this.scale - this.ref.nativeElement.firstChild.clientWidth / 2;
    const newY =
      (evt.clientY - this.offsetY) / this.scale -
      this.ref.nativeElement.firstChild.clientHeight / 2;
    this.marker.X = newX + this.sizeBase;
    this.marker.Y = newY + this.sizeBase;
    this.transform = this.getTransform();
  };

  mouseUp = (evt: MouseEvent) => {
    document.removeEventListener('mousemove', this.mouseMove);
    document.removeEventListener('mouseup', this.mouseUp);
    this.marker.X = Math.round(this.marker.X);
    this.marker.Y = Math.round(this.marker.Y);
    this.mouseInit();
    this.markerChanged.emit(this.marker);
  };

  selectMarker(evt?: MouseEvent) {
    if (evt) evt.stopPropagation();
    this.selected.emit(this.marker);
  }
}
