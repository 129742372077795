import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, of as observableOf, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Lookup } from '../shared/lookup';
import { AutomationDataVM } from '../shared/models/automation-data-vm';

@Injectable({
  providedIn: 'root'
})
export class ReportAutomationService {
  store: AutomationDataVM;

  constructor(private http: HttpClient) {}

  getParsedAutomationData(): Observable<AutomationDataVM> {
    if (this.store) {
      return observableOf(this.store);
    } else {
      const options = {
        headers: new HttpHeaders({ [Lookup.SkipAuthHeader]: 'true' }),
        responseType: 'text' as 'text' // casting as 'text' because nonsense Angular type declaration issue
      };
      return this.http.get('/assets/config/report-automation.json', options).pipe(
        map(res => {
          this.store = new AutomationDataVM(JSON.parse(res));
          return this.store;
        }),
        catchError((error: Error) => {
          return observableThrowError(error);
        })
      );
    }
  }
}
