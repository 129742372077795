import { Dictionary } from '../shared/data.structures';

export class Container {
  Name: string;
  Document: string;
  Containers: Dictionary<Container>;

  constructor(name: string, doc: string) {
    this.Name = name;
    this.Document = doc;
    this.Containers = new Dictionary<Container>();
  }
}
