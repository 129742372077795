import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';

import { SettingFieldType } from '@ml/common';
import { Lookup } from 'app/shared/lookup';
import { ExpandingSet, ExpandingSetItem } from '../expanding-set/expanding-set.component';
import { SettingVM } from '../models/settings-vm';
import {
  MultiExpandingSet,
  MultiExpandingSetItem
} from '../multi-expanding-set/multi-expanding-set.component';

@Component({
  selector: 'form-control-projector',
  templateUrl: './form-control-projector.component.html',
  styleUrls: ['./form-control-projector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormControlProjectorComponent implements OnInit, OnChanges {
  @Input() fieldType: SettingFieldType;
  @Input() value: any;
  @Input() valueOriginal: any;
  @Input() options: string | string[];
  @Input() extraData: any;
  @Input() useStage = false;
  @Input() disabled = false;
  @Input() enabledProductList: SettingVM[];
  @Input() settingsCategoryId: number;
  @Input() isSetAtHigherTier = false; // useful if setting value is an array that should be replaced among tiers and not combined
  @Output() valueChange = new EventEmitter<string>(); // used implicitly by Angular for ngModel
  @Output() fileInput = new EventEmitter<any>();
  @Output() fileChange = new EventEmitter<{
    FileToUpload?: File;
    FileToDelete?: string;
  }>();
  @Output() validateSetting = new EventEmitter<boolean>();
  @Output() valueOriginalChange = new EventEmitter();
  settingFieldTypes = SettingFieldType;

  // value formatted to fit its specific field type
  formattedValue: any;
  dropdownOptions: Array<string>;
  analyticsConfig: IAnalyticsConfig;

  maxLength: number = null;
  maxValue: number = null;
  minValue: number = null;
  canSort: boolean = null;
  fileBaseUrl: string;
  stageApiBase = window.location.protocol + '//' + Lookup.Argo.StageHost;

  ngOnInit() {
    this.fileBaseUrl = this.useStage
      ? Lookup.ApiStageFsProductDataEndpoint
      : Lookup.ProductDataBaseUrl;

    if (
      (this.fieldType === SettingFieldType.TextBox ||
        this.fieldType === SettingFieldType.TextArea) &&
      this.options &&
      this.options.includes('maxLength')
    ) {
      this.maxLength = this.getFieldDataValue('maxLength', this.options as string, 'int');
    }
    if (
      this.fieldType === SettingFieldType.NumberBox &&
      this.options &&
      this.options.includes('maxValue')
    ) {
      this.maxValue = this.getFieldDataValue('maxValue', this.options as string, 'int');
    }
    if (
      this.fieldType === SettingFieldType.NumberBox &&
      this.options &&
      this.options.includes('minValue')
    ) {
      this.minValue = this.getFieldDataValue('minValue', this.options as string, 'int');
    }
    if (
      this.fieldType === SettingFieldType.ExpandingSet &&
      this.options &&
      this.options.includes('canSort')
    ) {
      this.canSort = this.getFieldDataValue('canSort', this.options as string, 'bool');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.formattedValue = this.fromPrimitiveToFormatted(this.value);
    }
  }

  onChildChange(newValue) {
    this.formattedValue = newValue;
    this.change(newValue);
  }

  change(newValue) {
    if (this.minValue) {
      if (this.minValue > newValue) {
        this.value = this.fromFormattedToPrimitive(this.minValue);
        this.valueChange.emit(this.value);
      } else {
        this.value = this.fromFormattedToPrimitive(newValue);
        this.valueChange.emit(this.value);
      }
    } else {
      this.value = this.fromFormattedToPrimitive(newValue);
      this.valueChange.emit(this.value);
    }
  }

  fromPrimitiveToFormatted(value) {
    let retval = null;

    switch (this.fieldType) {
      case SettingFieldType.ExpandingSet:
        retval = this.convertToExpandingSet(value);
        break;
      case SettingFieldType.CheckBox:
        retval = value && (value === 'true' || value === true) ? true : false;
        break;
      case SettingFieldType.DropDown:
        retval = value;
        this.dropdownOptions = this.convertToDropdown(this.options);
        break;
      case SettingFieldType.TextBox:
        retval = value;
        break;
      case SettingFieldType.TextArea:
        retval = value;
        break;
      case SettingFieldType.ColorPicker:
        retval = value;
        break;
      case SettingFieldType.AnalyticsConfig:
        retval = this.convertToAnalyticsConfig(value);
        break;
      case SettingFieldType.FileUpload:
        const parsed = value ? JSON.parse(value) : [];
        retval = parsed instanceof Array ? parsed : [];
        break;
      case SettingFieldType.FloorplanSocial:
        try {
          retval = value ? JSON.parse(value) : '';
        } catch (err) {
          console.error('Failed to parse: ', value);
        }
        break;
      case SettingFieldType.MultiExpandingSet:
        retval = this.convertToMultiExpandingSet(value);
        break;
      case SettingFieldType.Icon:
        retval = value;
        break;
      default:
        retval = value;
        break;
    }

    return retval;
  }

  fromFormattedToPrimitive(value): string {
    let rawValue = '';

    switch (this.fieldType) {
      case SettingFieldType.ExpandingSet:
        const set: ExpandingSet = value;
        const justText = set.stored.map(x => x.name);
        rawValue = JSON.stringify(justText);
        break;
      case SettingFieldType.CheckBox:
        rawValue = JSON.stringify(value);
        break;
      case SettingFieldType.DropDown:
        rawValue = value;
        break;
      case SettingFieldType.TextBox:
        rawValue = value;
        break;
      case SettingFieldType.TextArea:
        rawValue = value;
        break;
      case SettingFieldType.ColorPicker:
        rawValue = value;
        break;
      case SettingFieldType.AnalyticsConfig:
        rawValue = JSON.stringify(this.analyticsConfig);
        break;
      case SettingFieldType.FileUpload:
        rawValue = JSON.stringify(value);
        break;
      case SettingFieldType.MultiExpandingSet:
        const multiSet: MultiExpandingSet = value;
        const anObj = multiSet.stored.map(x => {
          return {
            reportTitle: x.reportTitle,
            body: x.body,
            report: x.report,
            group: x.group,
            tableName: x.tableName,
            keyValuePairs: x.keyValuePairs,
            productSettingsCategoryTitle: x.productSettingsCategoryTitle,
            showTabs: x.showTabs
          } as MultiExpandingSetItem;
        });
        rawValue = JSON.stringify(anObj);
        break;
      case SettingFieldType.Icon:
        rawValue = value;
        break;
      case SettingFieldType.NumberBox:
        rawValue = !this.maxValue || value <= this.maxValue ? value : this.maxValue;
        break;
      default:
        rawValue = value;
        break;
    }

    return rawValue;
  }

  onFileInput(evt: any) {
    this.fileInput.emit(evt.target.files);
    evt.target.value = null;
  }

  onValidateSetting(isValid: boolean) {
    this.validateSetting.emit(isValid);
  }

  private convertToDropdown(value: string | string[]) {
    if (value instanceof Array) return value;
    const array = value.split('|');
    return array;
  }

  private convertToMultiExpandingSet(rawValue): MultiExpandingSet {
    const set = new MultiExpandingSet();
    const parsed: MultiExpandingSetItem[] = rawValue ? JSON.parse(rawValue) : [];
    if (Array.isArray(parsed)) {
      set.stored = parsed.map(x => {
        const i = new MultiExpandingSetItem();
        i.reportTitle = x.reportTitle;
        i.body = x.body;
        i.report = x.report;
        i.group = x.group;
        i.tableName = x.tableName;
        i.keyValuePairs = x.keyValuePairs;
        i.productSettingsCategoryTitle = x.productSettingsCategoryTitle;
        i.showTabs = x.showTabs;
        return i;
      });
      set.reindex();
    }
    return set;
  }

  private convertToExpandingSet(rawValue: string): ExpandingSet {
    const set = new ExpandingSet();

    const parsed: string[] = rawValue ? JSON.parse(rawValue) : [];
    if (Array.isArray(parsed)) {
      set.stored = parsed.map(x => {
        const i = new ExpandingSetItem();
        i.name = x;
        return i;
      });

      set.reindex();
    }

    return set;
  }

  private convertToAnalyticsConfig(stringValue: string) {
    this.analyticsConfig = JSON.parse(stringValue) as IAnalyticsConfig;
    return this.analyticsConfig;
  }

  private getFieldDataValue(valueName: string, fieldData: string, typeReturn: string) {
    const optionsArr = fieldData.split('&');
    const val = optionsArr.find(x => x.includes(valueName));

    switch (typeReturn) {
      case 'int':
        if (val) return parseInt(val.slice(val.indexOf('=') + 1), 10);
        break;
      case 'bool':
        if (val) return JSON.parse(val.slice(val.indexOf('=') + 1));
        break;
    }
    return null;
  }

  onAdvancedMediaValueChange(settingValue: string) {
    this.valueChange.emit(settingValue);
  }

  onAdvancedMediaFileAdd(fileToUpload: File) {
    this.fileChange.emit({
      FileToUpload: fileToUpload
    });
  }

  onAdvancedMediaFileDelete(fileToDelete: string) {
    this.fileChange.emit({
      FileToDelete: fileToDelete
    });
  }
}

interface IAnalyticsConfig {
  isEnabled: boolean;
  url: string;
  name: string;
}
