export class AclRole {
    UserId: string;
    RoleId: string;
    Name: string;
    IsAllowed: boolean;
    LastModified: string;
}

export class AclOperation {
    OperationName: string;
    Roles: AclRole[];
    Principals: any[];
    IsSpecial: boolean;
}

export class ACL {
    Id: string;
    PublicName: string;
    ResourceName: string;
    Domain: string;
    Category: string;
    Operations: AclOperation[];
}
