import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { Publish } from '../../content-editor/models/publish';

@Component({
  selector: 'publish-progress',
  templateUrl: './publish-progress.component.html',
  styleUrls: ['./publish-progress.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublishProgressComponent implements OnInit {
  @Input() publish: any;

  constructor() {}

  ngOnInit(): void {}
}
