import {
  Component,
  ViewEncapsulation,
  Input,
  EventEmitter,
  Output,
  ChangeDetectionStrategy
} from '@angular/core';
import { SettingVM } from '../models/settings-vm';
import { SettingAndFileDTO } from '../../content-editor/shared/base-page-edit.component';
import { ToastService } from '../toast/toast.service';
import { GoogleMapsService } from '../../content-editor/services/google-maps.service';

@Component({
  selector: 'settings-group',
  templateUrl: './settings-group.component.html',
  styleUrls: ['./settings-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsGroupComponent {
  @Input() settingVMs: SettingVM[] = [];
  @Input() settingsEditable = false;
  @Input() headerLabel: string;
  @Input() hideHeader = false;
  @Input() headerHelpText = '';
  @Output() settingChange = new EventEmitter<SettingVM>();
  @Output() settingDelete = new EventEmitter<SettingVM>();
  @Output() settingFileUploadChange = new EventEmitter<SettingAndFileDTO>();
  needsSave = false;

  constructor(private toaster: ToastService, private googleMaps: GoogleMapsService) {}

  onSettingChange(value: string, settingVM: SettingVM) {
    settingVM.Value = value;
    this.settingChange.emit(settingVM);
  }

  onSettingDelete(settingVM: SettingVM) {
    this.settingDelete.emit(settingVM);
  }

  onFileUpload(fileList: FileList, settingVM: SettingVM) {
    this.settingFileUploadChange.emit(new SettingAndFileDTO(settingVM, fileList));
  }

  handleValidateApiKey(key: string) {
    if (key) {
      this.toaster.showLoading();
      this.googleMaps.doPlaceSearch(key).then((data: any) => {
        if (data && data.status && (data.status === 'OK' || data.status === 'ZERO_RESULTS')) {
          this.toaster.showSuccess('Valid Google API Key');
        } else {
          this.toaster.showError(
            'Invalid Google API Key',
            `Api Key may be invalid if it doesn't match the key provided to you by Google.
            It may also be invalid if you have exceeded your quota.
            If the other two issues don't apply to you, Google may be having issues with their API.
            If this is the case, please try again later.`
          );
        }
      });
    }
  }

  trackBySettingsId(_, setting: SettingVM) {
    return setting.SettingsId;
  }

  onFileChange(data: { FileToUpload?: File; FileToDelete?: string }, settingVM: SettingVM) {
    if (data.FileToUpload) settingVM.FilesToUpload.push(data.FileToUpload);
    if (data.FileToDelete) {
      const indexOfFile = settingVM.FilesToUpload.findIndex(f => f.name === data.FileToDelete);
      if (indexOfFile > -1) {
        settingVM.FilesToUpload.splice(indexOfFile, 1);
      } else {
        settingVM.FilesToDelete.push(data.FileToDelete);
      }
    }
    this.settingChange.emit(settingVM);
  }
}
