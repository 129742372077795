import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';

import { CommunityDataLevel, IAmenityMap, IHotspotGroup } from '@ml/common';
import { IPage, PageVersion } from '../content-editor/services/page.service';
import { GenericHotspotVM } from '../content-editor/shared/generic-hotspot-vm';
import { Dictionary } from '../shared/data.structures';
import { Lookup } from '../shared/lookup';
import { Neighborhood } from './neighborhood.service';
import { SettingsCategory } from './settings';

// TODO: move Community class into its own file

@Injectable()
export class CommunityService {
  store: Dictionary<Community>;
  divisionHistory: number[];
  clientHistory: number[];
  storeByMapConfig = new Dictionary<Community[]>();
  stageApiBase = window.location.protocol + '//' + Lookup.Argo.StageHost;

  constructor(private http: HttpClient) {
    this.store = new Dictionary<Community>();
    this.divisionHistory = new Array<number>();
    this.clientHistory = new Array<number>();
  }

  getById(
    id: number,
    fromStage = false,
    dataLevel = CommunityDataLevel.Basic
  ): Observable<Community> {
    // check store first
    const comm = this.store[id];
    if (comm && !fromStage) {
      return observableOf(comm);
    } else {
      const baseHost = fromStage ? this.stageApiBase : '';
      const fullUrl = `${baseHost}/api/communities/${id}?dataLevel=${dataLevel}`;

      return this.http.get<Community>(fullUrl).pipe(
        map((c: Community) => {
          c = new Community(c);
          if (!fromStage) this.store.addOrUpdate(c.CommunityId.toString(), c);
          return c;
        })
      );
    }
  }

  getProductPermission(id: number, fromStage = false) {
    const base = fromStage ? this.stageApiBase : '';
    return this.http
      .get<ProductPermissions>(`${base}/api/communities/${id}/product-permissions`)
      .toPromise();
  }

  save(community: Community): Observable<Community> {
    const baseUrl = `${this.stageApiBase}/api/communities`;
    const observable = community.CommunityId
      ? this.http.put<Community>(`${baseUrl}/${community.CommunityId}`, community)
      : this.http.post<Community>(`${baseUrl}/`, community);

    return observable.pipe(map(c => new Community(c)));
  }

  getByCommunityIds(ids: number[], lite = false): Observable<Community[]> {
    const existingComms = [];
    const newCommIds = [];

    ids.filter(Boolean).forEach(id => {
      // check store first
      if (this.store[id]) {
        existingComms.push(this.store[id]);
      } else {
        newCommIds.push(id);
      }
    });

    const requestBody = { ids: newCommIds };

    if (newCommIds && newCommIds.length > 0) {
      return this.http
        .post<Community[]>(`/api/communities/list?${lite ? 'lite=true' : ''}`, requestBody)
        .pipe(
          map((newComms: Community[]) => {
            newComms = newComms.map(c => new Community(c));
            newComms.forEach(comm => {
              this.store.addOrUpdate(comm.CommunityId.toString(), comm);
            });

            newComms.push(...existingComms);

            return newComms;
          })
        );
    } else {
      return observableOf<Community[]>(existingComms);
    }
  }

  getByClientId(
    id: number,
    fromStage = false,
    includeProductPermissions = false,
    lite = false
  ): Observable<Community[]> {
    if (this.clientHistory.indexOf(id) > -1 && !fromStage) {
      return observableOf(this.store.values().filter(x => x.ClientId === id));
    } else {
      const url =
        `${fromStage ? this.stageApiBase : ''}/api/communities/client/${id}` +
        (includeProductPermissions ? '?includeProductPermissions=true' : '') +
        (lite ? '?lite=true' : '');

      return this.http.get<Community[]>(url).pipe(
        map(comms => {
          // only necessary since Community class has getters -- so must ensure these are actual class instances
          comms = comms.map(c => new Community(c));

          if (!fromStage) {
            comms.forEach(comm => {
              comm.ClientId = id;
              this.store.addOrUpdate(comm.CommunityId.toString(), comm);
            });

            const distinctDivisionsIds = comms
              .map(comm => comm.DivisionId)
              .filter((divisionId, i, arr) => {
                return arr.indexOf(divisionId) === i;
              });

            this.divisionHistory = this.divisionHistory.concat(distinctDivisionsIds);
            // add client id to history
            this.clientHistory.push(id);
          }

          return comms;
        })
      );
    }
  }

  getByDivisionId(
    id: number,
    withManagementMaps: boolean = false,
    includeActiveOnly: boolean = true,
    fromStage = false,
    includeAllNavigationProperties = false,
    includePages = false
  ): Observable<Community[]> {
    // divisionHistory tracks whether we have done an http get for all comms by divsion
    // if requesting withManagementMaps then skip the store
    if (
      !includeAllNavigationProperties &&
      !withManagementMaps &&
      includeActiveOnly &&
      this.divisionHistory.indexOf(id) > -1
    ) {
      return observableOf(this.store.values().filter(x => x.DivisionId === id));
    } else {
      // eslint-disable-next-line max-len
      let url = `/api/communities/project/${id}?withManagementMaps=${withManagementMaps}&includeActiveOnly=${includeActiveOnly}&includeAllNavigationProperties=${includeAllNavigationProperties}&includePages=${includePages}`;
      if (fromStage) url = this.stageApiBase + url;

      return this.http.get<Community[]>(url).pipe(
        map((comms: Community[]) => {
          comms = comms.map(c => new Community(c));
          // update store
          comms.forEach(x => {
            this.store.addOrUpdate(x.CommunityId.toString(), x);
          });

          // add divsion id to history
          if (!withManagementMaps) this.divisionHistory.push(id);

          return comms;
        })
      );
    }
  }

  getByMapConfigurationId(id: string): Observable<Community[]> {
    if (this.storeByMapConfig[id]) {
      return observableOf(this.storeByMapConfig[id]);
    } else {
      const url = `/api/communities/mapconfiguration/${id}`;

      return this.http.get<Community[]>(url).pipe(
        map((comms: Community[]) => {
          comms = comms.map(c => new Community(c));
          // update store
          this.storeByMapConfig.addOrUpdate(id, comms);

          return comms;
        })
      );
    }
  }

  updateDocumentEntity(community: Community): Observable<Community> {
    return this.http
      .put<Community>(
        `/api/communities-document/${community.Id.toLowerCase().replace('communities/', '')}`,
        community
      )
      .pipe(map(c => new Community(c)));
  }

  updateDocumentEntityWithFiles(community: Community, files: File[]): Observable<Community> {
    const formData = new FormData();
    files.forEach(file => {
      formData.append(file.name, file, file.name);
    });
    formData.append('community', JSON.stringify(community));
    return this.http
      .put<Community>(
        `/api/communities-document/${community.Id.toLowerCase().replace('communities/', '')}/files`,
        formData
      )
      .pipe(map(c => new Community(c)));
  }

  getCommunityDNSByDivisionId(projectId: number): Observable<Community[]> {
    return this.http
      .get<Array<Community>>(`/api/communities/GetCommunityDNSByDivisionId/${projectId}`)
      .pipe(
        map((comms: Array<Community>) => {
          comms = comms.map(c => new Community(c));
          comms.forEach(x => {
            const comm: Community = this.store[x.CommunityId];
            if (comm) {
              comm.Subdomain = x.Subdomain;
              this.store.addOrUpdate(comm.CommunityId.toString(), comm);
            }
          });

          return this.store.values().filter(x => x.DivisionId === projectId);
        })
      );
  }

  getAltCommunityDNSByDivisionId(projectId: number): Observable<CommunityThemeDTO[]> {
    return this.http.get<CommunityThemeDTO[]>(
      `/api/communities/GetAltCommunityDNSByDivisionId/${projectId}`
    );
  }
}

export class Community {
  CommunityId: number;
  ProjectId: number;
  ClientUniqueId: string;
  Name: string;
  PublicName: string;
  Id: string;
  ClientId: number;
  IsActive: boolean;
  MapSvgFilename: string;
  Subdomain: string;
  ClientName: string;
  AltSubdomain: string;
  AltThemeSettings = new Array<CommunityAlternateTheme>();
  Address: string;
  City: string;
  State: string;
  PostalCode: string;
  SalesCenterPhoneNumber: string;
  Latitude: string;
  Longitude: string;
  Neighborhoods: Neighborhood[] = [];
  ProductPermissions: ProductPermissions = new ProductPermissions();
  HasOfflineAccess: boolean;
  VirtualDirectory: string;
  SettingsCategories: SettingsCategory[];
  Pages: IPage[];
  CreatedOn: string;
  ModifiedOn: string;
  HotspotGroup: IHotspotGroup[];
  AmenityMaps: IAmenityMap[];
  Hotspots: GenericHotspotVM[] = [];
  IsMigratedFromRaven: boolean;
  ApiLastModifiedOn: string;
  HasExistingMyScpPages: boolean;

  LogoFilename: string; // Raven DB community

  get DivisionId(): number {
    return this.ProjectId;
  }

  set DivisionId(id: number) {
    this.ProjectId = id;
  }

  constructor(data?: Community) {
    Object.assign(this, data);
    this.Neighborhoods = this.Neighborhoods.map(n => new Neighborhood(n));
    this.HasExistingMyScpPages = this.Pages?.some(x => x.Version === PageVersion.MyScp);
  }
}

export class CommunityAlternateTheme {
  Subdomain: string;
  ThemeResourcesLocation: string;
}

export class CommunityThemeDTO {
  CommunityId: number;
  PublicName: string;
  Subdomain: string;
  ThemeResourcesLocation: string;
}

export class ProductPermissions {
  HasMyScpEnabled: boolean;
  HasMyScpStandaloneISMEnabled: boolean;
  HasMyScpStandaloneAreaMapEnabled: boolean;
  HasMyScpStandaloneVisualizerEnabled: boolean;
  HasHomeConfiguratorEnabled: boolean;
}
