import { ElementRef, ViewChild } from '@angular/core';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'video-with-preview',
  templateUrl: './video-with-preview.component.html',
  styleUrls: ['./video-with-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoWithPreviewComponent implements OnInit {
  @Input() src: string;
  @Input() previewUrl?: string;
  @ViewChild('video', { static: true }) video: ElementRef<HTMLVideoElement>;
  isVideoPlaying = false;

  constructor() {}

  ngOnInit() {}

  playVideo() {
    this.video.nativeElement.play();
  }

  onPause() {
    this.isVideoPlaying = false;
  }

  onPlay() {
    this.isVideoPlaying = true;
  }
}
