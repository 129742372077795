import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'alert-banner',
    templateUrl: './alert-banner.component.html',
    styleUrls: ['./alert-banner.component.scss']
})
// Alert message will be stored in session storage so that when user closes it once it does not show again for remainder of session
export class AlertBannerComponent implements OnInit {
    showAlert = true;
    @Input() name: string;
    @Input() isWarning = false;
    @Input() isError = false;
    @Input() isInfo = true; // default as Info
    @Input() allowClose = true;

    sessionStorageKey = 'viewed-alert-banners';

    constructor() {}

    ngOnInit() {
        // undo isInfo default if needed
        if (this.isError || this.isWarning) this.isInfo = false;

        const viewedAlerts = this.getViewedAlerts();
        this.showAlert = !viewedAlerts.some(m => this.name === m);
    }

    close() {
        this.showAlert = false;

        this.addViewedAlert(this.name);
    }

    private getViewedAlerts(): string[] {
        const viewedAlertsString = sessionStorage.getItem(this.sessionStorageKey);
        let viewedAlerts: string[] = new Array<string>();

        if (viewedAlertsString) viewedAlerts = JSON.parse(viewedAlertsString);

        return viewedAlerts;
    }

    private addViewedAlert(message: string) {
        const alerts = this.getViewedAlerts();

        if (alerts.indexOf(message) === -1) {
            alerts.push(message);
            sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(alerts));
        }
    }
}
