<div class="entity-editor-container">
    <section class="entity-editor" *ngFor="let group of iconGroups" [class.open]="group.IsAccordionOpen">
        <header class="entity-row-item">
            <button class="btn quick expander" (click)="group.IsAccordionOpen = !group.IsAccordionOpen">
                <div class="icon">
                    <svg-helper svgName="carrot_arrow"></svg-helper>
                </div>
            </button>
            <h4>{{group.Name}}</h4>
        </header>
        <div class="entity-form-content">
            <div class="icon-container">
                <div class="icon" *ngFor="let icon of group.Icons" (click)="iconSelect.emit(icon)" [class.selected]="icon.IconId === currentIconId">
                    <div class="preview" [innerHTML]="icon.SvgContent | safeHtml"></div>
                    <div class="icon-label" [matTooltip]="icon.Name">{{icon.Name}}</div>
                </div>
            </div>
        </div>
    </section>
</div>