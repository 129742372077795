import { DocumentBase } from './document-base';

export class RootDocumentContainer extends DocumentBase {
  public Document: string;

  public Product: string;

  public Containers: any;

  public AllDocuments: Array<string>;

  constructor(data?: RootDocumentContainer) {
    super();

    if (data) Object.assign(this, data);
  }
}
