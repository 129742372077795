import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ITag, sortByName } from '@ml/common';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { SimpleChanges } from '@angular/core';
import { enterLeaveFadeAnimation } from '../../utility/animations';
import { ToastService } from '../toast/toast.service';
import { BulkTagActionType, TagMappingAction, TagService } from './tag.service';

@Component({
  selector: 'tag-editor',
  templateUrl: './tag-editor.component.html',
  styleUrls: ['./tag-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [enterLeaveFadeAnimation()]
})
export class TagEditorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() tags: ITag[] = [];
  @Input() clientId: number;
  @Input() floorplanId: number;
  @Input() lotId: number;
  @Input() inventoryHomeId: number;
  @Input() suggestedTags: ITag[] = [];
  @Input() bulkTagActionType: BulkTagActionType;
  @Output() tagsChange = new EventEmitter<ITag[]>();

  searchCtrl = new UntypedFormControl();
  showProgressSpinner = false;
  searchedTags: ITag[] = [];
  addedTags: ITag[] = [];
  filteredSuggestedTags: ITag[] = [];
  sub: Subscription;
  eTagMappingAction = BulkTagActionType;

  constructor(
    private tagService: TagService,
    private cdr: ChangeDetectorRef,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.sub = this.searchCtrl.valueChanges.pipe(debounceTime(100)).subscribe(this.handleSearch);

    this.addedTags = [...this.tags].sort(sortByName);
    this.updateSuggestedTags();

    if (!this.floorplanId && !this.lotId && !this.inventoryHomeId && !this.clientId)
      console.error('TagEditor: Must provide at least one entity id');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.suggestedTags) this.updateSuggestedTags();
    if (changes.bulkTagActionType) this.handleBulkActionChange(changes.bulkTagActionType);
    if (changes.tags) this.addedTags = [...this.tags].sort(sortByName);
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  private updateSuggestedTags() {
    this.filteredSuggestedTags = this.suggestedTags.filter(t =>
      this.addedTags.every(x => t.TagId !== x.TagId)
    );
  }

  handleSearch = async (value: string | ITag) => {
    if (!value || typeof value !== 'string') return [];

    this.showProgressSpinner = true;
    this.cdr.markForCheck();

    const tags = await this.tagService.searchForTag(value.trim(), this.clientId);
    this.searchedTags = tags.filter(t => !this.addedTags.some(x => x.TagId === t.TagId));

    this.showProgressSpinner = false;
    this.cdr.markForCheck();
  };

  handleTagSelect(evt: MatAutocompleteSelectedEvent) {
    const tag = evt.option.value as ITag;

    if (!!this.bulkTagActionType) {
      this.searchCtrl.setValue('');

      if (!this.addedTags.some(t => t.TagId === tag.TagId)) {
        this.addedTags.push(tag);
        this.addedTags.sort(sortByName);
        this.tagsChange.emit(this.addedTags);
      }
    } else {
      this.handleTagMappingAction(tag, TagMappingAction.Add);
    }
  }

  handleTagRemove(tag: ITag) {
    // Check for bulk add/remove from lot functionality to only remove tag from array and not map to api
    if (this.bulkTagActionType === BulkTagActionType.Remove) {
      this.addedTags = this.addedTags.filter(t => t.TagId !== tag.TagId);
      this.tagsChange.emit(this.addedTags);
    } else {
      this.handleTagMappingAction(tag, TagMappingAction.Remove);
    }
  }

  handleTagMappingAction(tag: ITag, action: TagMappingAction) {
    try {
      this.showProgressSpinner = true;
      this.cdr.markForCheck();
      this.mapTagToEntity(tag, action);
    } catch (error) {
      console.error(error);
      this.toast.showSnackbarError('Unable to assign this tag');
    } finally {
      this.showProgressSpinner = false;
      this.cdr.markForCheck();
    }
  }

  async handleTagCreate() {
    try {
      const tagName =
        typeof this.searchCtrl.value === 'string'
          ? this.searchCtrl.value
          : (this.searchCtrl.value as ITag).Name;

      // ensure tag is not already added
      if (this.addedTags.some(x => x.Name.toLowerCase() === tagName.toLowerCase())) return;

      this.showProgressSpinner = true;
      this.cdr.markForCheck();

      // check if tag already created
      let tag = this.searchedTags.find(t => t.Name.toLowerCase() === tagName.toLowerCase());
      if (!tag) {
        tag = await this.tagService.create({
          TagId: null,
          Name: this.searchCtrl.value,
          ClientId: this.clientId
        });
      }
      await this.mapTagToEntity(tag, TagMappingAction.Add);
    } catch (error) {
      console.error(error);
      this.toast.showSnackbarError('Unable to create new tag');
    } finally {
      this.showProgressSpinner = false;
      this.cdr.markForCheck();
    }
  }

  private async mapTagToEntity(tag: ITag, action: TagMappingAction) {
    // check if already added/removed
    if (action === TagMappingAction.Add) {
      if (this.addedTags.some(t => t.TagId === tag.TagId)) {
        this.searchCtrl.setValue('');
        return;
      }
    } else if (this.addedTags.every(t => t.TagId !== tag.TagId)) return;

    if (this.floorplanId || this.lotId || this.inventoryHomeId) {
      const mappingResp = await this.tagService.updateMappings([
        {
          TagId: tag.TagId,
          Action: action,
          FloorPlanId: this.floorplanId,
          LotId: this.lotId,
          InventoryHomeId: this.inventoryHomeId
        }
      ]);
      if (mappingResp.SuccessCount !== 1 || mappingResp.Errors.length)
        throw Error(mappingResp.Errors.map(e => e.Message).join('\n'));
    }

    if (action === TagMappingAction.Add) {
      this.searchCtrl.setValue('');
      this.addedTags.push(tag);
      this.addedTags.sort(sortByName);
    } else {
      this.addedTags = this.addedTags.filter(t => t.TagId !== tag.TagId);
    }
    this.updateSuggestedTags();
    this.cdr.markForCheck();

    this.tagsChange.emit(this.addedTags);
    this.toast.showSnackbarSuccess(action === TagMappingAction.Add ? 'Tag added' : 'Tag removed');
  }

  displayFn(tag: ITag): string {
    return tag?.Name;
  }

  trackByTagId(_, tag: ITag) {
    return tag.TagId;
  }

  handleBulkActionChange(bulkAction: SimpleChange) {
    if (bulkAction.currentValue === BulkTagActionType.Add) this.addedTags = [];
    if (bulkAction.currentValue === BulkTagActionType.Remove)
      this.addedTags = [...this.tags].sort(sortByName);
  }
}
