import { Observable, firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';

import { IApiKey } from '../entities/apikey';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ApiKeyService {
  constructor(private http: HttpClient) {}

  getByUsername(username: string) {
    return firstValueFrom(this.http.get<IApiKey>(`/api/apikeys/${username}`));
  }

  create(userId: string) {
    return firstValueFrom(this.http.post<IApiKey>(`/api/apikeys/${userId}`, null));
  }

  delete(userName: string) {
    return firstValueFrom(this.http.delete<IApiKey>(`/api/apikeys/${userName}`));
  }
}
