/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import { Injectable } from '@angular/core';

import { ContentEditorProducts } from '../content-editor/models/enums';

@Injectable()
export class AppStateService {
  private _clientId: number;
  private _divisionId: number;
  private _communityId: number;
  private _neighborhoodId: number;
  private _managementMapId: string;
  private _lastSelectedProduct: ContentEditorProducts;

  // initialize every object as empty
  constructor() {
    const clientId = +localStorage.getItem('app-clientId');
    const divisionId = +localStorage.getItem('app-divisionId');
    const communityId = +localStorage.getItem('app-communityId');
    const neighborhoodId = +localStorage.getItem('app-neighborhoodId');
    const lastSelectedProduct = +localStorage.getItem('app-last-selected-product');

    this.clientId = clientId;
    this.divisionId = divisionId;
    this.communityId = communityId;
    this.neighborhoodId = neighborhoodId;
    this.lastSelectedProduct = lastSelectedProduct;
  }

  get clientId() {
    return this._clientId;
  }

  get divisionId() {
    return this._divisionId;
  }

  get communityId() {
    return this._communityId;
  }

  get neighborhoodId() {
    return this._neighborhoodId;
  }

  get managementMapId() {
    return this._managementMapId;
  }

  get lastSelectedProduct() {
    return this._lastSelectedProduct;
  }

  // note that setting a value will cascade down children and set them to 0
  set clientId(id: number) {
    id = id || 0;
    if (id === this._clientId) return;
    this._clientId = id;
    localStorage.setItem('app-clientId', id.toString());
    this.divisionId = 0;
  }

  set divisionId(id: number) {
    id = id || 0;
    if (id === this._divisionId) return;
    this._divisionId = id;
    localStorage.setItem('app-divisionId', id.toString());
    this.communityId = 0;
  }

  set communityId(id: number) {
    id = id || 0;
    if (id === this._communityId) return;
    this._communityId = id;
    localStorage.setItem('app-communityId', id.toString());
    this.neighborhoodId = 0;
  }

  set neighborhoodId(id: number) {
    id = id || 0;
    if (id === this._neighborhoodId) return;
    this._neighborhoodId = id;
    localStorage.setItem('app-neighborhoodId', id.toString());
    this.managementMapId = '';
  }

  set managementMapId(id: string) {
    id = id || '';
    this._managementMapId = id;
    localStorage.setItem('app-managementMapId', id.toString());
  }

  set lastSelectedProduct(p: ContentEditorProducts) {
    this._lastSelectedProduct = p;
    localStorage.setItem('app-last-selected-product', p.toString());
  }
}
