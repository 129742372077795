export const CanadianProvinceList = [{
  FullName: 'Alberta',
  Abreviation: 'AB'
}, {
  FullName: 'British Columbia',
  Abreviation: 'BC'
}, {
  FullName: 'Manitoba',
  Abreviation: 'MB'
}, {
  FullName: 'New Brunswick',
  Abreviation: 'NB'
}, {
  FullName: 'Newfoundland and Labrador',
  Abreviation: 'NL'
}, {
  FullName: 'Nova Scotia',
  Abreviation: 'NS'
}, {
  FullName: 'Ontario',
  Abreviation: 'ON'
}, {
  FullName: 'Prince Edward Island',
  Abreviation: 'PE'
}, {
  FullName: 'Quebec',
  Abreviation: 'QC'
}, {
  FullName: 'Saskatchewan',
  Abreviation: 'SK'
}];
