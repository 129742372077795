import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';

import { ToastOptions, ToastService, ToastState } from './toast.service';

@Component({
  selector: 'toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('scaleInOut', [
      state('in', style({ transform: 'scale(1)' })),
      transition('void => *', [style({ transform: 'scale(0)' }), animate(300)]),
      transition('* => void', [animate(300, style({ transform: 'scale(0)' }))])
    ])
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent implements OnInit {
  isShowing = false;
  options: ToastOptions = {};

  private timeout: number;
  private durationDefault = 3000; // keep comment in toast.service up to date

  constructor(private toaster: ToastService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.toaster.state.subscribe(toastState => {
      if (this.ignoreLoadingHide(toastState) || this.disregardErrorMessage(toastState)) {
        console.log('ignoring state');
        return;
      }

      window.clearTimeout(this.timeout);
      document.removeEventListener('click', this.onClick, false);

      this.options = toastState.options;
      this.setOptionPresets();

      if (this.options.clickToClose) {
        // inexplicably this event gets triggered from the click that launched this whole process
        // so we must wait 300ms before attaching listener
        window.setTimeout(() => {
          document.addEventListener('click', this.onClick, false);
        }, 300);
      }

      this.isShowing = toastState.isShowing;

      // timeout to remove toast
      if (!this.options.infinite) {
        this.timeout = window.setTimeout(() => {
          this.close();
        }, this.options.duration);
      }

      this.cdr.markForCheck();
    });
  }

  close() {
    this.isShowing = false;
    this.cdr.markForCheck();
    this.options = {};

    // unregister here in case user never actually clicked to close and just waited the duration
    document.removeEventListener('click', this.onClick, false);
  }

  onClick = () => {
    if (this.options.clickToClose) {
      window.clearTimeout(this.timeout);
      this.close();
    }
  };

  // presets for success, error, etc
  private setOptionPresets() {
    if (this.options.useSuccessStyle && !this.options.iconName)
      this.options.iconName = 'circle_check_bold';

    if (this.options.useErrorStyle) {
      if (!this.options.iconName) this.options.iconName = 'alert_bold';

      // default for error messages is longer
      if (!this.options.duration) this.options.duration = 5000;
    }

    if (this.options.onlyLoadingIndicator) this.options.infinite = true;
    else {
      // all none loading indicator toasts:

      // unless specifically set to false we default to allow click to close
      if (this.options.clickToClose !== false) this.options.clickToClose = true;
    }

    if (!this.options.duration) this.options.duration = this.durationDefault;
  }

  /** Check to make sure a call to hide loading does not force an actual message to be hidden */
  private ignoreLoadingHide(incomingState: ToastState): boolean {
    return (
      this.isShowing &&
      !incomingState.isShowing &&
      !(this.options.onlyLoadingIndicator || this.options.loadingIndicatorAndMessage) &&
      (incomingState.options.onlyLoadingIndicator ||
        incomingState.options.loadingIndicatorAndMessage)
    );
  }

  private disregardErrorMessage(incomingState: ToastState): boolean {
    return (
      this.isShowing &&
      incomingState.isShowing &&
      this.options.useErrorStyle &&
      incomingState.options.disregardIfErrorAlreadyShowing
    );
  }
}
