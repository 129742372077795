<div class="tooltip icon" [class.margin-both-sides]="marginBothSides"
     [matTooltip]="message" matTooltipHideDelay="1000" matTooltipPosition="after" [matTooltipClass]="extraClass"
     [ngSwitch]="type" (click)="$event.preventDefault()">

  <svg-helper *ngSwitchCase="'extracost'" svgName="dollar" class="extra-cost"></svg-helper>

  <svg *ngSwitchCase="'error'" class="error" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M12,2a.45.45,0,0,1,.4.25L22.7,21.3a.49.49,0,0,1,0,.47.45.45,0,0,1-.39.23H1.71a.45.45,0,0,1-.39-.23.48.48,0,0,1,0-.48l10.29-19A.46.46,0,0,1,12,2M12,.75a1.7,1.7,0,0,0-1.5.91L.21,20.69a1.73,1.73,0,0,0,1.5,2.56H22.29a1.73,1.73,0,0,0,1.5-2.56L13.5,1.66A1.7,1.7,0,0,0,12,.75Z" fill="#fff" />
    <path d="M12,8.33h0a1.86,1.86,0,0,0-1.83,2.15l.8,5.14a1.05,1.05,0,0,0,1,.9h0a1.05,1.05,0,0,0,1-.9l.8-5.14A1.86,1.86,0,0,0,12,8.33Z" fill="#fff" />
    <ellipse cx="12.01" cy="18.57" rx="1.31" ry="1.32" fill="#fff" />
  </svg>

  <svg-helper *ngSwitchCase="'warning'" svgName="exclamation_point" class="error"></svg-helper>

  <svg-helper *ngSwitchCase="'progressinfo'" svgName="circle_i" class="progressinfo"></svg-helper>

  <svg-helper *ngSwitchDefault svgName="circle_i" class="info"></svg-helper>
</div>