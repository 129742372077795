import { Directive, Input, ElementRef, ChangeDetectionStrategy, OnChanges } from '@angular/core';

import { GraphicsModel } from './data.structures';

// Use this directive to apply CSS Transforms to an element based on the GraphicsModel obj input param
// it will convert a GraphicsModel obj into a CSS Transform string and then apply it to the elements
// style (using appropriate browser property)
@Directive({
  selector: '[mlTransformer]'
})
export class MlTransformerDirective implements OnChanges {
  @Input('mlTransformer') gm: GraphicsModel;

  constructor(private elementRef: ElementRef) {}

  ngOnChanges(changes) {
    if (this.gm) {
      this.applyTransform();
    }
  }

  private applyTransform() {
    // only apply 3D translate if interaction is active
    let translate = `translate(${this.gm.x}px, ${this.gm.y}px)`;
    if (this.gm.activeInteraction) translate = `translate3d(${this.gm.x}px, ${this.gm.y}px, 0)`;

    const transform = `${translate} scale(${this.gm.scale})`;

    // this should cover IE, Chrome, Firefox, and Safari
    this.elementRef.nativeElement.style.transform = transform;
    this.elementRef.nativeElement.style.webkitTransform = transform;
  }
}
