export const UsStateList = [{
  FullName: 'Alabama',
  Abreviation: 'AL'
}, {
  FullName: 'Alaska',
  Abreviation: 'AK'
}, {
  FullName: 'Arizona',
  Abreviation: 'AZ'
}, {
  FullName: 'Arkansas',
  Abreviation: 'AR'
}, {
  FullName: 'California',
  Abreviation: 'CA'
}, {
  FullName: 'Colorado',
  Abreviation: 'CO'
}, {
  FullName: 'Connecticut',
  Abreviation: 'CT'
}, {
  FullName: 'Delaware',
  Abreviation: 'DE'
}, {
  FullName: 'District of Columbia',
  Abreviation: 'DC'
}, {
  FullName: 'Florida',
  Abreviation: 'FL'
}, {
  FullName: 'Georgia',
  Abreviation: 'GA'
}, {
  FullName: 'Hawaii',
  Abreviation: 'HI'
}, {
  FullName: 'Idaho',
  Abreviation: 'ID'
}, {
  FullName: 'Illinois',
  Abreviation: 'IL'
}, {
  FullName: 'Indiana',
  Abreviation: 'IN'
}, {
  FullName: 'Iowa',
  Abreviation: 'IA'
}, {
  FullName: 'Kansas',
  Abreviation: 'KS'
}, {
  FullName: 'Kentucky',
  Abreviation: 'KY'
}, {
  FullName: 'Louisiana',
  Abreviation: 'LA'
}, {
  FullName: 'Maine',
  Abreviation: 'ME'
}, {
  FullName: 'Maryland',
  Abreviation: 'MD'
}, {
  FullName: 'Massachusetts',
  Abreviation: 'MA'
}, {
  FullName: 'Michigan',
  Abreviation: 'MI'
}, {
  FullName: 'Minnesota',
  Abreviation: 'MN'
}, {
  FullName: 'Mississippi',
  Abreviation: 'MS'
}, {
  FullName: 'Missouri',
  Abreviation: 'MO'
}, {
  FullName: 'Montana',
  Abreviation: 'MT'
}, {
  FullName: 'Nebraska',
  Abreviation: 'NE'
}, {
  FullName: 'Nevada',
  Abreviation: 'NV'
}, {
  FullName: 'New Hampshire',
  Abreviation: 'NH'
}, {
  FullName: 'New Jersey',
  Abreviation: 'NJ'
}, {
  FullName: 'New Mexico',
  Abreviation: 'NM'
}, {
  FullName: 'New York',
  Abreviation: 'NY'
}, {
  FullName: 'North Carolina',
  Abreviation: 'NC'
}, {
  FullName: 'North Dakota',
  Abreviation: 'ND'
}, {
  FullName: 'Ohio',
  Abreviation: 'OH'
}, {
  FullName: 'Oklahoma',
  Abreviation: 'OK'
}, {
  FullName: 'Oregon',
  Abreviation: 'OR'
}, {
  FullName: 'Pennsylvania',
  Abreviation: 'PA'
}, {
  FullName: 'Rhode Island',
  Abreviation: 'RI'
}, {
  FullName: 'South Carolina',
  Abreviation: 'SC'
}, {
  FullName: 'South Dakota',
  Abreviation: 'SD'
}, {
  FullName: 'Tennessee',
  Abreviation: 'TN'
}, {
  FullName: 'Texas',
  Abreviation: 'TX'
}, {
  FullName: 'Utah',
  Abreviation: 'UT'
}, {
  FullName: 'Vermont',
  Abreviation: 'VT'
}, {
  FullName: 'Virginia',
  Abreviation: 'VA'
}, {
  FullName: 'Washington',
  Abreviation: 'WA'
}, {
  FullName: 'West Virginia',
  Abreviation: 'WV'
}, {
  FullName: 'Wisconsin',
  Abreviation: 'WI'
}, {
  FullName: 'Wyoming',
  Abreviation: 'WY'
}];
