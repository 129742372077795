import { decorateProperty as e } from "./base.js";
import { queryAssignedElements as t } from "./query-assigned-elements.js";
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
function o(o, n, r) {
  let l,
    s = o;
  return "object" == typeof o ? (s = o.slot, l = o) : l = {
    flatten: n
  }, r ? t({
    slot: s,
    flatten: n,
    selector: r
  }) : e({
    descriptor: e => ({
      get() {
        var e, t;
        const o = "slot" + (s ? `[name=${s}]` : ":not([name])"),
          n = null === (e = this.renderRoot) || void 0 === e ? void 0 : e.querySelector(o);
        return null !== (t = null == n ? void 0 : n.assignedNodes(l)) && void 0 !== t ? t : [];
      },
      enumerable: !0,
      configurable: !0
    })
  });
}
export { o as queryAssignedNodes };
