export interface IReport {
  Title?: string;
  Body?: string;
  ReportId?: string;
  GroupId?: string;
  TableName?: string;
  KeyValuePairs?: Array<any>;
  IsDisabled?: boolean;
  ShowTabs?: boolean;
  Desc?: string;
  RelatedProduct?: string;
}

export class ReportData implements IReport {
  Title: string;
  Body: string;
  ReportId: string;
  GroupId: string;
  TableName: string;
  KeyValuePairs: Array<any>;
  IsDisabled = false;
  ShowTabs = false;
  Desc: string;
  RelatedProduct?: string;

  constructor(report: IReport) {
    Object.assign(this, report);
  }
}

export interface IReportSettingValue {
  reportTitle: string;
  body: string;
  report: string;
  group: string;
  tableName: string;
  keyValuePairs: any[];
  createdIndex: number;
  productSettingsCategoryTitle: string;
  showTabs: boolean;
}
