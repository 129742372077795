import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { getArgoHeaders } from '@ml/common';
import { Lookup } from './lookup';

// service that wraps the default Angular Http service and applies request headers necessary for the Argo Api
// also intercepts responses to check specific header values and react accordingly
@Injectable()
export class ArgoGateway {
  argoHost = Lookup.Argo.LiveHost;
  protocol = window.location.protocol + '//';
  hmacToken: string;

  constructor(private http: HttpClient) {
    this.hmacToken = localStorage.getItem(Lookup.Auth.HmacTokenKey);
  }

  pullDataFromResponseHeaders(headers: HttpHeaders) {
    // grab auth token from response headers
    if (headers.has(Lookup.Argo.Headers.HmacToken)) {
      localStorage.setItem(Lookup.Auth.HmacTokenKey, headers.get(Lookup.Argo.Headers.HmacToken));
      this.hmacToken = headers.get(Lookup.Argo.Headers.HmacToken);
    }

    // grab auth expires from response headers
    if (headers.has(Lookup.Argo.Headers.HmacExpires)) {
      localStorage.setItem(
        Lookup.Auth.HmacExpiresKey,
        headers.get(Lookup.Argo.Headers.HmacExpires)
      );
    }

    // grab auth impersonate from response headers
    if (headers.has(Lookup.Argo.Headers.HmacImpersonatedBy)) {
      localStorage.setItem(
        Lookup.Auth.HmacImpersonatedByKey,
        headers.get(Lookup.Argo.Headers.HmacImpersonatedBy)
      );
    }
  }

  getHeadersByCurrentUser(req: HttpRequest<any>): HttpHeaders {
    const plain = this.getConvergeSpecificHeaders();
    req.headers.keys().forEach(k => (plain[k] = req.headers.get(k)));

    const authHeaders = getArgoHeaders({
      url: req.url,
      method: req.method,
      username: localStorage.getItem(Lookup.Auth.CurrentUserKey),
      authKey: this.hmacToken || localStorage.getItem(Lookup.Auth.HmacTokenKey),
      usingAuthToken: true,
      headers: plain
    });

    return new HttpHeaders(authHeaders);
  }

  getConvergeSpecificHeaders() {
    const headers: Record<string, string> = {};
    const impersonatedBy = localStorage.getItem(Lookup.Auth.HmacImpersonatedByKey);
    if (impersonatedBy) {
      headers[Lookup.Argo.Headers.HmacImpersonatedBy] = `${impersonatedBy}`;
    }

    return headers;
  }

  getHeadersWithAdminUser(url: string, method: string) {
    return getArgoHeaders({
      url,
      method,
      username: Lookup.Argo.ApiUser,
      authKey: Lookup.Argo.ApiKey
    });
  }

  fullBaseDomain(url: string): string {
    return this.protocol + this.argoHost + url;
  }

  /**
   * Use this when Angular's HTTP service won't suffice. Specific examples are posting FormData or when in progress events are desired.
   *
   * @param data Typed to any to make this more versatile. Initial use case is for FormData.
   * @param url This function will prefix the proper API domain. Only "/api/my-route" is needed
   */
  sendRawXhr(
    url: string,
    httpVerb: string,
    data: any,
    onProgressFn?: (percent: number) => void
  ): Observable<any> {
    return new Observable(observer => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200 || xhr.status === 201) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };

      // if progress fn then register to listen for those events
      if (onProgressFn) {
        xhr.upload.onprogress = event => {
          if (event.lengthComputable) {
            const percent = Math.round((event.loaded / event.total) * 100);
            onProgressFn(percent);
          }
        };
      }

      const authHeaders = getArgoHeaders({
        url,
        method: httpVerb,
        username: localStorage.getItem(Lookup.Auth.CurrentUserKey),
        authKey: this.hmacToken,
        usingAuthToken: true,
        headers: this.getConvergeSpecificHeaders()
      });

      xhr.open(httpVerb, this.fullBaseDomain(url), true);

      // add headers to xhr
      Object.keys(authHeaders).forEach(key => {
        xhr.setRequestHeader(key, authHeaders[key]);
      });

      xhr.send(data);
    });
  }

  checkIfStageIsUp(): Observable<boolean> {
    const stageHost = window.location.protocol + '//' + Lookup.Argo.StageHost;
    return this.http.get(`${stageHost}/api/utility/teststagedb`).pipe(map(() => true));
  }
}
