import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Dictionary } from '../shared/data.structures';
import { ProductInstance } from '../entities/product';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ProductInstanceService {
  store: Dictionary<ProductInstance>;

  constructor(private http: HttpClient) {
    this.store = new Dictionary<ProductInstance>();
  }

  getByClientId(id: number): Observable<ProductInstance[]> {
    return this.http.get<ProductInstance[]>(`/api/ProductInstances/client/${id}`).pipe(
      map((products: ProductInstance[]) => {
        // update store
        products.forEach(x => {
          this.store.addOrUpdate(x.ProductId.toString(), x);
        });

        return products;
      })
    );
  }

  getByCommunityId(communityId: number): Observable<ProductInstance[]> {
    return this.http.get<ProductInstance[]>(`/api/ProductInstances/community/${communityId}`, {
      headers: new HttpHeaders({ 'api-version': '2' })
    });
  }

  getWebSpinners(clientName: string): Observable<string[]> {
    return this.http
      .get<string[]>(`/api/ProductInstances/GetWebSpinnerPaths/${clientName}`, {
        headers: new HttpHeaders({ 'api-version': '2' })
      })
      .pipe(
        catchError(e => {
          return of([]);
        })
      );
  }

  GetPanosPathsByClientId(id: number): Observable<string[]> {
    return this.http.get<string[]>(`/api/ProductInstances/GetPanosPathsByClientId/${id}`, {
      headers: new HttpHeaders({ 'api-version': '2' })
    });
  }
}
