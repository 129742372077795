import { ManagementMap, Division, Community, Neighborhood } from '../../../entities';

export class ReportMapLocationVM {
    constructor(
        public map: ManagementMap,
        public division: Division,
        public community: Community,
        public neighborhood: Neighborhood
    ) {}
}

// Matches API request expectations
export class ReportMapDTO {
    ManagementMapId: string;
    DivisionIds = new Array<number>();
    CommunityIds = new Array<number>();
    NeighborhoodIds = new Array<number>();
}
