import { environment } from '../../environments/environment';

import { IApolloAplicationModules, ApolloApplicationModuleLookup } from './apollo-module-models';

export class Lookup {
  public static Modules: IApolloAplicationModules = ApolloApplicationModuleLookup;

  public static Auth = {
    HmacTokenKey: 'auth_token',
    HmacExpiresKey: 'auth_expires',
    HmacImpersonatedByKey: 'impersonated-by',
    CurrentUserKey: 'current_username',
    ApolloAdmin: 'ml-apAdmin',
    MLAdmin: 'ml-internalAdmin',
    LocationRolePrefixes: {
      division: 'division_',
      community: 'community_',
      neighborhood: 'neighborhood_'
    },
    ApplicationId: '42E6BCB5-7E2F-4D0D-B822-1CB9AE1D839C',
    SkipAuthHeader: 'x-ml-skipauth'
  };

  public static Argo = {
    Headers: {
      HmacToken: 'x-ml-authtoken',
      HmacExpires: 'x-ml-authexpires',
      HmacImpersonatedBy: 'impersonated-by'
    },
    AclDomain: 'Apollo',
    LiveHost: environment.argoHostLive,
    StageHost: environment.argoHostStage,
    ApiUser: 'converge.apiuser@medialabinc.local',
    ApiKey: 'mhajZ/V7vwnQvsZItURGU9lvsxVIQ1aL'
  };

  public static ClientIds = {
    MediaLab: 4,
    KHov: 5
  };

  public static Settings = {
    LotDefaultFill: '#efefef',
    LotDefaultSelected: '#f3b544',
    LotSecondaryStatusSelectedClass: 'secondary-selected',
    LotSecondarySymbolIdSuffix: '-secondarysymbol',
    CategoryIds: {
      HomeDashboard: {
        StatusReport: 32,
        General: 35,
        QuickLinks: 116
      }
    },
    HelpDeskEmailAddress: 'mdsd@medialab3dsolutions.atlassian.net',
    JobStatusTrackerSettingCategoryName: 'Job Status Tracker',
    JobTrackerClientSettingName: 'enableJobStatusTracker'
  };

  public static CacheTypes = {
    LotElements: 'lotElements'
  };

  public static Rpm = {
    Domain: environment.rpmDomain,
    Api: environment.rpmApi,
    ApiUsername: 'apicore.apiuser@medialabinc.local',
    ApiPassword: '6fqi8Q608xCv'
  };

  public static ProductDataBaseUrl = environment.productDataDomain + '/productdata';
  public static ProductDataDomain = environment.productDataDomain;
  public static IconBaseDomain = environment.iconBaseDomain;
  public static IconHostStage = environment.iconHostStage;
  public static KrakenHostStage = environment.krakenHostStage;
  public static IconLiteIfpBaseUrl = environment.iconBaseDomain + '/#/lite-floorplan/';

  /**Make sure you actually need this and not just ProductDataDomain */
  public static ApiFsEndpoint =
    window.location.protocol + '//' + environment.argoHostLive + '/api/fs';

  public static ApiStageFsProductDataEndpoint =
    window.location.protocol + '//' + environment.argoHostStage + '/productdata';

  public static ApiStageEndpoint = window.location.protocol + '//' + environment.argoHostStage;

  public static Graphics = {
    MaxScale: 4,
    MinScale: 1,
    ScaleStep: 0.5,
    LoadingAnimationHtml: '<div class="loading-bars"><div></div><div></div><div></div></div>'
  };

  public static Device = {
    IsIE: (() => {
      const msie = navigator.userAgent.indexOf('MSIE ');
      const trident = navigator.userAgent.indexOf('Trident/');
      let isIE = false;
      if (msie > 0 || trident > 0) isIE = true;

      return isIE;
    })()
  };

  public static EntityTypes = {
    Division: 'division',
    Community: 'community',
    Neighborhood: 'neighborhood'
  };

  public static SkipAuthHeader = 'x-ml-skipauth';

  public static Forms = {
    // TextMaskModule only supports an array of characters. Allow up to 20 additional characters for the masking
    PhoneMask: [
      '(',
      /\d/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\w/,
      /\w/,
      /\w/,
      /\w/,
      /\w/,
      /\w/,
      /\w/,
      /\w/,
      /\w/,
      /\w/,
      /\w/,
      /\w/,
      /\w/,
      /\w/,
      /\w/,
      /\w/,
      /\w/,
      /\w/,
      /\w/,
      /\w/
    ]
  };
}
