import { ReportData } from '../../tools/report/models/reportData';
import { KeyValueSetItem } from '../key-value-set/key-value-set.component';

export interface IAutomationData {
  Types: IAutomationReports[];
}
export class AutomationDataVM implements IAutomationData {
  Types: AutomationReports[];

  constructor(data?: IAutomationData) {
    if (data) {
      const types: AutomationReports[] = [];
      data.Types.forEach(x => {
        const report = new AutomationReports(x);
        types.push(report);
      });
      this.Types = types;
    }
  }
}

export interface IAutomationReports {
  Type: string;
  Reports: ReportData[];
}

export class AutomationReports implements IAutomationReports {
  Type: string;
  Reports: ReportData[];

  constructor(data?: IAutomationReports) {
    if (data) {
      this.Type = data.Type;
      const reports: ReportData[] = [];
      data.Reports.forEach(report => {
        const kvPair: KeyValueSetItem[] = [];
        report.KeyValuePairs.forEach(kv => {
          const kvSet = new KeyValueSetItem();
          kvSet.setKey = kv.setKey;
          kvSet.setValue = kv.setValue;
          kvPair.push(kvSet);
        });
        report.KeyValuePairs = kvPair;
        reports.push(report);
      });
      this.Reports = reports;
    }
  }
}
