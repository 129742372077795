import { DocumentBase } from './document-base';
import { Floor } from './floor';

export class FloorPlan extends DocumentBase {
  FloorPlanId: number;
  Title: string;
  BaseTotalSquareFeet: number;
  NeighborhoodId: number;
  CommunityId: number;
  NumberOfBedrooms: number;
  NumberOfFullBaths: number;
  NumberOfHalfBaths: number;
  NumberOfGarages: number;
  GarageCarSize: number;
  IsActive: boolean;
  ModifiedOn: Date;
  PixelsPerFoot: number;
  MasterFloorPlanId: number;
  LayerNameKey: string;
  SortOrder: number;
  DescriptionOfFeatures: string;
  Description2: string;
  Description3: string;
  OutdoorSquareFeet: number;
  NumberOfDens: number;
  IsReversedByDefault: boolean;
  IsReverseDisabled: boolean;
  Floors: Floor[];
}
