import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IElevation,
  IFloor,
  IFloorplan,
  IFloorPlanCustomData,
  IHotspot,
  IInventoryHome,
  IMedia,
  ITag
} from '@ml/common';

import { environment } from '../../environments/environment';
import { Dictionary } from '../shared/data.structures';
import { Lookup } from '../shared/lookup';
import { ActionSet } from './action-set';
import { FloorPlan } from './floorplan';
import { Hotspot } from './hotspot';

@Injectable()
export class FloorPlanService {
  stageApiBase = window.location.protocol + '//' + Lookup.Argo.StageHost;

  store: Dictionary<FloorPlan>;
  sqlFloorplanStore: Dictionary<SQLFloorPlan>;
  divisionHistory = new Array<number>();
  communityHistory = new Array<number>();
  neighborhoodHistory = new Array<number>();

  constructor(private http: HttpClient) {
    this.store = new Dictionary<FloorPlan>();
    this.sqlFloorplanStore = new Dictionary<SQLFloorPlan>();
  }

  getById(
    id: number,
    includeElevations = false,
    includeVirtualDirectory = false,
    includeActionSets = false,
    fromStage = false
  ): Observable<SQLFloorPlan> {
    // check store first

    const cached = this.sqlFloorplanStore[id];
    if (
      !fromStage &&
      cached &&
      (!includeElevations || cached.Elevations.length > 0) &&
      (!includeVirtualDirectory || !!cached.VirtualDirectory) &&
      cached.Floors?.length &&
      (!includeActionSets || !!cached.Floors[0].ActionSets)
    ) {
      return observableOf(cached);
    } else {
      const base = fromStage ? this.stageApiBase : '';
      let url = `${base}/api/floorplansv2/${id}`;
      url += `?includeelevations=${includeElevations}&includeVirtualDirectory=${includeVirtualDirectory}`;
      url += `&includeActionSets=${includeActionSets}`;

      return this.http.get<SQLFloorPlan>(url).pipe(
        map((fp: SQLFloorPlan) => {
          this.sqlFloorplanStore.addOrUpdate(fp.FloorplanId.toString(), fp);
          fp.Floors.sort((a, b) => (a.FloorNumber < b.FloorNumber ? -1 : 1));
          return fp;
        })
      );
    }
  }

  getByDivisionId(id: number): Observable<SQLFloorPlan[]> {
    if (this.divisionHistory.indexOf(id) > -1) {
      return observableOf(this.sqlFloorplanStore.values().filter(x => x.DivisionId === id));
    } else {
      const url = `/api/floorplansv2/division/${id}`;

      return this.http.get<SQLFloorPlan[]>(url).pipe(
        map((floorplans: SQLFloorPlan[]) => {
          // update store
          floorplans.forEach((fp: SQLFloorPlan) => {
            fp.DivisionId = id;
            this.sqlFloorplanStore.addOrUpdate(fp.FloorplanId.toString(), fp);
          });

          const distinctCommIds = floorplans
            .map(fp => fp.CommunityId)
            .filter((communityId, i, arr) => {
              return arr.indexOf(communityId) === i;
            });

          this.communityHistory = distinctCommIds;
          this.divisionHistory.push(id);

          return floorplans;
        })
      );
    }
  }

  getByCommunityId(
    id: number,
    includeElevations = false,
    fromStage = false
  ): Observable<SQLFloorPlan[]> {
    const ids = new Array<number>();
    ids.push(id);
    return this.getByCommunityIds(ids, includeElevations, fromStage);
  }

  getByCommunityIds(
    ids: number[],
    includeElevations: boolean = false,
    fromStage = false
  ): Observable<SQLFloorPlan[]> {
    let allFloorplans: SQLFloorPlan[] = new Array<SQLFloorPlan>();
    const communitiesToFetch: number[] = new Array<number>();

    ids.forEach(communityId => {
      if (includeElevations && this.communityHistory.indexOf(communityId) > -1) {
        allFloorplans = allFloorplans.concat(
          this.sqlFloorplanStore.values().filter(x => x.CommunityId === communityId)
        );
      } else {
        communitiesToFetch.push(communityId);
      }
    });

    if (communitiesToFetch.length > 0) {
      const base = fromStage ? this.stageApiBase : '';
      const url = `${base}/api/floorplansv2/community?includeelevations=${includeElevations}&ids=${communitiesToFetch.join(
        '&ids='
      )}`;

      return this.http.get<SQLFloorPlan[]>(url).pipe(
        map((floorplans: SQLFloorPlan[]) => {
          floorplans.forEach(fp => {
            this.sqlFloorplanStore.addOrUpdate(fp.FloorplanId.toString(), fp);
          });

          this.communityHistory = this.communityHistory.concat(communitiesToFetch);

          return allFloorplans.concat(floorplans);
        })
      );
    }

    return observableOf(allFloorplans);
  }

  getByNeighborhoodId(
    id: number,
    includeElevations = false,
    includeVirtualDirectory = false,
    fromStage = false,
    floorplanEntityOnly = false
  ): Observable<SQLFloorPlan[]> {
    if (this.neighborhoodHistory.some(nId => nId === id) && !fromStage && !floorplanEntityOnly) {
      return observableOf(this.sqlFloorplanStore.values().filter(fp => fp.NeighborhoodId === id));
    } else {
      const base = fromStage ? this.stageApiBase : '';
      return this.http
        .get<SQLFloorPlan[]>(
          // eslint-disable-next-line max-len
          `${base}/api/floorplansv2/neighborhood/${id}?includeelevations=${includeElevations}&includeVirtualDirectory=${includeVirtualDirectory}&floorplanEntityOnly=${floorplanEntityOnly}`
        )
        .pipe(
          map((floorplans: SQLFloorPlan[]) => {
            if (!fromStage && !floorplanEntityOnly) {
              floorplans.forEach((fp: SQLFloorPlan) => {
                this.sqlFloorplanStore.addOrUpdate(fp.FloorplanId.toString(), fp);
              });
              this.neighborhoodHistory.push(id);
            }

            return floorplans;
          })
        );
    }
  }

  getExportZipFileByName(fileName: string): Observable<Blob> {
    return this.http
      .get(`${environment.offlinePackager}/floorplan/zip/${fileName}`, {
        responseType: 'arraybuffer',
        observe: 'response'
      })
      .pipe(
        map((response: HttpResponse<ArrayBuffer>) => {
          const contentType = response.headers.get('Content-Type') || 'application/octet-stream';
          const blob = new Blob([response.body], { type: contentType });

          // using FileSaver library
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.setAttribute('download', fileName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();

          return blob;
        })
      );
  }

  update(floorplan: FloorPlan): Observable<FloorPlan> {
    return this.http.put<FloorPlan>(`/api/floorplans/${floorplan.numberId}`, floorplan).pipe(
      map((fp: FloorPlan) => {
        fp = Object.assign(new FloorPlan(), fp);
        this.store.addOrUpdate(fp.Id, fp);

        return fp;
      })
    );
  }

  saveHotspots(
    floorplanId: number,
    floorNumber: number,
    hotspots: Hotspot[]
  ): Observable<Hotspot[]> {
    return this.http
      .post<Hotspot[]>(`/api/floorplans/${floorplanId}/floor/${floorNumber}/hotspots`, hotspots)
      .pipe(
        map((hs: Hotspot[]) => {
          // if FP is in store, update it
          const fp: FloorPlan = this.store[floorplanId];
          if (fp) {
            const floor = fp.Floors.find(f => f.FloorNumber === floorNumber);
            floor.Hotspots = hs;
          }

          return hs;
        })
      );
  }

  patchValues(patchDTOs: IFloorPlanPatchDTO[]): Observable<SQLFloorPlan[]> {
    return this.http.patch<SQLFloorPlan[]>(`${this.stageApiBase}/api/floorplansv2`, patchDTOs);
  }

  patchActionSetValues(
    floorplanId: number,
    patchDTOs: ActionSetPatchDTO[],
    files: File[] = []
  ): Promise<ActionSet[]> {
    const formData = new FormData();
    formData.append('patchDto', JSON.stringify(patchDTOs));
    files.forEach(x => {
      formData.append(x.name, x, x.name);
    });
    return this.http
      .post<ActionSet[]>(
        `${this.stageApiBase}/api/floorplansv2/${floorplanId}/actionsets`,
        formData
      )
      .toPromise();
  }

  createWithSvgsAndRules(fp: IFloorplan) {
    return this.http
      .post<IFloorplan>(`${this.stageApiBase}/api/floorplansv2/svgs-and-rules`, fp)
      .toPromise();
  }
}

export interface IFloorPlanPatchDTO {
  FloorPlanId: number;
  ClientUniqueIdentifier?: string;
  PublicName?: string;
  SortOrder?: number;
  IsActive?: boolean;
  BaseTotalSquareFeet?: number;
  NumberOfBedrooms?: number;
  NumberOfFullBaths?: number;
  NumberOfHalfBaths?: number;
  NumberOfGarages?: number;
  GarageCarSize?: number;
  OutdoorSquareFeet?: number;
  NumberOfDens?: number;
  Price?: number;
  Description?: string;
  NumberOfBedroomsRange?: string;
  NumberOfFullBathsRange?: string;
  GarageCarSizeRange?: string;
  FloorRange?: string;
  NumberOfHalfBathsRange?: string;
  GarageNumberRange?: string;
  DenRange?: string;
  SquareFeetRange?: string;
  PriceRange?: string;
  InteriorPanoId?: number;
  ExteriorPanoId?: number;
  InteriorLayeredImageSetId?: number;
  ExteriorLayeredImageSetId?: number;
  InteriorStaticImageSetId?: string;
  ExteriorStaticImageSetId?: string;
  CustomData: { [key: string]: any };
}

export class ActionSetPatchDTO {
  ActionSetId: number;
  Name: string;
  SortOrder: number;
  Price: number;
  Description: string;
  MediaId: number;
  ImageFilename: string;
  HasNewMedia: boolean;
  SquareFeetAdj?: number;

  constructor(data?: ActionSet) {
    if (data) {
      this.ActionSetId = data.ActionSetId;
      this.Name = data.Name;
      this.SortOrder = data.SortOrder;
      this.Price = data.Price;
      this.Description = data.Description || '';
      this.MediaId = data.MediaId;
      this.ImageFilename = data.ImageFilename || '';
      this.HasNewMedia = data.HasNewMedia || false;
      this.SquareFeetAdj = data.SquareFeetAdj;
    }
  }
}

export class SQLElevation implements IElevation {
  Order?: number;
  Cost: number;
  ElevationId: number;
  FloorplanId: number;
  Title = '';
  ClientUniqueIdentifier = '';
  ShortTitle = '';
  IsActive = false;
  ImageFilename = '';
}

export class SQLFloor implements IFloor {
  FloorplanId: number;
  FullSvgAsString: string;
  IsBasement: boolean;
  IsOption: boolean;
  IsDefaultFloor: boolean;
  AffectedActionSetId?: number;
  FloorId: number;
  FloorPlanId: number;
  FloorNumber: number;
  SvgFilename: string;
  PreviewFilename: string;
  ActionSets: ActionSet[] = [];
  Hotspots: IHotspot[] = [];
  Title: string;
}

export class SQLFloorPlan implements IFloorplan {
  CustomData: IFloorPlanCustomData;
  Media: IMedia[];
  PixelsPerFoot?: number;
  SvgRevisionDate?: string;
  InventoryHomes: IInventoryHome[];
  InteriorPanoId?: number;
  ExteriorPanoId?: number;
  InteriorLayeredImageSetId?: number;
  ExteriorLayeredImageSetId?: number;
  InteriorStaticImageSetId: string;
  ExteriorStaticImageSetId: string;
  LayerNameKey: string;
  FloorplanId: number;
  ClientUniqueIdentifier: string;
  CommunityId: number;
  PublicName = '';
  Title = '';
  IsActive = false;
  Elevations: SQLElevation[] = new Array<SQLElevation>();
  DivisionId: number;
  NeighborhoodId: number;
  VirtualDirectory = '';
  SortOrder: number;
  BaseTotalSquareFeet: number;
  NumberOfBedrooms: number;
  NumberOfFullBaths: number;
  NumberOfHalfBaths: number;
  NumberOfGarages: number;
  GarageCarSize: number;
  OutdoorSquareFeet: number;
  NumberOfDens: number;
  Price: number;
  Description = '';
  NumberOfBedroomsRange: string;
  NumberOfFullBathsRange: string;
  GarageCarSizeRange: string;
  FloorRange: string;
  NumberOfHalfBathsRange: string;
  GarageNumberRange: string;
  DenRange: string;
  SquareFeetRange: string;
  PriceRange: string;
  MasterFloorPlanId?: number;

  Floors: SQLFloor[] = new Array<SQLFloor>();
  Tags: ITag[];
}
