import { decorateProperty as r } from "./base.js";
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
function e(e) {
  return r({
    descriptor: r => ({
      get() {
        var r, o;
        return null !== (o = null === (r = this.renderRoot) || void 0 === r ? void 0 : r.querySelectorAll(e)) && void 0 !== o ? o : [];
      },
      enumerable: !0,
      configurable: !0
    })
  });
}
export { e as queryAll };
