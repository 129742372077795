import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';
import { filter } from 'rxjs/operators';

import { GalleryImageVM } from './gallery-image-vm';

@Component({
  selector: 'gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryComponent implements OnChanges {
  @Input() images: Array<GalleryImageVM> = [];
  @Input() showTitle = false;
  @Input() useThumbnail = true;
  @Output() imageChange = new EventEmitter<GalleryImageVM>();
  selectedIndex = 0;
  isLoading = true;
  currentImage: GalleryImageVM;

  get currentImageSrc(): string {
    if (!this.currentImage) return '';
    return this.useThumbnail
      ? this.currentImage.ThumbnailUrl
      : this.currentImage.FullUrl;
  }

  constructor() {}

  ngOnChanges() {
    this.goToImage(this.selectedIndex);
  }

  onFileLoad = () => {
    this.isLoading = false;
  };

  goToImage(index: number) {
    // if (index === this.selectedIndex) return;
    // The index may stay the same for the same image if IsFavorite changes
    // The index may stay the same and a different image will be shown, ie if unfavorite in the brochure
    // I'm sure there's a better way but for now...
    if (index !== this.selectedIndex) this.isLoading = true;
    index = this.validateIndexBounds(index);
    this.selectedIndex = index;
    this.currentImage = this.images[this.selectedIndex];
    this.imageChange.emit(this.currentImage);
  }

  private validateIndexBounds(index: number) {
    if (index > this.images.length - 1) index = 0;
    if (index < 0) index = this.images.length - 1;
    return index;
  }

  onIndexChange(index: number) {
    this.selectedIndex = index;
  }
}
