<div class="field col-xs-12 set kvp" *ngFor="let item of expandingSet.stored; let i = index; trackBy: trackByIndex">
  <div class="input-container multi">

    <div class="inputs-kvp">
      <div class="label-input left">
        <div class="label">Key</div>
        <input [(ngModel)]="item.setKey" name="storedSetItem-{{itemLabel}}" type="text" (ngModelChange)="typedTextChange(expandingSet)" (change)="afterTypedTextChange(item, 'setKey')" />
      </div>
      <div class="label-input">
        <div class="label">Value</div>
        <input [(ngModel)]="item.setValue" name="storedSetItem-{{itemLabel}}" type="text" (ngModelChange)="typedTextChange(expandingSet)" (change)="afterTypedTextChange(item, 'setValue')" />
      </div>
    </div>

    <div class="btn-right">
      <div class="btn quick standard-negative" (click)="deleteItem(expandingSet, i)">
        <span class="icon">
          <svg-helper svgName="mini_trash"></svg-helper>
        </span>
      </div>
    </div>

  </div>
</div>
<!-- 
<div *ngIf="expandingSet.stored && expandingSet.stored.length > 0" class="multi-separator-container">
  <div class="multi-separator"></div>
</div> -->

<div class="field col-xs-12 new kvp" *ngIf="expandingSet">
  <div class="input-container multi" *ngIf="expandingSet.pending">
    <div class="inputs-kvp">
      <div class="label-input left">
        <div class="label">Key</div>
        <input [(ngModel)]="expandingSet.pending.setKey" name="pendingSetItem-{{itemLabel}}" type="text" (change)="afterTypedTextChange(expandingSet.pending, 'setKey', 'pending')" />
      </div>
      <div class="label-input">
        <div class="label">Value</div>
        <input [(ngModel)]="expandingSet.pending.setValue" name="pendingSetItem-{{itemLabel}}" type="text" (change)="afterTypedTextChange(expandingSet.pending, 'setValue', 'pending')" />
      </div>



    </div>

    <div class="btn quick standard-positive" [class.disabled]="!expandingSet.pending.setKey && !expandingSet.pending.setValue" (click)="addPending(expandingSet)">
      <span class="icon">
        <svg-helper svgName="plus_bold"></svg-helper>
      </span>
    </div>
  </div>
</div>

<!-- <div>{{expandingSet | json}}</div> -->