import { Component, Output, ElementRef, ViewChild, AfterViewInit, EventEmitter, Renderer2 } from '@angular/core';

import { UiHelperService } from '../ui-helper.service';

@Component({
    selector: 'dropzone',
    templateUrl: './dropzone.component.html',
    styleUrls: ['./dropzone.component.scss']
})
export class DropzoneComponent implements AfterViewInit {
    @ViewChild('dropzone', { static: true }) dropzone: ElementRef;
    @Output() filesDropped = new EventEmitter();

    constructor(private renderer: Renderer2, private uiHelper: UiHelperService) { }

    ngAfterViewInit() {

        this.renderer.listen(this.dropzone.nativeElement, 'dragenter', this.onDragEnter);
        this.renderer.listen(this.dropzone.nativeElement, 'dragleave', this.onDragLeave);
        this.renderer.listen(this.dropzone.nativeElement, 'drop', this.onDrop);
        // HTML5 nonsense - dragover event is required or else none of the drag related events work
        // HTML5 nonsense - dragover event is required or else none of the drag related events work
this.renderer.listen(this.dropzone.nativeElement, 'dragover', (evt: DragEvent) => evt.preventDefault());
    }

    onDragEnter = (evt: DragEvent) => {
        evt.preventDefault();
        this.uiHelper.addClassName(this.dropzone.nativeElement, 'drag-hover');
    };

    onDragLeave = (evt: DragEvent) => {
        evt.preventDefault();
        this.uiHelper.removeClassName(this.dropzone.nativeElement, 'drag-hover');
    };

    onDrop = (evt: DragEvent) => {
        evt.preventDefault();
        this.uiHelper.removeClassName(this.dropzone.nativeElement, 'drag-hover');

        this.filesDropped.emit(evt.dataTransfer.files);
    };
}
