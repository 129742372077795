<div class="counter-input">
    <div class="label">{{label}}</div>
    <input type="number" [ngModel]="value" (ngModelChange)="change($event)" />
    <div class="buttons">
        <div class="btn plus" (click)="increment()">
            <div class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="15.6" height="16.08" viewBox="0 0 15.6 16.08"><title>add</title><g id="8d84c9c3-d5bf-483f-b960-9bdfa448a2b3" data-name="Layer 2"><g id="9ef1ff78-efd5-4f2c-b7cb-af99befee989" data-name="Layer 1"><path d="M15.6,9.49H9.39v6.59H6.18V9.49H0V6.56H6.18V0H9.39V6.56H15.6Z" /></g></g></svg></div>
        </div>
        <div class="btn minus" (click)="decrement()">
            <div class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="11.57" height="1.5" viewBox="0 0 11.57 1.5"><title>minus</title><g id="519ba70d-6fc1-4998-8b2b-a605e401f1fc" data-name="Layer 2"><g id="665917e8-6bf0-4d3f-be9e-0a4a66cea283" data-name="Layer 1"><line y1="0.75" x2="5.79" y2="0.75" fill="none" stroke-width="1.5" /><line x1="11.57" y1="0.75" x2="5.79" y2="0.75" fill="none" stroke-width="1.5" /></g></g></svg></div>
        </div>
    </div>
</div>