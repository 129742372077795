import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';

import { IBuildingDTO_v2, IMapImage, INeighborhood } from '@ml/common';
import { Community } from '../entities';
import { Dictionary } from '../shared/data.structures';
import { Lookup } from '../shared/lookup';
import { SQLFloorPlan } from './floorplan.service';
import { Lot } from './lot';

// TODO: move Neighborhood class into its own file

@Injectable()
export class NeighborhoodService {
  store: Dictionary<Neighborhood>;
  communityHistory: number[];
  divisionHistory: number[];
  clientHistory: number[];
  storeByMapConfig = new Dictionary<Neighborhood[]>();
  stageApiBase = window.location.protocol + '//' + Lookup.Argo.StageHost;

  constructor(private http: HttpClient) {
    this.store = new Dictionary<Neighborhood>();
    this.communityHistory = new Array<number>();
    this.divisionHistory = new Array<number>();
    this.clientHistory = new Array<number>();
  }

  getById(id: number, fromStage = false, includeLotMapStatus = false): Observable<Neighborhood> {
    // check store first
    const storedNeighborhood = this.store[id];
    if (storedNeighborhood && !fromStage) {
      return observableOf(storedNeighborhood);
    } else {
      const base = fromStage ? this.stageApiBase : '';
      return this.http
        .get<Neighborhood>(
          `${base}/api/neighborhoods/${id}?includeLotMapStatus=${includeLotMapStatus}`
        )
        .pipe(
          map((hood: Neighborhood) => {
            hood = new Neighborhood(hood);
            if (!fromStage) this.store.addOrUpdate(hood.NeighborhoodId.toString(), hood);
            return hood;
          })
        );
    }
  }

  getByNeighborhoodIds(ids: number[]): Observable<Neighborhood[]> {
    const existingHoods = [];
    const newHoodIds: Array<number> = [];

    ids.forEach(id => {
      if (this.store[id]) {
        existingHoods.push(this.store[id]);
      } else {
        if (id) {
          newHoodIds.push(id);
        }
      }
    });

    const requestBody = { ids: newHoodIds };

    if (newHoodIds && newHoodIds.length > 0) {
      return this.http.post<Neighborhood[]>(`/api/neighborhoods/list`, requestBody).pipe(
        map((newhoods: Neighborhood[]) => {
          newhoods = newhoods.map(n => new Neighborhood(n));
          newhoods.push(...existingHoods);

          return newhoods;
        })
      );
    } else {
      return observableOf<Neighborhood[]>(existingHoods);
    }
  }

  getByClientId(id: number, includeForOverallCommunity = false): Observable<Neighborhood[]> {
    if (this.clientHistory.indexOf(id) > -1) {
      return observableOf(this.store.values().filter(x => x.ClientId === id));
    } else {
      const url = `/api/neighborhoods/client/${id}?includeActiveOnly=true`;

      return this.http.get<Neighborhood[]>(url).pipe(
        map((hoods: Neighborhood[]) => {
          hoods = hoods.map(n => new Neighborhood(n));
          if (!includeForOverallCommunity) hoods = hoods.filter(x => !x.IsForOverallCommunity);

          // update store
          hoods.forEach(hood => {
            hood.ClientId = id;
            this.store.addOrUpdate(hood.NeighborhoodId.toString(), hood);
          });

          const distinctCommunityIds = hoods
            .map(hood => hood.CommunityId)
            .filter((communityId, i, arr) => arr.indexOf(communityId) === i);

          this.communityHistory = this.communityHistory.concat(distinctCommunityIds);

          // add client id to history
          this.clientHistory.push(id);

          return hoods;
        })
      );
    }
  }

  getByDivisionId(id: number, includeForOverallCommunity = false): Observable<Neighborhood[]> {
    if (this.divisionHistory.indexOf(id) > -1) {
      return observableOf(this.store.values().filter(x => x.DivisionId === id));
    } else {
      const url = `/api/neighborhoods/division/${id}?includeActiveOnly=true`;

      return this.http.get<Neighborhood[]>(url).pipe(
        map((hoods: Neighborhood[]) => {
          hoods = hoods.map(n => new Neighborhood(n));
          if (!includeForOverallCommunity) hoods = hoods.filter(x => !x.IsForOverallCommunity);

          // update store
          hoods.forEach(hood => {
            hood.DivisionId = id;
            this.store.addOrUpdate(hood.NeighborhoodId.toString(), hood);
          });

          const distinctDivisionIds = hoods
            .map(hood => hood.DivisionId)
            .filter((divisionId, i, arr) => arr.indexOf(divisionId) === i);

          this.divisionHistory = this.divisionHistory.concat(distinctDivisionIds);
          this.divisionHistory.push(id);

          return hoods;
        })
      );
    }
  }

  getByCommunityId(
    id: number,
    withManagementMaps = false,
    includeActiveOnly = true,
    includeForOverallCommunity = false,
    includeFloorPlans = false,
    fromStage = false
  ): Observable<Neighborhood[]> {
    // communityHistory tracks whether we have done an http get for all hoods by community
    // if requesting withManagementMaps then skip the store
    if (
      !withManagementMaps &&
      !fromStage &&
      includeActiveOnly &&
      this.communityHistory.indexOf(id) > -1
    ) {
      return observableOf(this.store.values().filter(x => x.CommunityId === id));
    } else {
      const baseHost = fromStage ? this.stageApiBase : '';
      let url = `${baseHost}/api/neighborhoods/community/${id}`;
      url += `?withManagementMaps=${withManagementMaps}&includeActiveOnly=${includeActiveOnly}&includeFloorPlans=${includeFloorPlans}`;

      return this.http.get<Neighborhood[]>(url).pipe(
        map((hoods: Neighborhood[]) => {
          hoods = hoods.map(n => new Neighborhood(n));
          if (!includeForOverallCommunity) hoods = hoods.filter(x => !x.IsForOverallCommunity);

          // update store
          hoods.forEach(x => {
            this.store.addOrUpdate(x.NeighborhoodId.toString(), x);
          });

          // add community id to history
          if (!withManagementMaps) this.communityHistory.push(id);

          return hoods;
        })
      );
    }
  }

  getByCommunityIds(
    ids: number[],
    withManagementMaps = false,
    includeActiveOnly = true,
    includeForOverallCommunity = false
  ): Observable<Neighborhood[]> {
    let commIds = '';
    const newCommIds = [];

    ids.forEach(id => {
      if (!withManagementMaps && includeActiveOnly && this.communityHistory.indexOf(id) > -1) {
        const hat = observableOf(this.store.values().filter(x => x.CommunityId === id)); // NEED to concatenate with returned
        // hoods.push(observableOf(this.store[id]));
      } else {
        commIds = id ? (commIds.length > 0 ? `${commIds},${id}` : `${commIds}${id}`) : commIds;
        if (id) {
          newCommIds.push(id);
        }
      }
    });

    const requestBody = { ids: newCommIds };

    // communityHistory tracks whether we have done an http get for all hoods by community
    // if requesting withManagementMaps then skip the store

    // if (!withManagementMaps && includeActiveOnly && this.communityHistory.indexOf(id) > -1) {
    //     return observableOf(this.store.values().filter(x => x.CommunityId === id));
    // } else {

    const url = `/api/neighborhoods/communities?withManagementMaps=${withManagementMaps}&includeActiveOnly=${includeActiveOnly}`;

    return this.http.post<Neighborhood[]>(url, requestBody).pipe(
      map((hoods: Neighborhood[]) => {
        hoods = hoods.map(n => new Neighborhood(n));
        if (!includeForOverallCommunity) hoods = hoods.filter(x => !x.IsForOverallCommunity);

        // update store
        hoods.forEach(x => {
          this.store.addOrUpdate(x.NeighborhoodId.toString(), x);
        });

        ids.forEach(id => {
          // add community id to history
          if (!withManagementMaps) this.communityHistory.push(id);
        });

        return hoods;
      })
    );
    // }
  }

  getByMapConfigurationId(
    id: string,
    includeForOverallCommunity = false
  ): Observable<Neighborhood[]> {
    if (this.storeByMapConfig[id]) {
      return observableOf(this.storeByMapConfig[id]);
    } else {
      const url = `/api/neighborhoods/mapconfiguration/${id}`;

      return this.http.get<Neighborhood[]>(url).pipe(
        map((hoods: Neighborhood[]) => {
          hoods = hoods.map(n => new Neighborhood(n));
          if (!includeForOverallCommunity) hoods = hoods.filter(x => !x.IsForOverallCommunity);

          // update store
          this.storeByMapConfig.addOrUpdate(id, hoods);

          return hoods;
        })
      );
    }
  }

  patchValues(data: INeighborhoodPatchDTO): Observable<Neighborhood> {
    return this.http
      .patch<Neighborhood>(`${this.stageApiBase}/api/neighborhoods/${data.NeighborhoodId}`, data)
      .pipe(map(n => new Neighborhood(n)));
  }

  getLotMapStatuses(id: number, fromStage = false) {
    const base = fromStage ? this.stageApiBase : '';
    return this.http.get<LotMapStatus[]>(`${base}/api/neighborhoods/${id}/lotmapstatuses`);
  }
}

export interface INeighborhoodPatchDTO {
  NeighborhoodId: number;
  Name?: string;
  PublicName?: string;
  IsActive?: boolean;
  Description?: string;
  IconId: number;
  ExternalUrl: string;
  FocusLatitude?: number;
  FocusLongitude?: number;
}

export class LotMapStatus {
  LotMapStatusId: number;
  NeighborhoodId: number;
  Name: string;
  Description: string;
  ColorHexValue: string;
  FocusColorHexValue: string;
  Code: string;
  StatusType: number;
  ClientId: number;
  ModifiedOn: string;
  ApiLastModifiedOn: string;
  SortOrder: number;
}

export class Neighborhood implements INeighborhood {
  ClientId: number;
  NeighborhoodId: number;
  Name: string;
  PublicName: string;
  CommunityId: number;
  LotMapSvgFilename: string;
  LotMapBackgroundFilename: string;
  VirtualDirectory: string;
  Community: Community;
  DivisionId: number;
  IsForOverallCommunity: boolean;
  IsActive: boolean;
  FloorPlans: SQLFloorPlan[] = [];
  LotMapStatuses: LotMapStatus[] = [];
  Lots: Lot[] = [];
  Description: string;
  IconId: number;
  ExternalUrl: string;
  FocusLatitude?: number;
  FocusLongitude?: number;
  MapImages: IMapImage[];
  ClientUniqueId: string;
  Latitude?: number;
  Longitude?: number;
  RotationDegreesFromNorth?: number;
  LotMapPixelsPerFoot?: number;
  SvgModifiedOn?: string;
  IconSvgContent: string;
  Buildings: IBuildingDTO_v2[];

  get fullSvgUrl(): string {
    if (!this.LotMapSvgFilename) return '';

    return `${Lookup.ProductDataBaseUrl}${this.VirtualDirectory}/${this.LotMapSvgFilename}`;
  }

  get fullBackgroundFileUrl(): string {
    if (!this.LotMapBackgroundFilename) return '';

    return `${Lookup.ProductDataBaseUrl}${this.VirtualDirectory}/${this.LotMapBackgroundFilename}`;
  }

  constructor(data?: Neighborhood) {
    Object.assign(this, data);
  }
}
