import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Subscription } from 'rxjs';

import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { filter } from 'rxjs/operators';
import { AnimationState, heightExpandCollapseAnimation } from '../../utility/animations';
import { UiAction, UiHelperService } from '../ui-helper.service';

@Component({
  selector: 'accordion-form',
  templateUrl: './accordion-form.component.html',
  styleUrls: ['./accordion-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [heightExpandCollapseAnimation(100)]
})
export class AccordionFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() allowDuplicate = true;
  @Input() allowSave = false;
  @Input() allowPublish = false;
  @Input() allowEditRequest = false;
  @Input() allowDelete = true;
  @Input() closedOnInit = false;
  @Input() isDirty = false;
  @Input() isInvalid = false;
  @Input() internalNames: string[];
  @Input() publishing = false;
  @Output() publish = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() duplicateAndAdd = new EventEmitter();
  @Output() requestEdit = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() open = new EventEmitter();
  isOpen = true;
  animationState: string;
  sub: Subscription;
  hasOpenedOnce = false;

  constructor(private uiHelper: UiHelperService, private cdf: ChangeDetectorRef) {}

  ngOnInit() {
    this.isOpen = !this.closedOnInit;
    this.hasOpenedOnce = this.isOpen;
    this.updateAnimationState();

    if (this.internalNames?.length) {
      this.sub = this.uiHelper.messages$
        .pipe(filter(message => this.internalNames.includes(message.Name)))
        .subscribe(message => {
          if (message.Action === UiAction.Open && !this.isOpen) {
            this.toggle();
            this.cdf.markForCheck();
          }
        });
    }
  }

  ngOnChanges() {
    this.updateAnimationState();
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  toggle() {
    this.isOpen = !this.isOpen;
    this.updateAnimationState();

    if (this.isOpen) {
      this.open.emit();
      this.hasOpenedOnce = true;
    }
  }

  doDuplicate(evt: Event) {
    evt.stopPropagation();
    this.isOpen = false;
    this.duplicateAndAdd.emit();
  }

  handleSave(evt: Event) {
    evt.stopPropagation();
    this.save.emit();
  }

  handlePublish(evt: Event) {
    evt.stopPropagation();
    this.publish.emit();
  }

  private updateAnimationState() {
    this.animationState = this.isOpen ? AnimationState.Expanded : AnimationState.Collapsed;
  }
}
