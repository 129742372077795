export interface IIcon {
  IconId: number;
  Name: string;
  ClientId?: number;
  SvgContent: string;
  Type: number;
  CreatedOn: string;
  ModifiedOn: string;
}

export class Icon implements IIcon {
  IconId: number;
  Name: string;
  ClientId?: number = null;
  SvgContent: string;
  Type: IconType;
  CreatedOn: string;
  ModifiedOn: string;

  ClientName?: string;
  TypeName: string;

  constructor(data?: IIcon, clientName?: string) {
    if (data) Object.assign(this, data);

    if (clientName) this.ClientName = clientName;

    this.TypeName = IconType[this.Type];
  }
}

export enum IconType {
  Miscellaneous,
  'User Interface',
  Bedroom,
  Bathroom,
  Garage,
  Price,
  'Square Footage',
  Community,
  Floorplan,
  Lot,
  'Inventory Home',
  'Area Map',
  Favoriting,
  Hotspots
}
