import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'back-arrow',
  templateUrl: './back-arrow.component.html',
  styleUrls: ['./back-arrow.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BackArrowComponent implements OnInit {

  @Output() backTask = new EventEmitter();
  @Input() hoverTooltip: string;

  constructor() { }

  ngOnInit() {
  }

  doTask() {
    this.backTask.emit();
  }

}
