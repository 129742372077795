import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';

import { IIcon, IconType } from './icon';
import { sortByName } from '../shared/ui-helper.service';

@Injectable()
export class IconService {
  private store: IIcon[] = [];
  private hasMadeGetAllRequest = false;
  iconBaseUrl = '/api/icons';

  constructor(private http: HttpClient) { }

  getById(id: number): Promise<IIcon> {
    const icon = this.store.find(i => i.IconId === id);
    if (icon) return observableOf(icon).toPromise();
    return this.http
      .get<IIcon>(`${this.iconBaseUrl}/${id}`)
      .pipe(
        map((i: IIcon) => {
          this.store.push(i);
          return i;
        })
      )
      .toPromise();
  }

  getAll(): Promise<IIcon[]> {
    if (this.hasMadeGetAllRequest) return observableOf(this.store).toPromise();
    return this.http
      .get<IIcon[]>(this.iconBaseUrl)
      .pipe(
        map((icons: IIcon[]) => {
          this.hasMadeGetAllRequest = true;
          this.store.push(...icons);
          this.store = this.store.filter((value, index, self) => index === self.findIndex(
            (t) => t.IconId === value.IconId));
          return this.store;
        })
      )
      .toPromise();
  }

  save(icon: IIcon, forcePost = false): Promise<IIcon> {
    let obs: Observable<IIcon>;
    if (!icon.IconId || forcePost)
      obs = this.http.post<IIcon>(`${this.iconBaseUrl}`, icon).pipe(
        map((i: IIcon) => {
          this.store.push(i);
          return i;
        })
      );
    else
      obs = this.http.put<IIcon>(`${this.iconBaseUrl}/${icon.IconId}`, icon).pipe(
        map((i: IIcon) => {
          const index = this.store.findIndex(x => x.IconId === i.IconId);
          this.store[index] = i;
          return i;
        })
      );

    return obs.toPromise();
  }

  delete(id: number) {
    return this.http
      .delete<void>(`${this.iconBaseUrl}/${id}`)
      .pipe(
        map(() => {
          const index = this.store.findIndex(x => x.IconId === id);
          this.store.splice(index, 1);
          return;
        })
      )
      .toPromise();
  }

  getIconTypes(): Array<{ Value: string; Name: string }> {
    return Object.keys(IconType)
      .filter(key => isNaN(Number(key)))
      .map(key => ({ Value: IconType[key], Name: key }))
      .sort(sortByName);
  }
}
