﻿<div class="mapping-table">
  <div class="header">
    <div class="tab btn" (click)="showTab('divisions')" [ngClass]="{active: visibleTable === 'divisions'}">
      <div class="label">Divisions</div>
      <div class="stat">&#40; {{selectedDivs.length}} &#41;</div>
    </div>
    <div class="tab btn" (click)="showTab('communities')" [ngClass]="{hide: showOnlyDivision || selectedDivs.length === 0, active: visibleTable === 'communities'}">
      <div class="label">Community</div>
      <div class="stat">&#40; {{selectedComms.length}} &#41;</div>
    </div>
    <div class="tab btn" (click)="showTab('neighborhoods')" [ngClass]="{hide: showOnlyDivision || selectedComms.length === 0, active: visibleTable === 'neighborhoods'}">
      <div class="label">Neighborhood</div>
      <div class="stat">&#40; {{selectedHoods.length}} &#41;</div>
    </div>
    <div class="search-box">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.23 9.25">
        <path d="M1.53 3.85a2.31 2.31 0 1 1 2.31 2.32 2.31 2.31 0 0 1-2.31-2.32zm7.7 4.31L7.06 6A3.85 3.85 0 1 0 6 7.07l2.15 2.18z" fill="#95989a" data-name="Layer 1" />
      </svg>
      <input type="text" placeholder="Search" [formControl]="searchTerm" />
    </div>
  </div>
  <div class="body">
    <div class="divisions table" [ngClass]="{active: visibleTable === 'divisions'}">
      <div class="message" *ngIf="divisionPager.displayedItems?.length === 0">No results!</div>
      <div class="column" *ngFor="let col of divCols; let i = index" [ngClass]="{left: i === 0, right: i > 0 && i === divCols.length - 1}">
        <div *ngFor="let div of col; trackBy:trackDivisionBy" class="row btn" [ngClass]="{selected: selectedDivs.includes(div)}">
          <div class="label">{{div?.Name}}</div>
          <div class="toggle" (click)="selectedDivs.toggleItem(div)">
            <div class="dot"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="communities table" [ngClass]="{active: visibleTable === 'communities'}">
      <div class="message" *ngIf="communityPager.displayedItems?.length === 0">No results!</div>
      <div class="message" *ngIf="searchTerm.value && communityPager.displayedItems?.length > 0">Community search results for all selected divisions:</div>
      <div class="header" *ngIf="!searchTerm.value">
        <div class="tab btn" *ngFor="let div of selectedDivs.array; trackBy:trackDivisionBy" (click)="filterCommunities(div)"
             [ngClass]="{active: divFilteredBy.DivisionId === div.DivisionId}">
          <span class="label">{{div.Name}}</span>
          <span class="all-toggle" [ngClass]="{on:areAllCommunitiesSelected(div)}" (click)="toggleAllCommunities(div)">All</span>
        </div>
      </div>
      <div class="column" *ngFor="let col of commCols; let i = index" [ngClass]="{left: i === 0, right: i > 0 && i === commCols.length - 1}">
        <div *ngFor="let comm of col; trackBy:trackCommunitiesBy" class="row btn" [ngClass]="{selected: selectedComms.includes(comm)}">
          <div class="label">{{comm.Name}}</div>
          <div class="toggle" (click)="selectedComms.toggleItem(comm)">
            <div class="dot"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="neighborhoods table" [ngClass]="{active: visibleTable === 'neighborhoods'}">
      <div class="message" *ngIf="nhoodPager.displayedItems?.length === 0">No results!</div>
      <div class="message" *ngIf="searchTerm.value && nhoodPager.displayedItems?.length > 0">Neighborhood search results for all selected communities:</div>
      <div class="header" *ngIf="!searchTerm.value">
        <div class="tab btn" *ngFor="let comm of selectedComms.array; trackBy:trackCommunitiesBy" (click)="filterNeighborhoods(comm)"
             [ngClass]="{active: commFilteredBy.CommunityId === comm.CommunityId}">
          <span class="label">{{comm.Name}}</span>
          <span class="all-toggle" [ngClass]="{on:areAllNeighborhoodsSelected(comm)}" (click)="toggleAllNeighborhoods(comm)">All</span>
        </div>
      </div>
      <div class="column" *ngFor="let col of nhoodCols; let i = index" [ngClass]="{left: i === 0, right: i > 0 && i === nhoodCols.length - 1}">
        <div *ngFor="let hood of col; trackBy:trackNeighborhoodsBy" class="row btn" [ngClass]="{selected: selectedHoods.includes(hood)}">
          <div class="label">{{hood.Name}}<span *ngIf="!hood.LotMapSvgFilename">&nbsp;(No SVG Yet)</span></div>
          <div class="toggle" (click)="selectedHoods.toggleItem(hood)">
            <div class="dot"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <paginator *ngIf="visibleTable === 'divisions'" [pager]="divisionPager"></paginator>
    <paginator *ngIf="visibleTable === 'communities'" [pager]="communityPager"></paginator>
    <paginator *ngIf="visibleTable === 'neighborhoods'" [pager]="nhoodPager"></paginator>
  </div>
  <confirm-modal *ngIf="confirmDivisionRemoveData" (cancel)="handleDivisionRemoveConfirm(false)" (proceed)="handleDivisionRemoveConfirm(true)">
    <div class="title">
      <div class="icon">
        <svg-helper svgName="warning"></svg-helper>
      </div>
      <div class="label">Warning!</div>
    </div>
    <div class="message">
      <span>You are about to remove all mappings from {{confirmDivisionRemoveData.item.PublicName}}.</span>
      <span>Are you sure?</span>
    </div>
  </confirm-modal>
</div>