<section class="accordion-container" [class.open]="isOpen" [class.no-collapse]="!allowCollapse">
    <header class="accordion-header" (click)="toggleOpen()">
        <ng-content select="[header]"></ng-content>
        <button class="arrow" *ngIf="allowCollapse">
            <div *ngIf="useCarrotArrow; else elseBlock" class="carrot-arrow">
                <svg-helper svgName="carrot_arrow_right"></svg-helper>
            </div>
            <ng-template #elseBlock>
                <div class="plus">
                    <svg-helper svgName="minus"></svg-helper>
                    <svg-helper svgName="minus"></svg-helper>
                </div>
            </ng-template>
        </button>
    </header>
    <ng-content></ng-content>
    <div class="accordion-body" #accordionBody [@heightExpandCollapse]="animationState">
        <div class="body-content">
            <ng-content select="[body]"></ng-content>
        </div>
    </div>
</section>