import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectorRef,
  Input,
  Output,
  ChangeDetectionStrategy,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges
} from '@angular/core';

import { heightExpandCollapseAnimation, AnimationState } from '../../utility/animations';

@Component({
  selector: 'accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [heightExpandCollapseAnimation(200)]
})
export class AccordionComponent implements OnInit, OnChanges {
  @Input() openByDefault = false;
  @Input() allowCollapse = true;
  @Input() isOpen = false;
  @Input() useCarrotArrow = false;
  @Output() isOpenChanged = new EventEmitter<boolean>();
  @ViewChild('accordionBody', { static: true }) accordionBody: ElementRef<HTMLElement>;
  animationState: string;
  hasOpenedOnce = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.isOpen = !this.allowCollapse || this.openByDefault;
    this.hasOpenedOnce = this.isOpen;

    this.updateAnimationState();
  }

  ngOnChanges() {
    this.updateAnimationState();
  }

  toggleOpen() {
    if (!this.allowCollapse) return;

    this.isOpen = !this.isOpen;
    if (this.isOpen) this.hasOpenedOnce = true;

    this.updateAnimationState();

    this.cdr.detectChanges();
    this.isOpenChanged.emit(this.isOpen);
  }

  private updateAnimationState() {
    this.animationState = this.isOpen ? AnimationState.Expanded : AnimationState.Collapsed;
  }
}
