import { Frame } from '../entities/frame';
import { DocumentBase } from './document-base';

export class Spinner extends DocumentBase {
  Frames: Frame[] = [];

  constructor(frames?: Frame[]) {
    super();

    if (frames) this.Frames = frames;
  }
}
