import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ToastService {
  private _state: Subject<ToastState> = new Subject();

  constructor(private snackbar: MatSnackBar) {}

  get state(): Observable<ToastState> {
    return this._state.asObservable();
  }

  setState(isShowing: boolean, options: ToastOptions = {}) {
    this._state.next(new ToastState(isShowing, options));
  }

  showLoading(message?: string) {
    if (message) {
      this.setState(true, {
        message,
        useSuccessStyle: false,
        loadingIndicatorAndMessage: true,
        infinite: true
      });
    } else {
      this.setState(true, { message, onlyLoadingIndicator: true });
    }
  }

  /** Will only hide toast if currently displaying the loading indicator */
  hideLoading() {
    this.setState(false, { onlyLoadingIndicator: true });
  }

  /** Will hide toast no matter what the current display is */
  hide() {
    this.setState(false);
  }

  showError(title: string, message?: string, disregardIfErrorAlreadyShowing?: boolean) {
    this.setState(true, {
      useErrorStyle: true,
      title,
      message,
      clickToClose: true,
      infinite: true,
      disregardIfErrorAlreadyShowing
    });
  }

  showSuccess(title: string, message?: string, duration?: number) {
    this.setState(true, {
      useSuccessStyle: true,
      title,
      message,
      duration
    });
  }

  showMessage(message: string) {
    this.setState(true, {
      message,
      clickToClose: true,
      infinite: true
    });
  }

  showSnackbarSuccess(message: string, duration: number = 2500) {
    this.snackbar.open(message, null, { panelClass: 'success', duration });
  }

  showSnackbarError(message: string) {
    this.snackbar.open(message, null, { panelClass: 'error', duration: 5000 });
  }
}

export class ToastState {
  constructor(public isShowing: boolean = false, public options: ToastOptions = {}) {}
}

export interface ToastOptions {
  /**
    This will display only the loading spinner. The iconPath is not required and by default will display infinite
   **/
  onlyLoadingIndicator?: boolean;
  loadingIndicatorAndMessage?: boolean;

  /**
    This will keep the toast visible until setState is called again
   **/
  infinite?: boolean;

  /**
    Toast default duration is 3 seconds
   **/
  duration?: number; // check toast.component for default

  /**
     Allows the user to click the modal to close it instead of waiting on duration
   */
  clickToClose?: boolean;

  useSuccessStyle?: boolean;
  useErrorStyle?: boolean;

  title?: string;
  message?: string;
  iconName?: string;
  disregardIfErrorAlreadyShowing?: boolean;
}
