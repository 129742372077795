<section class="entity-editor" [class.open]="isOpen">
    <header class="entity-row-item flex-row">
        <ng-content select="[drag-handle]"></ng-content>
        <button class="btn quick expander" (click)="handleOpenToggle()">
            <div class="icon">
                <svg-helper svgName="carrot_arrow"></svg-helper>
            </div>
        </button>
        <input type="text" name="Category Name" placeholder="Category Name"
               [(ngModel)]="category.Name" (ngModelChange)="onInputChange()" />
        <button class="btn quick standard-negative push-right" (click)="delete.emit()">
            <div class="icon">
                <svg-helper svgName="trash_can_bold"></svg-helper>
            </div>
        </button>
    </header>
    <div class="entity-form-content">
        <div class="form-container">
            <div class="row">
                <div class="col-xs-4">
                    <div class="row">
                        <div class="col-xs-12 field">
                            <label>Keyword to search</label>
                            <input type="text" name="category-search-keyword" placeholder="Use same as name"
                                   [(ngModel)]="category.Keyword" (ngModelChange)="onInputChange()" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-6 field">
                            <button class="btn standard-positive preview-search-btn" [class.disabled]="!category.Name || !community"
                                    (click)="handleGoogleSearch()">
                                <div class="icon">
                                    <svg-helper svgName="eyeball"></svg-helper>
                                </div>
                                <label>Preview Google Results</label>
                            </button>
                        </div>
                        <div class="col-xs-6 field"><label>Sort by:</label><select name="sortByForGoogleResults" [(ngModel)]="sortByForGoogleResults" (change)="sortSearchResults()">
                                <option [ngValue]="searchResultSortBy.Alphabetical">Alphabetical</option>
                                <option [ngValue]="searchResultSortBy.Distance">Distance</option>
                            </select>
                        </div>
                    </div>
                    <ng-container *ngIf="isUsingSavedResults && searchResults.length">
                        <button class="btn standard-positive standalone" (click)="handleAllPoiAddOrRemove(true)">
                            <div class="icon">
                                <svg-helper svgName="plus_bold"></svg-helper>
                            </div>
                            <label>Add All</label>
                        </button>
                        <ul>
                            <li *ngFor="let poi of searchResults">
                                <button class="btn quick standard-positive" [class.disabled]="isIncluded(poi)"
                                        (click)="handlePoiIncludeToggle(poi)" matTooltip="Include">
                                    <div class="icon">
                                        <svg-helper svgName="plus_bold"></svg-helper>
                                    </div>
                                </button>
                                <label>{{poi.Name}}<span class="distance">{{poi.DistanceFromCommunity}} mi</span></label>
                            </li>
                        </ul>
                    </ng-container>
                    <ng-container *ngIf="!isUsingSavedResults">
                        <ul>
                            <li *ngFor="let poi of searchResults; let i=index">
                                <button class="btn quick standard-negative" [class.disabled]="isExcluded(poi)"
                                        (click)="handlePoiExcludeToggle(poi)" matTooltip="Exclude">
                                    <div class="icon">
                                        <svg-helper svgName="alert_bold"></svg-helper>
                                    </div>
                                </button>
                                <label>{{i + 1}}. {{poi.Name}}</label>
                            </li>
                        </ul>
                    </ng-container>
                </div>
                <div class="col-xs-4">
                    <ng-container *ngIf="isUsingSavedResults">
                        <h4>Included POIs</h4>
                        <button class="btn standard-positive add-poi-btn" (click)="handleOpenPoiForm()">
                            <div class="icon">
                                <svg-helper svgName="plus_bold"></svg-helper>
                            </div>
                            <label>Add POI</label>
                        </button>
                        <button *ngIf="category.IncludedSavedResults.length" class="btn standard-negative" (click)="verifyRemoveAllPOI()">
                            <div class="icon">
                                <svg-helper svgName="x"></svg-helper>
                            </div>
                            <label>Remove All</label>
                        </button>
                        <ul>
                            <li *ngFor="let poi of category.IncludedSavedResults; let i=index">
                                <button class="btn quick standard-negative" (click)="handlePoiIncludeToggle(poi)">
                                    <div class="icon">
                                        <svg-helper svgName="x"></svg-helper>
                                    </div>
                                </button>
                                <button *ngIf="poi.IsEditable" class="btn quick standard-positive" (click)="handleEditPoi(poi)">
                                    <div class="icon">
                                        <svg-helper svgName="edit"></svg-helper>
                                    </div>
                                </button>
                                <button class="btn quick standard-positive" (click)="toggleFileUploadDialog(poi)">
                                    <div class="icon">
                                        <svg-helper svgName="image"></svg-helper>
                                    </div>
                                </button>
                                <label>{{i + 1}}. {{poi.Name}}<span class="distance">{{distanceFromCommunityByPoiId[poi.Id]}} mi</span></label>
                            </li>
                        </ul>
                    </ng-container>
                    <ng-container *ngIf="!isUsingSavedResults">
                        <h4>Excluded POIs</h4>
                        <ul>
                            <li *ngFor="let poi of category.ExcludedMarkers; let i=index">
                                <button class="btn quick standard-negative" (click)="handlePoiExcludeToggle(poi)">
                                    <div class="icon">
                                        <svg-helper svgName="x"></svg-helper>
                                    </div>
                                </button>
                                <label>{{i + 1}}. {{poi.Name}}</label>
                            </li>
                        </ul>
                    </ng-container>
                </div>
                <div class="col-xs-2 field">
                    <label>Icon</label>
                    <icon-form-control [(iconId)]="category.IconId" (iconIdChange)="handleIconChange($event)"></icon-form-control>
                </div>
                <div class="col-xs-2">
                    <div class="row">
                        <div class="col-xs-12 field">
                            <label>Marker Color</label>
                            <form-control-projector [fieldType]="colorFieldType" [(value)]="category.MarkerColor" (valueChange)="handleMarkerColorChange()"></form-control-projector>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 field">
                            <label>Search Radius</label>
                            <div class="help-text">Search radius from the community in miles (max 50). Defaults to Area Map's Search Radius setting</div>
                            <input type="number" max="50" [(ngModel)]="category.SearchRadius" (ngModelChange)="onInputChange()" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <modal *ngIf="showFileUploadDialog" class="image-upload-modal" (close)="toggleFileUploadDialog()">
        <image-upload [images]="imageFromPoiToEdit ? [imageFromPoiToEdit] : []" [maxImagesAllowed]="1" [maxFileSizeMb]="1" [allowDelete]="true" (imagesChange)="handleImagesChange($event)"></image-upload>
    </modal>
    <modal *ngIf="showAddPoiForm" (close)="toggleAddPoiForm()">
        <add-poi [poi]="poiToEdit" (savePoi)="handleAddPoi($event)"></add-poi>
    </modal>
    <confirm-modal *ngIf="showPoiDelete" (cancel)="cancelPoiDelete()" (proceed)="handleAllPoiAddOrRemove(false)">
        <div class="title">
            <div class="label">Delete POIs?</div>
        </div>
        <div class="message">
            <span>You are about to delete all points of interests (POI) in this category.</span>
            <span>Are you sure?</span>
        </div>
    </confirm-modal>
</section>