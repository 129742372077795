import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'counter-input',
    templateUrl: './counter-input.component.html',
    styleUrls: ['./counter-input.component.scss']
})
export class CounterInputComponent {
    @Input() label: string;
    @Input() value: number;
    @Output() valueChange = new EventEmitter<number>();

    constructor() { }

    change(newValue) {
        this.value = newValue;
        this.valueChange.emit(newValue);
    }

    increment() {
        this.value++;
        this.valueChange.emit(this.value);
    }

    decrement() {
        this.value--;
        this.valueChange.emit(this.value);
    }
}
