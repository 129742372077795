import { IMedia, MediaType } from '@ml/common';
import { Lookup } from '../shared/lookup';
import { DocumentBase } from './document-base';

export class MediaSQL implements IMedia {
  MediaId: number;
  TriggerButtonId: string;
  ImageFilename: string;
  Title: string;
  ImageUrl: string;
  ExternalUrl: string;
  ModifiedOn: string;
  FloorPlanId?: number;
  FloorId?: number;
  CommunityId?: number;
  MediaType: MediaType;
  AmenityMapId?: number;
  Order?: number;
  ImageModifiedOn: string;
  ResourceFoldername: string;
  DefaultResourceFilename: string;
  LotId?: number;
  OpenExternalUrlInPopup: boolean;
  OpenExternalUrlInPopupNoChrome: boolean;
  LockAgainstApi: boolean;
  InternalImageUrl: string;
  FloorPlateId?: number;
  HotspotIds: number[] = [];
  IsPrimary: boolean;

  constructor(data?: MediaSQL) {
    if (data) Object.assign(this, data);
  }
}

export class Media extends DocumentBase {
  Type: number;

  Groups: string[] = new Array<string>();

  Uri: string;

  AlternateUri: string;

  SortOrder: number;

  ThumbUri: string;

  get fullUri(): string {
    if (!this.Uri) return '';

    if (this.Uri.startsWith('http')) {
      return this.Uri;
    }

    return `${Lookup.ApiFsEndpoint}/${this.Uri}`;
  }

  get fullAlternateUri(): string {
    if (!this.AlternateUri) return '';

    if (this.AlternateUri.startsWith('http')) {
      return this.AlternateUri;
    }

    return `${Lookup.ApiFsEndpoint}/${this.AlternateUri}`;
  }

  get fullThumbUri(): string {
    if (!this.ThumbUri) return '';

    if (this.ThumbUri.startsWith('http')) {
      return this.ThumbUri;
    }

    return `${Lookup.ApiFsEndpoint}/${this.ThumbUri}`;
  }
}
