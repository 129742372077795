import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
  Output,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { User, UserService } from '../../entities';
import { enterLeaveFadeAnimation } from '../../utility/animations';
import { ApolloApplicationModuleLookup } from '../apollo-module-models';
import { AppStateService } from '../app-state.service';

@Component({
  selector: 'user-search-control',
  templateUrl: './user-search-control.component.html',
  styleUrls: ['./user-search-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [enterLeaveFadeAnimation()]
})
export class UserSearchControlComponent implements OnInit, OnDestroy {
  @Input() user: Partial<User>;
  @Input() addedUsers: Partial<User>[] = [];
  @Output() userChange = new EventEmitter<User>();

  searchCtrl = new UntypedFormControl();
  showProgressSpinner = false;
  searchedUsers: User[] = [];
  sub: Subscription;
  selectedUser: User;

  constructor(
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private appState: AppStateService
  ) {}

  ngOnInit(): void {
    this.sub = this.searchCtrl.valueChanges.pipe(debounceTime(100)).subscribe(this.handleSearch);
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  handleSearch = async (value: string | User) => {
    if (!value || typeof value !== 'string') return [];

    this.showProgressSpinner = true;
    this.cdr.markForCheck();

    const users = await this.userService.search(
      value,
      this.appState.clientId,
      Object.values(ApolloApplicationModuleLookup.Orders.Roles)
    );
    this.searchedUsers = users.filter(u => !this.addedUsers.some(x => x.Id === u.Id));

    this.showProgressSpinner = false;
    this.cdr.markForCheck();
  };

  handleAddUser() {
    if (!this.selectedUser) return;
    this.userChange.emit(this.selectedUser);
    this.selectedUser = undefined;
    this.searchCtrl.setValue('');
  }

  handleUserSelect(evt: MatAutocompleteSelectedEvent) {
    const user = evt.option.value as User;
    this.selectedUser = user;
  }

  displayFn(user: User): string {
    return user?.FullName;
  }

  trackByUserId(_, user: User) {
    return user.Id;
  }
}
