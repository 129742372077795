import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Dictionary } from '../shared/data.structures';
import { Building } from './building';

@Injectable()
export class BuildingService {
  store: Dictionary<Building>;

  constructor(private http: HttpClient) {
    this.store = new Dictionary<Building>();
  }

  getById(id: number): Observable<Building> {
    // check store first
    const b = this.store[id];
    if (b) {
      return observableOf(b);
    } else {
      return this.http.get<Building>(`/api/buildings/${id}`).pipe(
        map((resBld: Building) => {
          const bld = new Building(resBld);
          this.store.addOrUpdate(bld.Id, bld);
          return bld;
        })
      );
    }
  }

  update(building: Building): Observable<Building> {
    const id = building.numberId
      ? building.numberId
      : building.Id.toLowerCase().replace('buildings/', '');

    return this.http.put<Building>(`/api/buildings/${id}`, building).pipe(
      map((resBld: Building) => {
        const b = new Building(resBld);
        this.store.addOrUpdate(b.Id, b);

        return b;
      })
    );
  }

  create(building: Building): Observable<Building> {
    return this.http.post<Building>(`/api/buildings/`, building).pipe(
      map((resBld: Building) => {
        const b = new Building(resBld);
        this.store.addOrUpdate(b.Id, b);

        return b;
      })
    );
  }

  delete(building: Building): Observable<Building> {
    return this.http.delete<Building>(`/api/buildings/${building.numberId}`).pipe(
      map((resBld: Building) => {
        const b = new Building(resBld);
        this.store.remove(building.Id);

        return b;
      })
    );
  }
}
