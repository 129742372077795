import { Injectable } from '@angular/core';

import { Dictionary } from './data.structures';

// Simple service to cache any value across life of app
// option to pass a type in order to group any cached items together (makes clearing out easier)
@Injectable()
export class CacheService {
    private _store: Dictionary<any>;

    constructor() {
        this._store = new Dictionary<any>();
    }

    getValue(key: string, type?: string) {
        if (type && this._store[type]) {
            return this._store[type][key];
        } else {
            return this._store[key];
        }
    }

    // will overwrite any existing value
    setValue(key: string, value: any, type?: string) {
        if (type) {
            if (!this._store[type])
                this._store.addOrUpdate(type, new Dictionary<any>());

            this._store[type].addOrUpdate(key, value);
        } else {
            this._store.addOrUpdate(key, value);
        }
    }

    hasKey(key: string, type?: string): boolean {
        if (type && this._store[type]) {
            return this._store[type].containsKey(key);
        } else {
            return this._store.containsKey(key);
        }
    }

    clearAllOfType(type: string) {
        if (this._store[type]) {
            this._store[type] = null;
        }
    }

    clearAll() {
        this._store = new Dictionary<any>();
    }
}
