import { Component, OnInit, ViewEncapsulation, Input, ElementRef } from '@angular/core';
import { getLibraryIcon } from '@ml/web-components';
import { LegacyIcons } from './legacy-icons';

@Component({
  selector: 'svg-helper',
  template: '',
  styleUrls: ['./svg-helper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SvgHelperComponent implements OnInit {
  @Input() svgName: string;

  svgShowing = '';

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    let svgToInject = getLibraryIcon(this.svgName);
    if (!svgToInject) svgToInject = LegacyIcons[this.svgName];

    if (svgToInject) this.elementRef.nativeElement.innerHTML = svgToInject;
    else console.error(`Error: No svg-name properly specified: ${this.svgName}`);
  }
}
