import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { ACL, AclService, ClientService, IconService, User } from '../../entities';
import { AppStateService } from '../app-state.service';
import { Lookup } from '../lookup';
import { ToastService } from '../toast/toast.service';

@Component({
  selector: 'quick-link-editor',
  templateUrl: './quick-link-editor.component.html',
  styleUrls: ['./quick-link-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickLinkEditorComponent implements OnInit {
  @Input() value: string;
  @Output() update = new EventEmitter<string>();
  quickLinks: QuickLink[] = [];
  quickLinkItem = new QuickLink();
  editEnabled = false;
  editIndex: number;
  allRoutes: RouteInfo[] = [];
  user: User;

  constructor(
    private iconService: IconService,
    private toaster: ToastService,
    private authService: AuthService,
    private aclService: AclService,
    private appState: AppStateService,
    private clientService: ClientService
  ) {}

  ngOnInit(): void {
    this.user = this.authService.currentUser;

    if (this.value) {
      const parsedValue: Array<IQuickLink> = JSON.parse(this.value);
      parsedValue.forEach(val => this.quickLinks.push(new QuickLink(val)));
    }

    this.clientService.getById(this.appState.clientId).subscribe(client => {
      const clientRole = `${client.Name}_${client.ClientId}`;
      this.aclService.getModulesAuthorizedForClient(clientRole).subscribe(acls => {
        acls.filter(this.filterSuperAdminModules).map(this.mapAclToNav);
      });
    });
  }

  async handleIconChange(iconId: string | number, item: QuickLink) {
    item.svgContent = (await this.iconService.getById(+iconId)).SvgContent;
  }

  addEditQuickLink() {
    if (
      !this.quickLinkItem.label ||
      !this.quickLinkItem.urlType ||
      (this.quickLinkItem.urlType === 'internal' && !this.quickLinkItem.route) ||
      (this.quickLinkItem.urlType === 'external' && !this.quickLinkItem.url)
    ) {
      this.toaster.showError('Missing Fields', 'Please complete all required fields');
    } else {
      if (!this.editEnabled) {
        this.quickLinks.push(this.quickLinkItem);
      } else {
        this.editEnabled = false;
      }
      this.quickLinkItem = new QuickLink();
    }
    this.update.emit(JSON.stringify(this.quickLinks));
  }

  editLink(index: number) {
    this.editEnabled = true;
    this.editIndex = index;

    this.quickLinkItem = this.quickLinks[index];
  }

  deleteLink(index: number) {
    this.quickLinks.splice(index, 1);
    this.update.emit(JSON.stringify(this.quickLinks));
  }

  drop(event: CdkDragDrop<QuickLink[]>) {
    if (!this.editEnabled) {
      moveItemInArray(this.quickLinks, event.previousIndex, event.currentIndex);
      this.update.emit(JSON.stringify(this.quickLinks));
    }
  }

  filterSuperAdminModules(acl: ACL): boolean {
    const mod = Lookup.Modules[acl.ResourceName];
    return mod && !mod.ForSuperAdminsOnly;
  }

  trackByIndex(index: number) {
    return index;
  }

  compareSelect(r1: RouteInfo, r2: RouteInfo): boolean {
    return r1 && r2 ? r1.Url === r2.Url : r1 === r2;
  }

  addDefaultLinks() {
    const linkGen = new QuickLink({
      label: 'Link Generator',
      icon: '',
      urlType: 'internal',
      url: '/tools/link-generator',
      svgContent: '',
      route: null
    });
    linkGen.route = new RouteInfo('Tools Links', '/tools/link-generator', 'Tools');
    this.quickLinks.push(linkGen);

    this.quickLinks.push(
      new QuickLink({
        label: 'MediaLab Homepage',
        icon: '',
        urlType: 'external',
        url: 'https://www.medialab3dsolutions.com/',
        svgContent: '',
        route: null
      })
    );
    this.quickLinks.push(
      new QuickLink({
        label: 'About Our Products',
        icon: '',
        urlType: 'external',
        url: 'https://www.medialab3dsolutions.com/portfolio/',
        svgContent: '',
        route: null
      })
    );

    this.quickLinks.push(
      new QuickLink({
        label: 'In-Production Jobs',
        icon: '',
        urlType: 'internal',
        url: '/orders/history/jobs/in-production',
        svgContent: '',
        route: new RouteInfo('Orders In-Production', '/orders/history/jobs/in-production', 'Orders')
      })
    );

    this.quickLinks.push(
      new QuickLink({
        label: 'Access MediaLab FTP',
        icon: '',
        urlType: 'external',
        url: 'https://ftp.medialabinc.com/WebInterface/login.html?u={{user?.FTPUsername}}&p={{user?.FTPPassCipher}}',
        svgContent: '',
        route: null
      })
    );
    this.update.emit(JSON.stringify(this.quickLinks));
  }

  private mapAclToNav = (acl: ACL) => {
    const mod = Lookup.Modules[acl.ResourceName];

    const navigableOps = mod.NavigableOperations;
    if (navigableOps.length > 0) {
      const navops = navigableOps.filter(op => op.isUserAllowed(this.user, acl));
      navops.forEach(x =>
        this.allRoutes.push(new RouteInfo(`${mod.Name} ${x.PublicName}`, x.BaseUrl, mod.Name))
      );
    } else {
      this.allRoutes.push(new RouteInfo(mod.Name, mod.BaseUrl, mod.Name));
    }
  };
}

export interface IQuickLink {
  label: string;
  icon: string;
  urlType: string;
  url: string;
  svgContent: string;
  route: RouteInfo;
}
export class QuickLink implements IQuickLink {
  label: string;
  icon: string;
  urlType: string;
  url: string;
  svgContent: string;
  route: RouteInfo;

  constructor(data?: IQuickLink) {
    if (data) {
      Object.assign(this, data);

      if (data.route) {
        this.route.Name = data.route.Name;
        this.route.Url = data.route.Url;
        this.route.Module = data.route.Module;
      }
    }
  }
}

export class RouteInfo {
  Name: string;
  Url: string;
  Module: string;

  constructor(Name: string, Url: string, Module: string) {
    this.Name = Name;
    this.Url = Url;
    this.Module = Module;
  }
}
