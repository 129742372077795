import { Component, OnInit, ViewEncapsulation, Input, ViewChild } from '@angular/core';

import { PaginationObject } from '../pagination/pagination-object';

@Component({
    selector: 'image-slider',
    templateUrl: './image-slider.component.html',
    styleUrls: ['./image-slider.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ImageSliderComponent implements OnInit {
    @Input() imageUrls = new Array<string>();
    pager = new PaginationObject<string>(1);
    containerTransform = 'translateX(0)';

    constructor() {}

    ngOnInit() {
        this.pager.allItems = this.imageUrls;
        this.pager.onIndexChanged = this.onPagerIndexChange;
    }

    onPagerIndexChange = () => {
        const x = this.pager.index * -100;
        this.containerTransform = `translateX(${x}%)`;
    };
}
