import { SettingFieldType } from '@ml/common';
import { Settings, OverrideTier } from '../../entities/settings';

export class SettingVM {
  SettingsId: number;
  MasterSettingsId: number;
  FallbackSettingsId: number;
  Name: string;
  PublicName: string;
  Value: string;
  ValueOriginal: string;
  SettingsCategoryId: number;
  ClientId: number;
  ProjectId: number;
  CommunityId: number;
  NeighborhoodId: number;
  FloorPlanId: number;
  ConfiguratorId: number;
  WebSpinnerId: number;
  HelpText: string;
  FieldDataOptions: string;
  FieldType: SettingFieldType = 0;
  SettingsCategoryName: string;
  Tier: OverrideTier;
  FallbackSettingsName: string;
  UIName: string;
  UIValue: string;
  DisplayName: string;
  HtmlId: string;

  isSelected = false; // used in copy-updates component
  canShowResetButton = false;
  tierBadge = '';
  badgeMessage = '';
  canUpdateSetting = true;
  FilesToUpload: File[] = [];
  FilesToDelete: string[] = [];

  constructor(setting: Settings, currentPageTier?: OverrideTier) {
    Object.assign(this, setting);

    if (!this.ValueOriginal) this.ValueOriginal = this.Value;

    if (this.Name) {
      this.DisplayName = this.PublicName || this.Name;
      this.HtmlId = this.Name.replace(/\s/gi, '_');
    }

    if (currentPageTier) this.canShowResetButton = currentPageTier === this.Tier;

    switch (this.Tier) {
      case OverrideTier.Community:
        this.tierBadge = 'CM';
        break;
      case OverrideTier.Configurator:
        this.tierBadge = 'CFG';
        break;
      case OverrideTier.WebSpinner:
        this.tierBadge = 'WS';
        break;
      case OverrideTier.Division:
        this.tierBadge = 'D';
        break;
      case OverrideTier.Client:
        this.tierBadge = 'CL';
        break;
      case OverrideTier.Neighborhood:
        this.tierBadge = 'N';
        break;
      case OverrideTier.FloorPlan:
        this.tierBadge = 'FP';
        break;
      case OverrideTier.Master:
        if (this.FallbackSettingsId) {
          this.tierBadge = 'FB';
          this.badgeMessage = `Since it has no override value this setting is falling back to ${this.FallbackSettingsName}`;
        }
        break;
    }
    if (this.Tier && this.Tier !== OverrideTier.Master)
      this.badgeMessage = `This value is a ${OverrideTier[this.Tier]} level override`;
  }

  get isClientOverride() {
    return this.ClientId && !this.isProjectOverride && !this.isConfiguratorOverride && !this.isWebSpinnerOverride;
  }

  get isProjectOverride() {
    return this.ProjectId && !this.isCommunityOverride && !this.isConfiguratorOverride && !this.isWebSpinnerOverride;
  }

  get isCommunityOverride() {
    return this.CommunityId && !this.isNeighborhoodOverride && !this.isConfiguratorOverride && !this.isWebSpinnerOverride;
  }

  get isNeighborhoodOverride() {
    return this.NeighborhoodId && !this.isFloorPlanOverride && !this.isConfiguratorOverride && !this.isWebSpinnerOverride;
  }

  get isFloorPlanOverride() {
    return this.FloorPlanId && !this.isConfiguratorOverride && !this.isWebSpinnerOverride;
  }

  get isConfiguratorOverride() {
    return this.ClientId && this.ProjectId && this.ConfiguratorId;
  }

  get isWebSpinnerOverride() {
    return this.ClientId && this.ProjectId && this.WebSpinnerId;
  }

  get useFullWidth(): boolean {
    return (
      this.FieldType === SettingFieldType.FloorplanSocial ||
      this.FieldType === SettingFieldType.ExpandingSet ||
      this.FieldType === SettingFieldType.MultiExpandingSet
    );
  }

  mapToEntity(coreOnly: boolean = false): Settings {
    const setting = new Settings();

    setting.SettingsId = this.SettingsId;
    setting.MasterSettingsId = this.MasterSettingsId;
    setting.FallbackSettingsId = this.FallbackSettingsId;
    setting.Value = this.Value;
    setting.FieldType = this.FieldType;
    setting.FilesToDelete = this.FilesToDelete;

    if (!coreOnly) {
      setting.Name = this.Name;
      setting.PublicName = this.PublicName;
      setting.SettingsCategoryId = this.SettingsCategoryId;
      setting.ClientId = this.ClientId;
      setting.ProjectId = this.ProjectId;
      setting.CommunityId = this.CommunityId;
      setting.HelpText = this.HelpText;
      setting.FieldDataOptions = this.FieldDataOptions;
      setting.SettingsCategoryName = this.SettingsCategoryName;
      setting.UIName = this.UIName;
      setting.WebSpinnerId = this.WebSpinnerId;
      setting.ConfiguratorId = setting.ConfiguratorId;
    }

    return setting;
  }

  mergeAndReturnNew(otherVM: SettingVM): SettingVM {
    const newVM = new SettingVM(this.mapToEntity());

    newVM.SettingsId = otherVM.SettingsId;
    newVM.MasterSettingsId = otherVM.MasterSettingsId;
    newVM.ClientId = otherVM.ClientId;
    newVM.CommunityId = otherVM.CommunityId;
    newVM.ProjectId = otherVM.ProjectId;
    newVM.Value = otherVM.Value;
    newVM.ValueOriginal = otherVM.Value;

    newVM.canShowResetButton = otherVM.canShowResetButton;
    newVM.tierBadge = otherVM.tierBadge;
    newVM.badgeMessage = otherVM.badgeMessage;
    newVM.FilesToUpload = otherVM.FilesToUpload;
    newVM.FilesToDelete = otherVM.FilesToDelete;

    return newVM;
  }
}
