import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Directive, Input, Output, ElementRef, EventEmitter } from '@angular/core';

import { CacheService } from './cache.service';
import { Lookup } from './lookup';

// Use this directive to inject SVGs (or non-Angular HTML) by specifying src path
// Possible enhancement: tap into Angular's component compiler so injected HTML could
// be real directives/components (instead of plain HTML or SVG)
@Directive({
  selector: '[mlInclude]'
})
export class MlIncludeDirective {
  @Input() includeRef?: any;
  @Output() includeLoaded = new EventEmitter<any>();
  @Output() loadFailed = new EventEmitter<Error>();

  constructor(
    private http: HttpClient,
    private elementRef: ElementRef,
    private cache: CacheService
  ) {}

  @Input() set mlInclude(src: string) {
    let toInject = '';

    if (src) {
      if (this.cache.hasKey(src)) {
        toInject = this.cache.getValue(src);
        this.elementRef.nativeElement.innerHTML = toInject;
        this.checkForIncludeLoadComplete();
      } else {
        this.http
          .get(src, {
            headers: new HttpHeaders({ [Lookup.SkipAuthHeader]: 'true' }),
            responseType: 'text'
          })
          .subscribe(
            res => {
              toInject = res;
              // quick check to make sure no scripts are trying to be injected
              if (toInject.indexOf('<script') === -1) {
                this.cache.setValue(src, toInject);
                this.elementRef.nativeElement.innerHTML = toInject;
                this.checkForIncludeLoadComplete();
              }
            },
            error => {
              this.loadFailed.emit(error);
            }
          );
      }
    }
  }

  checkForIncludeLoadComplete = () => {
    window.setTimeout(() => {
      if (this.elementRef.nativeElement.innerHTML) {
        this.includeLoaded.emit({
          refParam: this.includeRef,
          element: this.elementRef.nativeElement
        });
      } else {
        this.checkForIncludeLoadComplete();
      }
    }, 100);
  };
}
