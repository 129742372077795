import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'reverse',
    pure: false
})
// creates copy so that source array is unchanged... only reversed in display
export class ReversePipe implements PipeTransform {
    transform(arr) {
        const copy = arr.slice();
        return copy.reverse();
    }
}
