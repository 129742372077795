<div class="input-container">
    <input type="text" aria-label="Search Users" name="Search Users" matInput [formControl]="searchCtrl" [matAutocomplete]="auto" placeholder="Type a name...">
    <button class="btn quick standard-positive" matTooltip="Add User" (click)="handleAddUser()"
            [disabled]="!searchCtrl.value && !!selectedUser">
        <svg-helper svgName="plus_bold"></svg-helper>
    </button>
    <mat-spinner *ngIf="showProgressSpinner" @enterLeaveFadeAnimation diameter="30"></mat-spinner>
</div>
<mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn"
                  (optionSelected)="handleUserSelect($event)">
    <ng-container *ngIf="searchCtrl.value">
        <mat-option class="user-option" *ngFor="let user of searchedUsers; trackBy: trackByUserId" [value]="user">
            <p>{{user.FullName}} - {{user.Email}}</p>
        </mat-option>
    </ng-container>
</mat-autocomplete>