import { Observable, firstValueFrom, of as observableOf } from 'rxjs';

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { IProject } from '@ml/common';
import { Dictionary } from '../shared/data.structures';
import { Lookup } from '../shared/lookup';
import { sortByName } from '../shared/ui-helper.service';

// TODO: move Divison class to its own file

@Injectable()
export class DivisionService {
  store = new Dictionary<Division>();
  clientHistory = new Array<number>();
  storeByMapConfig = new Dictionary<Division[]>();
  stageApiBase = window.location.protocol + '//' + Lookup.Argo.StageHost;

  constructor(private http: HttpClient) {}

  getById(id: number, fromStage = false): Observable<Division> {
    // check store first
    const div = this.store[id];
    if (div) {
      return observableOf(div);
    } else {
      const baseHost = fromStage ? this.stageApiBase : '';
      return this.http.get<Division>(`${baseHost}/api/projects/${id}`).pipe(
        map((d: Division) => {
          d = new Division(d);
          this.store.addOrUpdate(d.DivisionId.toString(), d);
          return d;
        })
      );
    }
  }

  getManyByIds(ids: number[], fromStage = false) {
    if (!ids.length) return [];

    const baseHost = fromStage ? this.stageApiBase : '';
    return firstValueFrom(
      this.http.get<IProject[]>(`${baseHost}/api/projects?projectIds=${ids.join(',')}`)
    );
  }

  getByClientId(
    id: number,
    withManagementMaps = false,
    fromStage = false,
    isActive = false
  ): Observable<Division[]> {
    // clientHistory tracks whether we have done an http get for all hoods by community
    // if requesting withManagementMaps then skip the store
    if (!withManagementMaps && !fromStage && this.clientHistory.indexOf(id) > -1) {
      return observableOf(this.store.values().filter(x => x.ClientId === id));
    } else {
      const baseHost = fromStage ? this.stageApiBase : '';
      let url = `${baseHost}/api/projects/client/${id}`;
      url += withManagementMaps ? '?withManagementMaps=true' : '';
      url += isActive && withManagementMaps ? '&isActive=true' : isActive ? '?isActive=true' : '';

      return this.http.get<Division[]>(url).pipe(
        map((divs: Division[]) => {
          divs = divs.map(d => new Division(d)).sort(sortByName);

          if (!fromStage) {
            divs.forEach(x => {
              this.store.addOrUpdate(x.DivisionId.toString(), x);
            });
            if (!withManagementMaps) this.clientHistory.push(id);
          }

          return divs;
        })
      );
    }
  }

  getByMapConfigurationId(id: string): Observable<Division[]> {
    if (this.storeByMapConfig[id]) {
      return observableOf(this.storeByMapConfig[id]);
    } else {
      const url = `/api/projects/mapconfiguration/${id}`;

      return this.http.get<Division[]>(url).pipe(
        map((divs: Division[]) => {
          divs = divs.map(d => new Division(d));
          // update store
          this.storeByMapConfig.addOrUpdate(id, divs);

          return divs;
        })
      );
    }
  }

  save(project: Project, fromStage = false): Observable<Project> {
    const baseHost = fromStage ? this.stageApiBase : '';

    if (project.ProjectId)
      return this.http.put<Project>(`${baseHost}/api/projects/${project.ProjectId}`, project);
    else return this.http.post<Project>(`${baseHost}/api/projects/`, project);
  }

  async delete(id: number, fromStage = false): Promise<void> {
    const baseHost = fromStage ? this.stageApiBase : '';
    await firstValueFrom(this.http.delete(`${baseHost}/api/projects/${id}`));
  }
}

// Note: 'Divsion' and 'Project' refer to same entity.
// Backend system identifies it as Project but Division better aligns with business terms
export class Division {
  ClientId: number;
  ProjectId: number;
  Name: string;
  PublicName: string;
  RPMCustomerAccountId: number;
  GroupName: string;

  get DivisionId(): number {
    return this.ProjectId;
  }

  set DivisionId(id: number) {
    this.ProjectId = id;
  }

  constructor(data?: Division) {
    Object.assign(this, data);
  }
}

export class Project {
  ClientId: number;
  ProjectId: number;
  Name: string;
  PublicName: string;
}
