import { RootDocumentContainer } from './root-document-container';
import { Container } from './container';
import { DocumentBase } from './document-base';

export class Root {
  RootDocumentContainer: RootDocumentContainer;
  Data: any;

  constructor(data?: Root) {
    if (data) {
      this.RootDocumentContainer = new RootDocumentContainer(data.RootDocumentContainer);
      this.Data = data.Data;
    }
  }

  // Takes in a container and a type and returns all matching containers on the first container they are found in
  // if no container passed in then use RDC
  findByType(type: string, container?: Container): Container[] {
    container = container || this.RootDocumentContainer;

    let containers: any[] = [];

    if (container.Containers) {
      const containerKeys: string[] = Object.keys(container.Containers);

      containerKeys.forEach(cKey => {
        const c: any = container.Containers[cKey];
        const matches: string[] = c.Document.match(/(\w+)\//i);

        // If we have a match, then this container is the type we were looking for
        if (matches.length > 1 && matches[1].toLocaleUpperCase() === type.toLocaleUpperCase()) {
          containers.push(c);
        }
      });

      if (containers.length === 0) {
        containerKeys.forEach(cKey => {
          const c: any = container.Containers[cKey];
          if (containers.length === 0) {
            containers = this.findByType(type, c);
          }
        });
      }
    }

    return containers;
  }

  // returns every data object whose document id matches the passed type
  getAllDataOfType(dType: string, serializeAsDocBase: boolean = false): any[] {
    // filter out all keys of all matching object types
    const keys = Object.keys(this.Data).filter(k =>
      k.toLowerCase().startsWith(dType.toLowerCase())
    );

    // pluck actual objects using filtered keys
    const values = keys.map(k => {
      return serializeAsDocBase ? Object.assign(new DocumentBase(), this.Data[k]) : this.Data[k];
    });

    return values;
  }

  // counts the number of objects of the passed type in the root data
  countOfType(dType: string): number {
    return Object.keys(this.Data).filter(k => k.toLowerCase().startsWith(dType.toLowerCase()))
      .length;
  }
}
