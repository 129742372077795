import { DocumentBase } from './document-base';
import { Floor } from './floor';

export class Building extends DocumentBase {
  Latitude: number;
  Longitude: number;
  Address: string;
  City: string;
  State: string;
  SalesCenterPhoneNumber: string;
  Fax: string;
  DrivingDirections: string;
  PostalCode: string;
  IsActive: boolean;
  Floors: Floor[] = [];
  UIInstanceId: string;

  constructor(data?: Building) {
    super();

    if (data) {
      Object.assign(this, data);
      this.Floors = data.Floors.map(x => new Floor(x));
    }
  }
}
