import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';

import { UiHelperService } from './ui-helper.service';

@Component({
  selector: 'dropdown',
  templateUrl: './dropdown.component.html'
  // styles in the global default.scss
})
export class DropdownComponent implements OnInit, OnDestroy {
  @Input() dropdown: Dropdown;

  constructor(private uiHelper: UiHelperService) {}

  ngOnInit() {
    this.uiHelper.registerDropdown(this.dropdown);

    document.addEventListener('click', this.closeSelf, false);
  }

  ngOnDestroy() {
    this.uiHelper.unregisterDropdown(this.dropdown);
    document.removeEventListener('click', this.closeSelf, false);
  }

  toggle(evt: Event) {
    evt.stopPropagation();

    const oldState = this.dropdown.isOpen;
    this.uiHelper.closeAllDropdowns();
    this.dropdown.isOpen = !oldState;
  }

  handleSelect(opt: DropdownOption) {
    this.dropdown.selectedOption = opt;
    this.dropdown.onOptionSelect(opt);
    this.dropdown.isOpen = false;
  }

  trackById(index: number, option: DropdownOption) {
    return option.id;
  }

  private closeSelf = () => {
    this.dropdown.isOpen = false;
  };
}

export class Dropdown {
  title: string;
  description: string;
  icon: string;
  isOpenLabel: string;
  isOpen: boolean;
  onOptionSelect: (opt: DropdownOption) => void;
  selectedOption: DropdownOption;
  private _options: DropdownOption[];

  constructor(
    title?: string,
    description?: string,
    onOptionSelect?: (opt: DropdownOption) => void,
    icon?: string,
    isOpenLabel?: string
  ) {
    this.title = title;
    this.description = description;
    this.onOptionSelect = onOptionSelect;
    this.isOpen = false;
    this.icon = icon;
    this.isOpenLabel = isOpenLabel;
    this._options = new Array<DropdownOption>();
  }

  get options() {
    return this._options;
  }

  set options(opts: DropdownOption[]) {
    this._options = opts;
    this.selectedOption = this.options[0];
  }
}

export class DropdownOption {
  name: string;
  id: number;
  obj?: any;

  constructor(name?: string, id?: number, obj?: any) {
    this.name = name;
    this.id = id;
    this.obj = obj;
  }
}
