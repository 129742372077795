<div *ngIf="isLoading && images.length && !currentImage.IsVideo" class="loader">
    <div class="spin-stretch"></div>
</div>
<header [ngClass]="{'has-title': showTitle && currentImage?.Title}">
    <div *ngIf="showTitle && currentImage?.Title" class="title">{{currentImage.Title}}</div>
</header>

<img *ngIf="!currentImage?.IsVideo" [src]="currentImageSrc" (load)="onFileLoad()">
<video-with-preview *ngIf="currentImage?.IsVideo" [src]="currentImage.FullUrl" [previewUrl]="currentImage.ThumbnailUrl"></video-with-preview>
<div *ngIf="!currentImage" class="img-placeholder"></div>

<button class="right-arrow" *ngIf="images.length > 1" (click)="goToImage(selectedIndex + 1)">
    <svg-helper svgName="carrot_arrow"></svg-helper>
</button>
<button class="left-arrow" *ngIf="images.length > 1" (click)="goToImage(selectedIndex - 1)">
    <svg-helper svgName="carrot_arrow"></svg-helper>
</button>

<div class="pager" *ngIf="images.length > 1">
    <p class="page-number">
        <b>{{selectedIndex + 1}}</b> of {{images.length}}
    </p>
</div>