import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'up-down-arrows',
  templateUrl: './up-down-arrows.component.html',
  styleUrls: ['./up-down-arrows.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpDownArrowsComponent implements OnInit {
  @Input() upDisabled = false;
  @Input() downDisabled = false;
  @Output() upSelect = new EventEmitter();
  @Output() downSelect = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  handleUpSelect() {
    this.upSelect.emit();
  }

  handleDownSelect() {
    this.downSelect.emit();
  }
}
