import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  OnChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { ProgressTrackerStep } from '../../entities/progress-tracker';

@Component({
  selector: 'progress-tracker',
  templateUrl: './progress-tracker.component.html',
  styleUrls: ['./progress-tracker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressTrackerComponent implements OnChanges {
  @Input() steps: Readonly<ProgressTrackerStep[]>;
  @Input() currentStepIndex = 0;
  @Input() color: string;
  @Input() bellModal: boolean;
  @Output() bellModalVisible = new EventEmitter<boolean>();

  constructor(private el: ElementRef) {}

  ngOnChanges(): void {
    if (this.color) {
      this.el.nativeElement.style.setProperty('--completed-color', this.color);
    } else {
      this.el.nativeElement.style.removeProperty('--completed-color');
    }
  }
  toggleModal(): void {
    this.bellModalVisible.emit();
  }
}
