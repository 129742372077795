import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class ConfirmModalModel {
  Title: string;
  Message: string;
}

@Component({
  selector: 'confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmModalComponent implements OnInit {
  @Input() proceedDisabled = false;
  @Output() cancel = new EventEmitter();
  @Output() proceed = new EventEmitter();

  constructor(
    @Optional() public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ConfirmModalModel
  ) {}

  ngOnInit() {}

  doCancel() {
    this.cancel.emit();

    // if this was triggered from service then send close event
    if (this.dialogRef) this.dialogRef.close(false);
  }

  doProceed() {
    this.proceed.emit();

    // if this was triggered from service then send close event
    if (this.dialogRef) this.dialogRef.close(true);
  }

  @HostListener('document:keydown.escape') onEscHandler() {
    this.doCancel();
  }
}
