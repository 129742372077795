import { IHotspot, IMedia } from '@ml/common';
import { MediaSQL } from '../../entities';
import { ImageVM } from '../../shared/image-upload/image-upload.component';

export class HotspotVM implements IHotspot {
  Description: string;
  HotspotGroupId: number;
  HotspotId: number;
  NeighborhoodId: number;
  ShortDescription: string;
  Title: string;
  X: number;
  Y: number;
  AmenityMapId: number;
  Media: MediaSQL;
  MediaId: number;
  InitialPovDegrees: number;
  Url: string;

  IframeUrl: string;
  IsSelected = false;
  ImageVM: ImageVM;
  Type: HotspotType;
  ActionSetId: number;
  OffWithActionSetIds: string;

  constructor(data?: IHotspot, image?: ImageVM) {
    Object.assign(this, data);
    this.ImageVM = image;

    this.Type = null;
    if (!!this.NeighborhoodId) {
      this.Type = HotspotType.Neighborhood;
    } else if (!!this.AmenityMapId) {
      this.Type = HotspotType.AmenityMap;
    } else if (!!this.MediaId || !!this.Url || !!this.ImageVM) {
      this.Type = HotspotType.Media;
      this.ImageVM = image;
    }

    if (this.Url?.startsWith('http')) this.IframeUrl = this.Url;
  }
}

export enum HotspotType {
  Neighborhood,
  AmenityMap,
  Media
}
