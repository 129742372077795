<div class="form-box">
    <div cdkDropList class="flex-column" (cdkDropListDropped)="drop($event)">
        <div *ngFor="let item of media; let i = index; trackBy: trackByIndex" cdkDrag [cdkDragDisabled]="replacementMode" class="row">
            <button *ngIf="!replacementMode" drag-handle cdkDragHandle class="btn no-bkg drag-handle" matTooltip="Drag to reorder">
                <div class="icon">
                    <svg-helper svgName="drag_handle"></svg-helper>
                </div>
            </button>
            <div *cdkDragPreview class="drag-preview">
                <button class="btn no-bkg drag-handle">
                    <div class="icon">
                        <svg-helper svgName="drag_handle"></svg-helper>
                    </div>
                </button>
                <h4>{{item.Title ?? item.DisplayName}}</h4>
            </div>
            <div *cdkDragPlaceholder class="drag-placeholder"></div>
            <div class="item">
                <div class="label">{{item.Title ?? item.DisplayName}}</div>
            </div>
            <button class="btn quick standard-positive" (click)="togglePreview(item)" matTooltip="Preview">
                <span class="icon">
                    <svg-helper svgName="mini_preview"></svg-helper>
                </span>
            </button>
            <button *ngIf="!replacementMode" class="btn quick standard-positive"
                    matTooltip="Edit" (click)="editMedia(i)">
                <span class="icon">
                    <svg-helper svgName="pencil_box"></svg-helper>
                </span>
            </button>
            <button *ngIf="!replacementMode" class="btn quick standard-negative"
                    matTooltip="Delete" (click)="deleteMedia(i)">
                <span class="icon">
                    <svg-helper svgName="trash_can"></svg-helper>
                </span>
            </button>
        </div>
    </div>
</div>
<div class="form-box">
    <div class="box-content">
        <div class="row">
            <div class="col-xs-6 field">
                <div class="col-xs-12 row">
                    <div class="label">Title
                    </div>
                    <input type="text" name="Title" [(ngModel)]="mediaItem.Title" />
                </div>
                <div class="col-xs-12 row">
                    <div class="label">Description</div>
                    <textarea name="Description" [(ngModel)]="mediaItem.Description"></textarea>
                </div>
            </div>
            <div *ngIf="mediaItem.Type === settingMediaType.Image" class="col-xs-6 field">
                <div class="label"><span class="warning">*</span>Image Upload</div>
                <image-upload [images]="mediaItem.ImageVM ? [mediaItem.ImageVM] : []" [maxImagesAllowed]="1" [maxFileSizeMb]="2" [allowDelete]="false" [showDropzone]="false" (imagesChange)="handleImagesChange($event, mediaItem)"></image-upload>
            </div>
            <div *ngIf="mediaItem.Type === settingMediaType.PDF" class="col-xs-6 field">
                <div class="label"><span class="warning">*</span>PDF Upload</div>
                <div *ngIf="mediaItem?.ImageVM?.Filename" class="label">File: {{mediaItem.ImageVM.Filename}}</div>
                <file-input-form [maxFilesAllowed]="1" [maxFileSizeMb]="50" (filesChange)="handleFilesChange($event, mediaItem)"></file-input-form>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-6">
                <button class="btn standard-positive quick-add-btn" (click)="addMedia()">{{editEnabled ? 'Finish Edit': 'Add'}}</button>
            </div>
        </div>
    </div>
</div>
<modal id="preview" *ngIf="previewItem" (close)="togglePreview()">
    <div *ngIf="previewItem.Type === settingMediaType.Image" class="image" [style.background-image]="previewItem.ImageVM.PreviewUrl | safeBackgroundImageUrl"></div>
    <iframe *ngIf="previewItem.Type === settingMediaType.PDF" [src]="previewItem.ImageVM.PreviewUrl | safeUrl"></iframe>
</modal>