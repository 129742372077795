import { SQLElevation } from '.';
import { OrderProductInstance } from '../entities/order-product-instance';
import { User } from '../entities/user';

export class OrderLocation {
  ClientId: number;
  ProjectId: number;
  ProjectName: string;
  CommunityId: number;
  CommunityName: string;
  NeighborhoodId: number;
  NeighborhoodName: string;
  FloorPlanId: number;
  FloorPlanName: string;
  Elevations: Array<SQLElevation>;

  constructor(data?: OrderLocation) {
    if (data) Object.assign(this, data);
  }
}

export class Order {
  OrderId: string;
  OrderName: string;
  OrderNumber: string;
  ClientId: number;
  ProjectId: number;
  IsSubmitted = false;
  OrderFiles = '';
  CreatedOn: string;
  ModifiedOn: string;
  SubmittedOn: string;
  OrderProductInstance: Array<OrderProductInstance> = new Array<OrderProductInstance>();
  Users: Array<User>;
  AdditionalEmailRecipients = new Array<string>();
  RequestedDueDate: string;
  PoNumber: string;

  constructor(data?: Order) {
    if (data) {
      Object.assign(this, data);
      this.Users = data.Users.map(u => new User(u));
    }
  }
}
