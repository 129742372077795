<div class="row align-bottom">
    <div class="col-md-12">
        <div class="location-container">
            <div class="row align-bottom">
                <div class="field col-xs-3">
                    <div class="label">Division</div>
                    <select name="pendingDivision" [ngModel]="pending.orderLocation.ProjectId" (ngModelChange)="onDivisionChange($event, pending)">
                        <option *ngIf="divisionsOnly" [value]="allLocationsId">All</option>
                        <option *ngFor="let div of pending.divisions" [value]="div.ProjectId">{{div.Name}}</option>
                    </select>
                </div>
                <div *ngIf="!divisionsOnly" class="field col-xs-3">
                    <div class="label">Community</div>
                    <select name="pendingCommunity" [ngModel]="pending.orderLocation.CommunityId" (ngModelChange)="onCommunityChange($event, pending)">
                        <option [value]="allLocationsId">All</option>
                        <option *ngFor="let com of pending.communities" [value]="com.CommunityId">{{com.Name}}</option>
                    </select>
                </div>
                <div *ngIf="!divisionsOnly" class="field col-xs-3">
                    <div class="label">Neighborhood</div>
                    <select name="pendingNeighborhood" [ngModel]="pending.orderLocation.NeighborhoodId" (ngModelChange)="onNeigborhoodChange($event, pending)">
                        <option [value]="allLocationsId">All</option>
                        <option *ngIf="pending.orderLocation.CommunityId === allLocationsId" [value]="null">---</option>
                        <option *ngFor="let nhood of pending.neighborhoods" [value]="nhood.NeighborhoodId">{{nhood.Name}}</option>
                    </select>
                </div>
                <div class="col-xs-3">
                    <div class="btn standard-positive" [class.inactive]="(divisionsOnly && (pending.divisions.length === stored.length || isDivisionStored(pending.orderLocation.ProjectId))) || (!divisionsOnly && (!pending.orderLocation.ProjectId || !pending.orderLocation.CommunityId))"
                        (click)="addPending()">
                        <span class="icon"><svg id="mini_plus_large" data-name="mini plus large" xmlns="http://www.w3.org/2000/svg"
                                width="16" height="16" viewBox="0 0 16 16">
                                <polygon points="16 7.11 8.89 7.11 8.89 0 7.11 0 7.11 7.11 0 7.11 0 8.89 7.11 8.89 7.11 16 8.89 16 8.89 8.89 16 8.89 16 7.11"
                                    fill="#fff" /></svg></span>
                        <span class="label">Add New</span>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="row align-bottom">
    <div class="col-md-12" [class.col-lg-6]="!divisionsOnly" [class.col-lg-3]="divisionsOnly" *ngFor="let locationVM of stored; let i = index">
        <div class="location-container">
            <div class="row row-cell align-bottom">
                <div class="field" [class.col-lg-3]="!divisionsOnly" [class.col-lg-8]="divisionsOnly">
                    <div class="label">Division</div>
                    <select name="locationDivision-{{i}}" [ngModel]="locationVM.orderLocation.ProjectId"
                            (ngModelChange)="onDivisionChange($event, locationVM)" *ngIf="!lockExistingLocation">
                        <option *ngFor="let div of locationVM.divisions" [value]="div.ProjectId">{{div.Name}}</option>
                    </select>
                    <div class="locked-label" *ngIf="lockExistingLocation">{{locationVM.orderLocation.ProjectName}}</div>
                </div>
                <div *ngIf="!divisionsOnly" class="field col-xs-4">
                    <div class="label">Community</div>
                    <select name="locationCommunity-{{i}}" [ngModel]="locationVM.orderLocation.CommunityId"
                            (ngModelChange)="onCommunityChange($event, locationVM)" *ngIf="!lockExistingLocation">
                        <option *ngFor="let com of locationVM.communities" [value]="com.CommunityId">{{com.Name}}</option>
                    </select>
                    <div class="locked-label" *ngIf="lockExistingLocation">{{locationVM.orderLocation.CommunityName}}</div>
                </div>
                <div *ngIf="!divisionsOnly" class="field col-xs-4">
                    <div class="label">Neighborhood</div>
                    <select name="locationNeighborhood-{{i}}" [ngModel]="locationVM.orderLocation.NeighborhoodId"
                            (ngModelChange)="onNeigborhoodChange($event, locationVM)" *ngIf="!lockExistingLocation">
                        <option *ngFor="let nhood of locationVM.neighborhoods" [value]="nhood.NeighborhoodId">{{nhood.Name}}</option>
                    </select>
                    <div class="locked-label" *ngIf="lockExistingLocation">{{locationVM.orderLocation.NeighborhoodName}}</div>
                </div>
                <div class="field flex-row align-center col-xs-1">
                    <div class="btn quick standard-negative" (click)="deleteLocation(i)">
                        <span class="icon"><svg id="trash-can" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24">
                                <path d="M23.51,4.21h-5L17.13,1,15.18,0H8.82L6.87,1,5.46,4.21h-5a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h1.6V21a3,3,0,0,0,3,3H18.89a3,3,0,0,0,3-3V5.21h1.6a.5.5,0,0,0,.5-.5A.5.5,0,0,0,23.51,4.21ZM7.64,1.71,9.06,1h5.88l1.42.71,1.09,2.5H6.55ZM20.91,21a2,2,0,0,1-2,2H5.11a2,2,0,0,1-2-2V5.21H20.91Z"
                                    transform="translate(0.01)" fill="#fff" />
                                <path d="M12,20a.5.5,0,0,1-.5-.5V8.91a.5.5,0,0,1,1,0v10.6A.5.5,0,0,1,12,20Z" transform="translate(0.01)"
                                    fill="#fff" />
                                <path d="M17,20a.51.51,0,0,1-.5-.5V8.91a.51.51,0,0,1,.5-.5.5.5,0,0,1,.5.5v10.6A.5.5,0,0,1,17,20Z"
                                    transform="translate(0.01)" fill="#fff" />
                                <path d="M7,20a.51.51,0,0,1-.5-.5V8.91a.51.51,0,0,1,.5-.5.5.5,0,0,1,.5.5v10.6A.5.5,0,0,1,7,20Z"
                                    transform="translate(0.01)" fill="#fff" /></svg></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>