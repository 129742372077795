import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { IJobNote } from '@ml/common';

import { Lookup } from '../shared/lookup';
import { PagedApiResponse } from '../shared/pagination/pagination-object';
import { ArgoGateway } from '../shared/argo.gateway';
import { RpmJob, JobsRequestOptions, RpmJobExternalNote } from './rpm';

@Injectable()
export class RpmService {
  private rpmApi: HttpClient;
  private rpmApiOptions: { headers: HttpHeaders };
  readonly baseRpmApiDomain = Lookup.Rpm.Api;

  constructor(handler: HttpBackend, private argoGateway: ArgoGateway, private http: HttpClient) {
    this.rpmApi = new HttpClient(handler);
    const authKey = btoa(`${Lookup.Rpm.ApiUsername}:${Lookup.Rpm.ApiPassword}`);
    this.rpmApiOptions = {
      headers: new HttpHeaders({
        Authorization: `Basic ${authKey}`
      })
    };
  }

  getJobById(id: number): Promise<RpmJob> {
    return this.rpmApi
      .get<RpmJob>(`${this.baseRpmApiDomain}/jobs/${id}`, this.rpmApiOptions)
      .pipe(
        map((res: RpmJob) => {
          return new RpmJob(res);
        })
      )
      .toPromise();
  }

  getJobsByIds(jobIds: Array<number>): Promise<Array<RpmJob>> {
    return this.rpmApi
      .get<Array<RpmJob>>(`${this.baseRpmApiDomain}/jobs/jobsByIds`, {
        ...this.rpmApiOptions,
        params: { jobIds }
      })
      .pipe(
        map((res: Array<RpmJob>) => {
          return res.map(j => new RpmJob(j));
        })
      )
      .toPromise();
  }

  searchJobs(options: JobsRequestOptions): Promise<PagedApiResponse<RpmJob>> {
    return this.rpmApi
      .get<PagedApiResponse<RpmJob>>(
        `${this.baseRpmApiDomain}/jobs?${options.toQueryString()}`,
        this.rpmApiOptions
      )
      .toPromise();
  }

  getNotesByJobId(jobId: number): Promise<RpmJobExternalNote[]> {
    return this.http
      .get<IJobNote[]>(`/api/jobnotes/job/${jobId}`)
      .pipe(
        map((notes: IJobNote[]) => {
          return notes.map(n => new RpmJobExternalNote(n));
        })
      )
      .toPromise();
  }

  addNote(note: IJobNote, files: File[]): Promise<RpmJobExternalNote> {
    const formData = new FormData();
    formData.append('jobNoteDTO', JSON.stringify(note));
    files.forEach(f => formData.append(f.name, f, f.name));

    return this.argoGateway
      .sendRawXhr('/api/jobnotes/formdata', 'POST', formData)
      .pipe(
        map((jsonObject: any) => {
          return new RpmJobExternalNote(jsonObject as IJobNote);
        })
      )
      .toPromise();
  }

  updateNote(note: IJobNote): Promise<RpmJobExternalNote> {
    return this.http
      .put<IJobNote>(`/api/jobnotes/${note.JobNoteId}`, note)
      .pipe(
        map((n: IJobNote) => {
          return new RpmJobExternalNote(n);
        })
      )
      .toPromise();
  }

  getJobIdsThatNeedAttention(): Promise<number[]> {
    return this.http.get<number[]>(`/api/jobnotes/jobidsthatneedattention`).toPromise();
  }

  getJobIdsThatNeedAttentionByUserId(userId: string): Promise<number[]> {
    return this.http
      .get<number[]>(`/api/jobfollowers/user/${userId}/jobidsthatneedattention`)
      .toPromise();
  }

  getJobFollowers(jobId: number): Promise<JobFollower[]> {
    return this.http.get<JobFollower[]>(`/api/jobfollowers/job/${jobId}`).toPromise();
  }

  followJob(jobId: number, userId: string): Promise<void> {
    return this.http.post<void>(`/api/jobfollowers/job/${jobId}/user/${userId}`, null).toPromise();
  }

  unfollowJob(jobId: number, userId: string): Promise<void> {
    return this.http.delete<void>(`/api/jobfollowers/job/${jobId}/user/${userId}`).toPromise();
  }
}

export interface JobFollower {
  Id: string;
  Name: string;
  Email: string;
}
