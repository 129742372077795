import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ITag } from '@ml/common';

import { Lookup } from '../lookup';

@Injectable({
  providedIn: 'root'
})
export class TagService {
  stageApiBase = window.location.protocol + '//' + Lookup.Argo.StageHost;

  constructor(private http: HttpClient) {}

  getById(id: number) {
    return this.http.get<ITag>(`${this.stageApiBase}/api/tags/${id}`).toPromise();
  }

  getByClientId(clientId: number) {
    return this.http.get<ITag[]>(`${this.stageApiBase}/api/tags/client/${clientId}`).toPromise();
  }

  /** Returns in order of most used tag to least. Capped at 20 */
  getSuggestedByClientId(clientId: number, entityType: TagEntityType) {
    return this.http
      .get<ITag[]>(
        `${this.stageApiBase}/api/tags/client/${clientId}/suggested?entityType=${entityType}`
      )
      .toPromise();
  }

  searchForTag(term: string, clientId: number) {
    return this.http
      .get<ITag[]>(`${this.stageApiBase}/api/tags/client/${clientId}?searchTerm=${term}`)
      .toPromise();
  }

  getAllMappingsByClientId(clientId: number) {
    return this.http
      .get<BulkMappedTag[]>(`${this.stageApiBase}/api/tags/client/${clientId}/mappings`)
      .toPromise();
  }

  getAllMappingsByDivisionId(divisionId: number) {
    return this.http
      .get<BulkMappedTag[]>(`${this.stageApiBase}/api/tags/division/${divisionId}/mappings`)
      .toPromise();
  }

  create(tag: ITag) {
    return this.http.post<ITag>(`${this.stageApiBase}/api/tags`, tag).toPromise();
  }

  update(tag: ITag) {
    return this.http.put<ITag>(`${this.stageApiBase}/api/tags/${tag.TagId}`, tag).toPromise();
  }

  updateMappings(requests: TagMappingRequest[]) {
    return this.http
      .patch<TagMappingResponse>(`${this.stageApiBase}/api/tags/bulk-mappings`, requests)
      .toPromise();
  }

  delete(tagId: number) {
    return this.http.delete(`${this.stageApiBase}/api/tags/${tagId}`).toPromise();
  }
}

export class TagMappingRequest {
  TagId: number;
  Action: TagMappingAction;
  FloorPlanId: number;
  LotId: number;
  InventoryHomeId: number;
}

export enum TagMappingAction {
  Add,
  Remove
}

export class TagMappingResponse {
  SuccessCount: number;
  Errors: any[];
}

export enum TagEntityType {
  FloorPlan,
  Lot,
  InventoryHome
}

export enum BulkTagActionType {
  Add = 'Add',
  Remove = 'Remove'
}

export class BulkMappedTag {
  TagId: number;
  Name: string;
  LotId?: number;
  FloorPlanId?: number;

  constructor(tag: ITag, lotId?: number, floorPlanId?: number) {
    this.TagId = tag.TagId;
    this.Name = tag.Name;
    this.LotId = lotId;
    this.FloorPlanId = floorPlanId;
  }
}

export enum BulkTagMappedType {
  Lot = 'Lot',
  FloorPlan = 'FloorPlan'
}
