export function removeFromArray<T>(item: T, array: T[]): T[] {
  const copy = array.slice();
  const i = copy.findIndex(x => x === item);
  if (i > -1) copy.splice(i, 1);

  return copy;
}

export function removeByIndex<T>(index: number, array: T[]): T[] {
  const copy = array.slice();
  if (index > -1) copy.splice(index, 1);

  return copy;
}

export function roundToPlace(num: number, place: number = 0) {
  const mult = Math.pow(10, place);
  return Math.round(mult * num) / mult;
}

export function fileListToArray(fileList: FileList): File[] {
  const array = new Array<File>();
  for (let i = 0; i < fileList.length; i++) {
    array.push(fileList[i]);
  }
  return array;
}

export function enumEntries<T>(t: T): ReadonlyArray<readonly [keyof T, T[keyof T]]> {
  const entries = Object.entries(t);
  const plainStringEnum = entries.every(([key, value]) => typeof value === 'string');
  return (plainStringEnum ? entries : entries.filter(([k, v]) => typeof v !== 'string')) as any;
}

export function enumKeys<T>(t: T): ReadonlyArray<keyof T> {
  return enumEntries(t).map(([key]) => key);
}

export function enumValues<T>(t: T): ReadonlyArray<T[keyof T]> {
  const values = Object.values(t);
  const plainStringEnum = values.every(x => typeof x === 'string');
  return plainStringEnum ? values : values.filter(x => typeof x !== 'string');
}

export function elementToString(element: Element) {
  const wrapper = document.createElement('div');
  wrapper.appendChild(element);
  return wrapper.innerHTML;
}

export function compare(a: number | string, b: number | string, isAsc: boolean) {
  a = typeof a === 'string' ? a.toLowerCase() : a;
  b = typeof b === 'string' ? b.toLowerCase() : b;
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
