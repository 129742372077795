import jsSHA from 'jssha';
import { DefaultApiCredentials, HeaderKeys } from './auth.constants';
export const getArgoHeaders = (config, debugFn) => {
  let argoHeaders = Object.assign({
    [HeaderKeys.Date]: new Date().toUTCString(),
    [HeaderKeys.DebugHmacString]: debugFn ? 'true' : 'false'
  }, config.headers);
  if (config.usingAuthToken) argoHeaders = setAuthTokenSpecificHeaders(argoHeaders);
  argoHeaders.Authorization = getAuthorizationHeader(config, argoHeaders, debugFn);
  return argoHeaders;
};
function getAuthorizationHeader(config, finalHeaders, debugFn) {
  var _a, _b;
  const username = (_a = config.username) !== null && _a !== void 0 ? _a : DefaultApiCredentials.User;
  const authKey = (_b = config.authKey) !== null && _b !== void 0 ? _b : DefaultApiCredentials.Key;
  const hmac = getHmacSignature(config.url, config.method, finalHeaders, authKey, debugFn);
  return `${username} ${hmac}`;
}
function getHmacSignature(url, method, headers, authKey, debugFn) {
  let signature = '';
  url = ensureRelativeUrl(url);
  // if not already encoded then encode
  if (decodeURIComponent(url) === url) {
    url = encodeURI(url);
  }
  let unsigned = `${method.toUpperCase()}|${url}|||`;
  if (debugFn) debugFn(unsigned);
  Object.keys(headers).forEach(headerKey => {
    if (headerKey.indexOf('x-ml-') > -1) unsigned += `${headerKey}:${headers[headerKey].trim()}|`;
  });
  const sha = new jsSHA('SHA-256', 'TEXT', {
    hmacKey: {
      value: authKey,
      format: 'TEXT'
    }
  });
  sha.update(unsigned);
  signature = sha.getHash('B64');
  return signature;
}
function ensureRelativeUrl(url) {
  // must do startsWith in case url has query param with 'http'
  if (url.startsWith('http://')) url = url.replace('http://', '');
  if (url.startsWith('https://')) url = url.replace('https://', '');
  const i = url.indexOf('/api');
  if (i > 0) return url.slice(i);else return url;
}
function setAuthTokenSpecificHeaders(headers) {
  return Object.assign(Object.assign({}, headers), {
    [HeaderKeys.UseAuthToken]: 'true',
    [HeaderKeys.CorsExposeHeaders]: ''
  });
}
