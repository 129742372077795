import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';
import { Division, DivisionService } from '../../entities';
import { ReportAutomationService } from '../../entities/report-automation.service';
import { AutomationDataVM } from '../models/automation-data-vm';
import { ReportData } from '../../tools/report/models/reportData';
import { KeyValueSetItem } from '../key-value-set/key-value-set.component';
import { AppStateService } from '../app-state.service';
import { SettingVM } from '../models/settings-vm';

@Component({
  selector: 'report-settings-select',
  templateUrl: './report-settings-select.component.html',
  styleUrls: ['./report-settings-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportSettingsSelectComponent implements OnInit, OnChanges {
  @Input() type: string;
  @Input() enabledProductList: SettingVM[] = [];
  @Output() reportSelect = new EventEmitter<ReportData>();
  analyticsReportData: ReportData[];
  reportingReportData: ReportData[];
  reportsData: ReportData[] = [];
  selectedReport: ReportData = null;
  clientId: number;
  divisionId: number;
  divisions: Division[] = [];

  constructor(
    private reportAutomationService: ReportAutomationService,
    private appStateService: AppStateService,
    private divisionService: DivisionService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.clientId = this.appStateService.clientId;
    if (this.appStateService.divisionId) this.divisionId = this.appStateService.divisionId;

    this.divisions = this.divisionService.store.values();

    this.refreshDataList();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.enabledProductList.currentValue.length && this.clientId) {
      this.refreshDataList();
    }
  }

  onSelectReport(report: ReportData) {
    this.selectedReport = report;
    this.reportSelect.emit(this.selectedReport);
  }

  private refreshDataList() {
    //get model data from file
    this.reportAutomationService.getParsedAutomationData().subscribe(x => {
      x.Types.forEach(type => {
        const report: ReportData[] = [];
        type.Reports.forEach(item => {
          const reportData = new ReportData(item);
          report.push(reportData);
        });
        if (type.Type === 'Analytics') this.analyticsReportData = report;
        else this.reportingReportData = report;
      });

      if (this.type === 'Analytics') {
        this.reportsData = this.analyticsReportData.filter(analytic =>
          this.enabledProductList
            .flatMap(s => s.SettingsCategoryName)
            .includes(analytic.RelatedProduct)
        );
        this.reportsData = [
          ...this.reportsData,
          ...this.analyticsReportData.filter(analytic => !analytic.RelatedProduct)
        ];
      } else {
        this.reportsData = this.reportingReportData;
      }
      this.addKeyValues();
      this.cdr.markForCheck();
    });
  }

  private addKeyValues() {
    this.reportsData.forEach(report => {
      const kvPairs = [];
      report.KeyValuePairs.forEach(kv => {
        const kvPair = new KeyValueSetItem();
        switch (kv.setValue) {
          case 'ClientId':
            kvPair.setKey = kv.setKey;
            kvPair.setValue = this.clientId.toString();
            kvPairs.push(kvPair);
            break;
          case 'DivisionName':
            if (this.divisionId) {
              kvPair.setKey = kv.setKey;
              kvPair.setValue = this.divisions.find(x => x.DivisionId === this.divisionId).Name;
              kvPairs.push(kvPair);
            }
            break;
          case 'ProjectId':
            if (this.divisionId) {
              kvPair.setKey = kv.setKey;
              kvPair.setValue = this.divisions
                .find(x => x.DivisionId === this.divisionId)
                .ProjectId.toString();
              kvPairs.push(kvPair);
            }
            break;
          case 'RPM':
            if (!this.divisionId) {
              this.divisions.forEach(division => {
                if (division.RPMCustomerAccountId !== 0) {
                  const divisionKv = new KeyValueSetItem();
                  divisionKv.setKey = kv.setKey;
                  divisionKv.setValue = division.RPMCustomerAccountId.toString();
                  kvPairs.push(divisionKv);
                }
              });
            } else {
              kvPair.setKey = kv.setKey;
              kvPair.setValue = this.divisions
                .find(x => x.DivisionId === this.divisionId)
                .RPMCustomerAccountId.toString();
              kvPairs.push(kvPair);
            }
            break;
          case 'RPM Division Name':
            kvPair.setKey = kv.setKey;
            kvPair.setValue = 'ReplaceWithAccountName';
            kvPairs.push(kvPair);
            break;
          default:
            kvPair.setKey = kv.setKey;
            kvPair.setValue = kv.setValue;
            kvPairs.push(kvPair);
        }
      });
      report.KeyValuePairs = kvPairs;
    });
  }
}
