<div class="floorplan-list" *ngIf="floorplans">
    <div class="floorplan-msg-hashtag" *ngFor="let fp of formattedFloorplans">
        <div class="floorplan-title">
            {{fp.title}} - {{fp.floorplanId.toString()}} {{!fp.active ? '(INACTIVE)' : ''}}
        </div>
        <div class="hashtag-title">
            Hashtags <tooltip message="Hashtags may not contain any spaces."></tooltip>
        </div>
        <form-control-projector [class.width-auto]="false" [fieldType]="5" [(value)]="fp.hashtags"
                                [(valueOriginal)]="fp.hashtags" (valueChange)="doUpdate()">
        </form-control-projector>
        <div class="hashtag-title">
            Message
        </div>
        <input type="text" [(value)]="fp.message" [(ngModel)]="fp.message" name="message-{{fp.floorplanId}}"
               (keyup)="doUpdate()">
        <div class="hashtag-title">
            Custom Shared Link
        </div>
        <input type="text" [(value)]="fp.customSharedLink" [(ngModel)]="fp.customSharedLink"
               name="customSharedLink-{{fp.floorplanId}}" (keyup)="doUpdate()" (paste)="doUpdate()">
        <hr>
    </div>
</div>