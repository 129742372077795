import { decorateProperty as r } from "./base.js";
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
function e(e) {
  return r({
    finisher: (r, t) => {
      Object.assign(r.prototype[t], e);
    }
  });
}
export { e as eventOptions };
