import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from '@angular/core';

import { ToastService } from '../toast/toast.service';
import { sortByName } from '../ui-helper.service';
import { AppStateService } from '../app-state.service';
import { Icon, IconService } from '../../entities';

@Component({
  selector: 'icon-picker',
  templateUrl: './icon-picker.component.html',
  styleUrls: ['./icon-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconPickerComponent implements OnInit {
  @Input() currentIconId: number;
  @Output() iconSelect = new EventEmitter<Icon>();
  iconGroups: IconGroupVM[] = [];

  constructor(
    private iconService: IconService,
    private toaster: ToastService,
    private cdr: ChangeDetectorRef,
    private appState: AppStateService
  ) {}

  async ngOnInit() {
    this.toaster.showLoading();
    await this.getIconGroups();
    this.cdr.detectChanges();
    this.toaster.hideLoading();
  }

  private async getIconGroups() {
    const currentClientId = this.appState.clientId; // TODO - not good to rely on this....
    try {
      const allIcons = (await this.iconService.getAll())
        .filter(i => !i.ClientId || i.ClientId === currentClientId)
        .map(i => new Icon(i))
        .sort(sortByName);
      allIcons.forEach(icon => {
        const existingGroup = this.iconGroups.find(group => group.TypeId === icon.Type);
        if (existingGroup) {
          existingGroup.Icons.push(icon);
          if (icon.IconId === this.currentIconId) existingGroup.IsAccordionOpen = true;
        } else {
          this.iconGroups.push(new IconGroupVM(icon, icon.IconId === this.currentIconId));
        }
      });
      this.iconGroups = this.iconGroups.sort(sortByName);
    } catch (ex) {
      if (ex.status !== 404) {
        this.toaster.showError('Error', 'Sorry, we failed to load the icons just now');
        console.error(ex);
      }
    }
  }
}

class IconGroupVM {
  TypeId: number;
  Name: string;
  Icons: Icon[] = [];
  IsAccordionOpen = false;

  constructor(icon: Icon, isAccordionOpen: boolean) {
    this.TypeId = icon.Type;
    this.Name = icon.TypeName;
    this.Icons = [icon];
    this.IsAccordionOpen = isAccordionOpen;
  }
}
