import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { IScpContentImage } from '../../content-editor/models/pages-contentjson';
import { ToastService } from '../toast/toast.service';

@Component({
  selector: 'image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImageUploadComponent implements OnInit {
  @Input() images: ImageVM[] = [];
  @Input() maxImagesAllowed = 1;
  @Input() maxFileSizeMb = 1;
  @Input() allowedFileExts: string[] = ['.png', '.jpg', '.svg', '.jpeg'];
  @Input() allowDelete = false;
  @Input() showDropzone = true;
  @Input() showImageStatus = true;
  @Input() retainImages = true;
  @Input() hidePreview = false;
  @Output() imagesChange = new EventEmitter<ImageVM[]>();

  constructor(private cdr: ChangeDetectorRef, private toaster: ToastService) {}

  ngOnInit() {}

  handleFilesChange(files: File[]) {
    this.toaster.showLoading();
    if (this.maxImagesAllowed !== -1 && this.images.length + files.length > this.maxImagesAllowed) {
      this.toaster.showError(
        'Too many files',
        `The maximum amount of files you may upload is ${this.maxImagesAllowed}`
      );
      return;
    }

    let counter = 0;
    const newImages = files.map(file => {
      const image = { ...new ImageVM(), File: file };

      const reader = new FileReader();
      reader.onload = () => {
        image.PreviewUrl = reader.result as string;
        counter++;
        if (counter === files.length) {
          this.cdr.markForCheck();
          this.toaster.hideLoading();
          this.imagesChange.emit(this.images);
        }
      };
      reader.readAsDataURL(file);

      return image;
    });

    if (this.retainImages) this.images = this.images.concat(newImages);
    else this.images = newImages;
  }

  handleDelete(image: ImageVM) {
    const updatedImages = this.images.map(i => {
      return {
        ...i,
        MarkedForDeletion: i.Filename === image.Filename ? true : i.MarkedForDeletion
      };
    });
    this.images = updatedImages;
    this.imagesChange.emit(this.images);
  }
}

export class ImageVM {
  File: File;
  Filename: string;
  PreviewUrl: string;
  MarkedForDeletion: boolean;

  static fromIScpContentImage(image: IScpContentImage, baseUrl: string): ImageVM {
    if (!image) return undefined;

    const newImageVm = new ImageVM();
    newImageVm.Filename = image.Filename;
    newImageVm.PreviewUrl = `${baseUrl}/${newImageVm.Filename}`;

    return newImageVm;
  }

  constructor(data?: ImageVM) {
    if (data) Object.assign(this, data);
  }
}
