<div class="row">
  <div class="field col-xs-6 set" *ngFor="let item of expandingSet.stored; let i = index; trackBy: trackByIndex">
    <div class="inputs-container multi added">
      <div class="inputs-left">
        <div class="label">Title</div>
        <input [(ngModel)]="item.reportTitle" name="storedSetItem-{{itemLabel}}" type="text"
               (keyup)="typedTextChange(expandingSet)" required />
        <div [hidden]="item.reportTitle"
             class="alert-danger">
          Title is required
        </div>
        <div class="label">Desc</div>
        <input [(ngModel)]="item.body" name="storedSetItem-{{itemLabel}}" type="text"
               (keyup)="typedTextChange(expandingSet)" required />
        <div [hidden]="item.body"
             class="alert-danger">
          Desc is required
        </div>
        <div class="label">Group ID</div>
        <input [(ngModel)]="item.group" name="storedSetItem-{{itemLabel}}" type="text"
               (keyup)="typedTextChange(expandingSet)" required />
        <div [hidden]="item.group"
             class="alert-danger">
          Group ID is required
        </div>
        <div class="label">Report ID</div>
        <input [(ngModel)]="item.report" name="storedSetItem-{{itemLabel}}" type="text"
               (keyup)="typedTextChange(expandingSet)" required />
        <div [hidden]="item.report"
             class="alert-danger">
          Report ID is required
        </div>
        <div class="label">
          <span>Show Tabs</span>
          <input [(ngModel)]="item.showTabs" name="storedSetItem-{{itemLabel}}" type="checkbox"
                 (ngModelChange)="typedTextChange(expandingSet)" />
        </div>
        <div class="help-text">Hide or show tabs at the bottom of the report</div>
        <div class="label">Table Name</div>
        <input [(ngModel)]="item.tableName" name="storedSetItem-{{itemLabel}}" type="text"
               (keyup)="typedTextChange(expandingSet)" />
        <div *ngIf="shouldShowRelatedProduct" class="label">Related Product</div>
        <select *ngIf="shouldShowRelatedProduct" [(ngModel)]="item.productSettingsCategoryTitle"
                (ngModelChange)="typedTextChange(expandingSet)">
          <option value="">None</option>
          <option *ngFor="let product of productTitles" [value]="product">{{product}}</option>
        </select>
        <div class="label">Filter String(s)</div>
        <key-value-set [value]="item.keyValuePairs" (add)="addKeyValuePair($event, 'stored', i)"
                       (delete)="deleteKeyValuePair($event, 'stored', i)" (changed)="typedTextChange(expandingSet)">
        </key-value-set>
      </div>

      <div class="btn-right">
        <div class="btn quick standard-negative" (click)="deleteItem(expandingSet, i)">
          <span class="icon">
            <svg-helper svgName="mini_trash"></svg-helper>
          </span>
        </div>
      </div>

    </div>
  </div>

  <div *ngIf="expandingSet.stored && expandingSet.stored.length > 0" class="multi-separator-container">
    <div class="multi-separator"></div>
  </div>

  <div class="field col-xs-6 new" *ngIf="expandingSet">
    <div class="inputs-container multi" *ngIf="expandingSet.pending">
      <div class="input-top">
        <div class="inputs-left">
          <report-settings-select [type]="typeName" [enabledProductList]="enabledProductList" (reportSelect)="onReportSelect($event)"></report-settings-select>
          <div class="label">Title</div>
          <input class="form-control" name="pendingSetItem-{{itemLabel}}" type="text" [formControl]="pendingTitle" />
          <div [hidden]="pendingTitle.valid || pendingTitle.pristine"
               class="alert-danger">
            Title is required
          </div>
          <div class="label">Desc</div>
          <input class="form-control" name="pendingSetItem-{{itemLabel}}" type="text" [formControl]="pendingBody" />
          <div [hidden]="pendingBody.valid || pendingBody.pristine"
               class="alert-danger">
            Desc is required
          </div>
          <div class="label">Group ID</div>
          <input class="form-control" name="pendingSetItem-{{itemLabel}}" type="text" [formControl]="pendingGroup" />
          <div [hidden]="pendingGroup.valid || pendingGroup.pristine"
               class="alert-danger">
            Group ID is required
          </div>
          <div class="label">Report ID</div>
          <input class="form-control" name="pendingSetItem-{{itemLabel}}" type="text" [formControl]="pendingReport" />
          <div [hidden]="pendingReport.valid || pendingReport.pristine"
               class="alert-danger">
            Report ID is required
          </div>
          <div class="label">
            <span>Show Tabs</span>
            <input [(ngModel)]="expandingSet.pending.showTabs" name="pendingSetItem-{{itemLabel}}" type="checkbox" />
          </div>
          <div class="help-text">Hide or show tabs at the bottom of the report</div>
          <div class="label">Table Name</div>
          <input [(ngModel)]="expandingSet.pending.tableName" name="pendingSetItem-{{itemLabel}}" type="text" />
          <div *ngIf="shouldShowRelatedProduct" class="label">Related Product</div>
          <select *ngIf="shouldShowRelatedProduct" [(ngModel)]="expandingSet.pending.productSettingsCategoryTitle" name="pendingSetItem-{{itemLabel}}">
            <option value="">None</option>
            <option *ngFor="let product of productTitles" [value]="product">{{product}}</option>
          </select>
          <div class="label">Filter String(s)</div>
          <key-value-set [value]="expandingSet.pending.keyValuePairs" (add)="addKeyValuePair($event, 'pending')"
                         (delete)="deleteKeyValuePair($event, 'pending')"></key-value-set>
        </div>

        <div class="inputs-right">
          <div class="btn quick standard-positive"
               [class.disabled]="pendingTitle.pristine && pendingBody.pristine && pendingGroup.pristine && pendingReport.pristine"
               (click)="addPending(expandingSet)">
            <span class="icon">
              <svg-helper svgName="plus_bold"></svg-helper>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>