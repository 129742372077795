/** Sort by PublicName and fallback to Name - case insensitive */
export function sortByName(a, b) {
  var _a, _c;
  return (((_a = a.PublicName) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || a.Name.toLowerCase()) < (((_c = b.PublicName) === null || _c === void 0 ? void 0 : _c.toLowerCase()) || b.Name.toLowerCase()) ? -1 : 1;
}
/** Sort by Title - case insensitive */
export function sortByTitle(a, b) {
  return a.Title.toLowerCase() < b.Title.toLowerCase() ? -1 : 1;
}
/** Sort by SortOrder */
export function sortBySortOrder(a, b) {
  return a.SortOrder - b.SortOrder;
}
/** Sort by Order */
export function sortByOrder(a, b) {
  return a.Order - b.Order;
}
/** Sort by Order and if Order is undefined, it's sorted last */
export function sortByOrderNullable(a, b) {
  var _a, _c;
  return ((_a = a.Order) !== null && _a !== void 0 ? _a : Number.MAX_SAFE_INTEGER) - ((_c = b.Order) !== null && _c !== void 0 ? _c : Number.MAX_SAFE_INTEGER);
}
/** Sort by FloorNumber */
export function sortByFloorNumber(a, b) {
  return a.FloorNumber - b.FloorNumber;
}
export function sortFloorsWithBasementLast(floors) {
  const basement = floors.find(x => x.IsBasement);
  const nonBasements = floors.filter(x => !x.IsBasement);
  const sorted = nonBasements.sort(sortByFloorNumber);
  if (basement) sorted.push(basement);
  return sorted;
}
/**
 * Sort by IsFavorited
 * TODO: account for a fallback to SortOrder property
 */
export function sortByIsFavorited(a, _b) {
  return a.IsFavorited ? -1 : 1;
}
