import { Component, OnInit, ViewEncapsulation, EventEmitter, Output, Input } from '@angular/core';

import { fileListToArray } from '../../utility/helpers';
import { ToastService } from '../toast/toast.service';

@Component({
  selector: 'file-input-form',
  templateUrl: './file-input-form.component.html',
  styleUrls: ['./file-input-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FileInputFormComponent implements OnInit {
  @Input() maxFilesAllowed = -1;
  @Input() maxFileSizeMb = 2;
  @Input() allowedFileExts: string[] = [];
  @Input() showDropzone = true;
  @Input() isDisabled = false;
  @Output() filesChange = new EventEmitter<File[]>();

  constructor(private toaster: ToastService) {}

  ngOnInit() {}

  onFilesDrop(fileList: FileList) {
    const files = fileListToArray(fileList);
    this.validateAndEmit(files);
  }

  onFileInput(evt: any) {
    const files = fileListToArray(evt.target.files);
    evt.target.value = null;
    this.validateAndEmit(files);
  }

  validateAndEmit(files: File[]) {
    const tooBig = files.filter(f => f.size > this.maxFileSizeMb * 1000000);
    if (tooBig.length) {
      this.toaster.showError(
        'Invalid file size',
        `Max file size is ${this.maxFileSizeMb}mb. The following are too large:\r\n ${tooBig
          .map(f => f.name)
          .join(', ')}`
      );
      return;
    }

    if (this.maxFilesAllowed !== -1 && files.length > this.maxFilesAllowed) {
      this.toaster.showError(
        'Too many files',
        `The maximum amount of files you may upload is ${this.maxFilesAllowed}`
      );
      return;
    }

    if (!!this.allowedFileExts.length) {
      const wrongFileExt = files.filter(f =>
        this.allowedFileExts.every(ext => !f.name.toLowerCase().endsWith(ext))
      );
      if (wrongFileExt.length) {
        this.toaster.showError(
          'Invalid file',
          `Only the following types are allowed: ${this.allowedFileExts.join(', ')}`
        );
        return;
      }
    }

    this.filesChange.emit(files);
  }
}
