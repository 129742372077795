import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Component({
    selector: 'can-deactivate-modal',
    templateUrl: './can-deactivate-modal.component.html',
    styleUrls: ['./can-deactivate-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CanDeactivateModalComponent {
    @Input() showSaveAndContinueButton = true;
    @Input() message = 'All new changes will be lost if you exit this page before saving.';
    @Input() leavePageButtonText = 'Exit/lose changes';

    subject = new Subject<CanDeactivateResponse>();
    isVisible = false;

    trigger(): Observable<CanDeactivateResponse> {
        this.isVisible = true;
        return this.subject.asObservable();
    }

    leavePage() {
        this.isVisible = false;
        const response: CanDeactivateResponse = {
            canDeactivate: true,
            attemptSave: false
        };
        this.subject.next(response);
    }

    stayOnPage() {
        this.isVisible = false;
        const response: CanDeactivateResponse = {
            canDeactivate: false,
            attemptSave: false
        };
        this.subject.next(response);
    }

    saveAndLeavePage() {
        this.isVisible = false;
        const response: CanDeactivateResponse = {
            canDeactivate: true,
            attemptSave: true
        };
        this.subject.next(response);
    }
}

export interface CanDeactivateResponse {
    canDeactivate: boolean;
    attemptSave: boolean;
}
