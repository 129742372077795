import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { AuthService } from '../../auth/auth.service';
import { AreaMapContent } from '../../content-editor/models/pages-contentjson';
import { PageTitle, PageVersion } from '../../content-editor/services/page.service';
import {
  Client,
  ClientService,
  Community,
  CommunityService,
  Division,
  DivisionService,
  ManagementMap,
  ManagementMapsService,
  Neighborhood,
  NeighborhoodService,
  User
} from '../../entities';
import { AppStateService } from '../app-state.service';
import { Dropdown, DropdownOption } from '../dropdown.component';
import { Lookup } from '../lookup';
import { ToastService } from '../toast/toast.service';

@Component({
  selector: 'cascading-dropdowns',
  templateUrl: './cascading-dropdowns.component.html',
  styleUrls: ['./cascading-dropdowns.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CascadingDropdownsComponent implements OnInit {
  @Input()
  includeManagementMaps = false;
  @Input()
  includeNeighborhoods = true;
  @Input()
  includeInactiveNeighborhoods = false;
  @Input()
  includeCommunities = true;
  @Input()
  includeInactiveCommunities = false;
  @Input()
  includeNeighborhoodsForOverallCommunity = false;
  @Input()
  filterOptionsOnlyWithManagementMaps = false;
  @Input()
  showLabels = false;
  @Input()
  showEmptyOption = false;
  @Input()
  initEmpty = false;
  @Input()
  fromStageExceptManagementMaps = false;
  @Input()
  filterCommunityOptionsOnlyWithPOIs = false;
  @Input()
  useLoadingIndicator = false;
  @Output()
  clientSelected = new EventEmitter<Client>();
  @Output()
  divisionSelected = new EventEmitter<Division>();
  @Output()
  communitySelected = new EventEmitter<Community>();
  @Output()
  neighborhoodSelected = new EventEmitter<Neighborhood>();
  @Output()
  managementMapSelected = new EventEmitter<ManagementMap>();
  user: User;
  clients: Dropdown;
  divisions: Dropdown;
  communities: Dropdown;
  neighborhoods: Dropdown;
  manMaps: Dropdown;
  isApolloAdmin: boolean;
  isClientAdmin: boolean;
  isDivisionAdmin: boolean;

  private emptyOptionText = 'All';

  constructor(
    private authSer: AuthService,
    private divisionSer: DivisionService,
    private clientSer: ClientService,
    private manMapsSer: ManagementMapsService,
    private hoodSer: NeighborhoodService,
    private communitySer: CommunityService,
    private appStateService: AppStateService,
    private toast: ToastService,
    private cdr: ChangeDetectorRef
  ) {
    this.user = this.authSer.currentUser;
    this.isApolloAdmin = this.authSer.isSuperAdmin();
    this.isClientAdmin = this.authSer.isClientAdmin();
    this.isDivisionAdmin = this.authSer.isDivisionAdmin();

    this.clients = new Dropdown('', '', (opt: DropdownOption) => {
      this.clientSelected.emit(opt.obj);
      this.appStateService.clientId = opt.id;
      this.fetchDivisions(opt);
    });
    this.divisions = new Dropdown('', '', (opt: DropdownOption) => {
      this.divisionSelected.emit(opt.obj);
      this.appStateService.divisionId = opt.id;
      if (this.includeCommunities) this.fetchCommunities(opt);
    });
    this.communities = new Dropdown('', '', (opt: DropdownOption) => {
      this.communitySelected.emit(opt.obj);
      this.appStateService.communityId = opt.id;
      if (this.includeNeighborhoods) this.fetchNeigborhoods(opt);
    });
    this.neighborhoods = new Dropdown('', '', (opt: DropdownOption) => {
      this.neighborhoodSelected.emit(opt.obj);
      this.appStateService.neighborhoodId = opt.id;
      if (this.includeManagementMaps) this.fetchManagementMaps(opt);
    });
    this.manMaps = new Dropdown('', '', (opt: DropdownOption) => {
      this.managementMapSelected.emit(opt.obj);
    });
  }

  ngOnInit() {
    if (this.showLabels) {
      this.clients.title = 'Client';
      this.divisions.title = 'Division';
      this.communities.title = 'Community';
      this.neighborhoods.title = 'Neighborhood';
      this.manMaps.title = 'Management Map';
    }
    this.initOptions();
  }

  initOptions() {
    if (this.useLoadingIndicator) this.toast.showLoading();

    if (this.isApolloAdmin) {
      // Apollo admin can switch between clients
      this.clientSer
        .getAll(this.filterOptionsOnlyWithManagementMaps, this.fromStageExceptManagementMaps)
        .subscribe(clients => {
          this.clients.options = clients
            .map(c => {
              return new DropdownOption(c.Name, c.ClientId, c);
            })
            .sort(this.sortByName);

          if (this.appStateService.clientId) {
            this.clients.selectedOption = this.clients.options.find(
              x => x.id === this.appStateService.clientId
            );
          }

          if (this.clients.selectedOption) {
            // note: do not emit clientSelected here since this is during init
            // and parent component should have already handled any init operations
            this.fetchDivisions(this.clients.selectedOption, null, true);
          }
        });
    } else {
      // regular users will have filters on divisions, communities, hoods
      this.fetchDivisions(null, this.user.ClientId, true);
    }
  }

  fetchDivisions(client?: DropdownOption, clientId?: number, isInit?: boolean) {
    const id = client ? client.id : clientId;
    // in case slow API response, immediately clear out dropdowns for better UX
    this.divisions.options = [];
    this.communities.options = [];
    this.neighborhoods.options = [];
    this.manMaps.options = [];

    this.divisionSer
      .getByClientId(
        id,
        this.filterOptionsOnlyWithManagementMaps,
        this.fromStageExceptManagementMaps
      )
      .subscribe(divs => {
        if (!this.isApolloAdmin && !this.isClientAdmin)
          divs = divs.filter(d => this.user.mappedDivisionIds.indexOf(d.DivisionId) > -1);

        this.divisions.options = divs
          .map(d => {
            return new DropdownOption(d.Name, d.DivisionId, d);
          })
          .sort(this.sortByName);

        if (this.showEmptyOption) {
          this.divisions.options.unshift(new DropdownOption(this.emptyOptionText));
          this.divisions.selectedOption = this.divisions.options[0];
        }

        if (isInit && this.appStateService.divisionId && !this.initEmpty) {
          this.divisions.selectedOption = this.divisions.options.find(
            x => x.id === this.appStateService.divisionId
          );
        }

        if (this.divisions.selectedOption) {
          this.divisionSelected.emit(this.divisions.selectedOption.obj);

          if (this.includeCommunities) this.fetchCommunities(this.divisions.selectedOption, isInit);
          else {
            this.toast.hideLoading();
          }
        } else {
          this.toast.hideLoading();
        }
        this.cdr.markForCheck();
      });
  }

  fetchCommunities(division: DropdownOption, isInit?: boolean) {
    // in case slow API response, immediately clear out dropdowns for better UX
    this.communities.options = [];
    this.neighborhoods.options = [];
    this.manMaps.options = [];

    if (division.id) {
      if (this.useLoadingIndicator) this.toast.showLoading();
      this.communitySer
        .getByDivisionId(
          division.id,
          this.filterOptionsOnlyWithManagementMaps,
          !this.includeInactiveCommunities,
          this.fromStageExceptManagementMaps,
          false,
          this.filterCommunityOptionsOnlyWithPOIs
        )
        .subscribe(comms => {
          if (!this.isApolloAdmin && !this.isClientAdmin && !this.isDivisionAdmin)
            comms = comms.filter(c => this.user.mappedCommunityIds.indexOf(c.CommunityId) > -1);

          if (this.filterCommunityOptionsOnlyWithPOIs)
            comms = comms.filter(c => {
              const areaMapPageJson = c.Pages.find(
                p =>
                  p.PageTitle.toLowerCase() === PageTitle.AreaMap.toLowerCase() &&
                  (p.Version === PageVersion.MyScp ||
                    this.appStateService.clientId === Lookup.ClientIds.KHov)
              )?.ContentJson;

              const parsedContent = new AreaMapContent(areaMapPageJson);

              if (parsedContent?.Categories.length) return true;
              else return false;
            });

          this.communities.options = comms
            .map(c => {
              return new DropdownOption(c.PublicName || c.Name, c.CommunityId, c);
            })
            .sort(this.sortByName);

          if (this.showEmptyOption) {
            this.communities.options.unshift(new DropdownOption(this.emptyOptionText));
            this.communities.selectedOption = this.communities.options[0];
          }

          if (isInit && this.appStateService.communityId && !this.initEmpty) {
            this.communities.selectedOption = this.communities.options.find(
              x => x.id === this.appStateService.communityId
            );
          }

          if (this.communities.selectedOption) {
            this.communitySelected.emit(this.communities.selectedOption.obj);

            if (this.includeNeighborhoods)
              this.fetchNeigborhoods(this.communities.selectedOption, isInit);
            else {
              this.toast.hideLoading();
            }
          } else {
            this.toast.hideLoading();
          }
          this.cdr.markForCheck();
        });
    }
  }

  fetchNeigborhoods(community: DropdownOption, isInit?: boolean) {
    this.neighborhoods.options = [];
    this.manMaps.options = [];

    if (community.id) {
      if (this.useLoadingIndicator) this.toast.showLoading();
      this.hoodSer
        .getByCommunityId(
          community.id,
          this.filterOptionsOnlyWithManagementMaps,
          !this.includeInactiveNeighborhoods,
          this.includeNeighborhoodsForOverallCommunity,
          null,
          this.fromStageExceptManagementMaps
        )
        .subscribe(hoods => {
          if (!this.isApolloAdmin && !this.isClientAdmin && !this.isDivisionAdmin)
            hoods = hoods.filter(
              h => this.user.mappedNeighborhoodIds.indexOf(h.NeighborhoodId) > -1
            );

          const options = hoods
            .map(n => {
              return new DropdownOption(n.PublicName || n.Name, n.NeighborhoodId, n);
            })
            .sort(this.sortByName);

          const overallHoodIndex = options.findIndex(
            o => o.name.toLowerCase().indexOf('overall') > -1
          );

          if (overallHoodIndex > -1) {
            const overallHoodOption = options.splice(overallHoodIndex, 1);
            options.unshift(overallHoodOption[0]);
          }

          this.neighborhoods.options = options;

          if (this.showEmptyOption) {
            this.neighborhoods.options.unshift(new DropdownOption(this.emptyOptionText));
            this.neighborhoods.selectedOption = this.neighborhoods.options[0];
          }

          if (isInit && this.appStateService.neighborhoodId && !this.initEmpty) {
            this.neighborhoods.selectedOption = this.neighborhoods.options.find(
              x => x.id === this.appStateService.neighborhoodId
            );
          }

          if (this.neighborhoods.selectedOption) {
            this.neighborhoodSelected.emit(this.neighborhoods.selectedOption.obj);

            if (this.includeManagementMaps)
              this.fetchManagementMaps(this.neighborhoods.selectedOption, isInit);
            else this.toast.hideLoading();
          } else {
            this.toast.hideLoading();
          }
          this.cdr.markForCheck();
        });
    }
  }

  fetchManagementMaps(hood: DropdownOption, isInit?: boolean) {
    this.manMaps.options = [];

    if (hood.id) {
      if (this.useLoadingIndicator) this.toast.showLoading();
      this.manMapsSer.getByNeighborhoodId(hood.id).subscribe(maps => {
        this.manMaps.options = maps
          .sort((a, b) => a.SortOrder - b.SortOrder)
          .map((m, i) => {
            return new DropdownOption(m.PublicName || m.Name, i, m);
          });

        if (this.showEmptyOption) {
          this.manMaps.options.unshift(new DropdownOption(this.emptyOptionText));
          this.manMaps.selectedOption = this.manMaps.options[0];
        }

        if (this.appStateService.managementMapId && !this.initEmpty)
          this.setManagementMapOption(this.appStateService.managementMapId);

        if (this.manMaps.selectedOption)
          this.managementMapSelected.emit(this.manMaps.selectedOption.obj);
        this.cdr.markForCheck();
        this.toast.hideLoading();
      });
    }
  }

  setManagementMapOption(managementMapId: string) {
    this.manMaps.selectedOption = this.manMaps.options.find(
      x => x.obj.MapConfigurationId === managementMapId
    );
  }

  private sortByName(a: DropdownOption, b: DropdownOption) {
    return a.name < b.name ? -1 : 1;
  }
}
