// Not for public export, just use within this library
export const HeaderKeys = {
  Date: 'x-ml-date',
  UseAuthToken: 'x-ml-useauthtoken',
  CorsExposeHeaders: 'Access-Control-Expose-Headers',
  DebugHmacString: 'echo-sts'
};
export const DefaultApiCredentials = {
  User: 'converge.apiuser@medialabinc.local',
  Key: 'mhajZ/V7vwnQvsZItURGU9lvsxVIQ1aL'
};
