import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { forkJoin as observableForkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';
import { Community, CommunityService } from '../entities/community.service';
import { Division, DivisionService } from '../entities/division.service';
import { Neighborhood, NeighborhoodService } from '../entities/neighborhood.service';
import { SelectionList } from '../shared/data.structures';
import { Lookup } from '../shared/lookup';
import { PaginationObject } from '../shared/pagination/pagination-object';
import { ToastService } from './toast/toast.service';
import { sortByName } from './ui-helper.service';

@Component({
  selector: 'bulk-mapping',
  templateUrl: './bulk-mapping.component.html',
  styleUrls: ['./bulk-mapping.component.scss']
})
export class BulkMappingComponent implements OnChanges {
  @Input() clientId: number;
  @Input() initialMappings: MappingTableItem[];
  @Input() showOnlyDivision = false;
  @Output() updateMappings = new EventEmitter<MappingTableItem>();

  // available -> all items that are allowed to be shown or selected
  availableDivisions: Division[];
  availableCommunities: Community[];
  availableNeighborhoods: Neighborhood[];
  // pagers -> all items currently visible to user and split up for pagination
  divisionPager: PaginationObject<Division>;
  communityPager: PaginationObject<Community>;
  nhoodPager: PaginationObject<Neighborhood>;
  // cols -> sub-divided to allow column formatting in view
  divCols: Array<Division[]>;
  commCols: Array<Community[]>;
  nhoodCols: Array<Neighborhood[]>;
  // selected -> array of currently selected items, whether visible or not to user
  selectedDivs: SelectionList<Division>;
  selectedComms: SelectionList<Community>;
  selectedHoods: SelectionList<Neighborhood>;
  // filteredBy: current item by which child items are filtered for visibility
  divFilteredBy: Division;
  commFilteredBy: Community;
  visibleTable: string;
  searchTerm = new UntypedFormControl();
  confirmDivisionRemoveData: {
    item: Division;
    list: SelectionList<Division>;
    wasRemoved: boolean;
  } | null;

  constructor(
    private divisionSer: DivisionService,
    private hoodSer: NeighborhoodService,
    private communitySer: CommunityService,
    private auth: AuthService,
    private toaster: ToastService
  ) {
    this.availableCommunities = new Array<Community>();
    this.availableNeighborhoods = new Array<Neighborhood>();

    this.selectedDivs = new SelectionList<Division>(
      (item: Division, list: SelectionList<Division>, wasRemoved: boolean) => {
        if (wasRemoved) {
          this.confirmDivisionRemoveData = { item, list, wasRemoved };
        } else {
          this.onSelectedDivToggle(item, list, wasRemoved);
        }
      }
    );
    this.selectedComms = new SelectionList<Community>(
      (item: Community, list: SelectionList<Community>, wasRemoved: boolean) =>
        this.onSelectedCommToggle(item, list, wasRemoved)
    );
    this.selectedHoods = new SelectionList<Neighborhood>(
      (item: Neighborhood, list: SelectionList<Neighborhood>, wasRemoved: boolean) =>
        this.updateMappings.emit(
          new MappingTableItem(item.NeighborhoodId, Lookup.EntityTypes.Neighborhood, wasRemoved)
        )
    );

    this.visibleTable = 'divisions';

    this.divisionPager = new PaginationObject<Division>(20);
    this.divisionPager.onIndexChanged = (items: Division[]) => {
      this.divCols = this.splitToColumns(items, 10);
    };

    this.communityPager = new PaginationObject<Community>(20);
    this.communityPager.onIndexChanged = (items: Community[]) => {
      this.commCols = this.splitToColumns(items, 10);
    };

    this.nhoodPager = new PaginationObject<Neighborhood>(20);
    this.nhoodPager.onIndexChanged = (items: Neighborhood[]) => {
      this.nhoodCols = this.splitToColumns(items, 10);
    };

    this.searchTerm.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(term => {
      // filter all on search so user can quickly jump between tabs
      this.filterDivisions();
      this.filterCommunities();
      this.filterNeighborhoods();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['clientId'] && this.clientId) {
      this.resetAll();

      // get divisions for this client
      this.divisionSer.getByClientId(this.clientId).subscribe(divs => {
        // if division admin then filter available to only those divisions assigned
        if (
          this.auth.currentUser.hasRole(Lookup.Modules.UserManagement.AlternateRoles.divisionAdmin)
        )
          divs = divs.filter(d =>
            this.auth.currentUser.mappedDivisionIds.some(r => r === d.DivisionId)
          );

        this.availableDivisions = divs.sort(sortByName);
        this.divisionPager.allItems = this.availableDivisions;

        if (changes['initialMappings'] && this.initialMappings) this.setExistingMappings();
      });
    } else if (changes['initialMappings'] && this.initialMappings && this.availableDivisions) {
      this.setExistingMappings();
    }
  }

  resetAll() {
    this.divisionPager.allItems = null;
    this.communityPager.allItems = null;
    this.nhoodPager.allItems = null;

    this.availableDivisions = new Array<Division>();
    this.availableCommunities = new Array<Community>();
    this.availableNeighborhoods = new Array<Neighborhood>();

    this.divFilteredBy = null;
    this.commFilteredBy = null;

    this.visibleTable = 'divisions';

    this.selectedDivs.forEach(d =>
      this.updateMappings.emit(
        new MappingTableItem(d.DivisionId, Lookup.EntityTypes.Division, true)
      )
    );
    this.selectedDivs.reset();

    this.selectedComms.forEach(c =>
      this.updateMappings.emit(
        new MappingTableItem(c.CommunityId, Lookup.EntityTypes.Community, true)
      )
    );
    this.selectedComms.reset();

    this.selectedHoods.forEach(n =>
      this.updateMappings.emit(
        new MappingTableItem(n.NeighborhoodId, Lookup.EntityTypes.Neighborhood, true)
      )
    );
    this.selectedHoods.reset();
  }

  // if search term entered then filter all lists by it
  // if no term then use default entity filterBys
  filterDivisions() {
    if (this.searchTerm.value) {
      this.divisionPager.allItems = this.availableDivisions.filter(
        div => div.Name.toLowerCase().indexOf(this.searchTerm.value.toLowerCase()) > -1
      );
    } else {
      this.divisionPager.allItems = this.availableDivisions;
    }
  }

  filterCommunities(division?: Division) {
    if (division === this.divFilteredBy) return;

    if (division) this.divFilteredBy = division;

    this.communityPager.allItems = this.availableCommunities
      .filter(comm => {
        if (this.searchTerm.value) {
          return comm.Name.toLowerCase().indexOf(this.searchTerm.value.toLowerCase()) > -1;
        } else {
          return comm.DivisionId === this.divFilteredBy.DivisionId;
        }
      })
      .sort(sortByName);
  }

  filterNeighborhoods(community?: Community) {
    if (community === this.commFilteredBy) return;

    if (community) this.commFilteredBy = community;

    this.nhoodPager.allItems = this.availableNeighborhoods
      .filter(hood => {
        if (this.searchTerm.value) {
          return hood.Name.toLowerCase().indexOf(this.searchTerm.value.toLowerCase()) > -1;
        } else {
          return hood.CommunityId === this.commFilteredBy.CommunityId;
        }
      })
      .sort(sortByName);
  }

  private splitToColumns(array: any[], perColumn: number) {
    const retval = Array();
    let start = 0;

    while (start + perColumn < array.length) {
      retval.push(array.slice(start, start + perColumn));
      start += perColumn;
    }
    retval.push(array.slice(start));

    return retval;
  }

  private setExistingMappings() {
    // check initialMappings for divisions
    const savedDivs = new Array<Division>();
    this.initialMappings
      .filter(m => m.type === Lookup.EntityTypes.Division)
      .forEach(x => {
        // find division and add to selected array
        const div = this.availableDivisions.find(d => d.DivisionId === x.id);
        if (div) savedDivs.push(div);
      });
    if (savedDivs.length > 0) {
      this.selectedDivs.array = [...this.selectedDivs.array, ...savedDivs];
      this.divFilteredBy = savedDivs[0];

      this.toaster.showLoading();
      // load all communities for selected divs
      observableForkJoin(
        savedDivs.map(d => this.communitySer.getByDivisionId(d.DivisionId, false, false))
      ).subscribe(comResult => {
        const coms = comResult.reduce((all, curr) => all.concat(curr));
        this.availableCommunities = this.availableCommunities.concat(coms);
        this.communityPager.allItems = this.availableCommunities
          .filter(comm => comm.DivisionId === this.divFilteredBy.DivisionId)
          .sort(sortByName);

        // find comms and add to selected array
        const savedComms = new Array<Community>();
        this.initialMappings
          .filter(m => m.type === Lookup.EntityTypes.Community)
          .forEach(x => {
            const comm = this.availableCommunities.find(c => c.CommunityId === x.id);
            if (comm) savedComms.push(comm);
          });
        if (savedComms.length > 0) {
          this.selectedComms.array = [...this.selectedComms.array, ...savedComms];
          this.commFilteredBy = savedComms[0];

          // load all neighborhoods for selected comms
          const communityIDs = savedComms.map(x => x.CommunityId);
          this.hoodSer.getByCommunityIds(communityIDs, false, false, true).subscribe(hoodResult => {
            this.availableNeighborhoods = this.availableNeighborhoods.concat(hoodResult);
            this.nhoodPager.allItems = this.availableNeighborhoods
              .filter(hood => hood.CommunityId === this.commFilteredBy.CommunityId)
              .sort(sortByName);

            // find any hoods and add to selected array
            this.initialMappings
              .filter(m => m.type === Lookup.EntityTypes.Neighborhood)
              .forEach(x => {
                const hood = this.availableNeighborhoods.find(n => n.NeighborhoodId === x.id);
                if (hood) this.selectedHoods.addItem(hood, false);
              });

            this.toaster.hideLoading();
          });
        } else {
          this.toaster.hideLoading();
        }
      });
    }
  }

  handleDivisionRemoveConfirm(remove: boolean) {
    const { item, list, wasRemoved } = this.confirmDivisionRemoveData;
    if (remove) {
      this.onSelectedDivToggle(item, list, wasRemoved);
    } else {
      this.selectedDivs.toggleItem(item, false);
      this.confirmDivisionRemoveData = null;
    }
  }

  private onSelectedDivToggle(item: Division, list: SelectionList<Division>, wasRemoved: boolean) {
    if (wasRemoved) {
      // update selected children if affected
      this.selectedComms.array
        .filter(comm => comm.DivisionId === item.DivisionId)
        .forEach(comm => this.selectedComms.toggleItem(comm));

      // update current filters if affected
      if (this.divFilteredBy.DivisionId === item.DivisionId)
        this.divFilteredBy = this.selectedDivs.array[0];
      if (this.commFilteredBy && this.commFilteredBy.DivisionId === item.DivisionId)
        this.commFilteredBy = this.selectedComms.array[0];

      this.availableCommunities = this.availableCommunities.filter(
        comm => comm.DivisionId !== item.DivisionId
      );
      this.filterCommunities();
      this.confirmDivisionRemoveData = null;
    } else {
      if (!this.divFilteredBy) this.divFilteredBy = item;

      // load communities from this division
      this.toaster.showLoading();
      this.communitySer.getByDivisionId(item.DivisionId, false, false).subscribe(coms => {
        this.toaster.hideLoading();
        this.availableCommunities = this.availableCommunities.concat(coms);
        this.filterCommunities();
      });
    }

    this.updateMappings.emit(
      new MappingTableItem(item.DivisionId, Lookup.EntityTypes.Division, wasRemoved)
    );
  }

  private onSelectedCommToggle(
    item: Community,
    list: SelectionList<Community>,
    wasRemoved: boolean
  ) {
    if (wasRemoved) {
      // update selected children if affected
      this.selectedHoods.array
        .filter(hood => hood.CommunityId === item.CommunityId)
        .forEach(hood => this.selectedHoods.toggleItem(hood));

      if (this.commFilteredBy.CommunityId === item.CommunityId)
        this.commFilteredBy = this.selectedComms.array[0];

      this.availableNeighborhoods = this.availableNeighborhoods.filter(
        hood => hood.CommunityId !== item.CommunityId
      );
      this.filterNeighborhoods();
    } else {
      if (!this.commFilteredBy) this.commFilteredBy = item;

      // load hoods from this community
      this.toaster.showLoading();
      this.hoodSer.getByCommunityId(item.CommunityId, false, false, true).subscribe(hoods => {
        this.toaster.hideLoading();
        this.availableNeighborhoods = this.availableNeighborhoods.concat(hoods);
        hoods.forEach(x => this.selectedHoods.addItem(x));
        this.filterNeighborhoods();
      });
    }

    this.updateMappings.emit(
      new MappingTableItem(item.CommunityId, Lookup.EntityTypes.Community, wasRemoved)
    );
  }

  areAllCommunitiesSelected(division: Division): boolean {
    return this.availableCommunities
      .filter(c => c.DivisionId === division.DivisionId)
      .every(c => this.selectedComms.array.indexOf(c) > -1);
  }

  toggleAllCommunities(division: Division) {
    if (this.areAllCommunitiesSelected(division)) {
      this.availableCommunities
        .filter(c => c.DivisionId === division.DivisionId)
        .forEach(c => this.selectedComms.removeItem(c));
    } else {
      this.availableCommunities
        .filter(c => c.DivisionId === division.DivisionId)
        .forEach(c => this.selectedComms.addItem(c));
    }
  }

  areAllNeighborhoodsSelected(community: Community): boolean {
    return this.availableNeighborhoods
      .filter(h => h.CommunityId === community.CommunityId)
      .every(h => this.selectedHoods.array.indexOf(h) > -1);
  }

  toggleAllNeighborhoods(community: Community) {
    if (this.areAllNeighborhoodsSelected(community)) {
      this.availableNeighborhoods
        .filter(h => h.CommunityId === community.CommunityId)
        .forEach(h => this.selectedHoods.removeItem(h));
    } else {
      this.availableNeighborhoods
        .filter(h => h.CommunityId === community.CommunityId)
        .forEach(h => this.selectedHoods.addItem(h));
    }
  }

  showTab(entityType: string) {
    switch (entityType) {
      case 'communities':
        this.selectedDivs.array.sort(sortByName);
        break;
      case 'neighborhoods':
        this.selectedComms.array.sort(sortByName);
        break;
      default:
    }

    this.visibleTable = entityType;
  }

  trackDivisionBy(index: number, div: Division) {
    return div.DivisionId;
  }

  trackCommunitiesBy(index: number, com: Community) {
    return com.CommunityId;
  }

  trackNeighborhoodsBy(index: number, hood: Neighborhood) {
    return hood.NeighborhoodId;
  }
}

export class MappingTableItem {
  id: number;
  type: string;
  wasRemoved: boolean;

  constructor(id: number, type: string, wasRemoved: boolean = false) {
    this.id = id;
    this.type = type;
    this.wasRemoved = wasRemoved;
  }
}
