import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Lookup } from '../../shared/lookup';
import { ICustomSubPage } from '../models/custom-sub-page';
import { ContentEditorProducts } from '../models/enums';

@Injectable()
export class PageService {
  stageApiBase = window.location.protocol + '//' + Lookup.Argo.StageHost;

  constructor(private http: HttpClient) {}

  getById(id: number): Observable<IPage> {
    return this.http.get<IPage>(`${this.stageApiBase}/api/pages/${id}`);
  }

  getByCommunityId(id: number, version?: number): Observable<IPage[]> {
    const qParams = version ? `?version=${version}` : '';
    return this.http.get<IPage[]>(`${this.stageApiBase}/api/pages/community/${id}${qParams}`);
  }

  getByClientId(id: number, version?: number, initTemplatePages = false): Observable<IPage[]> {
    const qParams = [];
    if (version) qParams.push(`version=${version}`);
    if (initTemplatePages) qParams.push(`initializeIfNotFound=${initTemplatePages}`);
    const qString = qParams.length ? `?${qParams.join('&')}` : '';

    return this.http.get<IPage[]>(`${this.stageApiBase}/api/pages/client/${id}${qString}`);
  }

  getByDivisionId(id: number, version?: number, initTemplatePages = false): Observable<IPage[]> {
    const qParams = [];
    if (version) qParams.push(`version=${version}`);
    if (initTemplatePages) qParams.push(`initializeIfNotFound=${initTemplatePages}`);
    const qString = qParams.length ? `?${qParams.join('&')}` : '';

    return this.http.get<IPage[]>(`${this.stageApiBase}/api/pages/division/${id}${qString}`);
  }

  save(page: IPage) {
    return this.http.put<IPage>(`${this.stageApiBase}/api/pages/${page.PageId}`, page);
  }

  savePageToCustomSubPageMappings(mappings: PageToCustomSubPagePatch[]) {
    return this.http
      .patch<void>(`${this.stageApiBase}/api/pages/CustomSubPages`, mappings)
      .toPromise();
  }

  delete(page: IPage) {
    return this.http.delete<IPage>(`${this.stageApiBase}/api/pages/${page.PageId}`).toPromise();
  }

  deleteFile(pageId: number, filename: string) {
    return this.http.post<IPage>(`${this.stageApiBase}/api/pages/${pageId}/file`, {
      filename
    });
  }

  uploadFiles(pageId: number, files: File[]): Observable<IPage> {
    const formData = new FormData();

    files.forEach(file => {
      formData.append(file.name, file, file.name);
    });

    return this.http.put<IPage>(`${this.stageApiBase}/api/pages/${pageId}/files`, formData);
  }

  getBaseFileUrl(communityVirtualDirectory: string, page: IPage): string {
    const base = `${this.stageApiBase}/api/fs/pd`;
    return `${base}${communityVirtualDirectory}/Pages/${page.PageTitle}_${page.PageId}`;
  }
}

export function ProductHasPages(product: ContentEditorProducts) {
  return (
    product === ContentEditorProducts.MyScp || product === ContentEditorProducts.HomeConfigurator
  );
}

export function GetPageVersionFromProduct(product: ContentEditorProducts) {
  switch (product) {
    case ContentEditorProducts.MyScp:
      return PageVersion.MyScp;
    case ContentEditorProducts.HomeConfigurator:
      return PageVersion.HomeConfigurator;
    case ContentEditorProducts.UniversalThemeAndContent:
      return null;
  }
}

export enum PageVersion {
  MyScp = 3,
  HomeConfigurator = 4
}

export enum PageTitle {
  Homepage = 'Home',
  AreaMap = 'Area Map',
  CommunityMap = 'Community Map',
  FloorPlans = 'Floor Plans',
  Brochure = 'Brochure',
  Error = 'Error',
  CustomPage = 'Custom Page',
  EnergyEfficiency = 'Energy Efficiency',
  Visualizer = 'Visualizer',
  IframeContent = 'Iframe Content'
}

// 1 is legacy and not currently used by MyScp
export enum PageType {
  FastPrintContent = 1,
  IframeContent = 2,
  EnergyEfficiency = 3,
  Visualizer = 4
}

export interface IPage {
  PageId: number;
  CommunityId: number;
  PageTitle: string;
  PageHeading: string;
  NavigationTitle: string;
  HtmlContent: string;
  SortOrder: number;
  IsHome: boolean;
  CreatedOn: string;
  IsBuiltIn: boolean;
  ParentPageId: number;
  BuiltInPageNavigateUrl: string;
  BuiltInPageViewNameOverride: string;
  ModifiedOn: string;
  HtmlContent2: string;
  HtmlContent3: string;
  HtmlContent4: string;
  PageType: PageType;
  ContentJson: string;
  Version: number;
  IsActive: boolean;
  ClientId: number;
  ProjectId: number;
  IsLocked: boolean;
  CommunityName: string;
  IconId: number;
  IconSvgContent: string;

  CustomSubPageIds: number[];
  CustomSubPages: ICustomSubPage[];
}

export interface PageToCustomSubPagePatch {
  PageId: number;
  CustomSubPageId: number;
}
