import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Dictionary } from '../shared/data.structures';
import { Unit } from './unit';
import { ManagementMapDocument } from './managementmap-document';
import { Lookup } from '../shared/lookup';

@Injectable()
export class UnitService {
  store: Dictionary<Unit>;

  constructor(private http: HttpClient) {
    this.store = new Dictionary<Unit>();
  }

  getById(id: number): Observable<Unit> {
    // check store first
    const u = this.store[id];
    if (u) {
      return observableOf(u);
    } else {
      return this.http.get<Unit>(`/api/units/${id}`).pipe(
        map((unit: Unit) => {
          unit = new Unit(unit);
          this.store.addOrUpdate(unit.Id, unit);
          return unit;
        })
      );
    }
  }

  update(unit: Unit, file: File): Observable<Unit> {
    const formData = new FormData();
    if (file) formData.append(file.name, file, file.name);
    formData.append('obj', JSON.stringify(unit));
    return this.http.put<Unit>(`/api/units/${unit.numberId}/withfile`, formData).pipe(
      map((u: Unit) => {
        u = new Unit(u);
        this.store.addOrUpdate(u.Id, u);
        return u;
      })
    );
  }

  delete(unit: Unit): Observable<Unit> {
    return this.http.delete<Unit>(`/api/units/${unit.numberId}`).pipe(
      map((u: Unit) => {
        u = new Unit(u);
        this.store.remove(unit.Id);
        return u;
      })
    );
  }

  getManagementMapsForUnit(
    unit: Unit,
    managementMap?: string
  ): Observable<ManagementMapDocument[]> {
    const url = managementMap
      ? encodeURI(`/api/${unit.Id}/managementmaps/${managementMap}`)
      : encodeURI(`/api/${unit.Id}/managementmaps`);

    return this.http.get<ManagementMapDocument[]>(url);
  }
}
