import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { SQLFloorPlan } from 'app/entities';

@Component({
  selector: 'floorplan-specific-social',
  templateUrl: './floorplan-specific-social.component.html',
  styleUrls: ['./floorplan-specific-social.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FloorplanSpecificSocialComponent implements OnInit {
  @Input() model: any;
  @Output() onModelChange = new EventEmitter();
  @Input() floorplans: SQLFloorPlan[] = new Array<SQLFloorPlan>();
  formattedFloorplans: Array<any> = [];

  constructor() {}

  ngOnInit() {
    if (this.floorplans)
      this.formattedFloorplans = this.floorplans.map(item => {
        const existingItem = this.model
          ? this.model.find(savedFP => item.FloorplanId === savedFP.floorplanId)
          : null;
        // NEED: make type/class?
        return {
          communityId: item.CommunityId,
          floorplanId: item.FloorplanId,
          neighborhoodId: item.NeighborhoodId,
          title: item.PublicName || item.Title,
          active: item.IsActive,
          hashtags: existingItem ? existingItem.hashtags : '[]',
          message: existingItem ? existingItem.message : '',
          customSharedLink: existingItem ? existingItem.customSharedLink : ''
        };
      });

    this.formattedFloorplans.sort(this.alphabatize);
  }

  alphabatize(a, b) {
    if (a.title < b.title) return -1;
    if (a.title > b.title) return 1;
    return 0;
  }

  change(newValue: string) {
    this.onModelChange.emit(newValue);
  }

  doUpdate() {
    this.change(JSON.stringify(this.formattedFloorplans));
  }
}
