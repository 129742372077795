import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'expanding-set',
  templateUrl: './expanding-set.component.html',
  styleUrls: ['./expanding-set.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExpandingSetComponent implements OnInit {
  @Input()
  expandingSet: ExpandingSet = new ExpandingSet();
  @Input()
  itemLabel = 'Item';
  @Input() canSort = false;
  @Output()
  add = new EventEmitter<ExpandingSetItem>();
  @Output()
  delete = new EventEmitter<ExpandingSetItem>();
  @Output()
  typedText = new EventEmitter<any>();
  @Output() sort = new EventEmitter<ExpandingSetItem>();

  constructor() {}

  ngOnInit() {}

  addPending(set: ExpandingSet) {
    if (set.pending.name) {
      set.createdCount++;
      set.pending.createdIndex = set.createdCount;

      set.stored.push(set.pending);

      this.add.emit(set.pending);
      set.pending = new ExpandingSetItem();
    }
  }

  deleteItem(set: ExpandingSet, index: number) {
    if (set.stored[index]) {
      const deleted = set.stored.splice(index, 1);
      this.delete.emit(deleted[0]);
    }
  }

  typedTextChange(item: ExpandingSet) {
    this.typedText.emit(item);
  }

  trackByIndex(index: number) {
    return index;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.expandingSet.stored, event.previousIndex, event.currentIndex);
    this.sort.emit(this.expandingSet.pending);
  }
}

// ***Classes***

export class ExpandingSetItem {
  name = '';

  createdIndex = 0;
}

export class ExpandingSet {
  pending: ExpandingSetItem = new ExpandingSetItem();

  stored: ExpandingSetItem[] = new Array<ExpandingSetItem>();

  createdCount = 0;

  constructor(public name: string = '') {}

  // reset the created index to be in array order
  reindex() {
    this.stored.forEach((item, i) => {
      item.createdIndex = i + 1;
    });

    this.createdCount = this.stored.length;
  }

  clone(): ExpandingSet {
    const dupe = new ExpandingSet(this.name);

    // NOTE: this will not copy any class functions (the copies are not actually class instances)
    for (const attr in this) {
      if (this.hasOwnProperty(attr)) dupe[attr.toString()] = JSON.parse(JSON.stringify(this[attr]));
    }

    dupe.reindex();
    return dupe;
  }

  initStored(values: string[]) {
    if (values && values.length > 0) {
      this.stored = values.map((x, i) => {
        const item = new ExpandingSetItem();
        item.name = x;
        item.createdIndex = i;
        return item;
      });

      this.createdCount = this.stored.length;
    }
  }

  addItem(name: string) {
    const newValue = new ExpandingSetItem();
    newValue.name = name;
    this.stored.push(newValue);
  }
}
