<file-input-form *ngIf="maxImagesAllowed && images.length < maxImagesAllowed"
                 [maxFilesAllowed]="maxImagesAllowed" [maxFileSizeMb]="maxFileSizeMb"
                 [allowedFileExts]="allowedFileExts" [showDropzone]="showDropzone"
                 (filesChange)="handleFilesChange($event)"></file-input-form>

<div *ngIf="showImageStatus && images.length && !hidePreview" class="images-container">
  <div class="image-card" *ngFor="let image of images">
    <img *ngIf="!!image.PreviewUrl" class="preview" [src]="image.PreviewUrl | safeUrl" />
    <button *ngIf="allowDelete" class="btn quick standard-negative" (click)="handleDelete(image)">
      <span class="icon">
        <svg-helper svgName="trash_can_bold"></svg-helper>
      </span>
    </button>
  </div>
</div>