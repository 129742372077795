import { DocumentBase } from './document-base';
import { Dictionary } from '../shared/data.structures';
import { Lookup } from '../shared/lookup';
export class Unit extends DocumentBase {
  UnitId: number;
  FloorNumber: number;
  ShortDescription: string;
  Address: string;
  Cost: number;
  IsVisible: boolean;
  UIInstanceId: string;
  Premium: number;
  OptionsPremium: number;
  Statuses: Dictionary<string>;
  CustomData: Dictionary<string>;
  DollhouseImageFilename: string;

  DollhouseImageUrl: string;

  constructor(data?: Unit) {
    super();
    Object.assign(this, data);

    if (this.DollhouseImageFilename) {
      this.DollhouseImageUrl = `${Lookup.ApiFsEndpoint}/${this.ClientId}/${this.Id}/${this.DollhouseImageFilename}`;
    }
  }
}
