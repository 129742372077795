import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { AuthService } from '../../auth/auth.service';
import { ReportData } from './models/reportData';
import { ReportMapDTO, ReportMapLocationVM } from './models/management-maps';
import { Settings } from '../../entities';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ReportService {
  constructor(private http: HttpClient, private auth: AuthService) {}

  getPowerBIReportEmbedObject(groupId, reportId, userIdentity): Observable<any> {
    const pbiUrl = `/api/pbi/${groupId}/${reportId}?useridentity=${userIdentity}`;
    return this.http.get(pbiUrl);
  }

  formatEmbedQueryURL(url: string, report: ReportData) {
    if (
      !report.TableName ||
      !report.KeyValuePairs ||
      (report.KeyValuePairs && report.KeyValuePairs.length === 0)
    ) {
      return url;
    } else {
      const kvpString = this.getFilterString(report);
      const escaped = encodeURIComponent(`${kvpString}`);
      const finalURL = `${url}&filter=${escaped.replace('~2F', '%2F').replace(/\'/g, '%27')}`;
      return finalURL;
    }
  }

  private getFilterString(report: ReportData) {
    // Query string docs: https://docs.microsoft.com/en-us/power-bi/service-url-filters
    const filterStrings = Array<string>();
    const filters = new Map<string, Array<string>>();

    report.KeyValuePairs.forEach((param: any) => {
      let values = filters.get(param.setKey);
      if (!values) values = new Array<string>();
      values.push(param.setValue);
      filters.set(param.setKey, values);
    });

    filters.forEach((values: Array<string>, key: string) => {
      if (values.length > 1) {
        filterStrings.push(`${report.TableName}/${this.getInFilter(key, values)}`);
      } else {
        filterStrings.push(`${report.TableName}/${this.getEqFilter(key, values[0])}`);
      }
    });

    return filterStrings.join(' and ');
  }

  private translateVariables(value: string) {
    if (value === '%CURRENT_USER_NAME%' || value === '%CURRENT_USER_EMAIL%') {
      const user = this.auth.currentUser;
      if (value === '%CURRENT_USER_NAME%') value = `${user.FirstName} ${user.LastName}`;
      else if (value === '%CURRENT_USER_EMAIL%') value = user.Email;
    }
    return value;
  }

  private getEqFilter(key: string, value: string) {
    value = this.translateVariables(value);
    return `${key} eq '${value}'`;
  }

  private getInFilter(key: string, values: Array<string>) {
    const valuesString = values
      .map(val => {
        return `'${this.translateVariables(val)}'`;
      })
      .join(',');
    return `${key} in (${valuesString})`;
  }

  public isLocationRedundant(locations, location) {
    const locCopy = JSON.parse(JSON.stringify(locations));
    let isRedundant = false;
    let msg = '';

    if (!locations || locations.length === 0) {
      return { redundant: isRedundant, msg };
    } else {
      const divisionDupe = locCopy.some(
        item =>
          item.division &&
          location.division &&
          item.division.ProjectId === location.division.ProjectId
      );
      const uniqueCommunities = locCopy.some(
        item =>
          item.community &&
          location.community &&
          item.community.CommunityId !== location.community.CommunityId
      ); //so you can add multiple communities from the same division
      const communityDupe = locCopy.some(
        item =>
          item.community &&
          location.community &&
          item.community.CommunityId === location.community.CommunityId
      );
      const neighborhoodDupe = locCopy.some(
        item =>
          item.neighborhood &&
          location.neighborhood &&
          item.neighborhood.NeighborhoodId === location.neighborhood.NeighborhoodId
      );
      const uniqueNeighborhoods = locCopy.some(
        item =>
          item.neighborhood &&
          location.neighborhood &&
          item.neighborhood.NeighborhoodId !== location.neighborhood.NeighborhoodId
      ); //so you can add multiple neighborhoods from the same community

      if (divisionDupe && communityDupe && neighborhoodDupe) {
        //full map already added
        msg = 'This map has already been selected.';
        isRedundant = true;
      } else if (!location.neighborhood && divisionDupe && communityDupe) {
        //adding a community included in an existing collection
        msg = 'This community is included in a previous selection.';
        isRedundant = true;
      } else if (location.neighborhood && communityDupe && !uniqueNeighborhoods) {
        //adding neighborhood for a community already added
        msg = 'This neighborhood is included in a previously selected community.';
        isRedundant = true;
      } else if (location.community && divisionDupe && !uniqueCommunities && !uniqueNeighborhoods) {
        //adding community for a division already added
        msg = 'This community is included in a previously selected division.';
        isRedundant = true;
      } else if (divisionDupe && !uniqueCommunities && !uniqueNeighborhoods) {
        //adding just a division that's already added
        msg = 'This division is included in a previous selection.'; //this division is included in a previous selection
        isRedundant = true;
      }
      return { redundant: isRedundant, msg };
    }
  }

  private convertLocationsToReportDTO(locations: ReportMapLocationVM[]): ReportMapDTO[] {
    //can maybe be refactored out
    const reportDTOs = new Array<ReportMapDTO>();

    // The sorting here is very important so that the child/parent check below works properly
    // Also must make a copy before sort so that original array is unaffected
    const locCopy = JSON.parse(JSON.stringify(locations));
    locCopy.sort((a, b) => {
      let direction = 0;

      if (!a.neighborhood) {
        if (!b.neighborhood) {
          if (!a.community) {
            if (!b.community) direction = a.division.ProjectId - b.division.ProjectId;
            else direction = -1;
          } else {
            if (!b.community) direction = 1;
            else direction = a.community.CommunityId - b.community.CommunityId;
          }
        } else {
          direction = -1;
        }
      } else {
        if (!b.neighborhood) {
          direction = 1;
        } else {
          direction = a.neighborhood.NeighborhoodId - b.neighborhood.NeighborhoodId;
        }
      }

      return direction;
    });

    // loop thru and create DTO objects grouped by Map Id
    // only add child ids (neighborhood and community) if parent is not already included
    locCopy.forEach(l => {
      let mapReport = reportDTOs.find(x => x.ManagementMapId === l.map.MapConfigurationId);
      if (!mapReport) {
        mapReport = new ReportMapDTO();
        mapReport.ManagementMapId = l.map.MapConfigurationId;
        reportDTOs.push(mapReport);
      }

      if (
        l.neighborhood &&
        !mapReport.CommunityIds.some(cId => cId === l.community.CommunityId) &&
        !mapReport.DivisionIds.some(dId => dId === l.division.ProjectId)
      ) {
        mapReport.NeighborhoodIds.push(l.neighborhood.NeighborhoodId);
      } else if (l.community && !mapReport.DivisionIds.some(dId => dId === l.division.ProjectId)) {
        mapReport.CommunityIds.push(l.community.CommunityId);
      } else if (!mapReport.DivisionIds.some(dId => dId === l.division.ProjectId)) {
        mapReport.DivisionIds.push(l.division.ProjectId);
      }
    });

    return reportDTOs;
  }

  public combineMultipleDivisionSettings(divisionSettings: Settings[][]): Settings[] {
    let settingsResult: Settings[];
    let combinedJsonValueArray: SettingsData[];
    divisionSettings.forEach((divisionSetting, divisionSettingIndex) => {
      if (divisionSettingIndex === 0) {
        settingsResult = divisionSetting;
        combinedJsonValueArray = JSON.parse(divisionSetting[0].Value);
      } else {
        const allDivisionSettings: SettingsData[] = JSON.parse(divisionSetting[0].Value);
        allDivisionSettings.forEach(setting => {
          const currentSetting = combinedJsonValueArray.find(s => s.report === setting.report);
          if (currentSetting) {
            setting.keyValuePairs.forEach(keyValuePair => {
              if (
                !currentSetting.keyValuePairs.some(
                  kv => kv.setKey === keyValuePair.setKey && kv.setValue === keyValuePair.setValue
                )
              )
                currentSetting.keyValuePairs.push(keyValuePair);
            });
          } else {
            combinedJsonValueArray.push(setting);
          }
        });
        settingsResult[0].Value = JSON.stringify(combinedJsonValueArray);
      }
    });
    return settingsResult;
  }
}

export class SettingsData {
  body: string;
  group: string;
  keyValuePairs: KeyValuePair[];
  report: string;
  reportTitle: string;
  tableName: string;

  constructor(data?: any) {
    Object.assign(this, data);
  }
}

export class KeyValuePair {
  createdIndex: number;
  setKey: string;
  setValue: string;

  constructor(data?: any) {
    Object.assign(this, data);
  }
}
