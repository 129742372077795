<div *ngIf="svgContent" class="icon-preview" [innerHTML]="svgContent | safeHtml"></div>
<button *ngIf="svgContent && allowRemoval" class="btn quick standard-negative trash" (click)="handleIconRemove()">
    <svg-helper svgName="mini_trash"></svg-helper>
</button>
<button class="btn standard-positive file-input" (click)="toggleIconPicker()">
    <span class="icon">
        <svg-helper svgName="bracket_arrow_up"></svg-helper>
    </span>
    <span class="label">Choose from Library</span>
</button>
<modal id="icon-picker-modal" *ngIf="showIconPicker" (close)="toggleIconPicker()">
    <icon-picker [currentIconId]="currentIconId" (iconSelect)="handleIconSelect($event)"></icon-picker>
</modal>