import { Observable, lastValueFrom } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IHotspot, IHotspotGroup } from '@ml/common';
import { Lookup } from '../shared/lookup';

@Injectable()
export class HotspotGroupService {
  stageApiBase = window.location.protocol + '//' + Lookup.Argo.StageHost;

  constructor(private http: HttpClient) {}

  getByFloorPlan(id: number): Promise<IHotspotGroup[]> {
    const url = `${this.stageApiBase}/api/hotspotgroups/floorplan/${id}`;
    return lastValueFrom(this.http.get<IHotspotGroup[]>(url));
  }

  get(id: number): Promise<IHotspotGroup[]> {
    const url = `${this.stageApiBase}/api/hotspotgroups/community/${id}`;
    return lastValueFrom(this.http.get<IHotspotGroup[]>(url));
  }

  create(hg: IHotspotGroup): Promise<IHotspotGroup> {
    const url = `${this.stageApiBase}/api/hotspotgroups`;
    return lastValueFrom(this.http.post<IHotspotGroup>(url, hg));
  }

  delete(id: number): Promise<void> {
    const url = `${this.stageApiBase}/api/hotspotgroups/${id}`;
    return lastValueFrom(this.http.delete<void>(url));
  }

  saveHotspots(hotspots: IHotspot[], files: File[], id: number): Observable<IHotspot[]> {
    const formData = new FormData();
    formData.append('hotspotDTOs', JSON.stringify(hotspots));
    files.forEach(f => formData.append(f.name, f, f.name));

    const url = `${this.stageApiBase}/api/hotspotgroups/${id}/hotspots`;
    return this.http.post<IHotspot[]>(url, formData);
  }

  saveFloorHotspots(
    hotspots: IHotspot[],
    floorId: number,
    hotspotGroupId: number
  ): Observable<IHotspot[]> {
    const url = `${this.stageApiBase}/api/hotspotgroups/${hotspotGroupId}/floor/${floorId}/hotspots`;
    return this.http.put<IHotspot[]>(url, hotspots);
  }

  uploadFiles(groupId: number, files: File[]): Observable<string> {
    const url = `${this.stageApiBase}/api/hotspotgroups/${groupId}/file`;

    const formData = new FormData();

    files.forEach(file => {
      formData.append(file.name, file, file.name);
    });

    return this.http.put<string>(url, formData);
  }

  postImageFromUrl(groupId: number, urlToSave: string): Observable<string> {
    const url = `${this.stageApiBase}/api/hotspotgroups/${groupId}/image`;
    return this.http.put<string>(url, { urlForImage: urlToSave });
  }
}
