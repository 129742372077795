import { HotspotGroupType } from '../entities/enums';
export function convertLegacyCommunityMap(hg) {
  var _a;
  return Object.assign(Object.assign({}, hg), {
    HotspotGroupId: null,
    ParentType: HotspotGroupType.MyScpCommunityMap,
    ImageFilename: hg.ImageFilename || 'overall_map_kraken.jpg',
    Hotspot: (_a = hg.Hotspot) === null || _a === void 0 ? void 0 : _a.map(x => {
      const data = JSON.parse(x.Description);
      return Object.assign(Object.assign({}, x), {
        NeighborhoodId: (data === null || data === void 0 ? void 0 : data.hoodId) || null,
        AmenityMapId: (data === null || data === void 0 ? void 0 : data.amenityMapId) || null,
        Url: data === null || data === void 0 ? void 0 : data.imagePath
      });
    })
  });
}
