import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
  Output,
  ChangeDetectorRef,
  OnChanges
} from '@angular/core';
import { ISettingMedia, removeFromArray, SettingMediaType } from '@ml/common';
import { ImageVM } from '../image-upload/image-upload.component';
import { Lookup } from '../lookup';

import { ToastService } from '../toast/toast.service';

@Component({
  selector: 'advanced-media-editor',
  templateUrl: './advanced-media-editor.component.html',
  styleUrls: ['./advanced-media-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvancedMediaEditorComponent implements OnChanges {
  @Input() value: string;
  @Input() replacementMode = false; // true if value is set at higher tier so we replace and not edit the media
  @Output() valueUpdate = new EventEmitter<string>();
  @Output() fileAdd = new EventEmitter<File>();
  @Output() fileDelete = new EventEmitter<string>();
  media: SettingMedia[] = [];
  mediaItem = new SettingMedia();
  editEnabled = false;
  editIndex: number;
  settingMediaType = SettingMediaType;
  fileToUpload: File;
  previewItem: SettingMedia;

  // TODO - if we implement more media types, please update the brochure template
  // setting to only allow pdfs and to have optional title and description fields
  // potentially via FieldDataOptions

  constructor(private toaster: ToastService, private cdr: ChangeDetectorRef) {}

  ngOnChanges() {
    const parsedValue: Array<ISettingMedia> = JSON.parse(this.value || '[]');
    this.media = parsedValue.map(m => new SettingMedia(m));
  }

  emitUpdatedMedia() {
    this.valueUpdate.emit(
      this.media.length ? JSON.stringify(this.media.map(m => m.mapToEntity())) : ''
    );
  }

  addMedia() {
    if (!this.mediaItem.Filename && !this.mediaItem.FileToUpload) {
      this.toaster.showError('Missing Fields', 'Please complete all required fields');
      return;
    }

    if (this.replacementMode) {
      this.media = [];
      this.replacementMode = false;
    }

    if (!this.editEnabled) {
      this.media.push(this.mediaItem);
    } else {
      this.editEnabled = false;
    }
    this.mediaItem = new SettingMedia();
    this.emitUpdatedMedia();

    if (this.fileToUpload) {
      this.fileAdd.emit(this.fileToUpload);
      this.fileToUpload = null;
    }
    this.cdr.detectChanges();
  }

  editMedia(index: number) {
    this.editEnabled = true;
    this.editIndex = index;
    this.mediaItem = this.media[index];
  }

  deleteMedia(index: number) {
    const media = this.media[index];
    this.fileDelete.emit(media.Filename);
    if (media === this.mediaItem) this.mediaItem = new SettingMedia();
    this.fileToUpload = null;
    this.media.splice(index, 1);
    this.emitUpdatedMedia();
  }

  drop(event: CdkDragDrop<SettingMedia[]>) {
    if (!this.editEnabled) {
      moveItemInArray(this.media, event.previousIndex, event.currentIndex);
      this.emitUpdatedMedia();
    }
  }

  trackByIndex(index: number) {
    return index;
  }

  handleImagesChange(images: ImageVM[], media: SettingMedia) {
    const image = images[0];
    media.FileToUpload = image.File;
    media.ImageVM = image;
    media.Filename = image.File.name;
    media.DisplayName = image.File.name;
    this.fileToUpload = image.File;
  }

  handleFilesChange(files: File[], media: SettingMedia) {
    const file = files[0];
    media.FileToUpload = file;
    media.ImageVM = {
      ...new ImageVM(),
      File: file,
      Filename: file.name,
      PreviewUrl: URL.createObjectURL(file)
    };
    media.Filename = file.name;
    media.DisplayName = file.name;
    this.fileToUpload = file;
  }

  togglePreview(media?: SettingMedia) {
    if (!this.previewItem && media.ImageVM?.PreviewUrl) {
      this.previewItem = media;
    } else {
      this.previewItem = null;
    }
  }
}

export class SettingMedia implements ISettingMedia {
  Title: string;
  Filename: string;
  Type = SettingMediaType.PDF;
  Description: string;

  FileToUpload: File;
  ImageVM: ImageVM;
  DisplayName: string;

  constructor(data?: ISettingMedia) {
    Object.assign(this, data);

    if (this.Filename) {
      this.ImageVM = new ImageVM({
        File: null,
        Filename: this.Filename,
        MarkedForDeletion: false,
        PreviewUrl: `${Lookup.ApiStageFsProductDataEndpoint}${this.Filename}`
      });
      this.DisplayName = this.Filename.substring(this.Filename.lastIndexOf('/') + 1);
    }
  }

  mapToEntity(): ISettingMedia {
    return {
      Title: this.Title,
      Filename: this.Filename,
      Type: this.Type,
      Description: this.Description
    } as ISettingMedia;
  }
}
