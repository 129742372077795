import { MediaSQL } from './media';
import { ITag, ILot, ILotWatcher } from '@ml/common';

export class IdInfo {
  Id: number;
  ClientUniqueIdentifier: string;
}

// TODO - possibly replace this with the ILot interface in ml/common?
export class Lot implements ILot {
  LotId: number;
  NeighborhoodId: number;
  ClientId: number;
  StatusIds: string[];
  StatusId: number;
  Statuses: { [key: string]: string };
  ShortDescription: string;
  Description: string;
  Name: string;
  LotNumber: string;
  LotNumberDisplay: string;
  Block: string;
  BlockDisplay: string;
  Phase: string;
  Address: string;
  UnitNumber: string;
  Cost: number;
  IsVisible: boolean;
  UIInstanceId: string;
  ClientIdIsJdeId: boolean;
  ClientUniqueIdentifier: string;
  Swing: string;
  Premium: number;
  OptionsPremium: number;
  MappedFloorPlanIds: IdInfo[];
  MappedElevationIds: IdInfo[];
  CustomData: { [index: string]: any };
  Latitude: number;
  Longitude: number;
  ModifiedOn: string;
  StartedOn: string;
  /** @deprecated No longer used */
  TakedownOn: string;
  /** @deprecated No longer used */
  PermitReceivedOn: string;
  /** @deprecated No longer used */
  PermitAppliedOn: string;
  /** @deprecated No longer used */
  SoldOn: string;
  /** @deprecated No longer used */
  ClosedOn: string;
  ApiLastModifiedOn: string;
  InventoryHomeIds: number[] = [];
  Media: MediaSQL[] = [];
  NeighborhoodVirtualDirectory: string;
  Tags: ITag[];
  IsReverse: boolean;
  AllowEdit: boolean;
  AllowImageOverride: boolean;
  OverrideDisplayImageFilename: string;
  OverrideDisplayImageTitle: string;
  BuildStatusId?: number;
  LandStatusId?: number;
  SeriesStatusId?: number;
  ExtendedData: string;
  RotationDegreesFromNorth: number;
  TotalCost?: number;
  MappedElevationsJSON: string;
  MappedFloorPlansJSON: string;
  Watchers: ILotWatcher[];
}
