import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { ArgoGateway } from '../shared/argo.gateway';
import { FileMetadata } from '../shared/data.structures';

@Injectable()
export class FileSystemService {
  constructor(private argoGateway: ArgoGateway, private http: HttpClient) {}

  // although Angular http service is supposed to be able to post files and form data I was unable to make it work
  // so here we use a regular javascript XHR object... also this allows us to use the onProgress events for the upload
  add(
    file: File,
    documentId: string,
    onProgressFn?: (percent: number) => void
  ): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const meta = new FileMetadata(documentId);
    formData.append('metadata', JSON.stringify(meta));

    return this.argoGateway.sendRawXhr('/api/fs', 'POST', formData, onProgressFn);
  }

  download(url: string, name: string = ''): Observable<Blob> {
    return this.http
      .get(url, {
        responseType: 'arraybuffer',
        observe: 'response'
      })
      .pipe(
        map((res: HttpResponse<ArrayBuffer>) => {
          const contentType = res.headers.get('Content-Type') || 'text/plain';
          const blob = new Blob([res.body], { type: contentType });

          // using FileSaver library
          saveAs(blob, name || 'download');

          return blob;
        })
      );
  }

  //  Allows image download by url location without a need for an endpoint in Argo
  async downloadImageFromUrl(url: string, name?: string): Promise<any> {
    const imageType = url.endsWith('png') ? 'png' : 'jpg';
    try {
      const image = await this.getImageFromUrl(url);
      const blob = await this.getBlobFromImage(image, imageType);

      const link = document.createElement('a');
      document.body.appendChild(link); // for Firefox

      link.setAttribute('href', URL.createObjectURL(blob));
      link.setAttribute('download', name);
      link.click();
      link.remove();
      return true;
    } catch (err) {
      return err;
    }
  }

  private getImageFromUrl(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          resolve(img);
        };
        img.onerror = err => {
          reject(err);
        };
      } else {
        resolve(img);
      }
    });
  }

  private getBlobFromImage(img: HTMLImageElement, imageType: string): Promise<Blob> {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (blob.size > 0) {
          resolve(blob);
        } else {
          reject(blob);
        }
      }, `image/${imageType}`);
    });
  }
}
