import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';

import { OverrideTier, Settings, SettingsCategory } from '../entities/settings';
import { Dictionary } from '../shared/data.structures';
import { Lookup } from '../shared/lookup';
import { SettingVM } from '../shared/models/settings-vm';
import { Project } from './division.service';

@Injectable()
export class SettingsService {
  private getAllObservable: Observable<SettingsCategory[]>;
  stageApiBase = window.location.protocol + '//' + Lookup.Argo.StageHost;

  clientSettingsByNameObservablesById = new Map<string, Observable<Settings[]>>();
  store: Dictionary<Settings>;

  constructor(private http: HttpClient) {
    this.store = new Dictionary<Settings>();
  }

  getAllCategories(fromStage = false): Observable<SettingsCategory[]> {
    if (!this.getAllObservable) {
      const base = `${fromStage ? this.stageApiBase : ''}`;
      this.getAllObservable = this.http
        .get<SettingsCategory[]>(
          `${base}/api/settings/categories/application/${Lookup.Auth.ApplicationId}`
        )
        .pipe(
          map((s: SettingsCategory[]) => {
            this.getAllObservable = null;
            return s;
          }),
          catchError((error: Error) => {
            this.getAllObservable = null;
            return observableThrowError(error);
          })
        );
    }

    return this.getAllObservable;
  }

  getCategoryByName(name: string, fromStage = false) {
    const base = `${fromStage ? this.stageApiBase : ''}`;
    return this.http.get<SettingsCategory>(
      `${base}/api/settings/categories/application/${Lookup.Auth.ApplicationId}/category/${name}`
    );
  }

  getClientSettingsByCategory(clientId: number, categoryId: number): Observable<Settings[]> {
    return this.http.get<Settings[]>(`/api/settings/categories/${categoryId}/client/${clientId}`);
  }

  getClientSettingsByCategoryByIds(
    clientId: number,
    categoryIds: number[]
  ): Observable<Settings[]> {
    return this.http.post<Settings[]>(`/api/settings/categories/client/${clientId}`, categoryIds);
  }

  getDivisionSettingsByCategoryByIds(
    divisionId: number,
    categoryIds: number[]
  ): Observable<Settings[]> {
    return this.http.post<Settings[]>(
      `/api/settings/categories/division/${divisionId}`,
      categoryIds
    );
  }

  getDivisionSettingsByCategory(divisionId: number, categoryId: number): Observable<Settings[]> {
    return this.http.get<Settings[]>(
      `/api/settings/categories/${categoryId}/project/${divisionId}`
    );
  }

  getConfiguratorSettingsByCategoryName(
    configuratorId: number,
    categoryName: string,
    fromStage = false,
    rootCategory = false
  ): Observable<Settings[]> {
    const base = `${fromStage ? this.stageApiBase : ''}`;
    if (rootCategory)
      return this.http
        .get<Settings[][]>(
          `${base}/api/settings/application/${Lookup.Auth.ApplicationId}/rootcategoryname` +
            `/${categoryName}/configurator/${configuratorId}`
        )
        .pipe(
          map(settingsMatrix => {
            return settingsMatrix.reduce((all, curr) => [...all, ...curr], []);
          })
        );
    else
      return this.http.get<Settings[]>(
        `${base}/api/settings/application/${Lookup.Auth.ApplicationId}/categoryname/${categoryName}/configurator/${configuratorId}`
      );
  }

  getWebSpinnerSettingsByCategoryName(
    webSpinnerId: number,
    categoryName: string,
    fromStage = false,
    rootCategory = false
  ): Observable<Settings[]> {
    const base = `${fromStage ? this.stageApiBase : ''}`;
    if (rootCategory)
      return this.http
        .get<Settings[][]>(
          `${base}/api/settings/application/${Lookup.Auth.ApplicationId}/rootcategoryname` +
            `/${categoryName}/webspinner/${webSpinnerId}`
        )
        .pipe(
          map(settingsMatrix => {
            return settingsMatrix.reduce((all, curr) => [...all, ...curr], []);
          })
        );
    else
      return this.http.get<Settings[]>(
        `${base}/api/settings/application/${Lookup.Auth.ApplicationId}/categoryname/${categoryName}/webspinner/${webSpinnerId}`
      );
  }

  getCommunitySettingsByCategoryName(
    communityId: number,
    categoryName: string,
    fromStage = false,
    rootCategory = false,
    applicationId = Lookup.Auth.ApplicationId
  ): Observable<Settings[]> {
    const base = `${fromStage ? this.stageApiBase : ''}`;
    if (rootCategory)
      return this.http
        .get<Settings[][]>(
          `${base}/api/settings/application/${applicationId}/rootcategoryname` +
            `/${categoryName}/community/${communityId}`
        )
        .pipe(
          map(settingsMatrix => {
            return settingsMatrix.reduce((all, curr) => [...all, ...curr], []);
          })
        );
    else
      return this.http.get<Settings[]>(
        `${base}/api/settings/application/${applicationId}/categoryname/${categoryName}/community/${communityId}`
      );
  }

  getDivisionSettingsByCategoryName(
    divisionId: number,
    categoryName: string,
    fromStage = false,
    rootCategory = false
  ): Observable<Settings[]> {
    const base = `${fromStage ? this.stageApiBase : ''}`;
    if (rootCategory)
      return this.http
        .get<Settings[][]>(
          `${base}/api/settings/application/${Lookup.Auth.ApplicationId}/rootcategoryname/${categoryName}/project/${divisionId}`
        )
        .pipe(
          map(settingsMatrix => {
            return settingsMatrix.reduce((all, curr) => [...all, ...curr], []);
          })
        );
    else
      return this.http.get<Settings[]>(
        `${base}/api/settings/application/${Lookup.Auth.ApplicationId}/categoryname/${categoryName}/project/${divisionId}`
      );
  }

  /**BE AWARE: this function debounces requests */
  getClientSettingsByCategoryName(
    clientId: number,
    categoryName: string,
    fromStage = false,
    rootCategory = false
  ): Observable<Settings[]> {
    const base = `${fromStage ? this.stageApiBase : ''}`;
    if (rootCategory) {
      return this.http
        .get<Settings[][]>(
          `${base}/api/settings/application/${Lookup.Auth.ApplicationId}/rootcategoryname/${categoryName}/client/${clientId}`
        )
        .pipe(
          map(settingsMatrix => {
            return settingsMatrix.reduce((all, curr) => [...all, ...curr], []);
          })
        );
    } else {
      const key = `${clientId}_{${categoryName}}`;
      // multiple components may try to request this in quick succession so attempt to limit actual API calls by sharing observable
      if (this.clientSettingsByNameObservablesById.has(key)) {
        return this.clientSettingsByNameObservablesById.get(key);
      } else {
        const obs = this.http
          .get<Settings[]>(
            `${base}/api/settings/application/${Lookup.Auth.ApplicationId}/categoryname/${categoryName}/client/${clientId}`
          )
          .pipe(
            map(value => {
              this.clientSettingsByNameObservablesById.delete(key);
              return value;
            }),
            share()
          );
        this.clientSettingsByNameObservablesById.set(key, obs);
        return obs;
      }
    }
  }

  getCommunitySettingsByCategory(communityId: number, categoryId: number): Observable<Settings[]> {
    return this.http.get<Settings[]>(
      `/api/settings/categories/${categoryId}/community/${communityId}`
    );
  }

  getNeighborhoodSettingsByCategory(
    neighborhoodId: number,
    categoryId: number
  ): Observable<Settings[]> {
    return this.http.get<Settings[]>(
      `/api/settings/categories/${categoryId}/neighborhood/${neighborhoodId}`
    );
  }

  getFloorPlanSettingsByCategory(floorplanId: number, categoryId: number): Observable<Settings[]> {
    return this.http.get<Settings[]>(
      `/api/settings/categories/${categoryId}/floorplan/${floorplanId}`
    );
  }

  getCommunitySettingsByCategoryIds(
    communityId: number,
    categoryIds: number[]
  ): Observable<Settings[][]> {
    return this.http.post<Settings[][]>(
      `/api/settings/categories/community/${communityId}`,
      categoryIds
    );
  }

  getProjectSettingsByCategoryIds(
    projectId: number,
    categoryIds: number[]
  ): Observable<Settings[]> {
    return this.http.post<Settings[]>(`/api/settings/categories/project/${projectId}`, categoryIds);
  }

  /** Use this when you also need any affected Fallbacks in the response */
  bulkUpdate(settingsToUpdate: Settings[], useStage = false) {
    const base = `${useStage ? this.stageApiBase : ''}`;
    return this.http.put<BulkUpdateResponse>(`${base}/api/settings`, settingsToUpdate);
  }

  /** Use this when you also need any affected Fallbacks in the response */
  bulkDelete(settingsToDelete: Settings[], useStage = false) {
    const base = `${useStage ? this.stageApiBase : ''}`;
    return this.http.request<BulkUpdateResponse>('delete', `${base}/api/settings`, {
      body: settingsToDelete
    });
  }

  getSettingById(settingsId: number): Observable<Settings> {
    return this.http.get<Settings>(`/api/settings/${settingsId}`);
  }

  createOrUpdateClientOverride(setting: Settings): Observable<Settings> {
    return this.http.put<Settings>(
      `/api/settings/${setting.MasterSettingsId}/client/${setting.ClientId}`,
      setting
    );
  }

  createOrUpdateProjectOverride(setting: Settings): Observable<Settings> {
    return this.http.put<Settings>(
      `/api/settings/${setting.MasterSettingsId}/project/${setting.ProjectId}`,
      setting
    );
  }

  createOrUpdateCommunityOverride(setting: Settings): Observable<Settings> {
    return this.http.put<Settings>(
      `/api/settings/${setting.MasterSettingsId}/community/${setting.CommunityId}`,
      setting
    );
  }

  createOrUpdateNeighborhoodOverride(setting: Settings): Observable<Settings> {
    return this.http.put<Settings>(
      `/api/settings/${setting.MasterSettingsId}/neighborhood/${setting.NeighborhoodId}`,
      setting
    );
  }

  createOrUpdateFloorPlanOverride(setting: Settings): Observable<Settings> {
    return this.http.put<Settings>(
      `/api/settings/${setting.MasterSettingsId}/floorplan/${setting.FloorPlanId}`,
      setting
    );
  }

  deleteClientOverride(setting: Settings, useStage = false): Observable<Settings> {
    const base = `${useStage ? this.stageApiBase : ''}`;
    return this.http.delete<Settings>(
      `${base}/api/settings/${setting.MasterSettingsId}/client/${setting.ClientId}`
    );
  }

  deleteProjectOverride(setting: Settings, useStage = false): Observable<Settings> {
    const base = `${useStage ? this.stageApiBase : ''}`;
    return this.http.delete<Settings>(
      `${base}/api/settings/${setting.MasterSettingsId}/project/${setting.ProjectId}`
    );
  }

  deleteCommunityOverride(setting: Settings, useStage = false): Observable<Settings> {
    const base = `${useStage ? this.stageApiBase : ''}`;
    return this.http.delete<Settings>(
      `${base}/api/settings/${setting.MasterSettingsId}/community/${setting.CommunityId}`
    );
  }

  deleteNeighborhoodOverride(setting: Settings, useStage = false): Observable<Settings> {
    const base = `${useStage ? this.stageApiBase : ''}`;
    return this.http.delete<Settings>(
      `${base}/api/settings/${setting.MasterSettingsId}/neighborhood/${setting.NeighborhoodId}`
    );
  }

  deleteFloorPlanOverride(setting: Settings, useStage = false): Observable<Settings> {
    const base = `${useStage ? this.stageApiBase : ''}`;
    return this.http.delete<Settings>(
      `${base}/api/settings/${setting.MasterSettingsId}/floorplan/${setting.FloorPlanId}`
    );
  }

  deleteCommunityOverrideByName(
    categoryName: string,
    settingName: string,
    communityId: number,
    fromStage = false
  ): Observable<Settings> {
    const base = `${fromStage ? this.stageApiBase : ''}`;
    const pathWithParams =
      `application/${Lookup.Auth.ApplicationId}` +
      `/categoryname/${categoryName}/settingName/${settingName}/community/${communityId}`;

    return this.http.delete<Settings>(`${base}/api/settings/${pathWithParams}`);
  }

  uploadFilesThenUpdateSetting(
    fileList: FileList | File[],
    setting: Settings,
    useStage = false
  ): Observable<Settings> {
    const formData = new FormData();
    formData.append('setting', JSON.stringify(setting));

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < fileList.length; i++) {
      const f = fileList[i];
      formData.append(f.name, f, f.name);
    }

    const base = `${useStage ? this.stageApiBase : ''}`;
    return this.http.post<Settings>(
      `${base}/api/settings/${setting.MasterSettingsId}/files`,
      formData
    );
  }

  /**
   * Checks whether this setting is an override matching the current tier
   * and if not, then setup the entity for creation (ensure proper MasterSettingsId and SettingsId)
   */
  getEntityForApiUpdate({
    vm,
    overrideTier,
    clientId,
    divisionId,
    communityId,
    configuratorId,
    webSpinnerId
  }: {
    vm: SettingVM;
    overrideTier?: OverrideTier;
    clientId?: number;
    divisionId?: number;
    communityId?: number;
    configuratorId?: number;
    webSpinnerId?: number;
  }): Settings {
    const setting = vm.mapToEntity(true);

    if (!overrideTier)
      overrideTier = this.getOverrideTier(
        clientId,
        divisionId,
        communityId,
        configuratorId,
        webSpinnerId
      );

    switch (overrideTier) {
      case OverrideTier.Client:
        setting.ClientId = clientId;
        if (!vm.isClientOverride) {
          // settingVM should be system default so use its id as master
          setting.MasterSettingsId = vm.SettingsId;
          setting.SettingsId = null;
        }
        break;
      case OverrideTier.Division:
        setting.ClientId = clientId;
        setting.ProjectId = divisionId;
        if (!vm.isProjectOverride) {
          // if master exists then settingVM must be the client override so need to match that value
          // otherwise settingVM is the system default which should be set as master
          setting.MasterSettingsId = vm.MasterSettingsId ? vm.MasterSettingsId : vm.SettingsId;
          setting.SettingsId = null;
        }
        break;
      case OverrideTier.Community:
        setting.ClientId = clientId;
        setting.ProjectId = divisionId;
        setting.CommunityId = communityId;

        if (!vm.isCommunityOverride) {
          // if master exists then settingVM must be the project override so need to match that value
          // otherwise settingVM is the system default which should be set as master
          setting.MasterSettingsId = vm.MasterSettingsId ? vm.MasterSettingsId : vm.SettingsId;
          setting.SettingsId = null;
        }
        break;
      case OverrideTier.Configurator:
        setting.ClientId = clientId;
        setting.ProjectId = divisionId;
        setting.ConfiguratorId = configuratorId;

        if (!vm.isConfiguratorOverride) {
          // if master exists then settingVM must be the project override so need to match that value
          // otherwise settingVM is the system default which should be set as master
          setting.MasterSettingsId = vm.MasterSettingsId ? vm.MasterSettingsId : vm.SettingsId;
          setting.SettingsId = null;
        }
        break;
      case OverrideTier.WebSpinner:
        setting.ClientId = clientId;
        setting.ProjectId = divisionId;
        setting.WebSpinnerId = webSpinnerId;

        if (!vm.isWebSpinnerOverride) {
          // if master exists then settingVM must be the project override so need to match that value
          // otherwise settingVM is the system default which should be set as master
          setting.MasterSettingsId = vm.MasterSettingsId ? vm.MasterSettingsId : vm.SettingsId;
          setting.SettingsId = null;
        }
        break;
    }
    return setting;
  }

  getOverrideTier(
    clientId: number,
    divisionId?: number,
    communityId?: number,
    configuratorId?: number,
    webSpinnerId?: number
  ): OverrideTier {
    return !!configuratorId
      ? OverrideTier.Configurator
      : !!webSpinnerId
      ? OverrideTier.WebSpinner
      : !!communityId
      ? OverrideTier.Community
      : !!divisionId
      ? OverrideTier.Division
      : !!clientId
      ? OverrideTier.Client
      : OverrideTier.Unknown;
  }

  updateSettingVMs(
    newSettings: Settings[],
    existing: SettingVM[],
    currentPageTier?: OverrideTier
  ): SettingVM[] {
    const copy = [...existing];
    newSettings.forEach(sett => {
      const vm = new SettingVM(sett, currentPageTier);
      const i = copy.findIndex(
        x =>
          x.SettingsId === vm.SettingsId ||
          (vm.MasterSettingsId && x.SettingsId === vm.MasterSettingsId) ||
          (vm.MasterSettingsId && x.MasterSettingsId === vm.MasterSettingsId) ||
          (!vm.MasterSettingsId && x.MasterSettingsId === vm.SettingsId)
      );
      if (i > -1) {
        const merged = copy[i].mergeAndReturnNew(vm);
        copy[i] = merged;
      }
    });
    return copy;
  }

  //  Job tracker service to get client setting and verify setting value
  async isJobStatusTrackerEnabledForClient(clientId: number) {
    const settings = await this.getClientSettingsByCategoryName(
      clientId,
      Lookup.Settings.JobStatusTrackerSettingCategoryName
    ).toPromise();
    if (settings.length > 0) {
      const clientSetting = settings.find(
        s => s.Name === Lookup.Settings.JobTrackerClientSettingName
      );
      if (clientSetting.Value === 'true') {
        return true;
      }
      return false;
    }
    return false;
  }

  // Job Tracker for Division Setting to verify value
  async isJobTrackerEnabledForDivision(divisionId: number) {
    const divSettings = await this.getDivisionSettingsByCategoryName(
      divisionId,
      Lookup.Settings.JobStatusTrackerSettingCategoryName
    ).toPromise();
    if (divSettings.length > 0) {
      const jtsetting = divSettings.find(
        s => s.Name === Lookup.Settings.JobTrackerClientSettingName
      );
      if (jtsetting.Value === 'true') {
        return true;
      }
      return false;
    }
    return false;
  }

  async isJobTrackerStatusEnabledForCommunity(communityId: number) {
    const commSettings = await this.getCommunitySettingsByCategoryName(
      communityId,
      Lookup.Settings.JobStatusTrackerSettingCategoryName
    ).toPromise();
    if (commSettings.length > 0) {
      const jtsetting = commSettings.find(
        s => s.Name === Lookup.Settings.JobTrackerClientSettingName
      );
      if (jtsetting.Value === 'true') {
        return true;
      }
      return false;
    }
    return false;
  }

  async getJobStatusTrackerSettingsByRpmAccountId(rpmAccountId: number, projectId?: number) {
    const projects = await this.http
      .get<Project[]>(`/api/projects/RpmCustomerId/${rpmAccountId}`)
      .toPromise();
    // if an RPM customer acct is used for multiple projects, try to select the project from URL param, else just use the first one
    const currentDivision = projectId ? projects.find(d => d.ProjectId === projectId) : projects[0];
    const slaSettings = await this.getDivisionSettingsByCategoryName(
      currentDivision.ProjectId,
      Lookup.Settings.JobStatusTrackerSettingCategoryName
    ).toPromise();
    return slaSettings;
  }
}

export class BulkUpdateResponse {
  Errors: { Message: string; Setting: Settings }[];
  OK: Settings[];
}
