import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Dictionary } from '../shared/data.structures';
import { Product } from '../entities/product';

@Injectable()
export class ProductService {
  store: Dictionary<Product>;

  constructor(private http: HttpClient) {
    this.store = new Dictionary<Product>();
  }

  getById(id: number, version: number = 1): Observable<Product> {
    // check store first
    const product = this.store[id];
    if (product) {
      return observableOf(product);
    } else {
      // NOTE: must use version 1 by default as of 10/27/17.
      let headers = new HttpHeaders();
      headers = headers.append('api-version', version.toString());

      return this.http
        .get<Product>(`/api/products/${id}`, {
          headers
        })
        .pipe(
          map((p: Product) => {
            p = Object.assign(new Product(), p);
            this.store.addOrUpdate(p.Id.toString(), p);
            return p;
          })
        );
    }
  }

  getByClientId(id: number): Observable<Product[]> {
    return this.http.get<Product[]>(`/api/products/client/${id}`).pipe(
      map((products: Product[]) => {
        // update store
        products.forEach(x => {
          this.store.addOrUpdate(x.Id.toString(), x);
        });

        return products;
      })
    );
  }

  getByCommunityId(community: number): Observable<Product[]> {
    const headers = new HttpHeaders({ 'api-version': '2' });

    return this.http.get<Product[]>(`/api/products/community/${community}`, { headers });
  }

  getProductsByClientId(client: number): Observable<Product[]> {
    return this.http.get<Product[]>(`/api/product/client/${client}`);
  }

  getByRoot(docId: string): Observable<ProductSet> {
    return this.http.get<ProductSet>(`/api/products/root/${docId}`);
  }

  create(product: Product): Observable<Product> {
    return this.http.post<Product>(`/api/products`, product).pipe(
      map((p: Product) => {
        this.store.addOrUpdate(p.Id, p);
        return p;
      })
    );
  }

  update(product: Product): Observable<Product> {
    return this.http.put<Product>(`/api/products/${product.numberId}`, product).pipe(
      map((p: Product) => {
        this.store.addOrUpdate(p.Id, p);
        return p;
      })
    );
  }
}

export class ProductSet {
  Existing: Product[] = undefined;
  Available: Product[] = undefined;
}
