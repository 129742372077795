import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import CodeFlask from 'codeflask';

@Component({
  selector: 'code-editor',
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CodeEditorComponent implements AfterViewInit, OnChanges {
  @Input() code: string;
  @Output() codeChange = new EventEmitter<string>();
  flask: CodeFlask;

  constructor(private elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.flask && changes.code?.previousValue !== changes.code?.currentValue)
      this.flask.updateCode(changes.code.currentValue);
  }

  ngAfterViewInit(): void {
    this.flask = new CodeFlask(this.elementRef.nativeElement, {
      language: 'html',
      lineNumbers: true,
      handleSelfClosingCharacters: true,
      handleNewLineIndentation: true
    });

    this.flask.updateCode(this.code ?? '');

    this.flask.onUpdate((code: string) => {
      if (code !== this.code) this.codeChange.emit(code);
    });
  }
}
