export enum HealthStatus {
  Good,
  Warning,
  Error
}

export class DeviceInstanceHealthStatus {
  ClientName: string;
  CommunityName: string;
  CommunityId: number;
  UserGuid: string;
  LastCheckedInOn: string;
  HealthStatus: HealthStatus;
  HealthStatusReason: string;
  Notes: string;
  DeviceNotes: string;

  constructor(x?: DeviceInstanceHealthStatus) {
    if (x) {
      Object.assign(this, x);

      if (x?.LastCheckedInOn?.indexOf('z') > -1) {
        this.LastCheckedInOn = new Date(x.LastCheckedInOn).toLocaleString();
      } else {
        this.LastCheckedInOn = new Date(x.LastCheckedInOn + 'z').toLocaleString();
      }
    }
  }

  get isGoodState() {
    return this.HealthStatus === HealthStatus.Good;
  }

  get isWarningState() {
    return this.HealthStatus === HealthStatus.Warning;
  }

  get isErrorState() {
    return this.HealthStatus === HealthStatus.Error;
  }
}
