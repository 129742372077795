import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RootService } from './root.service';
import { Root } from './root';
import { Building } from './building';
import { Unit } from './unit';
import { Spinner } from './spinner';

@Injectable()
export class BuildingRootService {
  constructor(private rootService: RootService) {}

  getByBuildingId(id: number): Observable<Root> {
    return this.rootService.getByDocumentId(`buildings/${id}`).pipe(
      map((bldRoot: Root) => {
        bldRoot = Object.assign(new Root(), bldRoot);
        // must deserialize docs according to specific type (currently only Building and Unit)
        for (const key in bldRoot.Data) {
          if (bldRoot.Data.hasOwnProperty(key)) {
            const obj = bldRoot.Data[key];
            const lkey = key.toLowerCase();
            if (lkey.startsWith('buildings')) {
              bldRoot.Data[key] = new Building(obj);
            } else if (lkey.startsWith('units')) {
              bldRoot.Data[key] = Object.assign(new Unit(), obj);
            } else if (lkey.startsWith('spinners')) {
              bldRoot.Data[key] = new Spinner(obj);
            }
          }
        }

        return bldRoot;
      })
    );
  }
}
