import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit {
    @Input() hideCloseBtn = false;
    @Output() close = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    doClose() {
        this.close.emit();
    }
}
