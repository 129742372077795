import { DocumentBase } from './document-base';
import { Dictionary } from '../shared/data.structures';

export enum Cardinal {
  ZeroOrOne,
  OneOrMore,
  ZeroOrMore,
  OneAndOnlyOne
}

export class ProductComponent {
  public Description: string;

  public AllowUndefinedComponents: boolean;

  public Cardinality: Cardinal;

  public Type: string;

  public Name: string;

  public Components: ProductComponent[] = new Array<ProductComponent>();
}

export class Product extends DocumentBase {
  public AllowUndefinedComponents: boolean;

  public Symbol: string;

  public Components: ProductComponent[];

  public RootType: string;

  public RootDocumentType: string;

  public LiveURLStructure: string;

  public StageURLStructure: string;

  public SecureLiveURLStructure: string;

  public SecureStageURLStructure: string;
}

export class ProductInstance {
  constructor(productId: string = '') {
    this.ProductId = productId;
  }

  public ProductId: string;

  // NOT in the table, but sent over from the API that grabs it dynamically from the product Live URl Structure, etc.
  public UrlComponents: Dictionary<string>;

  public SecurityEnabled: boolean;
}
