export class DocumentBase {
  public Id: string;
  public ClientId: number;
  public ClientUniqueIdentifier: string;
  public Name: string;
  public Description: string;
  public PublicName: string;
  public CreatedOn: Date;
  public CustomData: { [index: string]: any };

  get numberId(): number {
    // check for string just in case Argo sends old number format
    if (typeof this.Id === 'string') {
      const match = this.Id.match(/(\d+)/);
      return match[1] ? +match[1] : null;
    } else {
      return this.Id;
    }
  }
}
