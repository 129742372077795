import { throwError as observableThrowError, of as observableOf, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Lookup } from '../shared/lookup';
import { ClientType, IClient } from '@ml/common';

// TODO: move this into own file
export class Client implements IClient {
  ClientId: number;
  Name: string;
  Description: string;
  Type: ClientType;

  constructor(data?: IClient) {
    Object.assign(this, data);
  }
}

@Injectable()
export class ClientService {
  private store: Client[];
  private haveMadeAllRequest: boolean;
  stageApiBase = window.location.protocol + '//' + Lookup.Argo.StageHost;

  constructor(private http: HttpClient) {
    this.store = new Array<Client>();
    this.haveMadeAllRequest = false;
  }

  getAll(withManagementMaps: boolean = false, fromStage = false): Observable<Client[]> {
    // if requesting withManagementMaps then skip the store
    if (!withManagementMaps && this.haveMadeAllRequest) {
      return observableOf(this.store);
    } else {
      const baseHost = fromStage ? this.stageApiBase : '';
      const url = withManagementMaps
        ? `${baseHost}/api/clients?withManagementMaps=true`
        : `${baseHost}/api/clients`;

      return this.http.get<IClient[]>(url).pipe(
        map((resClients: IClient[]) => {
          const clients = resClients.map(c => new Client(c));
          // only add to store if regular call
          if (!withManagementMaps && !fromStage) {
            this.haveMadeAllRequest = true;
            this.store = clients;
          }
          return clients;
        })
      );
    }
  }

  getById(id: number, fromStage = false): Observable<Client> {
    const baseHost = fromStage ? this.stageApiBase : '';
    const client = this.store.find(c => c.ClientId === id);
    if (client) {
      return observableOf(client);
    } else {
      return this.http.get<Client>(`${baseHost}/api/clients/${id}`).pipe(
        map((c: Client) => {
          const resClient = new Client(c);
          this.store.push(resClient);

          return resClient;
        }),
        catchError((error: Error) => {
          return observableThrowError(error);
        })
      );
    }
  }
}
