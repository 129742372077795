<dropzone *ngIf="showDropzone" (filesDropped)="onFilesDrop($event)" [class.disabled]="isDisabled">
    <p class="watermark">
        <svg-helper svgName="media_multi"></svg-helper>
    </p>
    <p>Drag and drop your files(s) here or browse and select them using the button below</p>
</dropzone>
<div class="flex-row align-center" [class.justify-start]="!showDropzone">
    <button class="btn standard-positive file-input" [class.disabled]="isDisabled">
        <span class="icon">
            <svg-helper svgName="bracket_arrow_up"></svg-helper>
        </span>
        <span class="label">Upload</span>
        <input type="file" [multiple]="maxFilesAllowed !== 1" (change)="onFileInput($event)" />
    </button>
</div>