<div cdkDropList class="field col-xs-8" (cdkDropListDropped)="drop($event)">
    <div cdkDrag *ngFor="let item of expandingSet.stored; let i = index; trackBy: trackByIndex">
        <div class="label">{{itemLabel}} {{i + 1}}</div>
        <div class="input-container">
            <input [(ngModel)]="item.name" name="setItem-{{itemLabel}}-{{item.createdIndex}}" type="text"
                   (ngModelChange)="typedTextChange(expandingSet)" />
            <button class="btn quick standard-negative" (click)="deleteItem(expandingSet, i)">
                <span class="icon">
                    <svg-helper svgName="trash_can_bold"></svg-helper>
                </span>
            </button>
            <ng-container *ngIf="canSort">
                <button drag-handle cdkDragHandle class="btn no-bkg drag-handle" matTooltip="Drag to reorder">
                    <div class="icon">
                        <svg-helper svgName="drag_handle"></svg-helper>
                    </div>
                </button>
                <div *cdkDragPreview class="csp-drag-preview">
                    <button class="btn no-bkg drag-handle">
                        <div class="icon">
                            <svg-helper svgName="drag_handle"></svg-helper>
                        </div>
                    </button>
                    <h4>{{item.name}}</h4>
                </div>
                <div class="drag-placeholder" *cdkDragPlaceholder></div>
            </ng-container>
        </div>
    </div>
</div>

<div class="field col-xs-8" *ngIf="expandingSet">
    <div class="label">{{itemLabel}} {{expandingSet.stored.length + 1}}</div>
    <div class="input-container" *ngIf="expandingSet.pending">
        <input [(ngModel)]="expandingSet.pending.name" name="pendingSetItem-{{itemLabel}}" type="text" />
        <button class="btn quick standard-positive" [class.disabled]="!expandingSet.pending.name"
                (click)="addPending(expandingSet)">
            <span class="icon">
                <svg-helper svgName="plus_bold"></svg-helper>
            </span>
        </button>
    </div>
</div>