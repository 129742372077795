import { SettingFieldType } from '@ml/common';

export class Settings {
  SettingsId: number;
  MasterSettingsId: number;
  FallbackSettingsId: number;
  ClientId: number;
  ProjectId: number;
  CommunityId: number;
  NeighborhoodId: number;
  FloorPlanId: number;
  ConfiguratorId: number;
  WebSpinnerId: number;
  Name: string;
  PublicName: string;
  Value: string;
  SettingsCategoryId: number;
  HelpText: string;
  FieldDataOptions: string;
  FieldType: SettingFieldType = 0;
  Tier: OverrideTier;
  SettingsCategoryName: string;
  FallbackSettingsName: string;
  UIName: string;
  UIValue: string;
  FilesToDelete: string[];
  OriginalSettingsId?: number;

  constructor(setting: Settings = null) {
    if (setting) {
      Object.assign(this, setting);
    }
  }
}

export class SettingsCategory {
  Id: number;
  ParentId: number;
  Title: string;
  PublicName: string;
  ApplicationId: string;
  SubCategories: SettingsCategory[];
  Settings: Settings[];
  HelpText: string;
  isOpen = false;

  constructor(category: SettingsCategory = null, settings: Array<Settings> = null) {
    if (category) {
      Object.assign(this, category);

      if (settings) {
        this.Settings = settings.map(x => new Settings(x));
      }
    }
  }
}

export enum OverrideTier {
  Unknown,
  Master,
  Client,
  Division,
  Community,
  Configurator,
  Neighborhood,
  FloorPlan,
  WebSpinner
}
