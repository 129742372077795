import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MetaData } from './meta-data';

@Injectable()
export class MetaDataService {
  constructor(private http: HttpClient) {}

  getById(id: string): Observable<MetaData> {
    return this.http.get<MetaData>(`/api/metadata/document/${id}`).pipe(
      map((result: any) => {
        const meta = new MetaData();
        meta.CreatedBy = result.CreatedBy?.Value ?? result['Created-By']?.Value;
        meta.CreatedOn = result.CreatedOn?.Value ?? result['Created-On']?.Value;
        meta.ModifiedOn = result.ModifiedOn?.Value ?? result['Modified-On']?.Value;
        meta.ModifiedBy = result.ModifiedBy?.Value ?? result['Modified-By']?.Value;

        return meta;
      })
    );
  }
}
