<div class="form-box" *ngIf="settingVMs?.length">
  <ng-container *ngIf="!hideHeader">
    <div class="box-header transparent">
      <span class="icon">
        <svg-helper svgName="circle_arrow_right"></svg-helper>
      </span>
      <label>{{headerLabel || 'Settings'}}</label>
    </div>
    <div *ngIf="headerHelpText" class="header-help-text">{{ headerHelpText }}</div>
  </ng-container>
  <div class="box-content">
    <div class="row flex-row align-top fadeIn">
      <div [id]="settingVM.HtmlId" *ngFor="let settingVM of settingVMs; trackBy: trackBySettingsId" class="setting col-xs-3" [class.disabled]="!settingsEditable">
        <div class="row flex-row align-center">
          <div class="field col-xs-12">
            <div [ngClass]="{ 'flex-row align-center': settingVM.FieldType === 2, 'flex-column align-left': settingVM.FieldType !== 2 }">
              <div class="flex-row align-center">
                <div *ngIf="settingVM.tierBadge" class="badge" [matTooltip]="settingVM.badgeMessage">{{settingVM.tierBadge}}</div>
                <label>{{ settingVM.DisplayName }}</label>
              </div>
              <div *ngIf="settingVM.HelpText && settingVM.FieldType !== 2" class="help-text">{{ settingVM.HelpText }}</div>
              <form-control-projector [class.width-auto]="settingVM.FieldType === 2" [fieldType]="settingVM.FieldType" [options]="settingVM.FieldDataOptions" [value]="settingVM.Value" (valueChange)="onSettingChange($event, settingVM)" [(valueOriginal)]="settingVM.ValueOriginal" (fileInput)="onFileUpload($event, settingVM)" [useStage]="true" (fileChange)="onFileChange($event, settingVM)">
              </form-control-projector>
              <button *ngIf="settingVM.Name === 'GoogleApiKey' && settingVM.Value" class="btn standard-positive reset" (click)="handleValidateApiKey(settingVM.Value)">
                <div class="icon">
                  <svg-helper svgName="check"></svg-helper>
                </div>Validate
              </button>
            </div>
            <div *ngIf="settingVM.HelpText && settingVM.FieldType === 2" class="help-text">{{ settingVM.HelpText }}</div>
            <button *ngIf="settingVM.canShowResetButton" class="btn standard-positive reset" (click)="onSettingDelete(settingVM)">
              <span class="icon">
                <svg-helper svgName="revert"></svg-helper>
              </span>
              <span class="label">Reset</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>