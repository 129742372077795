import { Injectable } from '@angular/core';

interface Scripts {
  name: string;
  src: string;
}

// for src, use angular.json config to make sure this file is added to final dist folder
export const ScriptStore: Scripts[] = [
  {
    name: 'v3d.2.16',
    src: '/v3d.2.16.js'
  },
  {
    name: 'v3d.3.8',
    src: '/v3d.3.8.js'
  },
  {
    name: 'v3d.4.5',
    src: '/v3d.4.5.js'
  }
];

declare let document: any;

@Injectable()
export class DynamicScriptLoaderService {
  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    const promises: Promise<ScriptLoadResult>[] = [];
    scripts.forEach(script => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise<ScriptLoadResult>((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {
          // for IE
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve(
                new ScriptLoadResult({
                  script: name,
                  loaded: true,
                  status: 'Loaded'
                })
              );
            }
          };
        } else {
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve(new ScriptLoadResult({ script: name, loaded: true, status: 'Loaded' }));
          };
        }

        script.onerror = (error: any) =>
          reject(new ScriptLoadResult({ script: name, loaded: false, status: 'Loaded' }));

        document.getElementsByTagName('body')[0].appendChild(script);
      } else {
        resolve(new ScriptLoadResult({ script: name, loaded: true, status: 'Already Loaded' }));
      }
    });
  }
}

export class ScriptLoadResult {
  script: string;
  loaded: boolean;
  status: string;

  constructor(result: ScriptLoadResult) {
    Object.assign(this, result);
  }
}
