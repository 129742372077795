import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { AreaMapPoi } from '../../content-editor/models/pages-contentjson';
import { Icon, IconService } from '../../entities';
import { ToastService } from '../toast/toast.service';

@Component({
  selector: 'add-poi',
  templateUrl: './add-poi.component.html',
  styleUrls: ['./add-poi.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddPoiComponent implements OnInit {
  @Input() poi: AreaMapPoi;
  @Output() savePoi = new EventEmitter<AreaMapPoi>();

  constructor(private toaster: ToastService, private iconService: IconService) {}

  ngOnInit(): void {}

  updatePoi() {
    if (this.poi.Name && this.poi.Latitude && this.poi.Longitude) {
      this.poi.IsEditable = true;
      this.savePoi.emit(this.poi);
    } else {
      this.toaster.showError('Incomplete', 'All required fields must be completed');
    }
  }

  async handleIconChange(icon: string | number) {
    this.poi.IconId = +icon;
    this.poi.SvgContent = (await this.iconService.getById(+icon)).SvgContent;
  }
}
