/* eslint-disable max-len */
import { IJobNote } from '@ml/common';

import { Lookup } from '../shared/lookup';
import { SortDirection } from '../shared/ui-helper.service';
import { enumKeys } from '../utility/helpers';

export class RpmJob {
  JobId: number;
  ProjectId: number;
  JobName: string;
  AccountManager: number;
  BillToAccountId: number;
  BillToContact: string;
  BillToLine1: string;
  BillToLine2: string;
  BillToCity: string;
  BillToState: string;
  BillToPostalCode: string;
  BillToCountry: string;
  BillToMethod: string;
  BillingInstruction: string;
  IsTimeAndMaterial: boolean;
  IsProgressBill: boolean;
  IsPeriodicBill: boolean;
  StatusId: number;
  ApprovedPrice: number;
  DateApproved: string;
  CompletedPrice: number;
  DateCompleted: string;
  ToBeInvoiced: boolean;
  CADFolderURL: string;
  ValidationDocFolderURL: string;
  PONumber: string;
  RequestNumber: string;
  TermId: number;
  DueDate: string;
  Descr: string;
  EstimatorDescr: string;
  DateInvoiced: string;
  DatePaid: string;
  IsInAccounting: boolean;
  IsPaid: boolean;
  IsOldJob: boolean;
  JobDiscount: number;
  EstimatedApprovedDate: string;
  ProposalComment: string;
  CreatedBy: number;
  DateCreated: string;
  ModifiedBy: number;
  DateModified: string;
  DateCanceled: string;
  FinalJobProductTaskDueDate: string;
  DateWaitingForCustomer: string;
  ProjectName: string;
  JobProductCategories: RpmJobProductCategory[];
  ProgressStatus?: RpmJobProgressStatus;
  ExternalNotes: RpmJobExternalNote[];
  JobStatusHistory: RpmJobStatusHistoryItem[];
  ConvergeOrderInfo: RpmJobConvergeOrderInfo;

  constructor(data?: RpmJob) {
    Object.assign(this, data);

    if (this.JobProductCategories)
      this.JobProductCategories = this.JobProductCategories.map(p => new RpmJobProductCategory(p));
  }
}

export class RpmJobExternalNote implements IJobNote {
  JobNoteId: number;
  JobId: number;
  Note: string;
  CreatedById: string;
  CreatedByName: string;
  CreatedByEmail: string;
  CreatedOn: string;
  Files: string;
  NeedsAttention: boolean;
  RpmCustomerAccountId: number;
  FollowersToAdd: string[];
  OrderId: string;

  HasBeenEdited = false;
  CreatedOnDate: Date;
  FileAttachments: FileAttachment[] = [];

  constructor(data?: IJobNote) {
    Object.assign(this, data);

    if (this.CreatedOn) this.CreatedOnDate = new Date(this.CreatedOn);

    if (this.Files) {
      const json = JSON.parse(this.Files);
      this.FileAttachments = json.map(f => {
        const fileName = f.replace(`/OOT/_JobNotes/${this.JobId}/`, '');
        const url = `${Lookup.ApiFsEndpoint}/download/vd${f}`;
        return new FileAttachment(fileName, url);
      });
    }
  }
}

export class FileAttachment {
  constructor(public Name: string, public Url: string) {}
}

export class RpmJobProductCategory {
  Name: string;
  ProductCount: number;
  ApprovedPrice: number;
  EstimatedDeliveryDate: string;
  CreatedOnDate: string;
  OnHold: boolean;
  ProgressStatus?: RpmJobProductCategoryProgressStatus;
  ReviewRounds: RpmJobProductCategoryReviewRound[];

  ProgressStatusLabel: string;
  ProgressStatusPercentage: number;
  ProgressStatusColor: string;

  SelectedReviewRoundIndex: number;

  constructor(data?: RpmJobProductCategory) {
    Object.assign(this, data);

    this.ProgressStatusLabel = this.OnHold
      ? 'Stopped'
      : RpmJobProductCategoryProgressStatus[this.ProgressStatus];

    // subtract 1 because they start at 0
    this.ProgressStatusPercentage =
      (this.ProgressStatus / (TotalRpmJobProductCategoryProgressStatuses - 1)) * 100;

    this.ProgressStatusColor = this.OnHold
      ? '#ff3b3b'
      : this.ProgressStatus === RpmJobProductCategoryProgressStatus.Closed
      ? '#4caf50'
      : undefined;

    // will always have at least one review round; index starts at 0 but displays as round 1;
    // will default to displaying the highest/current round
    this.SelectedReviewRoundIndex = this.ReviewRounds.length - 1;
  }
}

export class RpmJobProductCategoryReviewRound {
  EstimatedDeliveryDate: Date | null;
  ActualDeliveryDate: Date | null;
}

export class RpmJobConvergeOrderInfo {
  OrderSubmittedByName: string;
  OrderId: string;
}

export class JobsRequestOptions implements IJobsRequestOptions {
  PageSize: number;
  PageNumber: number;
  SortField: JobSortFields;
  SortDirection: SortDirection;
  SearchDateType: JobSortFields;
  CustomerAccountIds: number[] = [];
  SearchPhrase: string;
  FilterByStatus: JobFilterableStatusType;
  ReturnAllResults: boolean;

  constructor(options?: IJobsRequestOptions) {
    if (options) {
      this.PageSize = options.PageSize;
      this.PageNumber = options.PageNumber;
      this.SortField = options.SortField;
      this.SortDirection = options.SortDirection;
      this.SearchDateType = options.SearchDateType;
      this.FilterByStatus = options.FilterByStatus;
      this.ReturnAllResults = options.ReturnAllResults;
    }
  }

  toQueryString(): string {
    const parts = [];

    for (const customerAccountId of this.CustomerAccountIds)
      parts.push(`customerAccountIds=${customerAccountId}`);
    if (this.SearchPhrase)
      parts.push(`searchPhrase=${encodeURIComponent(this.SearchPhrase.trim())}`);
    if (this.PageSize) parts.push(`pageSize=${this.PageSize}`);
    if (this.PageNumber) parts.push(`page=${this.PageNumber}`);
    if (this.SortField) parts.push(`sortField=${this.SortField}`);
    if (this.SortDirection) parts.push(`sortDirection=${this.SortDirection}`);
    if (this.SearchDateType) parts.push(`searchDateType=${this.SearchDateType}`);
    if (this.FilterByStatus) parts.push(`filterByStatus=${this.FilterByStatus}`);
    if (this.ReturnAllResults) parts.push(`ReturnAllResults=${this.ReturnAllResults}`);

    return parts.join('&');
  }

  toggleDirection() {
    if (this.SortDirection === SortDirection.Ascending)
      this.SortDirection = SortDirection.Descending;
    else this.SortDirection = SortDirection.Ascending;
  }
}

export class RpmJobStatusHistoryItem {
  CreatedBy: number;
  DateCreated: string;
  JobId: number;
  JobStatusHistoryId: number;
  Status?: string;
  StatusId: number;
  Name?: string;
}

interface IJobsRequestOptions {
  PageSize?: number;
  PageNumber?: number;
  SortField?: JobSortFields;
  SortDirection?: SortDirection;
  SearchDateType?: JobSortFields;
  CustomerAccountIds?: number[];
  SearchPhrase?: string;
  FilterByStatus?: JobFilterableStatusType;
  ReturnAllResults?: boolean;
}

export enum JobSortFields {
  CreatedOn = 'createdOn',
  ModifiedOn = 'modifiedOn',
  JobName = 'jobName',
  CompletedOn = 'completedOn',
  CanceledOn = 'canceledOn',
  JobId = 'id',
  ApprovedPrice = 'approvedPrice',
  Project = 'projectName',
  Status = 'status',
  EstimatedDelivery = 'estimatedDeliveryDate',
  RequestedBy = 'requestedBy',
  // specific field name for in prod date table
  CreatedOnDate = 'date',
  DivisionName = 'divisionName',
  CompletedPrice = 'completedPrice'
}

// export enum JobSearchDateType {
//   CreatedOn = 'createdOn',
//   ModifiedOn = 'modifiedOn',
//   CompletedOn = 'completedOn',
//   CanceledOn = 'canceledOn'
// }

export enum JobFilterableStatusType {
  None = 'none',
  InProduction = 'inProduction',
  Completed = 'completed',
  Canceled = 'canceled',
  Archived = 'archived',
  Scope = 'scope',
  Estimate = 'estimate',
  WaitingForCustomer = 'waitingForCustomer',
  Propose = 'propose',
  Approved = 'approved'
}

export enum RpmJobProgressStatus {
  'Requested', // Converge Order Submitted
  'Validation Submitted', // RPM Job is moved to the "Estimate" status
  'Proposal Sent', //  RPM Job is moved to the "Proposed" status
  'Proposal Approved', // RPM Job is moved to the "Approved" status
  'Production Started', // when the first task for any product is set to available and assigned on a resource
  'In QA', // when the last task (with QA in the name) of any product(s) is set to available and assigned on a resource
  // and all previous tasks are not available
  'Out for Client Review', // when no tasks are available and at least one task has time accumulated
  'Job Closed' // RPM job is moved to the "Completed" status'
}

export enum RpmJobProductCategoryProgressStatus {
  'Requested', // Converge Order Submitted
  'Validation Submitted', // RPM Job is moved to the "Estimate" status and all tasks for the product have no values in the hh:mm fields
  'Proposal Sent', // RPM Job is moved to the "Proposed" status
  'Proposal Approved', // RPM Job is moved to the "Approved" status
  'Production Started', // when the first task for the product is set to available and assigned on a resource
  'In QA', // when the last task (with QA in the name) for the product is set to available and assigned on a resource
  // and all previous tasks are not available
  'Out For Client Review', // when no tasks for the product are available
  'Closed' // when all tasks for the product are closed
}

export enum RpmJobStatusHistory {
  'Scope' = 1,
  'Estimate' = 2,
  'Waiting For Customer' = 3,
  'Propose' = 4,
  'Approved' = 5,
  'Cancelled' = 6,
  'Completed' = 7,
  'Archived' = 8
}
interface RpmHistoryToProgressStep {
  [status: string]: string;
}

export const RpmJobHistoryToProgressStep: RpmHistoryToProgressStep = {
  ['Scope']: 'Requested',
  ['Estimate']: 'Validation Submitted',
  ['Propose']: 'Proposal Sent',
  ['Approved']: 'Proposal Approved',
  ['Completed']: 'Job Closed'
};
interface JobProgressStatusTip {
  [step: string]: { Tip: string; SlaSettingName?: string };
}

export const RpmJobProgressStatusTips: JobProgressStatusTip = {
  ['Requested']: { Tip: 'Your order has been submitted.', SlaSettingName: 'jobStatusSlaRequested' },
  ['Validation Submitted']: {
    Tip: 'Your order has been sent to our Production Team for validation and time estimation.',
    SlaSettingName: 'jobStatusSlaValidationSubmitted'
  },
  ['Proposal Sent']: {
    Tip: 'We have sent you the proposal for your order and are awaiting approval.'
  },
  ['Proposal Approved']: {
    Tip: 'You have approved the proposal and your order has been scheduled.'
  },
  ['Production Started']: { Tip: 'Your order has been started by our Production Team.' },
  ['In QA']: {
    Tip: 'A member of our Quality Assurance Team is reviewing your order for accuracy.'
  },
  ['Out for Client Review']: {
    Tip: 'Your order is complete and ready for your review and approval.'
  },
  ['Job Closed']: {
    Tip: 'You have approved this order and we have closed the project.'
  }
};

export const TotalRpmJobProductCategoryProgressStatuses = enumKeys(
  RpmJobProductCategoryProgressStatus
).length;
