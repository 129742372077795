import { Address } from '../shared/data.structures';
import { Lookup } from '../shared/lookup';

export class UserRole {
  Id: string;
  Name: string;
  ClientId: number;
  ProjectId: number;
  CommunityId: number;
  NeighborhoodId: number;
}

export class UserEditorPermissions {
  Overall = false;
  OptionsDataOverall = false;
  OptionsSpecs = false;
  OptionsNaming = false;
  OptionsSortOrder = false;
  FloorPlanPricing = false;
  FloorPlanSpecs = false;
  MediaManagerInteriors = false;
  MediaManagerExteriors = false;
  MediaManager3DImages = false;
  FloorPlanHotspotEditor = false;
  FloorPlanTagManager = false;
}

export class User {
  Id: string;
  FirstName = '';
  LastName = '';
  Email: string;
  UserName: string;
  FTPUsername = '';
  FTPPassCipher = '';
  TelephoneNumber: string;
  ClientName: string;
  ClientId: number;
  Roles: UserRole[] = new Array<UserRole>();
  PIN: number;
  Addresses = new Array<Address>();
  IsActive: boolean;
  Name?: string; // Used for the Job Followers

  MentionDisplay: string;
  EditorPermissions = new UserEditorPermissions();

  isSuperAdmin: boolean;
  isClientAdmin: boolean;
  isDivisionAdmin: boolean;
  isStandardUser: boolean;
  isApiUser: boolean;

  private _mappedDivisionIds: number[];
  private _mappedCommunityIds: number[];
  private _mappedNeighborhoodIds: number[];

  constructor(user?: User) {
    Object.assign(this, user);

    this.isSuperAdmin = this.hasRole(Lookup.Auth.ApolloAdmin) || this.hasRole(Lookup.Auth.MLAdmin);
    this.isClientAdmin = this.hasRole(Lookup.Modules.UserManagement.AlternateRoles.clientAdmin);
    this.isDivisionAdmin = this.hasRole(Lookup.Modules.UserManagement.AlternateRoles.divisionAdmin);
    this.isStandardUser = this.hasRole(Lookup.Modules.UserManagement.AlternateRoles.standard);
    this.isApiUser = this.hasRole(Lookup.Modules.UserManagement.AlternateRoles.apiUser);

    if (this.isSuperAdmin) {
      for (const key in this.EditorPermissions) {
        if (this.EditorPermissions.hasOwnProperty(key)) this.EditorPermissions[key] = true;
      }
    } else {
      this.EditorPermissions.Overall = this.hasRole(Lookup.Modules.ContentEditor.Roles.write);
      const ops = Lookup.Modules.ContentEditor.SpecialOperations;

      this.EditorPermissions.OptionsSpecs = this.hasRole(ops.OptionsSpecsAccess.Role);
      this.EditorPermissions.OptionsNaming = this.hasRole(ops.OptionsNamingAccess.Role);
      this.EditorPermissions.OptionsSortOrder = this.hasRole(ops.OptionsSortOrderAccess.Role);
      this.EditorPermissions.OptionsDataOverall =
        this.EditorPermissions.OptionsSpecs ||
        this.EditorPermissions.OptionsNaming ||
        this.EditorPermissions.OptionsSortOrder;

      this.EditorPermissions.FloorPlanPricing = this.hasRole(ops.FloorPlanPricingAccess.Role);
      this.EditorPermissions.FloorPlanSpecs = this.hasRole(ops.FloorPlanSpecsAccess.Role);
      this.EditorPermissions.MediaManagerInteriors = this.hasRole(
        ops.MediaManagerInteriorsAccess.Role
      );
      this.EditorPermissions.MediaManagerExteriors = this.hasRole(
        ops.MediaManagerExteriorsAccess.Role
      );
      this.EditorPermissions.FloorPlanHotspotEditor = this.hasRole(
        ops.FloorPlanHotspotEditorAccess.Role
      );
      this.EditorPermissions.FloorPlanTagManager = this.hasRole(ops.FloorPlanTagManagerAccess.Role);
      this.EditorPermissions.MediaManager3DImages = this.hasRole(
        ops.MediaManager3DImagesAccess.Role
      );
    }

    this.MentionDisplay = `${this.FullName} - ${this.Email}`;
  }

  get FullName(): string {
    return `${this.FirstName} ${this.LastName}`;
  }

  set FullName(name: string) {
    return;
  }

  get mappedDivisionIds(): number[] {
    if (!this._mappedDivisionIds) {
      const ids = [];
      this._mappedDivisionIds = this.Roles.filter(r => r.ProjectId).map(r => r.ProjectId);
    }

    return this._mappedDivisionIds;
  }

  get mappedCommunityIds(): number[] {
    if (!this._mappedCommunityIds) {
      const ids = [];
      this._mappedCommunityIds = this.Roles.filter(r => r.CommunityId).map(r => r.CommunityId);
    }

    return this._mappedCommunityIds;
  }

  get mappedNeighborhoodIds(): number[] {
    if (!this._mappedNeighborhoodIds) {
      const ids = [];
      this._mappedNeighborhoodIds = this.Roles.filter(r => r.NeighborhoodId).map(
        r => r.NeighborhoodId
      );
    }

    return this._mappedNeighborhoodIds;
  }

  addRole(role: UserRole) {
    // make sure Roles initialized
    if (!this.Roles) this.Roles = [];
    // check if role already added
    if (this.Roles.some(r => r.Name === role.Name)) return;

    this.Roles.push(role);

    // role change means mapped entities have likely changed... set to null so next get refreshes these arrays
    this._mappedDivisionIds = null;
    this._mappedCommunityIds = null;
    this._mappedNeighborhoodIds = null;
  }

  addRoleByName(roleName: string) {
    const newRole = new UserRole();
    newRole.Name = roleName;
    this.addRole(newRole);
  }

  setRoles(roles: UserRole[]) {
    this.Roles = roles;

    // role change means mapped entities have likely changed... set to null so next get refreshes these arrays
    this._mappedDivisionIds = null;
    this._mappedCommunityIds = null;
    this._mappedNeighborhoodIds = null;
  }

  setAddresses(addresses: Address[]) {
    this.Addresses = addresses;
  }

  removeRoleByName(roleName: string) {
    if (!this.Roles) return;

    const index = this.Roles.findIndex(r => r.Name === roleName);
    if (index > -1) this.Roles.splice(index, 1);

    // role change means mapped entities have likely changed... set to null so next get refreshes these arrays
    this._mappedDivisionIds = null;
    this._mappedCommunityIds = null;
    this._mappedNeighborhoodIds = null;
  }

  hasRole(roleName: string): boolean {
    return this.Roles.some(r => r.Name === roleName);
  }

  fillFromJson(json: string) {
    const jsonObj = JSON.parse(json);
    for (const propName in jsonObj) {
      if (jsonObj.hasOwnProperty(propName)) this[propName] = jsonObj[propName];
    }
  }
}
