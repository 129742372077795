<div class="tile">
    <div class="header">
        <div class="icon">
            <svg-helper [svgName]="svgName"></svg-helper>
        </div>
        <div class="label">{{title}}</div>
    </div>
    <div class="body">
        <div class="base">
            <ng-content select="[body-content]"></ng-content>
        </div>
    </div>
</div>