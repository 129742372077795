import { Hotspot } from './hotspot';
import { Unit } from './unit';
import { Lookup } from '../shared/lookup';

export class Floor {
  public FloorId: number;

  public Title: string;

  public FloorNumber: number;

  public IsOption: boolean;

  public IsBasement: boolean;

  public IsDefaultFloor: boolean;

  public SvgFilename: string;

  public FurnitureInstanceId: number;

  public ModifiedOn: Date;

  public CreatedOn: Date;

  public PixelsPerFoot: number;

  public IsActive: boolean;

  public Hotspots: Hotspot[] = new Array<Hotspot>();

  public units: Unit[];

  public get fullSvgFileUrl(): string {
    if (!this.SvgFilename) return '';

    // some floors were incorrectly saved with 'fs/' in the filename property
    return `${Lookup.ApiFsEndpoint}/${this.SvgFilename.replace('fs/', '')}`;
  }

  constructor(data?: Floor) {
    Object.assign(this, data);
  }
}
