import { DocumentBase } from './document-base';

export enum HotspotType {
  Media = 1,
  Navigation = 2,
  Action = 3
}

export class Hotspot extends DocumentBase {
  public Type: number;

  public X: number;

  public Y: number;

  public Ref: string;

  public ConeDegree: number;
}
