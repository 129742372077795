import { nothing as t, noChange as i } from "../lit-html.js";
import { Directive as r, PartType as s, directive as n } from "../directive.js";
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
class e extends r {
  constructor(i) {
    if (super(i), this.et = t, i.type !== s.CHILD) throw Error(this.constructor.directiveName + "() can only be used in child bindings");
  }
  render(r) {
    if (r === t || null == r) return this.ft = void 0, this.et = r;
    if (r === i) return r;
    if ("string" != typeof r) throw Error(this.constructor.directiveName + "() called with a non-string value");
    if (r === this.et) return this.ft;
    this.et = r;
    const s = [r];
    return s.raw = s, this.ft = {
      _$litType$: this.constructor.resultType,
      strings: s,
      values: []
    };
  }
}
e.directiveName = "unsafeHTML", e.resultType = 1;
const o = n(e);
export { e as UnsafeHTMLDirective, o as unsafeHTML };
