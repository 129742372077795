import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { IPaginationObject } from './pagination-object';

@Component({
  selector: 'paginator',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaginationComponent {
  @Input() pager: IPaginationObject<any>;
  @Output() pageChange = new EventEmitter<number>();

  incrementPage() {
    this.pager.setIndex(this.pager.index + 1);
    this.pageChange.emit(this.pager.index);
  }

  decrementPage() {
    this.pager.setIndex(this.pager.index - 1);
    this.pageChange.emit(this.pager.index);
  }
}
