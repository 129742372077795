import { Lookup } from '../shared/lookup';

export class Frame {
  public Name: string;

  public IsActive = true;

  public ImageFilename: string;

  public SvgOverlayFilename: string;

  public get fullImageFileUrl(): string {
    if (!this.ImageFilename) return '';

    if (this.ImageFilename.startsWith('http')) {
      return this.ImageFilename;
    }

    // some frames were incorrectly saved with 'fs/' in the filename property
    return `${this.baseUrl}/${this.ImageFilename.replace('fs/', '')}`;
  }

  public get fullSvgOverlayFileUrl(): string {
    if (!this.SvgOverlayFilename) return '';

    if (this.SvgOverlayFilename.startsWith('http')) {
      return this.SvgOverlayFilename;
    }

    // some frames were incorrectly saved with 'fs/' in the filename property
    return `${this.baseUrl}/${this.SvgOverlayFilename.replace('fs/', '')}`;
  }

  public imageLoaded: boolean;
  public svgOverlayLoaded: boolean;

  private baseUrl: string;

  constructor(data?: Partial<Frame>, baseUrl: string = null) {
    if (data) Object.assign(this, data);

    if (baseUrl) this.baseUrl = baseUrl;
  }
}
