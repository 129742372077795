import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Input } from '@angular/core';
import { forkJoin as observableForkJoin } from 'rxjs';

import {
    OrderLocation,
    Division,
    DivisionService,
    Community,
    CommunityService,
    Neighborhood,
    NeighborhoodService
} from '../../entities';
import { AuthService } from '../../auth/auth.service';
import { ToastService } from '../toast/toast.service';

@Component({
    selector: 'bulk-location',
    templateUrl: './bulk-location.component.html',
    styleUrls: ['./bulk-location.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BulkLocationComponent implements OnInit {
    // existingLocations only used for initializing
    @Input()
    existingLocations: OrderLocation[];
    @Input()
    clientId: number;
    @Input() lockExistingLocation = false;

    private _divisionsOnly = null;
    @Input()
    set divisionsOnly(value: boolean) {
        if (typeof this._divisionsOnly === 'boolean') {
            // reset stored
            this.stored = new Array<LocationVM>();
            this.emitChange();

            if (!value) this.fetchCommunities(this.pending.orderLocation.ProjectId, this.pending);
        }

        this._divisionsOnly = value;
    }
    get divisionsOnly(): boolean {
        return this._divisionsOnly;
    }

    @Output()
    locationsChange = new EventEmitter<OrderLocation[]>();

    stored: LocationVM[] = new Array<LocationVM>();
    pending: LocationVM = new LocationVM();

    // the id to represent the "All" option in any dropdown
    allLocationsId = -1;

    constructor(
        private authService: AuthService,
        private divisionService: DivisionService,
        private communityService: CommunityService,
        private neighborhoodService: NeighborhoodService,
        private toaster: ToastService
    ) {}

    ngOnInit() {
        this.pending.orderLocation.ClientId = this.clientId;

        if (this.existingLocations && this.existingLocations.length > 0) {
            // map existing locations into LocationVMs
            const lVMs = this.existingLocations.map(x => {
                const vm = new LocationVM();
                vm.orderLocation.ClientId = x.ClientId;
                vm.orderLocation.ProjectId = x.ProjectId;
                vm.orderLocation.ProjectName = x.ProjectName;
                vm.orderLocation.CommunityId = x.CommunityId;
                vm.orderLocation.CommunityName = x.CommunityName;
                vm.orderLocation.NeighborhoodId = x.NeighborhoodId;
                vm.orderLocation.NeighborhoodName = x.NeighborhoodName;
                return vm;
            });

            // populate lists for dropdowns
            lVMs.forEach(l => {
                this.fetchDivisions(this.clientId, l);
            });

            this.stored = lVMs;

            // make sure pending division matches existing
            this.pending.orderLocation.ClientId = this.clientId;
            this.pending.orderLocation.ProjectId = lVMs[0].orderLocation.ProjectId;
            this.pending.orderLocation.ProjectName = lVMs[0].orderLocation.ProjectName;
        }

        // kick off list population for pending dropdown
        this.fetchDivisions(this.clientId, this.pending);
    }

    onDivisionChange(id: number, locationVM: LocationVM) {
        locationVM.orderLocation.ProjectId = +id;
        locationVM.orderLocation.ProjectName = this.setProjectName(locationVM);

        if (!this.divisionsOnly && id !== this.allLocationsId)
            this.fetchCommunities(locationVM.orderLocation.ProjectId, locationVM);
    }

    setProjectName(locationVM: LocationVM) {
        const div = locationVM.divisions.find(
            d => d.ProjectId === locationVM.orderLocation.ProjectId
        );

        return div ? div.Name : 'All';
    }

    fetchDivisions(id: number, locationVM: LocationVM) {
        // clear these out immediately for better UX while waiting on API response
        locationVM.divisions = [];
        locationVM.communities = [];
        locationVM.neighborhoods = [];

        this.divisionService.getByClientId(id).subscribe(divs => {
            // filter out divisions this user is not authorized to see
            if (!this.authService.isSuperAdmin() && !this.authService.isClientAdmin())
                divs = divs.filter(
                    d => this.authService.currentUser.mappedDivisionIds.indexOf(d.DivisionId) > -1
                );

            locationVM.divisions = divs.sort((a, b) => (a.Name < b.Name ? -1 : 1));
            locationVM.orderLocation.ProjectName = this.setProjectName(locationVM);

            // check if there is currently set ProjectId and maintain it if so
            const current = divs.find(x => x.ProjectId === locationVM.orderLocation.ProjectId);
            if (locationVM.orderLocation.ProjectId && current) {
                if (!this.divisionsOnly) this.fetchCommunities(current.ProjectId, locationVM);
            } else {
                // otherwise reset and attempt to grab first in the list
                locationVM.orderLocation.ProjectId = null;
                locationVM.orderLocation.ProjectName = null;
                if (this.divisionsOnly) {
                    // default to "All" option
                    locationVM.orderLocation.ProjectId = this.allLocationsId;
                } else if (divs[0]) {
                    locationVM.orderLocation.ProjectId = divs[0].ProjectId;
                    locationVM.orderLocation.ProjectName = divs[0].Name;

                    if (!this.divisionsOnly)
                        this.fetchCommunities(locationVM.orderLocation.ProjectId, locationVM);
                }
            }
        });
    }

    onCommunityChange(id: number, locationVM: LocationVM) {
        locationVM.orderLocation.CommunityId = +id;
        locationVM.orderLocation.CommunityName = this.setCommunityName(locationVM);

        if (locationVM.orderLocation.CommunityId === this.allLocationsId)
            locationVM.neighborhoods = [];
        else this.fetchNeigborhoods(locationVM.orderLocation.CommunityId, locationVM);
    }

    setCommunityName(locationVM: LocationVM) {
        const com = locationVM.communities.find(
            x => x.CommunityId === locationVM.orderLocation.CommunityId
        );

        return com ? com.Name : 'All';
    }

    fetchCommunities(id: number, locationVM: LocationVM) {
        // clear these out immediately for better UX while waiting on API response
        locationVM.communities = [];
        locationVM.neighborhoods = [];

        this.communityService.getByDivisionId(id).subscribe(coms => {
            // filter out communities this user is not authorized to see
            if (
                !this.authService.isSuperAdmin() &&
                !this.authService.isClientAdmin() &&
                !this.authService.isDivisionAdmin()
            )
                coms = coms.filter(
                    x => this.authService.currentUser.mappedCommunityIds.indexOf(x.CommunityId) > -1
                );

            locationVM.communities = coms.sort((a, b) => (a.Name < b.Name ? -1 : 1));
            locationVM.orderLocation.CommunityName = this.setCommunityName(locationVM);

            // check if there is currently set CommunityId and maintain it if so
            const current = coms.find(x => x.CommunityId === locationVM.orderLocation.CommunityId);
            if (locationVM.orderLocation.CommunityId && current) {
                this.fetchNeigborhoods(current.CommunityId, locationVM, true); // skips auto selecting top one on load
            } else {
                // otherwise set both community and neighborhood to "All" option
                locationVM.orderLocation.CommunityId = this.allLocationsId;
                locationVM.orderLocation.NeighborhoodId = this.allLocationsId;
            }
        });
    }

    onNeigborhoodChange(id: number, locationVM: LocationVM) {
        locationVM.orderLocation.NeighborhoodId = +id;
        locationVM.orderLocation.NeighborhoodName = this.setNeighborhoodName(locationVM);
    }

    setNeighborhoodName(locationVM: LocationVM) {
        const nhood = locationVM.neighborhoods.find(
            x => x.NeighborhoodId === locationVM.orderLocation.NeighborhoodId
        );

        return nhood ? nhood.Name : 'All';
    }

    fetchNeigborhoods(id: number, locationVM: LocationVM, skipAutoSelect: boolean = false) {
        // clear these out immediately for better UX while waiting on API response
        locationVM.neighborhoods = [];

        this.neighborhoodService.getByCommunityId(id, false, true, true).subscribe(nhoods => {
            // filter out neighborhoods this user is not authorized to see
            if (
                !this.authService.isSuperAdmin() &&
                !this.authService.isClientAdmin() &&
                !this.authService.isDivisionAdmin()
            )
                nhoods = nhoods.filter(
                    x =>
                        this.authService.currentUser.mappedNeighborhoodIds.indexOf(
                            x.NeighborhoodId
                        ) > -1
                );

            locationVM.neighborhoods = nhoods.sort((a, b) => (a.Name < b.Name ? -1 : 1));
            locationVM.orderLocation.NeighborhoodName = this.setNeighborhoodName(locationVM);

            if (!locationVM.orderLocation.NeighborhoodId && nhoods[0] && !skipAutoSelect)
                locationVM.orderLocation.NeighborhoodId = nhoods[0].NeighborhoodId;
        });
    }

    addPending() {
        if (this.divisionsOnly) {
            if (this.pending.orderLocation.ProjectId === this.allLocationsId) {
                if (this.stored.length !== this.pending.divisions.length) {
                    this.stored = [];
                    this.pending.divisions.forEach(d => {
                        const locVM = this.createLocationAndAddToStored(d.DivisionId);
                        locVM.orderLocation.ProjectName = this.setProjectName(locVM);
                        locVM.orderLocation.CommunityName = this.setCommunityName(locVM);
                        locVM.orderLocation.NeighborhoodName = this.setNeighborhoodName(locVM);
                        locVM.divisions = [d];
                    });

                    this.emitChange();
                }
            } else if (this.pending.orderLocation.ProjectId) {
                const locVM = this.createLocationAndAddToStored(
                    this.pending.orderLocation.ProjectId
                );
                locVM.divisions = this.pending.divisions;
                locVM.orderLocation.ProjectName = this.setProjectName(locVM);
                locVM.orderLocation.CommunityName = this.setCommunityName(locVM);
                locVM.orderLocation.NeighborhoodName = this.setNeighborhoodName(locVM);

                this.emitChange();
            }
        } else {
            if (this.pending.orderLocation.ProjectId && this.pending.orderLocation.CommunityId) {
                if (
                    this.pending.orderLocation.CommunityId === this.allLocationsId &&
                    !(
                        this.pending.orderLocation.CommunityId === this.allLocationsId &&
                        this.pending.orderLocation.NeighborhoodId === this.allLocationsId
                    )
                ) {
                    // add all communities and neighborhoods from current divsion

                    // first slim down to only locations not in this current division
                    this.stored = this.stored.filter(
                        s => s.orderLocation.ProjectId !== this.pending.orderLocation.ProjectId
                    );

                    // add all available communities from this division to stored
                    this.pending.communities.forEach(c => {
                        const locVM = this.createLocationAndAddToStored(
                            this.pending.orderLocation.ProjectId,
                            c.CommunityId
                        );
                        locVM.divisions = this.pending.divisions;
                        locVM.communities = this.pending.communities;
                        locVM.orderLocation.ProjectName = this.setProjectName(locVM);
                        locVM.orderLocation.CommunityName = this.setCommunityName(locVM);
                        locVM.orderLocation.NeighborhoodName = this.setNeighborhoodName(locVM);
                        this.fetchNeigborhoods(c.CommunityId, locVM, true); // true, skips auto selecting first neightborhood
                    });
                } else if (
                    this.pending.orderLocation.CommunityId === this.allLocationsId &&
                    this.pending.orderLocation.NeighborhoodId === this.allLocationsId
                ) {
                    // add all neighborhoods from current division-community
                    this.toaster.showLoading();

                    // first slim down to only locations not in this current community
                    this.stored = this.stored.filter(
                        s => s.orderLocation.CommunityId !== this.pending.orderLocation.CommunityId
                    );

                    const hoodRequests = this.pending.communities.map(x =>
                        this.neighborhoodService.getByCommunityId(x.CommunityId, false, true, true)
                    );
                    observableForkJoin(hoodRequests).subscribe(hoodResponses => {
                        hoodResponses.forEach(hoods => {
                            hoods.forEach(hood => {
                                const locVM = this.createLocationAndAddToStored(
                                    this.pending.orderLocation.ProjectId,
                                    hood.CommunityId,
                                    hood.NeighborhoodId
                                );
                                locVM.divisions = this.pending.divisions;
                                locVM.communities = this.pending.communities;
                                locVM.neighborhoods = hoods;
                                locVM.orderLocation.NeighborhoodId = hood.NeighborhoodId;
                                locVM.orderLocation.ProjectName = this.setProjectName(locVM);
                                locVM.orderLocation.CommunityName = this.setCommunityName(locVM);
                                locVM.orderLocation.NeighborhoodName = this.setNeighborhoodName(locVM);
                            });
                        });
                        this.toaster.hideLoading();
                        this.emitChange();
                    });
                } else if (this.pending.orderLocation.NeighborhoodId === this.allLocationsId) {
                    // first slim down to only locations not in this current community
                    this.stored = this.stored.filter(
                        s => s.orderLocation.CommunityId !== this.pending.orderLocation.CommunityId
                    );

                    this.pending.neighborhoods.forEach(n => {
                        const locVM = this.createLocationAndAddToStored(
                            this.pending.orderLocation.ProjectId,
                            this.pending.orderLocation.CommunityId,
                            n.NeighborhoodId
                        );
                        locVM.divisions = this.pending.divisions;
                        locVM.communities = this.pending.communities;
                        locVM.neighborhoods = this.pending.neighborhoods;
                        locVM.orderLocation.ProjectName = this.setProjectName(locVM);
                        locVM.orderLocation.CommunityName = this.setCommunityName(locVM);
                        locVM.orderLocation.NeighborhoodName = this.setNeighborhoodName(locVM);
                    });
                } else {
                    // add just the single location values
                    this.stored.push(this.pending);

                    const temp = this.pending;

                    // preset values to what current was
                    this.pending = new LocationVM();
                    this.pending.orderLocation.ClientId = temp.orderLocation.ClientId;
                    this.pending.orderLocation.ProjectId = temp.orderLocation.ProjectId;
                    this.pending.orderLocation.ProjectName = temp.orderLocation.ProjectName;

                    this.pending.divisions = temp.divisions;
                    this.pending.communities = temp.communities;
                    this.pending.neighborhoods = temp.neighborhoods;

                    this.pending.orderLocation.ProjectName = this.setProjectName(this.pending);
                    this.pending.orderLocation.CommunityName = this.setCommunityName(this.pending);
                    this.pending.orderLocation.NeighborhoodName = this.setNeighborhoodName(this.pending);
                }

                this.emitChange();
            }
        }
    }

    private createLocationAndAddToStored(
        projectId: number,
        communityId?: number,
        neighborhoodId?: number
    ): LocationVM {
        const oLoc = new OrderLocation();
        oLoc.ProjectId = projectId;
        oLoc.CommunityId = communityId;
        oLoc.NeighborhoodId = neighborhoodId;

        const locVM = new LocationVM();
        locVM.orderLocation = oLoc;

        this.stored.push(locVM);
        return locVM;
    }

    deleteLocation(index: number) {
        if (this.stored[index]) {
            this.stored.splice(index, 1);

            this.emitChange();
        }
    }

    private emitChange() {
        const oLocations = this.stored.map(x => x.orderLocation);
        this.locationsChange.emit(oLocations);
    }

    isDivisionStored(id: number) {
        return this.stored.some(s => s.orderLocation.ProjectId === id);
    }
}

class LocationVM {
    orderLocation: OrderLocation = new OrderLocation();
    divisions: Division[] = new Array<Division>();
    communities: Community[] = new Array<Community>();
    neighborhoods: Neighborhood[] = new Array<Neighborhood>();
}
