<div>
    <div class="form-box">
        <div cdkDropList class="flex-column" (cdkDropListDropped)="drop($event)">
            <div cdkDrag *ngFor="let item of quickLinks; let i = index; trackBy: trackByIndex" class="flex-row">
                <button drag-handle cdkDragHandle class="btn no-bkg drag-handle" matTooltip="Drag to reorder">
                    <div class="icon">
                        <svg-helper svgName="drag_handle"></svg-helper>
                    </div>
                </button>
                <div *cdkDragPreview class="quick-drag-preview">
                    <button class="btn no-bkg drag-handle">
                        <div class="icon">
                            <svg-helper svgName="drag_handle"></svg-helper>
                        </div>
                    </button>
                    <button class="quick-btn">
                        <div *ngIf="!!item.svgContent" class="icon" [innerHTML]="item.svgContent | safeHtml"></div>
                        <div class="label">{{item.label}}</div>
                    </button>
                </div>
                <div *cdkDragPlaceholder class="quick-drag-placeholder"></div>
                <button class="btn standard-positive quick-btn">
                    <div *ngIf="!!item.svgContent" class="icon" [innerHTML]="item.svgContent | safeHtml"></div>
                    <div class="label">{{item.label}}</div>
                </button>
                <button class="btn quick standard-positive"
                        matTooltip="Edit Quick Link" (click)="editLink(i)">
                    <span class="icon">
                        <svg-helper svgName="pencil_box"></svg-helper>
                    </span>
                </button>
                <button class="btn quick standard-negative"
                        matTooltip="Delete Quick Link" (click)="deleteLink(i)">
                    <span class="icon">
                        <svg-helper svgName="trash_can"></svg-helper>
                    </span>
                </button>
            </div>

        </div>
    </div>
    <div class="form-box" *ngIf="quickLinks.length < 9">
        <div class="box-content">
            <div class="row">
                <div class="col-xs-6 field">
                    <div class="label">
                        <span class="warning">*</span>
                        Name
                    </div>
                    <input [(ngModel)]="quickLinkItem.label" name="Label" type="text" />
                </div>
                <div class="col-xs-6 field">
                    <div class="label">Icon</div>
                    <icon-form-control [(iconId)]="quickLinkItem.icon" (iconIdChange)="handleIconChange($event, quickLinkItem)"></icon-form-control>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6 field">
                    <div class="label">
                        <span class="warning">*</span>
                        Type
                    </div>
                    <select name="Type" [(ngModel)]="quickLinkItem.urlType">
                        <option value=""></option>
                        <option value="internal">Internal</option>
                        <option value="external">External</option>
                    </select>
                </div>
                <div *ngIf="!!quickLinkItem.urlType" class="col-xs-6 field">
                    <div class="label">
                        <span class="warning">*</span>
                        URL
                    </div>
                    <input *ngIf="quickLinkItem.urlType === 'external'" [(ngModel)]="quickLinkItem.url" name="URL" type="text" />
                    <select *ngIf="quickLinkItem.urlType === 'internal'" name="ConvergeLink" [(ngModel)]="quickLinkItem.route" [compareWith]="compareSelect">
                        <option *ngFor="let route of allRoutes; trackBy: trackByIndex" [ngValue]="route">{{route.Name}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6">
                    <button class="btn standard-positive quick-add-btn" (click)="addEditQuickLink()">{{editEnabled ? 'Finish Edit': 'Add Link'}}</button>
                </div>
                <div *ngIf="quickLinks.length < 5" class="col-xs-6">
                    <button class="btn standard-positive quick-add-btn" (click)="addDefaultLinks()">Add Default Quick Links</button>
                </div>
            </div>
        </div>
    </div>
</div>