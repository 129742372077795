export class Guid {
    // https://gist.github.com/jed/982883
    public static Generate = function b(a?): string {
        return a
            ? // eslint-disable-next-line no-bitwise
              (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
            : ((1e7).toString() + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, b);
    };

    public static IsValid(potential: string): boolean {
        return /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi.test(
            potential
        );
    }
}
