import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {
    @Input() isSelected: boolean;
    @Output() selectionToggle = new EventEmitter<boolean>();

    constructor() { }

    toggle() {
        this.isSelected = !this.isSelected;
        this.selectionToggle.emit(this.isSelected);
    }
}
