import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Dictionary } from '../shared/data.structures';
import { Spinner } from './spinner';

@Injectable()
export class SpinnerService {
  store: Dictionary<Spinner>;

  constructor(private http: HttpClient) {
    this.store = new Dictionary<Spinner>();
  }

  getById(id: number): Observable<Spinner> {
    // check store first
    const spinner = this.store[id];
    if (spinner) {
      return observableOf(spinner);
    } else {
      return this.http.get<Spinner>(`/api/spinners/${id}`).pipe(
        map((s: Spinner) => {
          s = Object.assign(new Spinner(), s);
          this.store.addOrUpdate(s.numberId.toString(), s);
          return s;
        })
      );
    }
  }

  getByClientId(id: number): Observable<Spinner[]> {
    return this.http.get<Spinner[]>(`/api/spinners/client/${id}`).pipe(
      map((spinners: Spinner[]) => {
        spinners = spinners.map(s => Object.assign(new Spinner(), s));
        spinners.forEach(s => {
          this.store.addOrUpdate(s.numberId.toString(), s);
        });

        return spinners;
      })
    );
  }

  update(spinner: Spinner): Observable<Spinner> {
    return this.http.put<Spinner>(`/api/spinners/${spinner.numberId}`, spinner).pipe(
      map((s: Spinner) => {
        s = Object.assign(new Spinner(), s);
        this.store.addOrUpdate(s.numberId.toString(), s);

        return s;
      })
    );
  }

  create(spinner: Spinner): Observable<Spinner> {
    return this.http.post<Spinner>(`/api/spinners`, spinner).pipe(
      map((s: Spinner) => {
        s = Object.assign(new Spinner(), s);
        this.store.addOrUpdate(s.numberId.toString(), s);

        return s;
      })
    );
  }
}
