<modal [class.visible]="isVisible" [hideCloseBtn]="true">
    <div class="message">
        <div class="icon large">
            <svg id="alert_bold" data-name="alert bold" xmlns="http://www.w3.org/2000/svg" width="23.94" height="24" viewBox="0 0 23.94 24">
                <path d="M12,1a.7.7,0,0,1,.6.42L22.9,21.74a1,1,0,0,1,0,.87.71.71,0,0,1-.6.39H1.77a.7.7,0,0,1-.6-.39,1,1,0,0,1,0-.89L11.43,1.41A.68.68,0,0,1,12,1m0-1a1.7,1.7,0,0,0-1.5,1L.27,21.27a2,2,0,0,0,0,1.82A1.71,1.71,0,0,0,1.77,24H22.29a1.7,1.7,0,0,0,1.47-.91,2,2,0,0,0,0-1.82L13.52,1A1.68,1.68,0,0,0,12,0Z"
                    transform="translate(-0.06)" fill="#fff" />
                <path d="M14,8a.47.47,0,0,0-.13-.32.52.52,0,0,0-.32-.18H10.56a.52.52,0,0,0-.32.18A.51.51,0,0,0,10.1,8l.23,7.59a.42.42,0,0,0,.46.38h2.47a.42.42,0,0,0,.45-.38Z"
                    transform="translate(-0.06)" fill="#fff" />
                <rect x="10.26" y="17.42" width="3.42" height="3.42" rx="0.43" ry="0.43" fill="#fff" />
            </svg>
        </div>
        <div class="title">Wait!</div>
        <p>{{message}}</p>
    </div>
    <div class="btn standard-negative" (click)="leavePage()">
        <span class="label">{{leavePageButtonText}}</span>
    </div>
    <div class="btn standard-positive" (click)="stayOnPage()">
        <span class="label">Stay on Page</span>
    </div>
    <div class="btn priority-positive" (click)="saveAndLeavePage()" *ngIf="showSaveAndContinueButton">
        <span class="label">Save and Continue</span>
    </div>
</modal>