import { ACL, User } from '../entities';

export interface IApolloAplicationModules {
  [key: string]: ApolloModuleModel;
}

// Check Confluence: Manage User roles and ACLs when needing to add
// an additional special operation (Need to do both Add ACL Operation and Adding ACL Permission)
// unless operation is meant for navigation bar only with no special permissions
export class ApolloModuleSpecialOperation {
  Name: string;
  Role?: string;
  PublicName?: string;
  Icon?: string;
  BaseUrl?: string;
  AltIdentifyingRoute?: string;
  ForSuperAdminsOnly?: boolean;
  SortOrder?: number = 0;

  constructor(data: ApolloModuleSpecialOperation) {
    Object.assign(this, data);
    if (!this.PublicName) this.PublicName = this.Name;
  }

  /**
   * Checks whether passed user has access to this operation.
   * Assumes user access has already been verified for parent ACL
   */
  isUserAllowed?(user: User, acl: ACL): boolean {
    if (user.isSuperAdmin) return true;
    if (this.ForSuperAdminsOnly) return false;

    // verify both user's client and user have access to this Operation
    const aclOp = acl.Operations.find(x => x.OperationName === this.Name);
    if (aclOp) {
      const clientRole = `${user.ClientName}_${user.ClientId}`;
      return (
        aclOp.Roles.some(r => r.Name === clientRole && r.IsAllowed) &&
        (!this.Role || user.hasRole(this.Role))
      );
    } else {
      // NOTE: convention at this point is if Operation does not exist in data we will allow user access
      return true;
    }
  }
}

export class ApolloModuleModel {
  /**
   * Returns any SpecialOperations that have a BaseUrl value which thereby makes them "Navigable"
   */
  NavigableOperations: ApolloModuleSpecialOperation[];

  constructor(
    public Name: string = '',
    public Icon: string = '',
    public BaseUrl: string = '',
    public Roles: { read: string; write: string } = { read: '', write: '' },
    public SortOrder: number = 0,
    public AlternateRoles: any = {},
    public SpecialOperations: { [key: string]: ApolloModuleSpecialOperation } = {},
    public ForSuperAdminsOnly: boolean = false
  ) {
    this.NavigableOperations = Object.keys(this.SpecialOperations)
      .filter(k => {
        const op = this.SpecialOperations[k];
        return op.BaseUrl ? true : false;
      })
      .map(k => this.SpecialOperations[k]);
  }
}

export const ApolloApplicationModuleLookup: IApolloAplicationModules = {
  ManagementMaps: new ApolloModuleModel(
    'ManagementMaps',
    'management_maps_nav',
    '/management-maps',
    { read: 'apollo.management-maps.read', write: 'apollo.management-maps.write' },
    2
  ),
  Orders: new ApolloModuleModel(
    'Orders',
    'ordering_new',
    '/orders',
    { read: 'apollo.order-management.read', write: 'apollo.order-management.write' },
    3,
    null,
    {
      MakeOrder: new ApolloModuleSpecialOperation({
        Name: 'MakeOrder',
        PublicName: 'New',
        BaseUrl: '/orders/product-list'
      }),
      InProgressOrders: new ApolloModuleSpecialOperation({
        Name: 'InProgressOrders',
        PublicName: 'Draft',
        BaseUrl: '/orders/drafts',
        AltIdentifyingRoute: '/orders/product-forms'
      }),
      RpmJobsInProduction: new ApolloModuleSpecialOperation({
        Name: 'RpmJobsInProduction',
        PublicName: 'In-Production',
        BaseUrl: '/orders/history/jobs/in-production'
      }),
      RpmJobsCompleted: new ApolloModuleSpecialOperation({
        Name: 'RpmJobsCompleted',
        PublicName: 'Completed',
        BaseUrl: '/orders/history/jobs/completed'
      }),
      RpmJobsCanceled: new ApolloModuleSpecialOperation({
        Name: 'RpmJobsCanceled',
        PublicName: 'Canceled',
        BaseUrl: '/orders/history/jobs/canceled'
      })
    }
  ),
  Tools: new ApolloModuleModel('Tools', 'tools_nav', '/tools', { read: '', write: '' }, 4, null, {
    Reporting: new ApolloModuleSpecialOperation({
      Name: 'Reporting',
      Role: 'apollo.reporting.write',
      BaseUrl: '/tools/reports',
      SortOrder: 0
    }),
    Analytics: new ApolloModuleSpecialOperation({
      Name: 'Analytics',
      Role: 'apollo.analytics.write',
      BaseUrl: '/tools/analytics',
      SortOrder: 1
    }),
    LinkGenerator: new ApolloModuleSpecialOperation({
      Name: 'LinkGenerator',
      Role: 'apollo.linkgenerator.write',
      PublicName: 'Links',
      BaseUrl: '/tools/link-generator',
      SortOrder: 2
    }),
    StatusReport: new ApolloModuleSpecialOperation({
      Name: 'StatusReport',
      Role: 'apollo.statusreport.write',
      PublicName: 'Status',
      BaseUrl: '/tools/status-report',
      SortOrder: 3
    }),
    OfflineSCP: new ApolloModuleSpecialOperation({
      Name: 'OfflineSCP',
      Role: 'apollo.offline.write',
      PublicName: 'Offline Kiosk',
      BaseUrl: '/tools/offline-scp',
      SortOrder: 4
    })
  }),
  Projects: new ApolloModuleModel(
    'Projects',
    'project_nav',
    '/3d-configurator',
    { read: 'apollo.projects.read', write: 'apollo.projects.write' },
    5,
    null,
    {
      Settings: new ApolloModuleSpecialOperation({
        Name: 'Settings',
        BaseUrl: '/3d-configurator/settings',
        ForSuperAdminsOnly: true
      }),
      Manage: new ApolloModuleSpecialOperation({
        Name: 'Projects',
        BaseUrl: '/3d-configurator/list',
        ForSuperAdminsOnly: true
      }),
      Models: new ApolloModuleSpecialOperation({
        Name: 'Models',
        BaseUrl: '/3d-configurator/models/list',
        AltIdentifyingRoute: '/3d-configurator/model'
      }),
      Products: new ApolloModuleSpecialOperation({
        Name: 'Library',
        BaseUrl: '/3d-configurator/library/list',
        AltIdentifyingRoute: '/3d-configurator/library'
      })
    }
  ),
  WebSpinner: new ApolloModuleModel(
    'WebSpinner',
    'arrow_refresh',
    '/web-spinner',
    { read: '', write: '' },
    6,
    null,
    {
      Links: new ApolloModuleSpecialOperation({
        Name: 'Links',
        PublicName: 'Models',
        Role: 'apollo.webspinner.read',
        BaseUrl: '/web-spinner/list',
        SortOrder: 1
      }),
      Settings: new ApolloModuleSpecialOperation({
        Name: 'Settings',
        Role: 'apollo.webspinner.write',
        BaseUrl: '/web-spinner/settings',
        SortOrder: 2
      })
    }
  ),
  ArViewer: new ApolloModuleModel(
    'ArViewer',
    'modules',
    '/ar-viewer',
    { read: '', write: '' },
    7,
    null,
    {
      Links: new ApolloModuleSpecialOperation({
        Name: 'Links',
        PublicName: 'Models',
        Role: 'ml-apAdmin',
        BaseUrl: '/ar-viewer/models',
        SortOrder: 1
      }),
      Settings: new ApolloModuleSpecialOperation({
        Name: 'Settings',
        Role: 'ml-apAdmin',
        BaseUrl: '/ar-viewer/settings',
        SortOrder: 2
      })
    }
  ),
  ContentEditor: new ApolloModuleModel(
    'ContentEditor',
    'pencil_box',
    '/content-editor',
    { read: 'apollo.content-editor.read', write: 'apollo.content-editor.write' },
    8,
    null,
    {
      Clients: new ApolloModuleSpecialOperation({
        Name: 'Clients',
        BaseUrl: '/content-editor/settings/client',
        ForSuperAdminsOnly: true
      }),
      Divisions: new ApolloModuleSpecialOperation({
        Name: 'Divisions',
        BaseUrl: '/content-editor/settings/division'
      }),
      Communities: new ApolloModuleSpecialOperation({
        Name: 'Communities',
        BaseUrl: '/content-editor/communities/list'
      }),
      OptionsSpecsAccess: new ApolloModuleSpecialOperation({
        Name: 'Options Data Access', // OG name -- must match SQL AclOperation
        PublicName: 'Options Specs Access',
        Role: 'apollo.options-data-access',
        SortOrder: 1
      }),
      OptionsNamingAccess: new ApolloModuleSpecialOperation({
        Name: 'Options Naming Access',
        Role: 'apollo.options-naming-access',
        SortOrder: 1
      }),
      OptionsSortOrderAccess: new ApolloModuleSpecialOperation({
        Name: 'Options Sort Order Access',
        Role: 'apollo.options-sort-order-access',
        SortOrder: 1
      }),
      FloorPlanPricingAccess: new ApolloModuleSpecialOperation({
        Name: 'Floor Plan Pricing Access',
        Role: 'apollo.fp-pricing-access',
        SortOrder: 2
      }),
      FloorPlanSpecsAccess: new ApolloModuleSpecialOperation({
        Name: 'Floor Plan Specs Access',
        Role: 'apollo.fp-specs-access',
        SortOrder: 3
      }),
      MediaManagerInteriorsAccess: new ApolloModuleSpecialOperation({
        Name: 'Media Manager Interiors Access',
        Role: 'apollo.media-manager-interiors-access',
        SortOrder: 4
      }),
      MediaManagerExteriorsAccess: new ApolloModuleSpecialOperation({
        Name: 'Media Manager Exteriors Access',
        Role: 'apollo.media-manager-exteriors-access',
        SortOrder: 5
      }),
      MediaManager3DImagesAccess: new ApolloModuleSpecialOperation({
        Name: 'Media Manager 3D Images Access',
        Role: 'apollo.media-manager-3d-images-access',
        SortOrder: 6
      }),
      FloorPlanHotspotEditorAccess: new ApolloModuleSpecialOperation({
        Name: 'Floor Plan Hotspot Editor Access',
        Role: 'apollo.fp-hotspot-editor-access',
        SortOrder: 7
      }),
      FloorPlanTagManagerAccess: new ApolloModuleSpecialOperation({
        Name: 'Floor Plan Tag Manager Access',
        Role: 'apollo.fp-tag-manager-access',
        SortOrder: 8
      })
    },
    false
  ),
  UserManagement: new ApolloModuleModel(
    'UserManagement',
    'user_two',
    '/users',
    { read: 'apollo.user-management.read', write: 'apollo.user-management.write' },
    9,
    {
      standard: 'apollo.standard-user',
      clientAdmin: 'apollo.client-admin',
      divisionAdmin: 'apollo.division-admin',
      apiUser: 'apollo.api-user'
    },
    {
      PinAccess: new ApolloModuleSpecialOperation({
        Name: 'Has PIN Access',
        Role: 'apollo.pin-access.write',
        PublicName: 'PIN Manager'
      })
    }
  ),
  Admin: new ApolloModuleModel(
    'Admin',
    'medialab_logo_large',
    '/admin',
    { read: 'apollo.admin.read', write: 'apollo.admin.write' },
    10,
    null,
    {
      Settings: new ApolloModuleSpecialOperation({
        Name: 'Settings',
        Role: 'apollo.admin-settings.write',
        BaseUrl: '/admin/settings'
      }),
      IconLibrary: new ApolloModuleSpecialOperation({
        Name: 'Icon Library',
        Role: 'apollo.admin-iconlibrary.write',
        BaseUrl: '/admin/icons',
        ForSuperAdminsOnly: true
      }),
      Brochures: new ApolloModuleSpecialOperation({
        Name: 'Brochures',
        BaseUrl: '/admin/brochures',
        SortOrder: 4,
        ForSuperAdminsOnly: true
      }),
      ColorSchemeDesigner: new ApolloModuleSpecialOperation({
        Name: 'ColorSchemeDesigner',
        PublicName: 'Color Scheme Designer',
        Role: 'apollo.admin-colorschemedesigner.write',
        BaseUrl: '/admin/color-scheme-designer'
      }),
      IntegrationsDashboard: new ApolloModuleSpecialOperation({
        Name: 'Integrations Dashboard',
        BaseUrl: '/admin/integrations-dashboard',
        ForSuperAdminsOnly: true
      }),
      FtpHelper: new ApolloModuleSpecialOperation({
        Name: 'FTP Helper',
        BaseUrl: '/admin/ftp-helper',
        ForSuperAdminsOnly: true
      })
    }
  )
};
