import { Directive, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { AuthService } from 'app/auth/auth.service';
import { Community } from '../../entities';
import { SettingVM } from '../../shared/models/settings-vm';
import { ToastService } from '../../shared/toast/toast.service';
import { createStringSorterByPropertyName } from '../../shared/ui-helper.service';
import { removeFromArray } from '../../utility/helpers';
import { IPage, PageService } from '../services/page.service';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class BasePageEditComponent implements OnChanges {
  @Input() page: IPage;
  @Input() community: Community;
  @Input() allSettings: SettingVM[];
  @Input() clientId: number;
  @Input() sortSettingsAlpha = true;
  @Input() relatedSettingCategoryName: string;
  @Output() pageChange = new EventEmitter<{
    page: IPage;
    filesToUpload?: File[];
    filesToDelete?: string[];
  }>();
  @Output() communityChange = new EventEmitter<Community>();
  @Output() settingChange = new EventEmitter<SettingVM>();
  @Output() settingDelete = new EventEmitter<SettingVM>();
  @Output() settingFileUpload = new EventEmitter<SettingAndFileDTO>();
  needsSave = false;
  allSettingsEditable = false;
  thisPageSettingVMs: SettingVM[] = [];
  fileBaseUrl: string;
  filesToUpload: File[] = [];
  filesToDelete: string[] = [];
  allCategoryNames: string[] = [];

  constructor(
    protected pageService: PageService,
    protected toaster: ToastService,
    protected auth: AuthService,
    public pageTitle: string,
    protected otherSettingCategoryNames?: string[]
  ) {
    this.pageTitle = pageTitle;
    this.allSettingsEditable = this.auth.currentUser.EditorPermissions.Overall;
    this.allCategoryNames = [this.relatedSettingCategoryName];
    if (otherSettingCategoryNames) this.allCategoryNames.push(...otherSettingCategoryNames);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.allSettings) {
      this.thisPageSettingVMs = this.getThisPageSettingVMs();
      if (this.sortSettingsAlpha)
        this.thisPageSettingVMs.sort(createStringSorterByPropertyName('PublicName'));
    }

    if (changes.community && this.page) {
      this.fileBaseUrl = this.pageService.getBaseFileUrl(
        this.community.VirtualDirectory,
        this.page
      );
    }
  }

  getThisPageSettingVMs(): SettingVM[] {
    if (!this.relatedSettingCategoryName || !this.allSettings) return [];
    return this.allSettings.filter(
      x =>
        x.SettingsCategoryName === this.relatedSettingCategoryName ||
        this.otherSettingCategoryNames?.some(n => x.SettingsCategoryName === n)
    );
  }

  onInputChange() {
    this.needsSave = true;
  }

  uploadFileForSetting(settingWithFile: SettingAndFileDTO) {
    this.settingFileUpload.emit(settingWithFile);
  }

  handleSettingChange(settingVM: SettingVM) {
    this.settingChange.emit(settingVM);
  }

  handleDeleteSetting(settingVM: SettingVM) {
    this.settingDelete.emit(settingVM);
  }

  handleFileAdd(file: File) {
    this.filesToUpload.push(file);
  }

  handleFileRemove(filename: string) {
    const pendingUpload = this.filesToUpload.find(f => f.name === filename);
    if (pendingUpload) {
      this.filesToUpload = removeFromArray(pendingUpload, this.filesToUpload);
    } else {
      this.filesToDelete.push(filename);
    }
  }
}

export class SettingAndFileDTO {
  constructor(public Setting: SettingVM, public FileList: FileList) {}
}
