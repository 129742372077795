import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import { KeyValueSetItem } from '../key-value-set/key-value-set.component';
import { SettingsService } from '../../entities';
import { sortByTitle } from '../ui-helper.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ReportData } from '../../tools/report/models/reportData';
import { SettingVM } from '../models/settings-vm';

@Component({
  selector: 'multi-expanding-set',
  templateUrl: './multi-expanding-set.component.html',
  styleUrls: ['./multi-expanding-set.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultiExpandingSetComponent implements OnInit, OnChanges {
  @Input()
  expandingSet: MultiExpandingSet = new MultiExpandingSet();
  @Input()
  itemLabel = 'Item';
  @Input() settingsCategoryId: number;
  @Input() enabledProductList: SettingVM[];
  @Output() validateSetting = new EventEmitter<boolean>();
  @Output()
  add = new EventEmitter<MultiExpandingSetItem>();
  @Output()
  delete = new EventEmitter<MultiExpandingSetItem>();
  @Output()
  typedText = new EventEmitter<any>();
  typeName = '';

  productTitles = new Array<string>();

  shouldShowRelatedProduct = true;
  pendingTitle = new UntypedFormControl('', {
    updateOn: 'change',
    validators: Validators.required
  });
  pendingBody = new UntypedFormControl('', { updateOn: 'change', validators: Validators.required });
  pendingGroup = new UntypedFormControl('', {
    updateOn: 'change',
    validators: Validators.required
  });
  pendingReport = new UntypedFormControl('', {
    updateOn: 'change',
    validators: Validators.required
  });

  constructor(private settingsService: SettingsService) {}

  ngOnInit() {
    this.settingsService.getAllCategories().subscribe(categories => {
      const productDashboard = categories.find(x => x.Title.toLowerCase() === 'product dashboard');
      if (productDashboard)
        this.productTitles = productDashboard.SubCategories.sort(sortByTitle).map(x => x.Title);
    });
    this.shouldShowRelatedProduct = this.showRelatedProductField();
    if (this.settingsCategoryId === 81) this.typeName = 'Analytics';
    else this.typeName = 'Reporting';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.expandingSet) this.resetPendingControls();
  }
  showRelatedProductField(): boolean {
    // Needs to not show in reporting field
    return this.settingsCategoryId && this.settingsCategoryId !== 82;
  }
  addKeyValuePair(kvp, type, index?: number) {
    if (type === 'pending') {
      this.expandingSet.pending.keyValuePairs.push(kvp);
    }
    if (type === 'stored') {
      this.expandingSet.stored[index].keyValuePairs.push(kvp);
    }
  }

  deleteKeyValuePair(kvp, type, index?: number) {
    if (type === 'pending') {
      const deleteIndex = this.expandingSet.pending.keyValuePairs.indexOf(kvp);
      this.expandingSet.pending.keyValuePairs.splice(deleteIndex, 1);
    }
    if (type === 'stored') {
      const reportFrom = this.expandingSet.stored[index];
      const deleteIndex = reportFrom.keyValuePairs.indexOf(kvp);
      reportFrom.keyValuePairs.splice(deleteIndex, 1);
    }
  }

  addPending(set: MultiExpandingSet) {
    if (
      this.pendingTitle.valid &&
      this.pendingBody.valid &&
      this.pendingGroup.valid &&
      this.pendingReport.valid
    ) {
      set.pending.reportTitle = this.pendingTitle.value;
      set.pending.body = this.pendingBody.value;
      set.pending.group = this.pendingGroup.value;
      set.pending.report = this.pendingReport.value;

      set.createdCount++;
      set.pending.createdIndex = set.createdCount;

      set.stored.push(set.pending);

      this.add.emit(set.pending);
      set.pending = new MultiExpandingSetItem();
      this.resetPendingControls();
    } else {
      this.markPendingControlsAsDirty();
    }
  }

  deleteItem(set: MultiExpandingSet, index: number) {
    if (set.stored[index]) {
      const deleted = set.stored.splice(index, 1);
      this.delete.emit(deleted[0]);
    }
  }

  typedTextChange(item: MultiExpandingSet) {
    const canSave = !item.stored.some(
      set => !set.reportTitle || !set.body || !set.group || !set.report
    );
    this.validateSetting.emit(canSave);
    this.typedText.emit(item);
  }

  trackByIndex(index: number) {
    return index;
  }

  onReportSelect(report: ReportData) {
    if (report) {
      this.pendingTitle.setValue(report.Title);
      this.pendingBody.setValue(report.Desc);
      this.pendingGroup.setValue(report.GroupId);
      this.pendingReport.setValue(report.ReportId);

      if (report.ShowTabs) this.expandingSet.pending.showTabs = true;
      else this.expandingSet.pending.showTabs = false;
      if (report.RelatedProduct)
        this.expandingSet.pending.productSettingsCategoryTitle = report.RelatedProduct;
      else this.expandingSet.pending.productSettingsCategoryTitle = '';
      if (report.TableName) this.expandingSet.pending.tableName = report.TableName;
      else this.expandingSet.pending.tableName = '';
      this.expandingSet.pending.keyValuePairs = report.KeyValuePairs;
      this.pendingTitle.markAsDirty();
    } else {
      this.expandingSet.pending.showTabs = false;
      this.expandingSet.pending.productSettingsCategoryTitle = '';
      this.expandingSet.pending.tableName = '';
      this.expandingSet.pending.keyValuePairs = [];
      this.resetPendingControls();
    }
  }

  private resetPendingControls() {
    this.pendingTitle.reset();
    this.pendingBody.reset();
    this.pendingGroup.reset();
    this.pendingReport.reset();
  }
  private markPendingControlsAsDirty() {
    this.pendingTitle.markAsDirty();
    this.pendingBody.markAsDirty();
    this.pendingGroup.markAsDirty();
    this.pendingReport.markAsDirty();
  }
}

export class MultiExpandingSetItem {
  reportTitle = '';
  body = '';
  report = '';
  group = '';
  tableName = '';
  keyValuePairs = [];
  createdIndex = 0;
  productSettingsCategoryTitle: string;
  showTabs: boolean;
}

export class MultiExpandingSet {
  pending: MultiExpandingSetItem = new MultiExpandingSetItem();
  stored: MultiExpandingSetItem[] = new Array<MultiExpandingSetItem>();
  createdCount = 0;

  constructor(
    public reportTitle: string = '',
    public body: string = '',
    public report: string = '',
    public group: string = '',
    public tableName: string = '',
    public keyValuePairs: Array<KeyValueSetItem> = []
  ) {}

  // reset the created index to be in array order
  reindex() {
    this.stored.forEach((item, i) => {
      item.createdIndex = i + 1;
    });

    this.createdCount = this.stored.length;
  }

  clone(): MultiExpandingSet {
    const dupe = new MultiExpandingSet(
      this.reportTitle,
      this.body,
      this.report,
      this.group,
      this.tableName,
      this.keyValuePairs
    );

    // NOTE: this will not copy any class functions (the copies are not actually class instances)
    for (const attr in this) {
      if (this.hasOwnProperty(attr)) dupe[attr.toString()] = JSON.parse(JSON.stringify(this[attr]));
    }

    dupe.reindex();
    return dupe;
  }

  initStored(values: any) {
    if (values && values.length > 0) {
      this.stored = values.map((x, i) => {
        const item = new MultiExpandingSetItem();
        item.reportTitle = x;
        item.body = x;
        item.report = x;
        item.group = x;
        item.tableName = x;
        item.keyValuePairs = x;
        item.createdIndex = i;
        return item;
      });

      this.createdCount = this.stored.length;
    }
  }

  addItem(
    reportTitle: string,
    body: string,
    report: string,
    group: string,
    tableName: string,
    keyValuePairs: Array<any>
  ) {
    const newValue = new MultiExpandingSetItem();
    newValue.reportTitle = reportTitle;
    newValue.body = body;
    newValue.report = report;
    newValue.group = group;
    newValue.tableName = tableName;
    newValue.keyValuePairs = keyValuePairs;
    this.stored.push(newValue);
  }
}
