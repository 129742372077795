import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  OnChanges
} from '@angular/core';
import { ReportData } from '../models/reportData';
import { ReportService } from '../report.service';
import { ToastService } from 'app/shared/toast/toast.service';
import * as pbi from 'powerbi-client';

@Component({
  selector: 'report-overlay',
  templateUrl: './report-overlay.component.html',
  styleUrls: ['./report-overlay.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportOverlayComponent implements OnInit, OnChanges {
  @Input() currentReport: ReportData;
  @Input() open = false;
  @Input() noToastLoader = false;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter();
  @Output() overlayError = new EventEmitter<string>();
  @ViewChild('embedContainer', { static: true }) embedContainer: ElementRef;
  embeddedReport: any;
  showEmbeddedReport = false;

  constructor(private reportService: ReportService, private toaster: ToastService) {}

  ngOnInit() {}

  ngOnChanges() {
    if (
      this.open &&
      this.currentReport &&
      this.currentReport.GroupId &&
      this.currentReport.ReportId &&
      this.currentReport.GroupId
    ) {
      this.getReport(
        this.currentReport.GroupId,
        this.currentReport.ReportId,
        this.currentReport.ShowTabs
      );
    }
  }

  getReport(groupId: string, reportId: string, showTabs: boolean) {
    if (!this.noToastLoader) this.toaster.showLoading();

    this.reportService.getPowerBIReportEmbedObject(groupId, reportId, '').subscribe(
      x => {
        const statusReportEmbedUrl = this.reportService.formatEmbedQueryURL(
          x.EmbedURL,
          this.currentReport
        );
        // code from https://microsoft.github.io/PowerBI-JavaScript/demo/v2-demo/index.html#
        const models = pbi.models;
        const permissions = models.Permissions.All;
        const config: pbi.IEmbedConfiguration = {
          type: 'report',
          tokenType: models.TokenType.Embed,
          accessToken: x.EmbedToken,
          embedUrl: statusReportEmbedUrl,
          id: x.ReportId,
          permissions,
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: showTabs
          } as pbi.IEmbedSettings
        };

        // Embed the report and display it within the div container.
        const powerbi = new pbi.service.Service(
          pbi.factories.hpmFactory,
          pbi.factories.wpmpFactory,
          pbi.factories.routerFactory
        );
        powerbi.reset(this.embedContainer.nativeElement);
        this.embeddedReport = powerbi.embed(this.embedContainer.nativeElement, config);
        setTimeout(() => {
          this.toaster.hideLoading();
        }, 1000);

        // Power BI Embedded has a 15 second limit between refresh requests
        // this.embeddedReport.refresh(); // No need to refresh - data is scheduled to refresh daily on the backend
        this.showEmbeddedReport = true;
        this.embeddedReport.on('loaded', function () {
          // console.log("report loaded");
        });
      },
      error => {
        this.toaster.hideLoading();
        this.overlayError.emit(this.currentReport.Title);
        console.log(error);
      }
    );
  }

  closeOverlay() {
    const isOpen = false;
    this.close.emit(isOpen);
  }
}
