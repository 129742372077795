<div *ngIf="!bulkTagActionType" class="flex-row align-center">
    <svg-helper svgName="filter"></svg-helper>
    <h4>Filter Tags</h4>
</div>

<label *ngIf="!bulkTagActionType" for="Search Tags">Add Tag</label>
<div *ngIf="bulkTagActionType !== eTagMappingAction.Remove" class="input-container">
    <input type="text"
           aria-label="Search Tags"
           name="Search Tags"
           matInput
           [formControl]="searchCtrl"
           [matAutocomplete]="auto">
    <button class="btn quick standard-positive" matTooltip="Add Tag" (click)="handleTagCreate()"
            [disabled]="!searchCtrl.value">
        <svg-helper svgName="plus_bold"></svg-helper>
    </button>
    <mat-spinner *ngIf="showProgressSpinner" @enterLeaveFadeAnimation diameter="30"></mat-spinner>
</div>
<mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn"
                  (optionSelected)="handleTagSelect($event)">
    <ng-container *ngIf="searchCtrl.value">
        <mat-option class="tag-option" *ngFor="let tag of searchedTags; trackBy: trackByTagId" [value]="tag">
            <p>{{tag.Name}}</p>
        </mat-option>
    </ng-container>
    <ng-container *ngIf="!searchCtrl.value">
        <h4>Suggestions:</h4>
        <mat-option class="tag-option" *ngFor="let tag of filteredSuggestedTags; trackBy: trackByTagId" [value]="tag">
            <p>{{tag.Name}}</p>
        </mat-option>
    </ng-container>
</mat-autocomplete>

<div class="assigned-tags">
    <label *ngIf="!bulkTagActionType">Assigned Tags:</label>
    <label *ngIf="bulkTagActionType === eTagMappingAction.Add && addedTags.length">Tags To Be Assigned To The Lot Selections Below:</label>
    <label *ngIf="bulkTagActionType === eTagMappingAction.Remove && addedTags.length">Tags To Be Removed From The Lot Selections Below:</label>
    <ul>
        <li *ngFor="let tag of addedTags; trackBy: trackByTagId" class="tag" @enterLeaveFadeAnimation>
            {{tag.Name}}
            <button class="btn quick standard-positive" (click)="handleTagRemove(tag)" matTooltip="Remove Tag">
                <svg-helper svgName="x"></svg-helper>
            </button>
        </li>
    </ul>
</div>

<alert-banner *ngIf="!addedTags.length && !bulkTagActionType">There are no Filter Tags assigned to this item. Assign tags by entering your term above.</alert-banner>