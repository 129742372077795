import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  OnChanges
} from '@angular/core';

@Component({
  selector: 'percentage-bar',
  templateUrl: './percentage-bar.component.html',
  styleUrls: ['./percentage-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PercentageBarComponent implements OnChanges {
  @Input() percentage = 0;
  @Input() color: string;

  constructor(private el: ElementRef) {}

  ngOnChanges(): void {
    if (this.color) {
      this.el.nativeElement.style.setProperty('--percentage-bar-color', this.color);
    } else {
      this.el.nativeElement.style.removeProperty('---percentage-bar-color');
    }
  }
}
